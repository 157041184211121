import { useState, useEffect } from 'react';
import { Space, Image } from 'antd';
import CustomText from '@/common/CustomText';
import tng1 from '../../images/Network/tng1.svg';
import tng2 from '../../images/Network/tng2.svg';
import tng3 from '../../images/Network/tng3.svg';
import tng4 from '../../images/Network/tng4.svg';
import tng5 from '../../images/Network/tng5.svg';
import SponsorModal from '../sponsorModal';
import AmbassadorsModal from '../ambassadorsModal';
import ChampionsModal from '../championsModal';
import AgentsModal from '../agentsModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSponsorAndCoSponsorList,
  getAmbassadorsLists,
  getChampionsLists,
  getAgentsLists
} from '../../redux/slice';

const Network = () => {
  const [sponsorModal, setSponsorModal] = useState(false);
  const [ambassadorsModal, setAmbassadorsModal] = useState(false);
  const [championsModal, setChampionsModal] = useState(false);
  const [agentsModal, setAgentsModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchSponsor, setSearchSponsor] = useState('');
  const [searchAmbassadors, setSearchAmbassadors] = useState('');
  const [searchChampions, setSearchChampions] = useState('');
  const [searchAgents, setSearchAgents] = useState('');

  const {
    ambassadorsListsLoading,
    ambassadorsLists,
    paginationAmbassadors,
    paginationSponsor,
    sponsorAndCoSponsorLoading,
    sponsorAndCoSponsorList,
    championsListsLoading,
    championsLists,
    paginationChampions,
    agentsListsLoading,
    agentsLists,
    paginationAgents
  } = useSelector((state) => state.networks);

  const dispatch = useDispatch();

  useEffect(() => {
    handleSponsorAndCoSponsorList();
  }, [page, searchSponsor]);
  useEffect(() => {
    handleAmbassadorsLists();
  }, [page, searchAmbassadors]);
  useEffect(() => {
    handleChampionsLists();
  }, [page, searchChampions]);
  useEffect(() => {
    handleAgentsLists();
  }, [page, searchAgents]);

  const handleSponsorAndCoSponsorList = () => {
    let payload = {
      page,
      per_page: pageSize,
      search: searchSponsor
    };
    dispatch(getSponsorAndCoSponsorList(payload));
  };
  const handleAmbassadorsLists = () => {
    let payload = {
      page,
      per_page: pageSize,
      search: searchAmbassadors
    };
    dispatch(getAmbassadorsLists(payload));
  };
  const handleChampionsLists = () => {
    let payload = {
      page,
      per_page: pageSize,
      search: searchChampions
    };
    dispatch(getChampionsLists(payload));
  };
  const handleAgentsLists = () => {
    let payload = {
      page,
      per_page: pageSize,
      search: searchAgents
    };
    dispatch(getAgentsLists(payload));
  };

  return (
    <>
      <Space direction="vertical" size={20}>
        <Space direction="vertical" size={8}>
          <CustomText
            font="regular"
            size={18}
            //color={1}
            style={{ color: '#667085', fontWeight: '600' }}
          >
            The Multi - tiered NEEV CAN Network
          </CustomText>
          <CustomText font="regular" size={16} style={{ color: '#667085' }}>
            “The Change Agent Network consists of cross-functional stakeholders
            across various Businesses and Functions to promote user readiness
            for SAP ERP implementation by acting as the “voice of the user”.
            These Ambassadors access readiness levels, recommend and implement
            opportunities to promote the sustainable adoption of new systems and
            ways of working”.
          </CustomText>
        </Space>
        <div>
          <Image
            loading="lazy"
            src={tng1}
            preview={false}
            // alt={`Image`}
            style={{
              width: '72%',
              height: '72%',
              marginLeft: '25%',
              cursor: 'pointer'
            }}
            onClick={() => setSponsorModal(true)}
          />

          <Image
            loading="lazy"
            // className={styles.tng1}
            src={tng2}
            preview={false}
            // alt={`Image`}
            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
            onClick={() => setAmbassadorsModal(true)}
          />
          <Image
            loading="lazy"
            // className={styles.tng1}
            src={tng3}
            preview={false}
            // alt={`Image`}
            style={{
              width: '100%',
              height: '100%',
              marginLeft: '8%',
              cursor: 'pointer'
            }}
            onClick={() => setChampionsModal(true)}
          />
          <Image
            loading="lazy"
            // className={styles.tng1}
            src={tng4}
            preview={false}
            // alt={`Image`}
            style={{
              width: '100%',
              height: '100%',
              marginLeft: '0%',
              cursor: 'pointer'
            }}
            onClick={() => setAgentsModal(true)}
          />
          <Image
            loading="lazy"
            // className={styles.tng1}
            src={tng5}
            preview={false}
            // alt={`Image`}
            style={{ width: '70%', height: '70%', marginLeft: '0%' }}
          />
        </div>
      </Space>
      {sponsorModal ? (
        <SponsorModal
          sponsorModal={sponsorModal}
          setSponsorModal={setSponsorModal}
          networkData={sponsorAndCoSponsorList} //{networkData}
          setSearchRequest={setSearchSponsor}
          loading={sponsorAndCoSponsorLoading}
          pagination={paginationSponsor}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        ''
      )}
      {ambassadorsModal ? (
        <AmbassadorsModal
          sponsorModal={ambassadorsModal}
          setSponsorModal={setAmbassadorsModal}
          networkData={ambassadorsLists} //{networkData}
          setSearchRequest={setSearchAmbassadors}
          loading={ambassadorsListsLoading}
          pagination={paginationAmbassadors}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        ''
      )}
      {championsModal ? (
        <ChampionsModal
          sponsorModal={championsModal}
          setSponsorModal={setChampionsModal}
          networkData={championsLists} //{networkData}
          setSearchRequest={setSearchChampions}
          loading={championsListsLoading}
          pagination={paginationChampions}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        ''
      )}
      {agentsModal ? (
        <AgentsModal
          sponsorModal={agentsModal}
          setSponsorModal={setAgentsModal}
          networkData={agentsLists} //{networkData}
          setSearchRequest={setSearchAgents}
          loading={agentsListsLoading}
          pagination={paginationAgents}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Network;
