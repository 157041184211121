import { useState, useEffect } from 'react';
import { Col, Divider, Row, Space, Image } from 'antd';

import styles from './mediaConsurSAC.module.less';
import SAPConcur from '../../images/banners/SAPConcur.mp4';
import SACVideo from '../../images/SAC Video.mp4';
import SAPConcurThumbnail from '../../images/Concur 1.png';
import SACThumbnail from '../../images/SAC 1.png';

import { sizeChecker } from 'utilities/helpers';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
//import CustomButton from '@/common/CustomButton';
//import ParentTab from './Demo';

import v1 from '../../images/travelVideos/v1.svg';
import v2 from '../../images/travelVideos/v2.svg';
import TrainingCalendarTable from '../../images/TrainingCalendarTable.png';

import { pdfjs } from 'react-pdf'; // Document,

import ProcessFlowsSAC from './ProcessFlowsSAC';
import UserSupportSPOCDetails from './UserSupportSPOCDetails';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { history } from '@/app/history';
//import { useLocation } from 'react-router-dom';
import FAQs from './FAQs';
import UserGuides from './UserGuides';
//import CustomButton from '@/common/CustomButton';

const titlesFinance = [
  { key: 0, title: 'Training Calender' }
  // { key: 1, title: 'Benefits for End Users' },
  // { key: 2, title: 'Training Calendar' },
  // { key: 3, title: 'FAQs' },
  // { key: 4, title: 'User Support & SPOC Details' }
];
const titlesSCM = [
  { key: 0, title: 'Training Calender' }
  // { key: 1, title: 'Benefits for End Users' },
  // { key: 2, title: 'Training Calendar' },
  // { key: 3, title: 'FAQs' },
  // { key: 4, title: 'User Support & SPOC Details' }
];
const titlesProjectSystem = [
  { key: 0, title: 'Training Calender' }
  // { key: 1, title: 'Benefits for End Users' },
  // { key: 2, title: 'Training Calendar' },
  // { key: 3, title: 'FAQs' },
  // { key: 4, title: 'User Support & SPOC Details' }
];
const titlesFleetResouces = [
  { key: 0, title: 'Training Calender' }
  // { key: 1, title: 'Benefits for End Users' },
  // { key: 2, title: 'Training Calendar' },
  // { key: 3, title: 'FAQs' },
  // { key: 4, title: 'User Support & SPOC Details' }
];
const titlesQualityTMU = [
  { key: 0, title: 'Training Calender' }
  // { key: 1, title: 'Benefits for End Users' },
  // { key: 2, title: 'Training Calendar' },
  // { key: 3, title: 'FAQs' },
  // { key: 4, title: 'User Support & SPOC Details' }
];

const titles = [
  { key: 0, title: 'Overview & Concur Video' },
  { key: 1, title: 'Benefits for End Users' },
  { key: 2, title: 'Training Calendar' },
  {
    key: 3,
    title: 'E-Learning Tool Kits',
    children: ['User Guides', 'System Navigation Videos']
  },
  { key: 4, title: 'FAQs' },
  { key: 5, title: 'User Support & SPOC Details' }
];
const titlesSAC = [
  { key: 0, title: 'Overview & SAC Video' },
  { key: 1, title: 'Benefits for End Users' },
  { key: 2, title: 'Process Flows' },
  // { key: 3, title: 'E-Learning Tool Kits' },
  // { key: 4, title: 'Training Calendar' },
  // { key: 5, title: 'FAQs' },
  { key: 6, title: 'User Support & SPOC Details' }
];
const BenefitsForUsers = [
  {
    key: 1,
    title: 'Benefits & Features of SAP Concur - Travel',
    headerBackground: '#42A6E1',
    children: [
      {
        id: 1,
        mainText: 'Your SAP Concur profile',
        subText: 'One time profile update with your preferences'
      },
      {
        id: 2,
        mainText: 'Expedite Booking',
        subText: 'Faster end to end booking'
      },
      {
        id: 3,
        mainText: 'Self Service Portal',
        subText: 'Book through host of flight & hotel options yourself'
      },
      {
        id: 4,
        mainText: 'Projects control on spend',
        subText: 'RCM / PMs empowered to approve site travel requests'
      },
      {
        id: 5,
        mainText: 'Manage your travel plans',
        subText: 'Modify your plans in an agile manner'
      }
    ]
  },
  {
    key: 2,
    title: 'Benefits & Features of SAP Concur - Expense',
    headerBackground: '#40D4E2',
    children: [
      {
        id: 1,
        mainText: 'Expense Categorization',
        subText: 'Categorize expenses for easy tracking'
      },
      {
        id: 2,
        mainText: 'Enable e-Receipts',
        subText: 'Directly upload e-receipts through the mobile app'
      },
      {
        id: 3,
        mainText: 'On-the-go Approvals',
        subText: 'Approval of expense reports through the mobile app'
      },
      {
        id: 4,
        mainText: 'Empowered Finance Processing',
        subText:
          'High visibility on accurate spend, documentation and accounting'
      }
    ]
  },
  {
    key: 3,
    title: 'Benefits & Features of SAP Concur - Site Imprest',
    headerBackground: '#43D1A1',
    children: [
      {
        id: 1,
        mainText: 'Advanced Visibility and Tracking',
        subText: (
          <>
            <div>• Invoice itemization</div>

            <div> • GST breakup available for tax calculation</div>
          </>
        )
      },
      {
        id: 2,
        mainText: 'Efficient documentation',
        subText:
          '• Automatic capture of vendor invoice number on upload of receipt'
      },
      {
        id: 3,
        mainText: 'Streamlined Project Management',
        subText: (
          <>
            <div>• Dynamic project and task code selection</div>

            <div> • Visibility of outstanding advance balance</div>
          </>
        )
      },
      {
        id: 4,
        mainText: 'Enhanced policy adherences',
        subText: (
          <>
            <div>{`• System flag for single cash transactions > 10k`}</div>

            <div>{`• Advance + Replenishment = Rs. 1 lac`}</div>
          </>
        )
      }
    ]
  }
];

const SAP_SAC_Benefits = [
  {
    id: 1,
    title: '• Single Integrated Platform with Automated process'
  },
  {
    id: 2,
    title: '• Clear governance structure with role based user access'
  },
  {
    id: 3,
    title: '• Auto consolidation'
  },
  {
    id: 4,
    title: '• Management review of SAC platform'
  },
  {
    id: 5,
    title: '• Better visibility of KPIs'
  },
  {
    id: 6,
    title: '• Reduced forecast and consolidated time'
  },
  {
    id: 7,
    title: '• System driven reports and dashboards'
  }
];

const TravelVideos = [
  {
    id: 1,
    video: null,
    thumbnailUrl: v1,
    title: 'Login and Navigate through SAP Concur',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Log%20in%20and%20Navigate%20SAP%20Concur_System%20Video.mp4'
  },
  {
    id: 2,
    video: null,
    thumbnailUrl: v2,
    title: 'Set up your Profile',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Set%20up%20Your%20Profile%20in%20SAP%20Concur_System%20Video.mp4'
  },
  {
    id: 3,
    video: null,
    thumbnailUrl: v2,
    title: 'Raise a Travel Request Form',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Create%20a%20Travel%20Request.mp4'
  },
  {
    id: 4,
    video: null,
    thumbnailUrl: v2,
    title: 'Make a Travel Self-Booking',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Make%20a%20Self%20Travel%20Booking_System%20Video.mp4'
  },
  {
    id: 5,
    video: null,
    thumbnailUrl: v2,
    title: 'Approve or Reject a Travel Request and Expense Claims',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Approve%20or%20Reject%20a%20Travel%20or%20Expense%20Request_System%20video.mp4'
  },
  {
    id: 6,
    video: null,
    thumbnailUrl: v2,
    title: 'Modify or Cancel Booked Travel Request',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Modify%20or%20Cancel%20a%20Booked%20Travel%20Request_System%20video.mp4'
  }
];
const ExpenseVideos = [
  {
    id: 1,
    video: null,
    thumbnailUrl: v1,
    title: 'Create a Travel Expense Report',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Create%20a%20Travel%20Expense%20Report_System%20video.mp4'
  },
  {
    id: 2,
    video: null,
    thumbnailUrl: v1,
    title: 'Create a Non-Travel Expense Report',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Create%20a%20Non-Travel%20Expense%20Report_System%20video.mp4'
  }
];
const SiteImprestVideos = [
  {
    id: 1,
    video: null,
    thumbnailUrl: v1,
    title: 'Request Cash Advance',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Site%20Imprest/Request%20for%20Cash%20Advance%20for%20Site%20Imprest%20Expenses_System%20Video.mp4'
  },
  {
    id: 2,
    video: null,
    thumbnailUrl: v1,
    title: 'Claim an Expense',
    designation: '',
    link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Site%20Imprest/Claim%20Site%20Imprest%20Expenses_System%20Video.mp4'
  }
];

const MediaConsurSACNew = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = sizeChecker('xs');
  //const location = useLocation();

  const [sideTab, setSideTab] = useState('Cutover');
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedChild, setSelectedChild] = useState(0);

  // Update URL parameters whenever selectedTab or selectedChild changes
  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedTab) {
      params.set('', selectedTab.toString());
    }
    if (selectedChild) {
      params.set('sub', selectedChild);
    } else {
      // Remove sub parameter from URL if it is null
      params.delete('sub');
    }
    // console.log('PARAM', params.toString());
    // console.log('SAP', sideTab);
    // console.log('selectedTab', selectedTab);
    // console.log('selectedChild', selectedChild);
    history.push({
      search:
        sideTab === 'Cutover'
          ? selectedTab === 1
            ? '/Cutover'
            : params.toString()
          : sideTab === 'Finance'
          ? selectedTab === 1
            ? '/Finance-TrainingCalender'
            : params.toString()
          : sideTab === 'SCM'
          ? selectedTab === 1
            ? '/SCM-TrainingCalender'
            : params.toString()
          : sideTab === 'ProjectSystem'
          ? selectedTab === 1
            ? '/ProjectSystem-TrainingCalender'
            : params.toString()
          : sideTab === 'FleetResources'
          ? selectedTab === 1
            ? '/FleetResouces-TrainingCalender'
            : params.toString()
          : sideTab === 'QualityTMU'
          ? selectedTab === 1
            ? '/QualityTMU-TrainingCalender'
            : params.toString()
          : sideTab === 'SAP'
          ? selectedTab === 1
            ? '/Overview'
            : selectedTab === 2
            ? '/Benefits'
            : selectedTab === 3
            ? '/Calendar'
            : selectedTab === 4 && selectedChild === 'User Guides'
            ? '/Tool-Kits/User-Guides'
            : selectedTab === 4 && selectedChild === 'System Navigation Videos'
            ? '/Tool-Kits/System-Navigation-Videos'
            : selectedTab === 5
            ? '/Questions'
            : selectedTab === 6
            ? '/User-Support'
            : params.toString()
          : sideTab === 'SAC'
          ? selectedTab === 1
            ? '/Overview-SAC-Video'
            : selectedTab === 2
            ? '/Benefits-for-End-Users'
            : selectedTab === 3
            ? '/Process-Flows'
            : // : selectedTab === 4
            // ? '/E-Learning-Tool-Kits'
            // : selectedTab === 5
            // ? '/Training-Calendar'
            // : selectedTab === 6
            // ? '/FAQs'
            selectedTab === 4
            ? '/User-Support-SPOC-Details'
            : params.toString()
          : null
    });
  }, [selectedTab, selectedChild, sideTab]);

  const onClickDecider = (index, child = null) => {
    // If child is provided, update selectedChild and do not change selectedTab

    if (child !== null) {
      setSelectedChild(child);
      return;
    }

    // If no child is provided, set selectedChild to the first child of the tab
    const tab = titles[index - 1];
    if (tab && tab.children && tab.children.length > 0) {
      setSelectedChild(tab.children[0]);
    } else {
      // If the title does not have children, set selectedChild to null
      setSelectedChild(null);
    }

    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // setSelectedChild(null); // Reset selectedChild when parent is clicked
        setSelectedTab(index);
        break;

      // Handle other cases if needed
      default:
        break;
    }
  };

  // console.log('Clicked:', selectedTab, selectedChild);
  //console.log('Selected Child:', selectedChild);

  const onClickSideTab = (value) => {
    //console.log('Value:', value);
    setSelectedTab(1);
    value === 'Cutover'
      ? setSideTab('Cutover')
      : value === 'Finance'
      ? setSideTab('Finance')
      : value === 'SCM'
      ? setSideTab('SCM')
      : value === 'ProjectSystem'
      ? setSideTab('ProjectSystem')
      : value === 'FleetResources'
      ? setSideTab('FleetResources')
      : value === 'QualityTMU'
      ? setSideTab('QualityTMU')
      : value === 'SAP'
      ? setSideTab('SAP')
      : setSideTab('SAC');
  };
  //console.log('SIDETAB', sideTab);

  const newsletterContent = () => {
    if (sideTab === 'Cutover') {
      switch (selectedTab) {
        case 1:
          return (
            <>
              {/*  <ParentTab /> */}
              <Space direction="vertical" size={10}>
                <CustomText size={18} className={styles.training_cal_style}>
                  CutOver
                </CustomText>
              </Space>
            </>
          );
        default:
          return window?.scrollTo(0, document.body.scrollHeight);
      }
    } else if (sideTab === 'Finance') {
      switch (selectedTab) {
        case 1:
          return (
            <>
              {/*  <ParentTab /> */}
              <Space direction="vertical" size={10}>
                <CustomText size={18} className={styles.training_cal_style}>
                  Finance Training Calender
                </CustomText>
              </Space>
            </>
          );
        default:
          return window?.scrollTo(0, document.body.scrollHeight);
      }
    } else if (sideTab === 'SCM') {
      switch (selectedTab) {
        case 1:
          return (
            <>
              {/*  <ParentTab /> */}
              <Space direction="vertical" size={10}>
                <CustomText size={18} className={styles.training_cal_style}>
                  SCM Training Calendar
                </CustomText>
              </Space>
            </>
          );
        default:
          return window?.scrollTo(0, document.body.scrollHeight);
      }
    } else if (sideTab === 'ProjectSystem') {
      switch (selectedTab) {
        case 1:
          return (
            <>
              {/*  <ParentTab /> */}
              <Space direction="vertical" size={10}>
                <CustomText size={18} className={styles.training_cal_style}>
                  Project Systems Training Calendar
                </CustomText>
              </Space>
            </>
          );
        default:
          return window?.scrollTo(0, document.body.scrollHeight);
      }
    } else if (sideTab === 'FleetResources') {
      switch (selectedTab) {
        case 1:
          return (
            <>
              {/*  <ParentTab /> */}
              <Space direction="vertical" size={10}>
                <CustomText size={18} className={styles.training_cal_style}>
                  Fleet & Resources Training Calendar
                </CustomText>
              </Space>
            </>
          );
        default:
          return window?.scrollTo(0, document.body.scrollHeight);
      }
    } else if (sideTab === 'QualityTMU') {
      switch (selectedTab) {
        case 1:
          return (
            <>
              {/*  <ParentTab /> */}
              <Space direction="vertical" size={10}>
                <CustomText size={18} className={styles.training_cal_style}>
                  Quality and TMU Training Calendar
                </CustomText>
              </Space>
            </>
          );
        default:
          return window?.scrollTo(0, document.body.scrollHeight);
      }
    } else if (sideTab === 'SAP') {
      switch (selectedTab) {
        case 1:
          return (
            <>
              {/*  <ParentTab /> */}
              <Space direction="vertical" size={10}>
                <CustomText size={18} className={styles.training_cal_style}>
                  Embrace efficiency through seamless travels and expense
                  approvals, as Project NEEV integrates SAP Concur, the
                  undisputed leader in travel and expense technology. This
                  cutting-edge solution not only simplifies the entire travel
                  and expense management process but also enhances visibility
                  and accessibility.
                </CustomText>

                <CustomText size={18} className={styles.training_cal_style}>
                  Experience a transformative journey with <b>Concur</b>,
                  ensuring a seamless and intuitive approach to <b>Travel</b>{' '}
                  and
                  <b> Expenses</b> across TPL!
                </CustomText>
                <CustomText size={16} font="bold">
                  Click the play button below to view a 3-minute video which
                  gives an overview on SAP Concur.
                </CustomText>
              </Space>
              <div style={{ marginTop: '20px' }}>
                <video
                  poster={SAPConcurThumbnail}
                  className={styles.custom_video}
                  controls={true}
                  src={SAPConcur}
                  width="100%"
                  height="100%"
                />
              </div>
            </>
          );
        case 2:
          return (
            <div>
              {BenefitsForUsers?.map((item) => {
                const { id, title, headerBackground, children } = item;
                return (
                  <Space
                    key={id}
                    direction="vertical"
                    size={10}
                    style={{ width: '100%' }}
                  >
                    {/* <Space
                      direction="vertical"
                      size={10}
                      className={styles.benefits_users_container}
                    >
                      <CustomText
                        font="bold"
                        size={30}
                        style={{ color: '#323282' }}
                      >
                        {title}
                      </CustomText>
                    </Space> */}
                    <div className={styles.benefits_table}>
                      {/* Header row with background color */}
                      <div
                        className={`${styles.table_row} ${styles.header_row}`}
                        style={{ backgroundColor: headerBackground }}
                      >
                        <div className={styles.table_header}>
                          <CustomText
                            font="bold"
                            size={24}
                            style={{ color: 'white' }}
                          >
                            {title}
                          </CustomText>
                        </div>
                      </div>

                      {/* Body rows */}
                      {children.map((item) => (
                        <Row className={styles.table_row} key={item.id}>
                          <Col
                            span={12}
                            className={`${styles.table_cell} ${styles.main_text}`}
                            style={{ fontWeight: 'bold' }}
                          >
                            {item.mainText}
                          </Col>
                          <Col
                            span={12}
                            className={`${styles.table_cell} ${styles.main_text}`}
                          >
                            {item.subText}
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Space>
                );
              })}
            </div>
          );
        case 3:
          return (
            <>
              <Space direction="vertical" size={14}>
                <CustomText size={18} className={styles.training_cal_style}>
                  We have initiated our SAP journey with the successful go-live
                  of the first two modules on January 1st: SAP Concur and SAP
                  SAC (AOP).
                </CustomText>

                <CustomText size={18} className={styles.training_cal_style}>
                  In the last week of December, we conducted Concur Learning
                  Week, featuring six informative webinars on SAP Concur Travel
                  & Expense and Site Imprest.
                </CustomText>
                <CustomText size={18} className={styles.training_cal_style}>
                  Looking ahead, Refresher trainings are scheduled for the first
                  week of January. You can choose a convenient slot and attend
                  the training. The calendar is outlined below:
                </CustomText>
                {/*  <ul className={styles.training_cal_style}>
                  <li> SAP Concur Travel & Expenses Module </li>
                  <li> SAP Concur Site Imprest Module.</li>
                </ul>

                <CustomText size={18} className={styles.training_cal_style}>
                  You can choose the time slots the best suits your schedule and
                  availability. Calendar invites will follow,
                </CustomText>
                <CustomText size={18} className={styles.training_cal_style}>
                  Its mandatory to attend the webinars. These sessions will
                  provide you with an opportunity to understand SAP Concur
                  better anf get you queries resolved immediately.
                </CustomText>
                <CustomText size={18} className={styles.training_cal_style}>
                  More details coming soon.
                </CustomText> */}
                <Image
                  loading="lazy"
                  className={styles.galleryImages}
                  src={TrainingCalendarTable}
                  preview={false}
                  // alt={`Image`}
                />
              </Space>
            </>
          );
        case 4:
          if (selectedChild === 'User Guides') {
            return (
              <>
                <UserGuides />
              </>
            );
          } else if (selectedChild === 'System Navigation Videos') {
            return (
              <>
                <Space direction="vertical" size={60}>
                  <div>
                    <Space
                      direction="vertical"
                      size={0}
                      className={styles.benefits_users_container}
                    >
                      <CustomText
                        font="bold"
                        size={30}
                        style={{ color: '#323282' }}
                      >
                        Travel Demo Videos
                      </CustomText>
                    </Space>
                    <Row gutter={[24, 30]}>
                      {TravelVideos?.map((item) => (
                        <Col key={item.id} xl={8}>
                          <a
                            href={item?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <video
                              poster={item.thumbnailUrl}
                              className={styles.custom_video}
                              controls={true}
                              src={item.video}
                              width="100%"
                              height="100%"
                            />
                          </a>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              textAlign: 'center'
                            }}
                          >
                            <CustomText font="bold" size={12} color={1}>
                              {item.title}
                            </CustomText>
                            <CustomText font="bold" size={10} color={1}>
                              {item.designation}
                            </CustomText>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div>
                    <Space
                      direction="vertical"
                      size={10}
                      className={styles.benefits_users_container}
                    >
                      <CustomText
                        font="bold"
                        size={30}
                        style={{ color: '#323282' }}
                      >
                        Expense Demo Videos
                      </CustomText>
                    </Space>
                    <Row className="mt-3" gutter={[24, 48]}>
                      {ExpenseVideos?.map((item) => (
                        <Col key={item.id} xl={12}>
                          <a
                            href={item?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <video
                              poster={item.thumbnailUrl}
                              className={styles.custom_video}
                              controls={true}
                              src={item.video}
                              width="100%"
                              height="100%"
                            />
                          </a>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              textAlign: 'center'
                            }}
                          >
                            <CustomText font="bold" size={12} color={1}>
                              {item.title}
                            </CustomText>
                            <CustomText font="bold" size={10} color={1}>
                              {item.designation}
                            </CustomText>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div>
                    <Space
                      direction="vertical"
                      size={10}
                      className={styles.benefits_users_container}
                    >
                      <CustomText
                        font="bold"
                        size={30}
                        style={{ color: '#323282' }}
                      >
                        Site Imprest Demo Videos
                      </CustomText>
                    </Space>
                    <Row className="mt-3" gutter={[24, 48]}>
                      {SiteImprestVideos?.map((item) => (
                        <Col key={item.id} xl={12}>
                          <a
                            href={item?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <video
                              poster={item.thumbnailUrl}
                              className={styles.custom_video}
                              controls={true}
                              src={item.video}
                              width="100%"
                              height="100%"
                            />
                          </a>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              textAlign: 'center'
                            }}
                          >
                            <CustomText font="bold" size={12} color={1}>
                              {item.title}
                            </CustomText>
                            <CustomText font="bold" size={10} color={1}>
                              {item.designation}
                            </CustomText>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Space>
              </>
            );
          }
          break;
        case 5:
          return (
            <>
              <FAQs />
            </>
          );
        case 6:
          return (
            <Space direction="vertical" size={20}>
              <Space direction="vertical" size={14}>
                <CustomText size={18} className={styles.training_cal_style}>
                  SAP Concur is our new Travel, Expense and Site Imprest tool.
                  It went live on 1st January 2024. To ensure you get the
                  adequate support, help and resolution on SAP Concur, we have
                  put together a robust Hypercare Support Model for you. Feel
                  free to reach out to our Hypercare Team and SPOCs with any
                  questions or concerns.
                </CustomText>
              </Space>
              <table
                style={{ width: '100%', borderCollapse: 'collapse' }}
                className={styles.table_style1}
              >
                <tr>
                  <th className={styles.tableHeader_style1}>
                    SAP Concur Module
                  </th>
                  <th className={styles.tableHeader_style2}>Audience</th>
                  <th className={styles.tableHeader_style3}>
                    Level 1 – Secondary (Type of Query: Functional)
                  </th>
                </tr>
                <tr>
                  <td rowSpan="2" className={styles.tableHeader_style6}>
                    Travel
                  </td>
                  <td className={styles.tableHeader_style6}>
                    TPL Corporate Offices
                  </td>
                  <td className={styles.tableHeader_style6}>
                    <div>• Travel Helpdesk - 022 - 66255600</div>
                    <div>
                      <a
                        href="https://login.live.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#008CD2',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 500,
                          marginLeft: '10px'
                        }}
                      >
                        traveldeskconcur@tataprojects.com
                      </a>
                    </div>
                    <div> • Mumbai – Jeethesh Karunakaran</div>{' '}
                    <div>
                      <a
                        href="https://login.live.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#008CD2',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 500,
                          marginLeft: '10px'
                        }}
                      >
                        (jeetheshk@tataprojects.com){' '}
                      </a>
                    </div>{' '}
                    <div>• Noida – Neeraj Kumar Sinha</div>{' '}
                    <div>
                      <a
                        href="https://login.live.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#008CD2',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 500,
                          marginLeft: '10px'
                        }}
                      >
                        (neerajkumarsinha@tataprojects.com)
                      </a>
                    </div>
                    <div> • Hyderabad – Sunder Rajan</div>
                    <div>
                      <a
                        href="https://login.live.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#008CD2',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 500,
                          marginLeft: '10px'
                        }}
                      >
                        {' '}
                        (rajan@tataprojects.com)
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tableHeader_style6}>
                    TPL Project Sites
                  </td>
                  <td className={styles.tableHeader_style6}>
                    <div>• Travel Helpdesk - 022 - 66255600</div>
                    <div>
                      <a
                        href="https://login.live.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#008CD2',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 500,
                          marginLeft: '10px'
                        }}
                      >
                        traveldeskconcur@tataprojects.com
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td rowSpan="2" className={styles.tableHeader_style6}>
                    Expenses
                  </td>
                  <td className={styles.tableHeader_style6}>
                    TPL Corporate Offices
                  </td>
                  <td rowSpan="2" className={styles.tableHeader_style6}>
                    <div> • IT Service Desk - 022 - 68473025/26/31/33/34</div>
                    <div>
                      {' '}
                      <a
                        href="https://servicedesk@tataprojects.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#008CD2',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 500,
                          marginLeft: '10px'
                        }}
                      >
                        https://servicedesk@tataprojects.com/
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={styles.tableHeader_style6}>
                    TPL Project Sites
                  </td>
                </tr>
                <tr>
                  <td className={styles.tableHeader_style6}>Imprest</td>
                  <td className={styles.tableHeader_style6}>Site HR</td>
                  <td className={styles.tableHeader_style6}>
                    <div>• IT Service Desk - 022 - 68473025/26/31/33/34</div>
                    <div>
                      <a
                        href="https://login.live.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: '#008CD2',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 500,
                          marginLeft: '10px'
                        }}
                      >
                        {' '}
                        traveldeskconcur@tataprojects.com
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </Space>
          );

        default:
          return window?.scrollTo(0, document.body.scrollHeight);
      }
    } else if (sideTab === 'SAC') {
      switch (selectedTab) {
        case 1: // Check for selectedChild instead of selectedTab
          return (
            <>
              <Space direction="vertical" size={14}>
                <CustomText size={18} className={styles.training_cal_style}>
                  Embark on a digital evolution as we revolutionize the Annual
                  Operating Plan (AOP) preparation through SAP Analytics Cloud
                  (SAC). Recognized as a visionary in analytics and business
                  intelligence platforms by Gartner, SAC introduces an
                  integrated platform for planning and reporting. This
                  transformative solution simplifies access and consolidation of
                  AOP and reports across Project, SBU, SBG, Functions, and
                  Corporate levels. Empowering project managers to create and
                  validate AOP, SAC becomes the catalyst for enhanced
                  accountability and transparency throughout TPL.
                </CustomText>
                <CustomText size={16} font="bold">
                  Watch the video below of SAP Analytics Cloud (SAC).
                </CustomText>
              </Space>
              <div style={{ marginTop: '20px' }}>
                <video
                  poster={SACThumbnail}
                  className={styles.custom_video}
                  controls={true}
                  src={SACVideo}
                  width="100%"
                  height="100%"
                />
              </div>
            </>
          );
        case 2:
          return (
            <div>
              <Space direction="vertical" size={10} style={{ width: '100%' }}>
                <Space
                  direction="vertical"
                  size={10}
                  // className={styles.benefits_users_container}
                >
                  <CustomText
                    font="bold"
                    size={30}
                    style={{ color: '#323282' }}
                  >
                    SAP SAC - Benefits
                  </CustomText>
                </Space>
                <Space direction="vertical" size={10}>
                  {SAP_SAC_Benefits?.map((item) => (
                    <div key={item?.id}>
                      <CustomText size={18} style={{ color: '#344054' }}>
                        {item?.title}
                      </CustomText>
                    </div>
                  ))}
                </Space>
              </Space>
            </div>
          );
        case 3:
          return (
            <>
              <ProcessFlowsSAC />
              {/* <Space direction="vertical" size={10}>
                <CustomText size={18} color={31}>
                  Coming soon...
                </CustomText>
              </Space> */}
            </>
          );
        // case 4:
        //   return (
        //     <>
        //       <Space direction="vertical" size={10}>
        //         <CustomText size={18} color={31}>
        //           Coming soon...
        //         </CustomText>
        //       </Space>
        //     </>
        //   );
        // case 5:
        //   return (
        //     <>
        //       <Space direction="vertical" size={10}>
        //         <CustomText size={18} color={31}>
        //           Coming soon...
        //         </CustomText>
        //       </Space>
        //     </>
        //   );
        // case 6:
        //   return (
        //     <>
        //       <Space direction="vertical" size={10}>
        //         <CustomText size={18} color={31}>
        //           Coming soon...
        //         </CustomText>
        //       </Space>
        //     </>
        //   );
        case 4:
          return (
            <>
              <UserSupportSPOCDetails />
              {/* <Space direction="vertical" size={10}>
                <CustomText size={18} color={31}>
                  Coming soon...
                </CustomText>
              </Space> */}
            </>
          );
        default:
          return window?.scrollTo(0, document.body.scrollHeight);
      }
    }
  };

  return (
    <div className={styles.container}>
      <CustomContainer containerClassname={styles.newsletter_container}>
        <Row gutter={[64, 30]}>
          <Col xs={24} sm={6} className={styles.remove_margin}>
            <Space direction="vertical" size={8}>
              <Space direction="vertical" size={2}>
                <CustomText
                  font="medium"
                  size={16}
                  color={sideTab === 'Cutover' ? 'secondary' : '#444444'}
                  className={`cursor-pointer mb-4 ${styles.left_text}${
                    sideTab === 'Cutover' ? ` ${styles.selected}` : ''
                  }`}
                  onClick={() => onClickSideTab('Cutover')}
                >
                  Cutover
                </CustomText>

                <CustomText
                  font="medium"
                  size={16}
                  color={sideTab === 'Finance' ? 'secondary' : '#444444'}
                  className={`cursor-pointer mb-4 ${styles.left_text}${
                    sideTab === 'Finance' ? ` ${styles.selected}` : ''
                  }`}
                  onClick={() => onClickSideTab('Finance')}
                >
                  Finance
                </CustomText>
                <Space
                  direction="vertical"
                  size={0}
                  style={{ marginLeft: '20px' }}
                >
                  {sideTab === 'Finance'
                    ? titlesFinance?.map((item, index) => (
                        <div key={index}>
                          <CustomText
                            font="medium"
                            size={16}
                            color={selectedTab === index + 1 ? 'secondary' : 1}
                            className={`cursor-pointer mb-4 ${
                              styles.left_text
                            }${
                              selectedTab === index + 1
                                ? ` ${styles.selected}`
                                : ''
                            }`}
                            onClick={() => onClickDecider(index + 1)}
                          >
                            {item.title}
                            <span style={{ fontSize: '14px' }}>
                              {item?.subTitle}
                            </span>
                          </CustomText>
                          {selectedTab === index + 1 && (
                            <div
                              style={{ marginLeft: '20px', marginTop: '5px' }}
                            >
                              {item?.children?.map((child, childIndex) => (
                                <CustomText
                                  key={childIndex}
                                  font="medium"
                                  size={13}
                                  color={
                                    selectedChild === child ? 'secondary' : 1
                                  }
                                  className={`cursor-pointer mb-3 ${
                                    styles.left_text
                                  }${
                                    selectedChild === child
                                      ? ` ${styles.selected}`
                                      : ''
                                  }`}
                                  onClick={() =>
                                    onClickDecider(index + 1, child)
                                  }
                                >
                                  {child}
                                  <br />
                                </CustomText>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                </Space>

                <CustomText
                  font="medium"
                  size={16}
                  color={sideTab === 'SCM' ? 'secondary' : '#444444'}
                  className={`cursor-pointer mb-4 ${styles.left_text}${
                    sideTab === 'SCM' ? ` ${styles.selected}` : ''
                  }`}
                  onClick={() => onClickSideTab('SCM')}
                >
                  Supply Chain Management
                </CustomText>
                <Space
                  direction="vertical"
                  size={0}
                  style={{ marginLeft: '20px' }}
                >
                  {sideTab === 'SCM'
                    ? titlesSCM?.map((item, index) => (
                        <div key={index}>
                          <CustomText
                            font="medium"
                            size={16}
                            color={selectedTab === index + 1 ? 'secondary' : 1}
                            className={`cursor-pointer mb-4 ${
                              styles.left_text
                            }${
                              selectedTab === index + 1
                                ? ` ${styles.selected}`
                                : ''
                            }`}
                            onClick={() => onClickDecider(index + 1)}
                          >
                            {item.title}
                            <span style={{ fontSize: '14px' }}>
                              {item?.subTitle}
                            </span>
                          </CustomText>
                          {selectedTab === index + 1 && (
                            <div
                              style={{ marginLeft: '20px', marginTop: '5px' }}
                            >
                              {item?.children?.map((child, childIndex) => (
                                <CustomText
                                  key={childIndex}
                                  font="medium"
                                  size={13}
                                  color={
                                    selectedChild === child ? 'secondary' : 1
                                  }
                                  className={`cursor-pointer mb-3 ${
                                    styles.left_text
                                  }${
                                    selectedChild === child
                                      ? ` ${styles.selected}`
                                      : ''
                                  }`}
                                  onClick={() =>
                                    onClickDecider(index + 1, child)
                                  }
                                >
                                  {child}
                                  <br />
                                </CustomText>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                </Space>

                <CustomText
                  font="medium"
                  size={16}
                  color={sideTab === 'ProjectSystem' ? 'secondary' : '#444444'}
                  className={`cursor-pointer mb-4 ${styles.left_text}${
                    sideTab === 'ProjectSystem' ? ` ${styles.selected}` : ''
                  }`}
                  onClick={() => onClickSideTab('ProjectSystem')}
                >
                  Project System
                </CustomText>
                <Space
                  direction="vertical"
                  size={0}
                  style={{ marginLeft: '20px' }}
                >
                  {sideTab === 'ProjectSystem'
                    ? titlesProjectSystem?.map((item, index) => (
                        <div key={index}>
                          <CustomText
                            font="medium"
                            size={16}
                            color={selectedTab === index + 1 ? 'secondary' : 1}
                            className={`cursor-pointer mb-4 ${
                              styles.left_text
                            }${
                              selectedTab === index + 1
                                ? ` ${styles.selected}`
                                : ''
                            }`}
                            onClick={() => onClickDecider(index + 1)}
                          >
                            {item.title}
                            <span style={{ fontSize: '14px' }}>
                              {item?.subTitle}
                            </span>
                          </CustomText>
                          {selectedTab === index + 1 && (
                            <div
                              style={{ marginLeft: '20px', marginTop: '5px' }}
                            >
                              {item?.children?.map((child, childIndex) => (
                                <CustomText
                                  key={childIndex}
                                  font="medium"
                                  size={13}
                                  color={
                                    selectedChild === child ? 'secondary' : 1
                                  }
                                  className={`cursor-pointer mb-3 ${
                                    styles.left_text
                                  }${
                                    selectedChild === child
                                      ? ` ${styles.selected}`
                                      : ''
                                  }`}
                                  onClick={() =>
                                    onClickDecider(index + 1, child)
                                  }
                                >
                                  {child}
                                  <br />
                                </CustomText>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                </Space>

                <CustomText
                  font="medium"
                  size={16}
                  color={sideTab === 'FleetResources' ? 'secondary' : '#444444'}
                  className={`cursor-pointer mb-4 ${styles.left_text}${
                    sideTab === 'FleetResources' ? ` ${styles.selected}` : ''
                  }`}
                  onClick={() => onClickSideTab('FleetResources')}
                >
                  Fleet & Resources
                </CustomText>
                <Space
                  direction="vertical"
                  size={0}
                  style={{ marginLeft: '20px' }}
                >
                  {sideTab === 'FleetResources'
                    ? titlesFleetResouces?.map((item, index) => (
                        <div key={index}>
                          <CustomText
                            font="medium"
                            size={16}
                            color={selectedTab === index + 1 ? 'secondary' : 1}
                            className={`cursor-pointer mb-4 ${
                              styles.left_text
                            }${
                              selectedTab === index + 1
                                ? ` ${styles.selected}`
                                : ''
                            }`}
                            onClick={() => onClickDecider(index + 1)}
                          >
                            {item.title}
                            <span style={{ fontSize: '14px' }}>
                              {item?.subTitle}
                            </span>
                          </CustomText>
                          {selectedTab === index + 1 && (
                            <div
                              style={{ marginLeft: '20px', marginTop: '5px' }}
                            >
                              {item?.children?.map((child, childIndex) => (
                                <CustomText
                                  key={childIndex}
                                  font="medium"
                                  size={13}
                                  color={
                                    selectedChild === child ? 'secondary' : 1
                                  }
                                  className={`cursor-pointer mb-3 ${
                                    styles.left_text
                                  }${
                                    selectedChild === child
                                      ? ` ${styles.selected}`
                                      : ''
                                  }`}
                                  onClick={() =>
                                    onClickDecider(index + 1, child)
                                  }
                                >
                                  {child}
                                  <br />
                                </CustomText>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                </Space>

                <CustomText
                  font="medium"
                  size={16}
                  color={sideTab === 'QualityTMU' ? 'secondary' : '#444444'}
                  className={`cursor-pointer mb-4 ${styles.left_text}${
                    sideTab === 'QualityTMU' ? ` ${styles.selected}` : ''
                  }`}
                  onClick={() => onClickSideTab('QualityTMU')}
                >
                  Quality and TMU
                </CustomText>
                <Space
                  direction="vertical"
                  size={0}
                  style={{ marginLeft: '20px' }}
                >
                  {sideTab === 'QualityTMU'
                    ? titlesQualityTMU?.map((item, index) => (
                        <div key={index}>
                          <CustomText
                            font="medium"
                            size={16}
                            color={selectedTab === index + 1 ? 'secondary' : 1}
                            className={`cursor-pointer mb-4 ${
                              styles.left_text
                            }${
                              selectedTab === index + 1
                                ? ` ${styles.selected}`
                                : ''
                            }`}
                            onClick={() => onClickDecider(index + 1)}
                          >
                            {item.title}
                            <span style={{ fontSize: '14px' }}>
                              {item?.subTitle}
                            </span>
                          </CustomText>
                          {selectedTab === index + 1 && (
                            <div
                              style={{ marginLeft: '20px', marginTop: '5px' }}
                            >
                              {item?.children?.map((child, childIndex) => (
                                <CustomText
                                  key={childIndex}
                                  font="medium"
                                  size={13}
                                  color={
                                    selectedChild === child ? 'secondary' : 1
                                  }
                                  className={`cursor-pointer mb-3 ${
                                    styles.left_text
                                  }${
                                    selectedChild === child
                                      ? ` ${styles.selected}`
                                      : ''
                                  }`}
                                  onClick={() =>
                                    onClickDecider(index + 1, child)
                                  }
                                >
                                  {child}
                                  <br />
                                </CustomText>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                </Space>

                <CustomText
                  font="medium"
                  size={16}
                  color={sideTab === 'SAP' ? 'secondary' : '#444444'}
                  className={`cursor-pointer mb-4 ${styles.left_text}${
                    sideTab === 'SAP' ? ` ${styles.selected}` : ''
                  }`}
                  onClick={() => onClickSideTab('SAP')}
                >
                  SAP Concur
                </CustomText>
                <Space
                  direction="vertical"
                  size={0}
                  style={{ marginLeft: '20px' }}
                >
                  {sideTab === 'SAP'
                    ? titles?.map((item, index) => (
                        <div key={index}>
                          <CustomText
                            font="medium"
                            size={16}
                            color={selectedTab === index + 1 ? 'secondary' : 1}
                            className={`cursor-pointer mb-4 ${
                              styles.left_text
                            }${
                              selectedTab === index + 1
                                ? ` ${styles.selected}`
                                : ''
                            }`}
                            onClick={() => onClickDecider(index + 1)}
                          >
                            {item.title}
                            <span style={{ fontSize: '14px' }}>
                              {item?.subTitle}
                            </span>
                          </CustomText>
                          {selectedTab === index + 1 && (
                            <div
                              style={{ marginLeft: '20px', marginTop: '5px' }}
                            >
                              {item?.children?.map((child, childIndex) => (
                                <CustomText
                                  key={childIndex}
                                  font="medium"
                                  size={13}
                                  color={
                                    selectedChild === child ? 'secondary' : 1
                                  }
                                  className={`cursor-pointer mb-3 ${
                                    styles.left_text
                                  }${
                                    selectedChild === child
                                      ? ` ${styles.selected}`
                                      : ''
                                  }`}
                                  onClick={() =>
                                    onClickDecider(index + 1, child)
                                  }
                                >
                                  {child}
                                  <br />
                                </CustomText>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                </Space>
                <CustomText
                  font="medium"
                  size={16}
                  color={sideTab === 'SAC' ? 'secondary' : '#444444'}
                  className={`cursor-pointer mb-4 ${styles.left_text}${
                    sideTab === 'SAC' ? ` ${styles.selected}` : ''
                  }`}
                  onClick={() => onClickSideTab('SAC')}
                >
                  SAC (AOP-Annual Operating Plan)
                </CustomText>
                <Space
                  direction="vertical"
                  size={0}
                  style={{ marginLeft: '20px' }}
                >
                  {sideTab === 'SAC'
                    ? titlesSAC?.map((item, index) => (
                        <div key={index}>
                          <CustomText
                            font="medium"
                            size={16}
                            color={selectedTab === index + 1 ? 'secondary' : 1}
                            className={`cursor-pointer mb-4 ${
                              styles.left_text
                            }${
                              selectedTab === index + 1
                                ? ` ${styles.selected}`
                                : ''
                            }`}
                            onClick={() => onClickDecider(index + 1)}
                          >
                            {item.title}
                            <span style={{ fontSize: '14px' }}>
                              {item?.subTitle}
                            </span>
                          </CustomText>
                          {selectedTab === index + 1 && (
                            <div
                              style={{ marginLeft: '20px', marginTop: '5px' }}
                            >
                              {item?.children?.map((child, childIndex) => (
                                <CustomText
                                  key={childIndex}
                                  font="medium"
                                  size={13}
                                  color={
                                    selectedChild === child ? 'secondary' : 1
                                  }
                                  className={`cursor-pointer mb-3 ${
                                    styles.left_text
                                  }${
                                    selectedChild === child
                                      ? ` ${styles.selected}`
                                      : ''
                                  }`}
                                  onClick={() =>
                                    onClickDecider(index + 1, child)
                                  }
                                >
                                  {child}
                                  <br />
                                </CustomText>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    : ''}
                </Space>
              </Space>
            </Space>
            {!isMobile ? <Divider style={{ margin: '32px 0' }} /> : null}
          </Col>
          <Col xs={24} sm={18}>
            <div className={styles.right_container}>{newsletterContent()}</div>
          </Col>
        </Row>
      </CustomContainer>
    </div>
  );
};

export default MediaConsurSACNew;
