import React, { useState } from 'react';
import { Button } from 'antd';
import styles from './index.module.less';

import microsoft from 'features/login/images/microsoft_login_button.png';
import { Message, Icon } from 'semantic-ui-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slice';
import { useLocation } from 'react-router-dom';
import tataBlue from '../../images/tataBlue.png';
//import login_bg from '../../images/login_bg.svg';
import neev_logo from '../../images/neev_logo.svg';

const LoginPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [errorMsg] = useState('');
  const dispatch = useDispatch();
  let location = useLocation();

  return (
    <div className={`${styles.full_container_div}`}>
      <img className={`${styles.rightTata_logo}`} src={tataBlue} />
      <div className={`${styles.centered_content_page}`}>
        <div className={`${styles.centered_content}`}>
          <img src={neev_logo} alt="Centered Image" />
        </div>
        <div className={`${styles.centered_text}`}>Welcome!</div>
        <div className={`${styles.centered_subtext}`}>
          Please enter your details.
        </div>
        <Button
          className={`${styles.centered_button}`}
          src={microsoft}
          id="microsoft-login"
          centered="true"
          alt="Microsoft"
          onClick={() => {
            const user_details = LocalStorage.getItem('neev_login_user');
            console.log(user_details ? user_details : 'No response');

            instance
              .loginPopup({
                scopes: ['User.Read']
              })
              .then((result) => {
                return dispatch(login({ token: result?.accessToken }));
              })
              .then((result) => {
                console.log('result', result);
                if (result?.payload?.success) {
                  LocalStorage.setItem(
                    'neev_login_user',
                    JSON.stringify(result?.payload)
                  );

                  const user = result?.payload;
                  console.log('user', user);
                  if (user?.user_details?.user_role === 'user') {
                    location.state?.from?.pathname
                      ? history.push(`${location.state?.from?.pathname}`)
                      : history.push('/home');
                  } else if (user?.user_details?.user_role === 'admin') {
                    location.state?.from?.pathname
                      ? history.push(`${location.state?.from?.pathname}`)
                      : history.push('/home');
                  }
                } else {
                  console.log('$$$$$$$$$$$$$$$$$$$');
                }
              })
              .catch((err) => {
                console.log('noresponse', err, err);
                isAuthenticated
                  ? instance.logoutRedirect({
                      account: LocalStorage.homeAccountId,
                      postLogoutRedirectUri: '/',
                      mainWindowRedirectUri: '/'
                    })
                  : '';
              });
          }}
        >
          <span className={`${styles.button_title}`}>Go to your Account</span>
        </Button>
      </div>
      {/* <div>
        <Row className={`${styles.images_container}`}>
          <div className={`${styles.big_neevLogo}`}>
            <img className={`${styles.big_neevLogo_img}`} src={neev_logo} />
          </div>
          <div className={`${styles.welcome_text}`}>
            <p className={`${styles.welcome_text_title}`}>Welcome back</p>
            <p className={`${styles.welcome_text_subtitle1}`}>
              Welcome back! Please enter your details.
            </p>

            <Button
              className={`${styles.login_button}`}
              src={microsoft}
              id="microsoft-login"
              centered="true"
              alt="Microsoft"
              onClick={() => {
                const user_details = LocalStorage.getItem('neev_login_user');
                console.log(user_details ? user_details : 'No response');

                instance
                  .loginPopup({
                    scopes: ['User.Read']
                  })
                  .then((result) => {
                    return dispatch(login({ token: result?.accessToken }));
                  })
                  .then((result) => {
                    console.log('result', result);
                    if (result?.payload?.success) {
                      LocalStorage.setItem(
                        'neev_login_user',
                        JSON.stringify(result?.payload)
                      );

                      const user = result?.payload;
                      console.log('user', user);
                      if (user?.user_details?.user_role === 'user') {
                        location.state?.from?.pathname
                          ? history.push(`${location.state?.from?.pathname}`)
                          : history.push('/home');
                      }
                    } else {
                      console.log('$$$$$$$$$$$$$$$$$$$');
                    }
                  })
                  .catch((err) => {
                    console.log('noresponse', err, err);
                    isAuthenticated
                      ? instance.logoutRedirect({
                          account: LocalStorage.homeAccountId,
                          postLogoutRedirectUri: '/',
                          mainWindowRedirectUri: '/'
                        })
                      : '';
                  });
              }}
            >
              <span className={`${styles.button_title}`}>
                Go to your Account
              </span>
            </Button>
          </div>
        </Row>
      </div> */}

      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
