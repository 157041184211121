import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  //adminAnnouncements,
  post_update_announcement,
  post_update_events,
  adminEvents,
  adminMilestones,
  adminPoll,
  post_update_poll,
  adminLoginStats,
  adminQuizStats,
  adminPollStats,
  forAll
} from './api';
import { history } from '@/app/history';

const initialState = {
  get_announcementsLoading: false,
  postCreateuAnnouncementLoading: false,
  postCreateUpdateEventsLoading: false,
  get_eventsLoading: false,
  get_milestonesLoading: false,
  get_pollLoading: false,
  postCreateUpdatePollLoading: false,
  getLoginStatsLoading: false,
  getPollStatsLoading: false,
  getQuizStatsLoading: false,
  announcementsData: [],
  pagination: [],
  eventsData: [],
  milestonesData: [],
  pollData: [],
  loginStatsData: [],
  quizStatsData: [],
  pollStatsData: []
};

const actions = {
  GET_ANNOUNCEMENTS: 'get_announcements/GET_ANNOUNCEMENTS',
  POST_CREATE_ANNOUCEMENT: 'postCreateuAnnouncement/POST_CREATE_ANNOUCEMENT',
  GET_EVENTS: 'get_events/GET_EVENTS',
  GET_MILESTONES: 'get_milestones/GET_MILESTONES',
  POST_CREATE_UPDATE_EVENTS: 'postCreateUpdateEvents/POST_CREATE_UPDATE_EVENTS',
  GET_POLL: 'get_poll/GET_POLL',
  POST_CREATE_UPDATE_POLL: 'postCreateUpdatePoll/POST_CREATE_UPDATE_POLL',
  GET_LOGIN_STATS: 'getLoginStats/GET_LOGIN_STATS',
  GET_QUIZ_STATS: 'getQuizStats/GET_QUIZ_STATS',
  GET_POLL_STATS: 'getPollStats/GET_POLL_STATS'
};

export const getQuizStats = createAsyncThunk(
  actions.GET_QUIZ_STATS,
  async (payload) => {
    const response = await adminQuizStats(payload);
    return response;
  }
);
export const getPollStats = createAsyncThunk(
  actions.GET_POLL_STATS,
  async (payload) => {
    const response = await adminPollStats(payload);
    return response;
  }
);

export const getLoginStats = createAsyncThunk(
  actions.GET_LOGIN_STATS,
  async (payload) => {
    const response = await adminLoginStats(payload);
    return response;
  }
);

// export const get_announcements = createAsyncThunk(
//   actions.GET_ANNOUNCEMENTS,
//   async (payload) => {
//     const response = await adminAnnouncements(payload);
//     return response;
//   }
// );

export const get_announcements = createAsyncThunk(
  actions.GET_ANNOUNCEMENTS,
  async ({ role, payload }) => {
    const response = await forAll(role, 'get_announcements', payload);
    return response;
  }
);

export const postCreateuAnnouncement = createAsyncThunk(
  actions.POST_CREATE_QUIZ,
  async (payload) => {
    const response = await post_update_announcement(payload);
    return response;
  }
);
export const get_events = createAsyncThunk(
  actions.GET_EVENTS,
  async ({ role, payload }) => {
    const response = await adminEvents(role, payload);
    return response;
  }
);

export const get_milestones = createAsyncThunk(
  actions.GET_MILESTONES,
  async ({ role, payloadMilestones }) => {
    const response = await adminMilestones(role, payloadMilestones);
    return response;
  }
);

export const postCreateUpdateEvents = createAsyncThunk(
  actions.POST_CREATE_UPDATE_EVENTS,
  async (payload) => {
    const response = await post_update_events(payload);
    return response;
  }
);
export const get_poll = createAsyncThunk(actions.GET_POLL, async (payload) => {
  const response = await adminPoll(payload);
  return response;
});
export const postCreateUpdatePoll = createAsyncThunk(
  actions.POST_CREATE_UPDATE_POLL,
  async (payload) => {
    const response = await post_update_poll(payload);
    return response;
  }
);

export const adminConsoleSlice = createSlice({
  name: 'adminConsole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(get_announcements.pending, (state) => {
        state.get_announcementsLoading = true;
      })
      .addCase(get_announcements.fulfilled, (state, action) => {
        state.get_announcementsLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.announcementsData = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.announcementsData = [];
          // message.error(msg);
        }
      })
      .addCase(get_announcements.rejected, (state) => {
        // const { message: msg } = action.error;

        state.get_announcementsLoading = false;

        // message.error(msg);
      });
    builder
      .addCase(postCreateuAnnouncement.pending, (state) => {
        state.postCreateuAnnouncementLoading = true;
      })
      .addCase(postCreateuAnnouncement.fulfilled, (state, action) => {
        state.postCreateuAnnouncementLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          message.error(msg);
        }
      })
      .addCase(postCreateuAnnouncement.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postCreateuAnnouncementLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_events.pending, (state) => {
        state.get_eventsLoading = true;
      })
      .addCase(get_events.fulfilled, (state, action) => {
        state.get_eventsLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.eventsData = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.eventsData = [];
          message.error(msg);
        }
      })
      .addCase(get_events.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.get_eventsLoading = false;
        message.error(msg);
      });
    builder
      .addCase(postCreateUpdateEvents.pending, (state) => {
        state.postCreateUpdateEventsLoading = true;
      })
      .addCase(postCreateUpdateEvents.fulfilled, (state, action) => {
        state.postCreateUpdateEventsLoading = false;
        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          message.error(msg);
        }
      })
      .addCase(postCreateUpdateEvents.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.postCreateUpdateEventsLoading = false;
        message.error(msg);
      });
    builder
      .addCase(get_poll.pending, (state) => {
        state.get_pollLoading = true;
      })
      .addCase(get_poll.fulfilled, (state, action) => {
        state.get_pollLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.pollData = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.pollData = [];
          message.error(msg);
        }
      })
      .addCase(get_poll.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.get_pollLoading = false;
        message.error(msg);
      });
    builder
      .addCase(postCreateUpdatePoll.pending, (state) => {
        state.postCreateUpdatePollLoading = true;
      })
      .addCase(postCreateUpdatePoll.fulfilled, (state, action) => {
        state.postCreateUpdatePollLoading = false;
        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          message.error(msg);
        }
      })
      .addCase(postCreateUpdatePoll.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.postCreateUpdatePollLoading = false;
        message.error(msg);
      });
    builder
      .addCase(getLoginStats.pending, (state) => {
        state.getLoginStatsLoading = true;
      })
      .addCase(getLoginStats.fulfilled, (state, action) => {
        state.getLoginStatsLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.loginStatsData = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.loginStatsData = [];
          message.error(msg);
        }
      })
      .addCase(getLoginStats.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getLoginStatsLoading = false;
        message.error(msg);
      });
    builder
      .addCase(getQuizStats.pending, (state) => {
        state.getQuizStatsLoading = true;
      })
      .addCase(getQuizStats.fulfilled, (state, action) => {
        state.getQuizStatsLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.quizStatsData = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.quizStatsData = [];
          message.error(msg);
        }
      })
      .addCase(getQuizStats.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getQuizStatsLoading = false;
        message.error(msg);
      });
    builder
      .addCase(getPollStats.pending, (state) => {
        state.getPollStatsLoading = true;
      })
      .addCase(getPollStats.fulfilled, (state, action) => {
        state.getPollStatsLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.pollStatsData = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.pollStatsData = [];
          message.error(msg);
        }
      })
      .addCase(getPollStats.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getPollStatsLoading = false;
        message.error(msg);
      });
    builder
      .addCase(get_milestones.pending, (state) => {
        state.get_milestonesLoading = true;
      })
      .addCase(get_milestones.fulfilled, (state, action) => {
        state.get_milestonesLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.milestonesData = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.milestonesData = [];
          message.error(msg);
        }
      })
      .addCase(get_milestones.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.get_milestonesLoading = false;
        message.error(msg);
      });
  }
});

export default adminConsoleSlice.reducer;
