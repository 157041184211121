import { useState, useEffect } from 'react';
import { Col, Divider, Row, Space, Image, Modal, Carousel } from 'antd';

import styles from './change_enablement.module.less';

import { sizeChecker } from 'utilities/helpers';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';

import { pdfjs } from 'react-pdf'; // Document,
import mdDesk from '../../images/md_desk.svg';

import VinayakPai from '../../images/Leadership/Vinayak Pai.mp4';
import RahulShah from '../../images/Leadership/Rahul Shah.mp4';
import Himanshu from '../../images/Leadership/Himanshu Chaturvedi.mp4';
import Ganesh from '../../images/Leadership/Ganesh Chandan.mp4';
import Neeraj from '../../images/Leadership/Neeraj Agrawal.mp4';
import Sanjay from '../../images/Leadership/Sanjay Sharma.mp4';
import DeepakN from '../../images/Leadership/16Nov23Video/Deepak Natarajan.mp4';
import Bhupesh from '../../images/Leadership/16Nov23Video/Bhupesh Chowdary.mp4';
import RKP from '../../images/Leadership/16Nov23Video/RK Panday.mp4';
import DKSharma from '../../images/Leadership/16Nov23Video/DK Sharma.mp4';
import Raman from '../../images/Leadership/16Nov23Video/Raman Kapil.mp4';
import Apeksha from '../../images/Leadership/16Nov23Video/Apeksha Balan.mp4';
import Amar from '../../images/Leadership/16Nov23Video/Amar Kulkarni.mp4';
import MohdInam from '../../images/Leadership/16Nov23Video/MohdInam.mp4';
import Mandula from '../../images/Leadership/16Nov23Video/Mandula Srinivasa Rao.mp4';
import Sriram from '../../images/Leadership/16Nov23Video/Sriram.mp4';
import Ranjit from '../../images/Leadership/16Nov23Video/Ranjit Manjrekar.mp4';
import Amitesh from '../../images/Leadership/16Nov23Video/Amitesh Ghosh.mp4';
import Naga from '../../images/Leadership/16Nov23Video/Nagalatchoumy Reddy.mp4';
import Rajendra from '../../images/Leadership/16Nov23Video/Rajendra Inani.mp4';
import Raghu from '../../images/Leadership/16Nov23Video/Raghu Agrawal.mp4';
import Keerti from '../../images/Leadership/16Nov23Video/Keerti Prakash.mp4';
import Unni from '../../images/Leadership/16Nov23Video/Unnikrishnan Nair & Manoj Tipnis.mp4';
import Mahesh from '../../images/Leadership/16Nov23Video/Mahesh Vaidyanathan.mp4';
import Neha from '../../images/Leadership/16Nov23Video/Neha Khanna.mp4';
import SanjayK from '../../images/Leadership/16Nov23Video/Sanjay Kaul.mp4';
import Vish from '../../images/Leadership/16Nov23Video/Vishwanathan S.mp4';
import DShanta from '../../images/Leadership/16Nov23Video/D Santhakumar.mp4';
import Ankush from '../../images/Leadership/16Nov23Video/Ankush Kak.mp4';
import Raju from '../../images/Leadership/16Nov23Video/Raju Dandu.mp4';
import Suja from '../../images/Leadership/16Nov23Video/Suja Savio.mp4';
import RahulSharma from '../../images/Leadership/16Nov23Video/Rahul Sharma.mp4';

import VinThumbnail from '../../images/Screenshot 2023-10-16 at 4.11.22 PM.png';
import RahThumbnail from '../../images/Screenshot 2023-10-16 at 5.38.59.png';
import HimThumbnail from '../../images/Screenshot 2023-10-18 at 4.33 1.png';

import GaneshChandan from '../../images/Screenshot Ganesh Chandan.png';
import Neeraj_Agrawal from '../../images/Neeraj_Agrawal.png';
import SanjaySharma from '../../images/Screenshot Sanjay Sharma.png';
import Amar_S from '../../images/Leadership/16NovScreenshot/Amar Kulkarni .png';
import Amitesh_S from '../../images/Leadership/16NovScreenshot/Amitesh Ghosh .png';
import Ankush_S from '../../images/Leadership/16NovScreenshot/Ankush Kak .png';
import Apeksha_S from '../../images/Leadership/16NovScreenshot/Apeksha Balan .png';
import Bhupesh_S from '../../images/Leadership/16NovScreenshot/Bhupesh Chowdary.png';
import DShanta_S from '../../images/Leadership/16NovScreenshot/D Shantakumar.png';
import Deepak_S from '../../images/Leadership/16NovScreenshot/Deepak Nat.png';
import DKSharma_S from '../../images/Leadership/16NovScreenshot/DK Sharma.png';
import Keerti_S from '../../images/Leadership/16NovScreenshot/Keerti.png';
import Mahesh_S from '../../images/Leadership/16NovScreenshot/Mahesh Vaidyanathan.png';
import Mandula_S from '../../images/Leadership/16NovScreenshot/Mandula Srinivasa Rao.png';
import MohdInam_S from '../../images/Leadership/16NovScreenshot/MohdInam.png';
import Naga_S from '../../images/Leadership/16NovScreenshot/Nagalatchoumy Reddy.png';
import Neha_S from '../../images/Leadership/16NovScreenshot/Neha Khanna.png';
import Raghu_S from '../../images/Leadership/16NovScreenshot/Raghu Agrawal.png';
import RahulSharma_S from '../../images/Leadership/16NovScreenshot/Rahul Sharma.png';
import Rajendra_S from '../../images/Leadership/16NovScreenshot/Rajendra Inani.png';
import Raju_S from '../../images/Leadership/16NovScreenshot/Raju Dandu.png';
import Raman_S from '../../images/Leadership/16NovScreenshot/Raman Kapil.png';
import Ranjit_S from '../../images/Leadership/16NovScreenshot/Ranjit Manjrekar.png';
import RK_S from '../../images/Leadership/16NovScreenshot/RK Pandey.png';
import SanjayK_S from '../../images/Leadership/16NovScreenshot/Sanjay Kaul.png';
import Sriram_S from '../../images/Leadership/16NovScreenshot/Sriram Ayyar.png';
import Suja_S from '../../images/Leadership/16NovScreenshot/Suja Savio 1.png';
import Unni_S from '../../images/Leadership/16NovScreenshot/Unnikrishnan Nair & Manoj Tipnis.png';
import Vish_S from '../../images/Leadership/16NovScreenshot/Vishwanathan S.png';

import August from '../../images/August.jpg';

import Sept28 from '../../images/Project NEEV Bulletin_Sep28-book.jpg';
import Sept12 from '../../images/Project NEEV Bulletin_Sep12-book.jpg';
import Oct12 from '../../images/NEEV-Bulletin_Oct12_V1.jpg';
import Nov11 from '../../images/Project NEEV Bulletin_Nov11_Book View.png';
import Nov23 from '../../images/NEEV-Bulletin-6th-Edition_23Nov_BookView.jpg';
import Dec23 from '../../images/BulletinEdition_7/Bulletin-Edition-07.jpg';
import Bulletin8thbook from '../../images/BulletinEdition_8/Bulletin8thbook.jpg';
import Bulletin9thbook from '../../images/BulletinEdition_9/Bulletin9thbook.jpg';
import Bulletin10thbook from '../../images/BulletinEdition_10/Bulletin10thbook.jpg';
import FlipBook from '../Flipbook';

import mailers1 from '../../images/1. Project NEEV A Quick Guide.jpg';
import mailers2 from '../../images/2. Project NEEV A Quick Guide- Key Benefits.jpg';
import mailers3 from '../../images/3. Project NEEV A Quick Guide - SAP Modules.png';
import mailers4 from '../../images/4. Project NEEV A Quick Guide- SAP Modules series 1.jpg';
import mailers5 from '../../images/5. Project NEEV A Quick Guide - SAP Modules series 2.jpg';
import mailers6 from '../../images/6. Project NEEV A Quick Guide - SAP Modules series 3.jpg';
import mailers7 from '../../images/7. Project NEEV A Quick Guide - SAP Modules series 4.png';
import mailers8 from '../../images/8. Project NEEV A Quick Guide - SAP Modules series 5.png';
import mailers9 from '../../images/9. Project NEEV A Quick Guide - SAP Modules series 6.png';
import mailers10 from '../../images/10. Project NEEV A Quick Guide - SAP Module series 7.png';
import mailers11 from '../../images/Mailers/SAC  AOP Planning benefits.png';
import mailers12 from '../../images/Mailers/SAP Concur Benefits.jpg';
import mailers13 from '../../images/Mailers/SAP SAC Analytics Cloud for AOP.png';

import RoadshowNew1 from '../../images/Roadshows/NEEV Roadshow  1.jpeg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import Gallery1 from '../../images/Gallery/1.JPG';
import Gallery2 from '../../images/Gallery/2.JPG';
import Gallery3 from '../../images/Gallery/3.png';
import Gallery4 from '../../images/Gallery/4.png';
import Gallery5 from '../../images/Gallery/5.png';
import Gallery6 from '../../images/Gallery/6.png';
import Gallery7 from '../../images/Gallery/7.png';
import Gallery8 from '../../images/Gallery/8.png';
import Gallery9 from '../../images/Gallery/NEEV Floor Connect 1.jpg';
import Gallery10 from '../../images/Gallery/NEEV Floor Connect 2 - Copy.jpg';
import Gallery11 from '../../images/Gallery/NEEV Floor Connect 3.jpg';
import Gallery12 from '../../images/Gallery/NEEV Floor Connect 4 - Copy.jpg';
import Gallery13 from '../../images/Gallery/NEEV Floor Connect 5.jpg';
import Gallery14 from '../../images/Gallery/NEEV Floor Connect 6.jpg';
import Gallery15 from '../../images/Gallery/NEEV Floor Connect 7.jpg';
import Gallery16 from '../../images/Gallery/NEEV Floor Connect 8.jpg';
import GalleryD1 from '../../images/Gallery/DiwaliCelebration/MicrosoftTeams-image (42).png';
import GalleryD2 from '../../images/Gallery/DiwaliCelebration/MicrosoftTeams-image (43).png';
import GalleryD3 from '../../images/Gallery/DiwaliCelebration/MicrosoftTeams-image (44).png';
import GalleryD4 from '../../images/Gallery/DiwaliCelebration/MicrosoftTeams-image (45).png';
import GalleryD5 from '../../images/Gallery/DiwaliCelebration/MicrosoftTeams-image (46).png';
import GalleryD6 from '../../images/Gallery/DiwaliCelebration/MicrosoftTeams-image (47).png';
import GalleryD7 from '../../images/Gallery/DiwaliCelebration/PHOTO-2023-11-10-17-58-27 2.jpg';
import GalleryD8 from '../../images/Gallery/DiwaliCelebration/PHOTO-2023-11-10-17-58-27.jpg';
import GalleryD9 from '../../images/Gallery/DiwaliCelebration/PHOTO-2023-11-10-17-58-28.jpg';
import GalleryD10 from '../../images/Gallery/DiwaliCelebration/PHOTO-2023-11-10-17-58-29.jpg';
import GalleryD11 from '../../images/Gallery/DiwaliCelebration/PHOTO-2023-11-10-17-58-30.jpg';
import GalleryD12 from '../../images/Gallery/DiwaliCelebration/PHOTO-2023-11-10-17-58-31.jpg';
import GalleryMD1 from '../../images/Gallery/MDsSupriseVisit/MicrosoftTeams-image (41).png';
import GalleryMD2 from '../../images/Gallery/MDsSupriseVisit/MicrosoftTeams-image (60).png';
import GalleryMD3 from '../../images/Gallery/MDsSupriseVisit/MicrosoftTeams-image (61).png';
import GalleryMD4 from '../../images/Gallery/MDsSupriseVisit/MicrosoftTeams-image (62).png';

import project_neev from '../../images/project_neev.mp4';
import TPL3Months from '../../images/TPL - 3 months recap.mp4';
import SAPConcur from '../../../home/images/banners/SAPConcur.mp4';

import Paper from '@/common/PaperFAQs';
import ocmImage from '../../images/OCM/ocmImage1.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { history } from '@/app/history';
import Network from '../network';
//import { useLocation } from 'react-router-dom';
//import CustomButton from '@/common/CustomButton';

const leadershipVideos = [
  {
    id: 1,
    video: VinayakPai,
    thumbnailUrl: VinThumbnail,
    title: 'Vinayak Pai',
    designation: 'MD & CEO'
  },
  {
    id: 2,
    video: RahulShah,
    thumbnailUrl: RahThumbnail,
    title: 'Rahul Shah ',
    designation: 'President & COO, B&I'
  },
  {
    id: 3,
    video: Himanshu,
    thumbnailUrl: HimThumbnail,
    title: 'Himanshu Chaturvedi',
    designation: 'Chief Strategy & Growth Officer'
  },
  {
    id: 4,
    video: Ganesh,
    thumbnailUrl: GaneshChandan,
    title: 'Ganesh Chandan',
    designation: 'Chief Human Resource Officer'
  },
  {
    id: 5,
    video: Neeraj,
    thumbnailUrl: Neeraj_Agrawal,
    title: 'Neeraj Agrawal',
    designation: 'Chief Operating Officer'
  },
  {
    id: 6,
    video: Sanjay,
    thumbnailUrl: SanjaySharma,
    title: 'Sanjay Sharma',
    designation: 'Chief Financial Officer'
  },
  {
    id: 7,
    video: RahulSharma,
    thumbnailUrl: RahulSharma_S,
    title: 'Rahul Sharma',
    designation: 'Chief Information Officer'
  },
  {
    id: 8,
    video: DeepakN,
    thumbnailUrl: Deepak_S,
    title: 'Deepak Natarajan',
    designation: 'Chief of Staff'
  },
  {
    id: 9,
    video: Bhupesh,
    thumbnailUrl: Bhupesh_S,
    title: 'Bhupesh Chowdary',
    designation: 'SBU Head - Power & Metals and Chief of Operations – E&I'
  },
  {
    id: 10,
    video: RKP,
    thumbnailUrl: RK_S,
    title: 'RK Pandey',
    designation: 'SBU Head - Transmission & Distribution'
  },
  {
    id: 11,
    video: DKSharma,
    thumbnailUrl: DKSharma_S,
    title: 'DK Sharma',
    designation: 'SBU Head - Transportation & Strategic Infrastructure'
  },
  {
    id: 12,
    video: Raman,
    thumbnailUrl: Raman_S,
    title: 'Raman Kapil',
    designation: 'SBU Head - Metros, Tunnels & Environment'
  },
  {
    id: 13,
    video: Apeksha,
    thumbnailUrl: Apeksha_S,
    title: 'Apeksha Balan',
    designation: 'Head - Business Transformation'
  },
  {
    id: 14,
    video: Amar,
    thumbnailUrl: Amar_S,
    title: 'Amar Kulkarni',
    designation: 'Head - Project Monitoring, Cost Control & Estimation, E&I'
  },
  {
    id: 15,
    video: MohdInam,
    thumbnailUrl: MohdInam_S,
    title: `Mohd. Inam`,
    designation: 'Vice President - Manufacturing Operations'
  },
  {
    id: 16,
    video: Mandula,
    thumbnailUrl: Mandula_S,
    title: 'Mandula Srinivasa Rao (MSR)',
    designation: 'Head - Finance COE'
  },
  {
    id: 17,
    video: Sriram,
    thumbnailUrl: Sriram_S,
    title: 'Sriram Ayyar',
    designation: 'Vice President - Project Controls'
  },
  {
    id: 18,
    video: Ranjit,
    thumbnailUrl: Ranjit_S,
    title: 'Ranjit Manjrekar',
    designation: 'Vice President - Fleet & Resources'
  },
  {
    id: 19,
    video: Amitesh,
    thumbnailUrl: Amitesh_S,
    title: 'Amitesh Ghosh',
    designation: 'Program Director - SAP'
  },
  {
    id: 20,
    video: Naga,
    thumbnailUrl: Naga_S,
    title: 'Nagalatchoumy Reddy',
    designation: 'Assistant Vice President - Projects, E&I'
  },
  {
    id: 21,
    video: Rajendra,
    thumbnailUrl: Rajendra_S,
    title: 'Rajendra Inani',
    designation: 'BU Head - Smart Cities'
  },
  {
    id: 22,
    video: Raghu,
    thumbnailUrl: Raghu_S,
    title: 'Raghu Agrawal',
    designation: 'Assistant Vice President - Supply Chain Management'
  },
  {
    id: 23,
    video: Keerti,
    thumbnailUrl: Keerti_S,
    title: 'Keerti Prakash',
    designation: 'Partner - Deloitte'
  },
  {
    id: 24,
    video: Unni,
    thumbnailUrl: Unni_S,
    title: 'Unnikrishnan Nair & Manoj Tipnis',
    designation: 'Head - Central Engineering'
  },
  {
    id: 25,
    video: Mahesh,
    thumbnailUrl: Mahesh_S,
    title: 'Mahesh Vaidyanathan',
    designation: 'Solution Advisor - TCS'
  },
  {
    id: 26,
    video: Neha,
    thumbnailUrl: Neha_S,
    title: 'Neha Khanna',
    designation: 'General Manager- Organizational Change Management'
  },
  {
    id: 27,
    video: SanjayK,
    thumbnailUrl: SanjayK_S,
    title: 'Sanjay Kaul',
    designation: 'Vice President - Engineering, E&I'
  },
  {
    id: 28,
    video: Vish,
    thumbnailUrl: Vish_S,
    title: 'Vishwanathan S',
    designation: 'Vice President - Supply Chain Management, E&I'
  },
  {
    id: 29,
    video: DShanta,
    thumbnailUrl: DShanta_S,
    title: 'D Shantakumar',
    designation: 'General Manager - Materials Management'
  },
  {
    id: 30,
    video: Ankush,
    thumbnailUrl: Ankush_S,
    title: 'Ankush Kak - Senior Manager',
    designation: 'Information Technology & ERP'
  },
  {
    id: 31,
    video: Raju,
    thumbnailUrl: Raju_S,
    title: 'Raju Dandu',
    designation: 'CPM Principal Consultant - SAP'
  },
  {
    id: 32,
    video: Suja,
    thumbnailUrl: Suja_S,
    title: 'Suja Savio',
    designation: 'Partner, Deloitte'
  }
];

const titles = [
  { key: 0, title: 'NEEV Mailers ' },
  {
    key: 1,
    title: 'NEEV Bulletins ',
    children: [
      'Edition 10',
      'Edition 9',
      'Edition 8',
      'Edition 7',
      'Edition 6',
      'Edition 5',
      'Edition 4',
      'Edition 3',
      'Edition 2',
      'Edition 1'
    ]
  },
  { key: 2, title: 'Roadshows', children: ['Roadshows'] },
  {
    key: 3,
    title: 'Gallery',
    children: ['Images', 'Videos']
  }
];
const mailerImages = [
  { id: 1, imageUrl: mailers11, title: 'SAC  AOP Planning benefits' },
  { id: 2, imageUrl: mailers12, title: 'SAP Concur Benefits' },
  { id: 3, imageUrl: mailers13, title: 'SAP SAC Analytics Cloud for AOP' },
  { id: 4, imageUrl: mailers1, title: 'Key Business Processes' },
  { id: 5, imageUrl: mailers2, title: 'Key Benefits' },
  { id: 6, imageUrl: mailers3, title: 'Key SAP Modules' },
  { id: 7, imageUrl: mailers4, title: 'Know Your SAP Modules - Series 1' },
  { id: 8, imageUrl: mailers5, title: 'Know Your SAP Modules - Series 2' },
  { id: 9, imageUrl: mailers6, title: 'Know Your SAP Modules - Series 3' },
  { id: 10, imageUrl: mailers7, title: 'Know Your SAP Modules - Series 4' },
  { id: 11, imageUrl: mailers8, title: 'Know Your SAP Modules - Series 5' },
  { id: 12, imageUrl: mailers9, title: 'Know Your SAP Modules - Series 6' },
  { id: 13, imageUrl: mailers10, title: 'Know Your SAP Modules - Series 7' }
];
const Roadshows = [{ id: 1, image: RoadshowNew1 }];
const galleryImages = [
  { id: 41, image: GalleryMD1 },
  { id: 42, image: GalleryMD2 },
  { id: 43, image: GalleryMD3 },
  { id: 44, image: GalleryMD4 },
  { id: 45, image: GalleryD1 },
  { id: 46, image: GalleryD2 },
  { id: 47, image: GalleryD3 },
  { id: 48, image: GalleryD4 },
  { id: 49, image: GalleryD5 },
  { id: 50, image: GalleryD6 },
  { id: 51, image: GalleryD7 },
  { id: 52, image: GalleryD8 },
  { id: 53, image: GalleryD9 },
  { id: 54, image: GalleryD10 },
  { id: 55, image: GalleryD11 },
  { id: 56, image: GalleryD12 },
  { id: 71, image: Gallery1 },
  { id: 72, image: Gallery2 },
  { id: 73, image: Gallery3 },
  { id: 74, image: Gallery4 },
  { id: 75, image: Gallery5 },
  { id: 76, image: Gallery6 },
  { id: 77, image: Gallery7 },
  { id: 78, image: Gallery8 },
  { id: 79, image: Gallery9 },
  { id: 410, image: Gallery10 },
  { id: 411, image: Gallery11 },
  { id: 412, image: Gallery12 },
  { id: 413, image: Gallery13 },
  { id: 414, image: Gallery14 },
  { id: 415, image: Gallery15 },
  { id: 416, image: Gallery16 }
];
const galleryVideos = [
  { id: 1, videoUrl: SAPConcur },
  { id: 2, videoUrl: project_neev },
  { id: 3, videoUrl: TPL3Months }
]; /*  */
const WhatIsOCM = [
  {
    text: 'How will I know what is changing and how are we progressing?',
    description: (
      <div>
        To stay updated about the progress of SAP implementation, you could go
        through the mailers that are sent out every week through
        <a
          href="https://login.live.com/"
          target="_blank"
          rel="noreferrer"
          style={{
            color: '#2973c6',
            marginLeft: '3px',
            marginRight: '3px'
          }}
        >
          projectneev@tataprojects.com
        </a>
        <span>
          {`email id. It is important to track the ongoing journey through the
          bulletins, yammer posts, events and the NEEV microsite. You could also
          get in touch with the Change Agent at your office and always check
          with him/her.`}
        </span>
      </div>
    )
  },
  {
    text: `Who will be my local guide through this change?`,
    description: (
      <div>
        The nominated Change Champions and Change Agents from your respective
        sites and offices would be your local guide for change throughout this
        journey.
      </div>
    )
  },
  {
    text: `How do I communicate my ideas or suggestions?`,
    description: (
      <div>
        You could communicate your ideas or suggestions by posing them
        <span
          style={{
            color: '#207ae0',
            marginLeft: '3px',
            marginRight: '3px',
            cursor: 'pointer'
          }}
          onClick={() => history.push('/ideas/list')}
        >
          here
        </span>{' '}
        on our queries and suggestion page. You could also write to us at
        <a
          href="https://login.live.com/"
          target="_blank"
          rel="noreferrer"
          style={{
            color: '#2973c6',
            marginLeft: '3px'
          }}
        >
          projectneev@tataprojects.com
        </a>
        .
      </div>
    )
  },
  {
    text: 'How will I gather the right knowledge and ability for the updated processes and new system?',
    description: (
      <div>
        You could gather all relevant knowledge for your processes and systems
        by actively participating in the webinars and training sessions designed
        for you.
      </div>
    )
  },
  {
    text: 'How do I know I am ready for this change?',
    description: (
      <div>
        There will be a change readiness survey that would help you understand
        what your change readiness score is. Also, your training assessment
        scores would let you know your level of awareness.
      </div>
    )
  },
  {
    text: 'Who will support me post go live?',
    description: (
      <div>
        There will be a Hypercare Team and help desk who would be your go to
        groups for any queries or support post go live.
      </div>
    )
  }
];

const ChangeEnablementPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = sizeChecker('xs');
  //const location = useLocation();

  const [sideTab, setSideTab] = useState('MD');
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedChild, setSelectedChild] = useState(0);
  const [visibleReadMore, setVisibleReadMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [slide, setSlide] = useState({
    Roadshows: 0,
    Conclave: 0
  });
  //const [open, setOpen] = useState(false);

  // Update URL parameters whenever selectedTab or selectedChild changes
  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedTab) {
      params.set('', selectedTab.toString());
    }
    if (selectedChild) {
      params.set('sub', selectedChild);
    } else {
      // Remove sub parameter from URL if it is null
      params.delete('sub');
    }
    // console.log('PARAM', params.toString());
    // console.log('SAP', sideTab);
    // console.log('selectedTab', selectedTab);
    // console.log('selectedChild', selectedChild);
    history.push({
      search:
        sideTab === 'MD'
          ? selectedTab === 1
            ? '/MDs-Desk'
            : params.toString()
          : sideTab === 'LS'
          ? selectedTab === 1
            ? '/Leaders-Speak'
            : params.toString()
          : sideTab === 'OCM'
          ? selectedTab === 1
            ? '/OCM'
            : params.toString()
          : sideTab === 'CAN'
          ? selectedTab === 1
            ? '/NEEV-CAN-Network'
            : params.toString()
          : sideTab === 'CCOM'
          ? selectedTab === 1
            ? '/Change-Communication/NEEV-Mailers'
            : selectedTab === 2
            ? '/Change-Communication/NEEV-Bulletins'
            : selectedTab === 3
            ? '/Change-Communication/Roadshows'
            : selectedTab === 4
            ? '/Change-Communication/Gallery'
            : params.toString()
          : null
    });
  }, [selectedTab, selectedChild, sideTab]);

  const onClickDecider = (index, child = null) => {
    // If child is provided, update selectedChild and do not change selectedTab
    console.log('child', child);
    if (child !== null) {
      setSelectedChild(child);
      setSlide({
        Roadshows: 0,
        Conclave: 0
      });
      return;
    }
    // If no child is provided, set selectedChild to the first child of the tab
    const tab = titles[index - 1];
    if (tab && tab.children && tab.children.length > 0) {
      setSelectedChild(tab.children[0]);
    } else {
      // If the title does not have children, set selectedChild to null
      setSelectedChild(null);
    }
    setSlide({
      Roadshows: 0,
      Conclave: 0
    });

    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // setSelectedChild(null); // Reset selectedChild when parent is clicked
        setSelectedTab(index);
        break;

      // Handle other cases if needed
      default:
        break;
    }
  };

  // console.log('Clicked:', selectedTab, selectedChild);
  // console.log('Selected Child:', selectedChild);

  const onClickSideTab = (value) => {
    console.log('Value:', value);
    setSelectedTab(1);
    value === 'MD'
      ? setSideTab('MD')
      : value === 'LS'
      ? setSideTab('LS')
      : value === 'OCM'
      ? setSideTab('OCM')
      : value === 'CAN'
      ? setSideTab('CAN')
      : value === 'CCOM'
      ? setSideTab('CCOM')
      : setSideTab('SAC');
  };
  // console.log('selectedTab:', selectedTab);
  // console.log('SIDETAB:', sideTab);
  // console.log('selectedChild:', selectedChild);

  const LeftArrow = ({ ...restArrowProps }) => {
    return <LeftOutlined {...restArrowProps} />;
  };

  const RightArrow = ({ ...restArrowProps }) => {
    return <RightOutlined {...restArrowProps} />;
  };

  const newsletterContent = () => {
    if (sideTab === 'MD' && selectedTab === 1) {
      return (
        <div className={styles.md_desk_container}>
          <Space direction="vertical" size={18}>
            <Image
              loading="lazy"
              className={styles.md_image}
              alt="md-desk"
              src={mdDesk}
              preview={false}
            />

            <div className={`${styles.md_quote}`}>
              <Space direction="vertical" size={32}>
                <CustomText font="medium" size={22}>
                  “A change of this magnitude will be challenging, but we
                  believe that transitioning to the new ERP system will
                  ultimately make our business operations more productive &
                  predictable.”
                </CustomText>
                <CustomText size={16}>
                  — Vinayak Pai, MD, Tata Projects
                </CustomText>
              </Space>
            </div>
            <CustomText size={18}>{`Dear Colleagues,`}</CustomText>
            <CustomText size={18}>
              {`As we progress on our transformation journey, technology is
          expected to play a core role in our project selection, delivery
          and supervision. A technology-led transformation is, therefore,
          the need of the hour.`}
            </CustomText>
            {/*  <CustomText size={18}>{`Dear Colleagues,`}</CustomText> */}
            <CustomText size={18}>
              {`A key organisational priority is to move away from the
            fragmented systems landscape that exists today, to an integrated
            ERP system and associated digital ecosystem. This will help
            overcome existing process and compliance challenges, ensure a
            ‘single source of truth’ and lead to quicker and more accurate
            decision-making for better business outcomes.`}
            </CustomText>
            {visibleReadMore && (
              <>
                <CustomText size={18}>
                  {`With the above imperatives in mind, we have decided to
                implement SAP S4 HANA as our core ERP with an integrated
                ecosystem across Finance, Supply Chain, and Project
                Management. This decision was the result of an intense and
                thoughtful evaluation process, during which we carefully
                assessed all options and their impact on business. The
                implementation of a new integrated ERP system will provide
                us with many benefits that align with our organisational
                goals and objectives. It will enable us to streamline our
                operations, reduce redundancies, and improve data accuracy,
                efficiency, predictability, and visibility across all
                departments. Additionally, advanced analytics and reporting
                capabilities will help us make better-informed decisions.`}
                </CustomText>
                <CustomText size={18}>
                  {`The IT and Business teams have set an aggressive target to plan for the system Go-Live on April 1, 2024. Achievement of this important and aspirational organisational goal will require the support and cooperation of all employees. Your wholehearted participation and valuable feedback during the implementation process will be crucial for the success of this program. Many of you are already participating in design deliberations and in due course, a large section of our employees will be imparted training to ensure that the transition is seamless and smooth for all stakeholders involved.`}
                </CustomText>
                <CustomText size={18}>
                  {`Needless to say, a change of this magnitude will be
                challenging, but we believe that transitioning to the new
                ERP system will ultimately make our business operations more
                productive & predictable and will enable us to serve our
                customers better. I am excited about this new chapter in our
                company's digital transformation journey and confident that
                the successful implementation of the new ERP will prove to
                be a game-changer for Tata Projects.`}
                </CustomText>
              </>
            )}
            <CustomText font="medium" color={10}>
              <a onClick={() => setVisibleReadMore(!visibleReadMore)}>
                {visibleReadMore ? 'Read Less' : 'Read More'}
              </a>
            </CustomText>
          </Space>
        </div>
      );
    } else if (sideTab === 'LS' && selectedTab === 1) {
      return (
        <>
          <CustomText font="regular" size={18} color={1}>
            Catch a glimpse of TPL Leaders sharing insights on Project NEEV
          </CustomText>

          <Row className="mt-3" gutter={[24, 48]}>
            {leadershipVideos?.map((item) => (
              <Col key={item.id} xl={8}>
                <video
                  poster={item.thumbnailUrl}
                  className={styles.custom_video}
                  controls={true}
                  src={item.video}
                  width="100%"
                  height="100%"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center'
                  }}
                >
                  <CustomText font="bold" size={12} color={1}>
                    {item.title}
                  </CustomText>
                  <CustomText font="bold" size={10} color={1}>
                    {item.designation}
                  </CustomText>
                </div>
              </Col>
            ))}
          </Row>
          {/* <div className={styles.md_desk_container}>
          <Space direction="vertical" size={18}>
            <CustomText font="medium" size={16}>
              LS....... Desk
            </CustomText>
          </Space>
        </div> */}
        </>
      );
    } else if (sideTab === 'OCM' && selectedTab === 1) {
      return (
        <>
          <Space direction="vertical" size={40} style={{ width: '100%' }}>
            <Space direction="vertical" size={10}>
              <CustomText size={18} className={styles.training_cal_style}>
                Project NEEV is an important initiative at Tata Projects which
                entails a huge change for TPL employees using the existing
                Oracle ERP and bespoke apps to shift to new SAP ecosystem.
              </CustomText>

              <CustomText size={18} className={styles.training_cal_style}>
                With a change this BIG,{' '}
                <b>
                  a lot of questions come up in the minds of the impacted end
                  user. Change Management
                </b>{' '}
                is a systematic approach designed to support the impacted end
                users by helping them understand what is changing, making them
                ready to handle change, and experience the desired benefits from
                the transformation.
              </CustomText>
            </Space>
            <Row style={{ width: '100%' }}>
              <Col span={12} className={styles.ocm_imageStyle}>
                <Image
                  loading="lazy"
                  // className={styles.galleryImages}
                  src={ocmImage}
                  preview={false}
                  // alt={`Image`}
                  style={{ width: '300px', height: '300' }}
                />
              </Col>
              <Col span={12}>
                <Paper data={WhatIsOCM} />
              </Col>
            </Row>
          </Space>
        </>
      );
    } else if (sideTab === 'CAN' && selectedTab === 1) {
      return (
        <>
          <Network />{' '}
        </>
      );
    } else if (sideTab === 'CCOM') {
      switch (selectedTab) {
        case 1:
          return (
            <Row gutter={[32, 32]}>
              {mailerImages.map((key) => (
                <Col key={key?.id} xs={24} xl={6}>
                  <div className={styles.mailerImagesContainer}>
                    <div className={styles.imageWrapper}>
                      <Image
                        loading="lazy"
                        className={styles.mailerImages}
                        src={key.imageUrl}
                        alt={`Image ${key}`}
                        preview={true}
                      />
                      <div className={`df-jc-ac text-center ${styles.blur_bg}`}>
                        <CustomText font="medium" size={12} color="white">
                          {key.title}
                        </CustomText>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          );
        case 2:
          if (selectedChild === 'Edition 1') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={August}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          } else if (selectedChild === 'Edition 3') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={Sept28}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          } else if (selectedChild === 'Edition 2') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={Sept12}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          } else if (selectedChild === 'Edition 4') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={Oct12}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          } else if (selectedChild === 'Edition 5') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={Nov11}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          } else if (selectedChild === 'Edition 6') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={Nov23}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          } else if (selectedChild === 'Edition 7') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={Dec23}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          } else if (selectedChild === 'Edition 8') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={Bulletin8thbook}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          } else if (selectedChild === 'Edition 9') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={Bulletin9thbook}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          } else if (selectedChild === 'Edition 10') {
            return (
              <div className={styles.md_desk_container}>
                <Image
                  loading="lazy"
                  className={styles.newsLetterImage}
                  onClick={() => setOpen(true)}
                  preview={false}
                  src={Bulletin10thbook}
                />
                <Modal
                  style={{ marginTop: '-5%' }}
                  footer={false}
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={isMobile ? '100%' : '85%'}
                  height={isMobile ? '100%' : '90%'}
                >
                  <div className="df-jc-ac mt-3">
                    <FlipBook selectedChild={selectedChild} />
                  </div>
                </Modal>
              </div>
            );
          }
          // Handle other children of the 'Gallery' tab if needed
          break;
        case 3:
          if (selectedChild === 'Roadshows') {
            const settings = {
              effect: 'fade',
              arrows: true,
              autoplay: false,
              nextArrow: <RightArrow />,
              prevArrow: <LeftArrow />,
              afterChange: (currentSlide) => {
                setSlide((prevSlide) => ({
                  ...prevSlide,
                  Roadshows: currentSlide
                }));
              }
            };
            return (
              <>
                <Carousel
                  dots={false}
                  {...settings}
                  className={styles.carousel_container}
                >
                  {Roadshows?.map((imageUrl, index) => {
                    return (
                      <div key={index}>
                        <img
                          loading="lazy"
                          className={styles.carousel_image}
                          src={imageUrl.image}
                          alt={`Image ${index + 1}`}
                        />
                      </div>
                    );
                  })}
                </Carousel>
                <div className={`${styles.currentImageHolder}`}>
                  <div className={styles.currentImage}>
                    <CustomText
                      className={`text-center df-jc-ac`}
                      font="bold"
                      size={14}
                      color={'white'}
                    >
                      {slide.Roadshows + 1}/{Roadshows.length}
                    </CustomText>
                  </div>
                </div>
              </>
            );
          }
          break;
        case 4:
          if (selectedChild === 'Images') {
            return (
              <div className={styles.md_desk_container}>
                <Row gutter={[16, 24]}>
                  <Image.PreviewGroup>
                    {galleryImages?.map((itm) => (
                      <Col
                        className={`df-jc-ac ${styles.galleryImagesContainer}`}
                        key={itm.id}
                        xs={24}
                        xl={6}
                      >
                        <Image
                          loading="lazy"
                          className={styles.galleryImages}
                          src={itm.image}
                          preview={true}
                          alt={`Image ${itm.id}`}
                        />
                      </Col>
                    ))}
                  </Image.PreviewGroup>
                </Row>
              </div>
            );
          } else if (selectedChild === 'Videos') {
            return (
              <Row gutter={[24, 24]}>
                {galleryVideos?.map((item) => (
                  <Col key={item.id} xl={8}>
                    <video
                      className={styles.custom_video}
                      src={item.videoUrl}
                      controls={true}
                      width="100%"
                      height="100%"
                    />
                  </Col>
                ))}
              </Row>
            );
          }
          // Handle other children of the 'Gallery' tab if needed
          break;

        default:
          return window?.scrollTo(0, document.body.scrollHeight);
      }
    }
  };

  return (
    <div className={styles.container}>
      <CustomContainer containerClassname={styles.newsletter_container}>
        <Row gutter={[64, 30]}>
          <Col xs={24} sm={6} className={styles.remove_margin}>
            <Space direction="vertical" size={8}>
              <Space direction="vertical" size={5}>
                <div>
                  <CustomText
                    font="medium"
                    size={16}
                    color={sideTab === 'MD' ? 'secondary' : '#444444'}
                    className={`cursor-pointer mb-4 ${styles.left_text}${
                      sideTab === 'MD' ? ` ${styles.selected}` : ''
                    }`}
                    onClick={() => onClickSideTab('MD')}
                  >
                    {`From the MD's Desk`}
                  </CustomText>
                </div>
                <div>
                  <CustomText
                    font="medium"
                    size={16}
                    color={sideTab === 'LS' ? 'secondary' : '#444444'}
                    className={`cursor-pointer mb-4 ${styles.left_text}${
                      sideTab === 'LS' ? ` ${styles.selected}` : ''
                    }`}
                    onClick={() => onClickSideTab('LS')}
                  >
                    Leaders Speak
                  </CustomText>
                </div>
                <div>
                  <CustomText
                    font="medium"
                    size={16}
                    color={sideTab === 'OCM' ? 'secondary' : '#444444'}
                    className={`cursor-pointer mb-4 ${styles.left_text}${
                      sideTab === 'OCM' ? ` ${styles.selected}` : ''
                    }`}
                    onClick={() => onClickSideTab('OCM')}
                  >
                    What is OCM
                  </CustomText>
                </div>
                <div>
                  <CustomText
                    font="medium"
                    size={16}
                    color={sideTab === 'CAN' ? 'secondary' : '#444444'}
                    className={`cursor-pointer mb-4 ${styles.left_text}${
                      sideTab === 'CAN' ? ` ${styles.selected}` : ''
                    }`}
                    onClick={() => onClickSideTab('CAN')}
                  >
                    NEEV CAN Network
                  </CustomText>
                </div>
                <div>
                  <CustomText
                    font="medium"
                    size={16}
                    color={sideTab === 'CCOM' ? 'secondary' : '#444444'}
                    className={`cursor-pointer mb-4 ${styles.left_text}${
                      sideTab === 'CCOM' ? ` ${styles.selected}` : ''
                    }`}
                    onClick={() => onClickSideTab('CCOM')}
                  >
                    Change Communication
                  </CustomText>
                  <Space
                    direction="vertical"
                    size={0}
                    style={{ marginLeft: '20px' }}
                  >
                    {sideTab === 'CCOM'
                      ? titles?.map((item, index) => (
                          <div key={index}>
                            <CustomText
                              font="medium"
                              size={16}
                              color={
                                selectedTab === index + 1 ? 'secondary' : 1
                              }
                              className={`cursor-pointer mb-4 ${
                                styles.left_text
                              }${
                                selectedTab === index + 1
                                  ? ` ${styles.selected}`
                                  : ''
                              }`}
                              onClick={() => onClickDecider(index + 1)}
                            >
                              {item.title}
                              <span style={{ fontSize: '14px' }}>
                                {item?.subTitle}
                              </span>
                            </CustomText>
                            {selectedTab === index + 1 && (
                              <div
                                style={{ marginLeft: '20px', marginTop: '5px' }}
                              >
                                {item?.children?.map((child, childIndex) => (
                                  <CustomText
                                    key={childIndex}
                                    font="medium"
                                    size={13}
                                    color={
                                      selectedChild === child ? 'secondary' : 1
                                    }
                                    className={`cursor-pointer mb-3 ${
                                      styles.left_text
                                    }${
                                      selectedChild === child
                                        ? ` ${styles.selected}`
                                        : ''
                                    }`}
                                    onClick={() =>
                                      onClickDecider(index + 1, child)
                                    }
                                  >
                                    {child}
                                    <br />
                                  </CustomText>
                                ))}
                              </div>
                            )}
                          </div>
                        ))
                      : ''}
                  </Space>
                </div>
              </Space>
            </Space>
            {!isMobile ? <Divider style={{ margin: '32px 0' }} /> : null}
          </Col>
          <Col xs={24} sm={18}>
            <div className={styles.right_container}>{newsletterContent()}</div>
          </Col>
        </Row>
      </CustomContainer>
    </div>
  );
};

export default ChangeEnablementPage;
