import CustomContainer from '@/common/CustomContainer';
import styles from './idea.module.less';
import { Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import IdeaCardDetails from '../IdeaCardDetails';
import { history } from '@/app/history';
import { sizeChecker } from 'utilities/helpers';
import CustomText from '@/common/CustomText';

const IdeaPage = ({
  idea,
  handlePostLikeIdea,
  handlePostDislikeIdea,
  postCommentIdeasLoading,
  handlePostCommentIdea
}) => {
  if (!idea) return null;

  const isMobile = sizeChecker('xs');
  return (
    <CustomContainer containerClassname={styles.container}>
      <Space direction="vertical" size={44} className="w-100">
        {isMobile ? (
          <CustomText font="medium" size={26} color={10}>
            Queries & Suggestions
          </CustomText>
        ) : null}
        {/* <div className={styles.share_btn_container}>
          <CustomButton
            type="primary"
            onClick={() => history.push('/ideas/create')}
          >
            Share your thoughts
          </CustomButton>
        </div> */}
        <IdeaCardDetails
          item={idea}
          handlePostLikeIdea={handlePostLikeIdea}
          handlePostDislikeIdea={handlePostDislikeIdea}
          containerStyle={{ padding: 26 }}
          postCommentIdeasLoading={postCommentIdeasLoading}
          handlePostCommentIdea={handlePostCommentIdea}
        />
      </Space>
      <div style={{ marginTop: '20px' }}>
        <CustomButton
          className={`${styles.back_style}`}
          onClick={() => history.push('/ideas/list')}
        >
          Back
        </CustomButton>
      </div>
    </CustomContainer>
  );
};

export default IdeaPage;
