import React, { useEffect, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import August1 from '../../images/Project NEEV Bulletin_Aug23-1.jpg';
import August2 from '../../images/Project NEEV Bulletin_Aug23-2.jpg';

import September1 from '../../images/Project NEEV Bulletin_Sep28-1.jpg';
import September2 from '../../images/Project NEEV Bulletin_Sep28-2.jpg';

import September12_1 from '../../images/Project NEEV Bulletin_Sep12-1.jpg';
import September12_2 from '../../images/Project NEEV Bulletin_Sep12-2.jpg';

import October12_1 from '../../images/NEEV-Bulletin_Oct12_page1.jpg';
import October12_2 from '../../images/NEEV-Bulletin_Oct12_page2.jpg';

import November11_1 from '../../images/Project NEEV Bulletin_Nov11_Page 1.png';
import November11_2 from '../../images/Project NEEV Bulletin_Nov11_Page 2.png';
import November23_1 from '../../images/NEEV-Bulletin-6th-Edition-01_23Nov.jpg';
import November23_2 from '../../images/NEEV-Bulletin-6th-Edition-02_23Nov.jpg';

import Dec_1 from '../../images/BulletinEdition_7/Bulletin Edition 07-1.jpg';
import Dec_2 from '../../images/BulletinEdition_7/Bulletin Edition 07-02.jpg';
import Dec_3 from '../../images/BulletinEdition_7/Bulletin Edition 07-03.jpg';
import Dec_4 from '../../images/BulletinEdition_7/Bulletin Edition 07-04.jpg';
import Dec_5 from '../../images/BulletinEdition_7/Bulletin Edition 07-05.jpg';
import Dec_6 from '../../images/BulletinEdition_7/Bulletin Edition 07-06.jpg';

import Bulletin8th01 from '../../images/BulletinEdition_8/Bulletin8th01.jpg';
import Bulletin8th02 from '../../images/BulletinEdition_8/Bulletin8th02.jpg';

import Bulletin9th01 from '../../images/BulletinEdition_9/Bulletin9th01.jpg';
import Bulletin9th02 from '../../images/BulletinEdition_9/Bulletin9th02.jpg';

import Bulletin10th01 from '../../images/BulletinEdition_10/Bulletin10th01.jpg';
import Bulletin10th02 from '../../images/BulletinEdition_10/Bulletin10th02.jpg';

import { sizeChecker } from 'utilities/helpers';
// import { Carousel } from 'antd';
// import styles from './index.module.less';
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const FlipBook = ({ selectedChild }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth * 0.2,
    height: window.innerHeight * 0.8
  });

  const isMobile = sizeChecker('xs');

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth * 0.2,
        height: window.innerHeight * 0.8
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { width, height } = dimensions;
  let images = [];

  // Load images based on selectedChild value
  if (selectedChild === 'Edition 1') {
    images = [August1, August2];
  } else if (selectedChild === 'Edition 3') {
    images = [September1, September2];
  } else if (selectedChild === 'Edition 2') {
    images = [September12_1, September12_2];
  } else if (selectedChild === 'Edition 4') {
    images = [October12_1, October12_2];
  } else if (selectedChild === 'Edition 5') {
    images = [November11_1, November11_2];
  } else if (selectedChild === 'Edition 6') {
    images = [November23_1, November23_2];
  } else if (selectedChild === 'Edition 7') {
    images = [Dec_1, Dec_2, Dec_3, Dec_4, Dec_5, Dec_6];
  } else if (selectedChild === 'Edition 8') {
    images = [Bulletin8th01, Bulletin8th02];
  } else if (selectedChild === 'Edition 9') {
    images = [Bulletin9th01, Bulletin9th02];
  } else if (selectedChild === 'Edition 10') {
    images = [Bulletin10th01, Bulletin10th02];
  }

  return (
    <React.Fragment
    // Apply zoom classes based on state
    >
      <HTMLFlipBook
        minWidth={isMobile ? 300 : width}
        minHeight={isMobile ? 700 : height}
        width={isMobile ? 300 : width}
        height={isMobile ? 700 : height}
        size="stretch"
        // minWidthCss={isMobile ? 300 : width}
        // minHeightCss={isMobile ? 100 : height}
        autoSize={true}
        maxShadowOpacity={0.5}
        mobileScrollSupport={true}
      >
        {images.map((image, index) => (
          <img key={index} src={image} alt="" />
        ))}
      </HTMLFlipBook>
    </React.Fragment>
  );
};

export default FlipBook;
