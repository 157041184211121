import { useState } from 'react';
import { Carousel, Col, Image, Modal, Row, Card } from 'antd';
//import { Fade } from 'react-reveal';
import styles from './bannersConcurSAC.module.less';

//import Carousel_1 from '../../images/banners/Emailer_Carousel_1.svg';
import Carousel_1 from '../../images/banners/GoLiveCarouselBanner_2.png';
import MobCarousel_1 from '../../images/banners/GoLiveCarouselBanner_2.png';
import playCircle from '../../images/play-circle.svg';
import Carousel_2 from '../../images/banners/newBanners/Concur1.jpg';
import Carousel_3 from '../../images/banners/newBanners/Concur2.jpg';
import Carousel_4 from '../../images/banners/newBanners/Concur3.jpg';
import Carousel_5 from '../../images/banners/newBanners/Concur4.jpg';
import Carousel_6 from '../../images/banners/newBanners/Concur5.jpg';
import Carousel_7 from '../../images/banners/newBanners/Concur6.jpg';

//import CustomText from 'common/CustomText';
import CustomButton from 'common/CustomButton';
import { sizeChecker } from 'utilities/helpers';
//import { history } from '@/app/history';
import project_neev from '../../images/banners/project_neev.mp4';
import VinayakPai from '../../images/banners/VinayakPai.mp4';

import icon1 from '../../images/banners/seedNeev_icon/icon1.svg';
import icon2 from '../../images/banners/seedNeev_icon/icon2.svg';
import icon3 from '../../images/banners/seedNeev_icon/icon3.svg';
import seedMap from '../../images/banners/seedNeev_icon/seedMap.svg';
import SAPConcur from '../../images/banners/SAPConcur.mp4';

const data = [
  {
    id: 1,
    titleProps: {
      text: '', // 'SAP Concur Overview Video',
      font: 'medium'
    },
    description: '',
    subDescription: ``,
    btnProps: {
      icon: playCircle,
      title: '',
      bg: 'transparent',
      borderColor: '#FFF'
    },
    image: null,
    bg: Carousel_1,
    mbBg: MobCarousel_1 //mb1
  },
  {
    id: 2,
    titleProps: {
      text: '', // 'SAP Concur Overview Video',
      font: 'medium'
    },
    description: '',
    subDescription: ``,
    btnProps: {
      icon: null, // playCircle,
      title: '',
      bg: 'transparent',
      borderColor: '#FFF'
    },
    image: null,
    bg: Carousel_2,
    mbBg: null //mb1
  },
  {
    id: 3,
    titleProps: {
      text: '', // 'SAP Concur Overview Video',
      font: 'medium'
    },
    description: '',
    subDescription: ``,
    btnProps: {
      icon: null, // playCircle,
      title: '',
      bg: 'transparent',
      borderColor: '#FFF'
    },
    image: null,
    bg: Carousel_3,
    mbBg: null //mb1
  },
  {
    id: 4,
    titleProps: {
      text: '', // 'SAP Concur Overview Video',
      font: 'medium'
    },
    description: '',
    subDescription: ``,
    btnProps: {
      icon: null, // playCircle,
      title: '',
      bg: 'transparent',
      borderColor: '#FFF'
    },
    image: null,
    bg: Carousel_4,
    mbBg: null //mb1
  },
  {
    id: 5,
    titleProps: {
      text: '', // 'SAP Concur Overview Video',
      font: 'medium'
    },
    description: '',
    subDescription: ``,
    btnProps: {
      icon: null, // playCircle,
      title: '',
      bg: 'transparent',
      borderColor: '#FFF'
    },
    image: null,
    bg: Carousel_5,
    mbBg: null //mb1
  },
  {
    id: 6,
    titleProps: {
      text: '', // 'SAP Concur Overview Video',
      font: 'medium'
    },
    description: '',
    subDescription: ``,
    btnProps: {
      icon: null, // playCircle,
      title: '',
      bg: 'transparent',
      borderColor: '#FFF'
    },
    image: null,
    bg: Carousel_6,
    mbBg: null //mb1
  },
  {
    id: 7,
    titleProps: {
      text: '', // 'SAP Concur Overview Video',
      font: 'medium'
    },
    description: '',
    subDescription: ``,
    btnProps: {
      icon: null, // playCircle,
      title: '',
      bg: 'transparent',
      borderColor: '#FFF'
    },
    image: null,
    bg: Carousel_7,
    mbBg: null //mb1
  }

  //--------------------
  // {
  //   id: 2,
  //   titleProps: {
  //     text: '', // 'Catch a glimpse of our MD sharing insights on Project NEEV',
  //     font: 'medium'
  //   },
  //   description: '',
  //   subDescription: '', //`- Vinayak Pai, MD`,
  //   btnProps: '',
  //   //  {
  //   //   icon: playCircle,
  //   //   title: 'Watch Video',
  //   //   bg: 'transparent',
  //   //   borderColor: '#FFF'
  //   // },
  //   //image: '', // md_desk,
  //   bg: one,
  //   mbBg: mb1
  // }
  // {
  //   id: 3,
  //   titleProps: { text: 'Making Tata Projects Future Ready', font: 'bold' },
  //   description:
  //     'With the implementation of SAP S/4HANA, Tata Projects will benefit from cutting-edge technology that helps us streamline operations, enhance collaboration, make better decisions and drive excellence—as one team.',
  //   btnProps: {
  //     title: 'Discover How The Seed of NEEV was Planted',
  //     bg: 'transparent',
  //     borderColor: '#FFF'
  //   },
  //   image: '',
  //   bg: two,
  //   mbBg: mb1
  // },
  // {
  //   id: 4,
  //   titleProps: { text: 'Project NEEV Kicks Off', font: 'medium' },
  //   description:
  //     "After weeks of intense deliberation and planning, Project NEEV was officially kicked off in the presence of the senior management and the core team. Here's to a successful engagement.",
  //   btnProps: {
  //     title: 'See Updates',
  //     bg: 'transparent', // '#2364AD',
  //     borderColor: '#FFF' // '#2364AD'
  //   },
  //   imageProps: {
  //     img1: neevCickOff1,
  //     img2: neevCickOff2,
  //     img3: neevCickOff3
  //   },
  //   bg: three,
  //   mbBg: mb2
  // },
  // {
  //   id: 5,
  //   titleProps: { text: 'A Project of Towering Ambition', font: 'medium' },
  //   description:
  //     'Project NEEV is an undertaking of massive scale and scope that could potentially reshape the future of Tata Projects. Meeting the aggressive timeline of April 01, 2024 will be key to maintaining momentum in our transformation journey.',
  //   btnProps: {
  //     title: 'Contribute',
  //     bg: 'transparent', // '#2364AD',
  //     borderColor: '#FFF' // '#2364AD'
  //   },
  //   imageProps: {
  //     img1: towring4,
  //     img2: towring5,
  //     img3: towring6
  //   },
  //   bg: four,
  //   mbBg: mb3
  // },
  // {
  //   id: 6,
  //   titleProps: { text: 'From Potential to Performance', font: 'medium' },
  //   description:
  //     'Project NEEV is about planting the seeds for the Tata Projects of the future, about nourishing its potential to become a company that delivers all-round excellence consistently.',
  //   btnProps: {
  //     icon: playCircle,
  //     title: 'Watch Video',
  //     bg: 'transparent',
  //     borderColor: '#FFF'
  //   },
  //   bg: five,
  //   mbBg: mb4
  // }
];

const BannersConcurSAC = () => {
  const isMobile = sizeChecker('xs');

  const [slide, setSlide] = useState(0);
  const [openAboutNeevModal, setOpenAboutNeevModal] = useState(false);
  const [openAboutWatchVideoModal, setOpenAboutWatchVideoModal] =
    useState(false);
  //const [isHovered, setIsHovered] = useState(false);
  const [openMDWatchVideoModal, setOpenMDWatchVideoModal] = useState(false);
  const [openSAPConcurVideoModal, setOpenSAPConcurVideoModal] = useState(false);

  // const handleMouseToggle = () => {
  //   setIsHovered(!isHovered);
  // };

  console.log('slide', slide);

  const toggleOpenAboutNeevModal = () => {
    setOpenAboutNeevModal(!openAboutNeevModal);
  };

  const toggleOpenAboutWatchVideoModal = () => {
    setOpenAboutWatchVideoModal(!openAboutWatchVideoModal);
  };

  const toggleOpenMDWatchVideoModal = () => {
    setOpenMDWatchVideoModal(!openMDWatchVideoModal);
  };
  const toggleOpenSAPConcurVideoModal = () => {
    setOpenSAPConcurVideoModal(!openSAPConcurVideoModal);
  };

  // const btnOnClick = {
  //   1: toggleOpenSAPConcurVideoModal,
  //   2: toggleOpenMDWatchVideoModal, //toggleOpenAboutNeevModal,
  //   3: toggleOpenAboutNeevModal, //() => history.push('/feelTheChange'),
  //   // 3: () => history.push('/ideas/list'),
  //   6: toggleOpenAboutWatchVideoModal
  // };

  return (
    <div id="banners" className={styles.container}>
      <Carousel
        effect="fade"
        className={styles.carousel_container}
        autoplay
        autoplaySpeed={5000}
        beforeChange={(currentSlide) => {
          setSlide(currentSlide + 1);
        }}
        afterChange={(currentSlide) => {
          setSlide(currentSlide);
        }}
      >
        {data.map((item) => {
          const {
            id,
            // titleProps,
            // description,
            // btnProps,
            bg,
            mbBg
            // image,
            // imageProps
          } = item;

          return (
            <div key={id} className={styles.carousel_item_container}>
              <div
                className={styles.outer_container}
                style={{
                  background: `url(${
                    isMobile && id === 1 ? mbBg : bg
                  }) no-repeat center`
                  // cursor: id === 1 ? 'pointer' : null
                }}
                // onClick={() => {
                //   if (id === 1) {
                //     if (btnOnClick[id]) {
                //       btnOnClick[id]();
                //     }
                //   }
                // }}
              >
                {/*  <Space
                  direction="vertical"
                  size={isMobile ? 20 : 48}
                  className={styles.inner_container}
                  {...(isMobile ? { align: 'start' } : {})} 
                >
                  <Space
                    direction={isMobile ? 'vertical' : 'horizontal'}
                    size={isMobile ? 20 : 24}
                    style={{ width: '100%' }}
                  >
                    <Row
                      gutter={100}
                      style={{
                        display: isMobile ? 'flex' : '',
                        flexDirection: isMobile ? 'column-reverse' : '',
                        gap:
                          (isMobile && id === 6) ||
                          (isMobile && id === 2) ||
                          (isMobile && id === 3)
                            ? '20px'
                            : isMobile
                            ? '40px'
                            : ''
                      }}
                    >
                      <Col span={isMobile ? 24 : 12}>
                        {id === 2 || id === 3 || id === 6 ? (
                          <div className={`${styles.tab2_text_container}`}>
                            <div style={{ marginBottom: '25px' }}>
                              <Fade top when={slide === index} duration={2000}>
                                <CustomText
                                  className={`${styles.titleProps_text}`}
                                  font="medium"
                                  size={isMobile && 28}
                                  color="white"
                                  {...(isMobile
                                    ? {}
                                    : {
                                        letterSpacing: -1.08,
                                        lineHeight: '42px'
                                      })}
                                >
                                  {titleProps.text}
                                </CustomText>
                              </Fade>
                            </div>
                            <Fade left when={slide === index} duration={2000}>
                              <CustomText
                                size={isMobile && 16}
                                color="white"
                                className={`${styles.titleProps_descriptionText}`}
                              >
                                {description}
                              </CustomText>
                            </Fade>

                            {id === 2 && (
                              <Fade
                                bottom
                                when={slide === index}
                                duration={2000}
                              >
                                <div
                                  onMouseEnter={handleMouseToggle}
                                  onMouseLeave={handleMouseToggle}
                                  className={`${styles.btn_container_tab1} cursor-pointer`}
                                  style={{
                                    backgroundColor: isHovered
                                      ? '#090'
                                      : btnProps.bg,
                                    border: `2px solid ${btnProps.borderColor}`
                                  }}
                                  onClick={() => {
                                    if (btnOnClick[id]) {
                                      btnOnClick[id]();
                                    }
                                  }}
                                >
                                  <Space align="start">
                                    {btnProps?.icon ? (
                                      <Image src={btnProps?.icon} />
                                    ) : null}
                                    <CustomText
                                      font="bold"
                                      color="white"
                                      size={18}
                                      className={
                                        (isMobile && id === 2) || id === 3
                                          ? `${styles.btn_container_text_tab5}`
                                          : `${styles.btn_container_text}`
                                      }
                                    >
                                      {btnProps.title}
                                    </CustomText>
                                  </Space>
                                </div>
                              </Fade>
                            )}
                          </div>
                        ) : (
                          <div
                            className={
                              id === 5
                                ? `${styles.tab2_text_container4}`
                                : id === 1 && isMobile
                                ? `${styles.tab1_text_container3}`
                                : `${styles.tab2_text_container3}`
                            }
                          >
                            <div
                              style={{
                                marginBottom:
                                  isMobile && id === 5
                                    ? '10px'
                                    : isMobile === false && id === 1
                                    ? '10px'
                                    : '25px'
                              }}
                            >
                              <Fade top when={slide === index} duration={2000}>
                                <CustomText
                                  className={`${styles.titleProps_text3}`}
                                  font="medium"
                                  size={isMobile && 28}
                                  color={id === 1 ? '#323282' : 'white'}
                                  style={{
                                    color: id === 1 ? '#323282' : 'white'
                                  }}
                                  {...(isMobile
                                    ? {}
                                    : {
                                        letterSpacing: -1.08,
                                        lineHeight: '42px'
                                      })}
                                >
                                  {titleProps.text}
                                </CustomText>
                              </Fade>
                            </div>
                            <Fade left when={slide === index} duration={2000}>
                              <CustomText
                                size={isMobile && 16}
                                color="white"
                                className={
                                  id === 1
                                    ? ''
                                    : `${styles.titleProps_descriptionText3}`
                                }
                              >
                                {id === 1 ? (
                                  <div
                                    style={{
                                      color: 'black',
                                      fontSize: isMobile ? '19px' : '16px',
                                      fontFamily: 'CerebriSans Regular',
                                      lineHeight: '0px !important',
                                      marginTop: '20px'
                                    }}
                                  >
                                    <b style={{ fontSize: '18px' }}>
                                      Click the play button{' '}
                                    </b>{' '}
                                    to view a 3-minute video which gives you an
                                    overview of SAP Concur
                                    <div
                                      style={{
                                        fontSize: '18px',
                                        marginTop: '15px',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      //  Going LIVE on 1<sup>st</sup> January 2024 
                                    </div>
                                  </div>
                                ) : (
                                  description
                                )}
                              </CustomText>
                            </Fade>
                          </div>
                        )}
                      </Col>
                      <Col span={isMobile ? 24 : 12}>
                        {id === 2 ? (
                          <Fade right when={slide === index} duration={2000}>
                            <div>
                              <Image
                                // alt="md_desk"
                                src={image}
                                preview={false}
                                //style={{ width: '110%', height: '110%' }}
                                className={`${styles.banners_image_md}`}
                              />
                            </div>
                          </Fade>
                        ) : id === 4 ? (
                          <div className={`${styles.image_container3}`}>
                            <Row
                              gutter={[20, 20]}
                              style={{ marginRight: '0px' }}
                            >
                              <Col span={12}>
                                <Fade
                                  top
                                  when={slide === index}
                                  duration={2000}
                                >
                                  <Image
                                    alt="md_desk"
                                    src={imageProps?.img1}
                                    preview={false}
                                    className={`${styles.tab3_image1}`}
                                  />

                                  <Image
                                    className={`${styles.tab3_image2}`}
                                    alt="md_desk"
                                    src={imageProps?.img2}
                                    preview={false}
                                    //style={{ width: '120%', height: '120%' }}
                                  />
                                </Fade>
                              </Col>

                              <Col span={12} style={{ marginTop: '20%' }}>
                                <Fade
                                  right
                                  when={slide === index}
                                  duration={2000}
                                >
                                  <Image
                                    className={`${styles.tab3_image3}`}
                                    alt="md_desk"
                                    src={imageProps?.img3}
                                    preview={false}
                                    // style={{
                                    //   width: '120%',
                                    //   height: '120%',
                                    //   marginLeft: '40px'
                                    // }}
                                  />
                                </Fade>
                              </Col>
                            </Row>
                          </div>
                        ) : id === 5 ? (
                          <div className={`${styles.image_container4}`}>
                            <Row gutter={[20, 20]}>
                              <Col
                                span={12}
                                style={{
                                  marginTop: isMobile ? '0px' : '10px'
                                }}
                              >
                                <Fade
                                  top
                                  when={slide === index}
                                  duration={2000}
                                >
                                  <Image
                                    alt="md_desk"
                                    src={imageProps?.img1}
                                    preview={false}
                                    // style={{ width: '130%', height: '130%' }}
                                    className={`${styles.tab4_image1}`}
                                  />
                                </Fade>
                              </Col>
                              <Col span={12}>
                                <div
                                  // style={{
                                  //   marginLeft: '70px',
                                  //   marginTop: '23%'
                                  // }}
                                  className={`${styles.tab4_image_subContainer}`}
                                >
                                  <Fade
                                    right
                                    when={slide === index}
                                    duration={2000}
                                  >
                                    <Image
                                      alt="md_desk"
                                      src={imageProps?.img2}
                                      preview={false}
                                      // style={{
                                      //   width: '145%',
                                      //   height: '145%',
                                      //   marginBottom: '10px'
                                      // }}
                                      className={`${styles.tab4_image2}`}
                                    />
                                  </Fade>
                                  <Fade
                                    right
                                    when={slide === index}
                                    duration={2000}
                                  >
                                    <Image
                                      alt="md_desk"
                                      src={imageProps?.img3}
                                      preview={false}
                                      // style={{ width: '145%', height: '145%' }}
                                      className={`${styles.tab4_image3}`}
                                    />
                                  </Fade>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </Space>
                  {id === 3 || id === 6 ? (
                    <Fade bottom when={slide === index} duration={2000}>
                      <div
                        onMouseEnter={handleMouseToggle}
                        onMouseLeave={handleMouseToggle}
                        className={
                          isMobile && id === 1
                            ? `${styles.btn_container_tab5} cursor-pointer`
                            : `${styles.btn_container} cursor-pointer`
                        }
                        style={{
                          backgroundColor: isHovered ? '#090' : btnProps.bg,
                          border: `2px solid ${btnProps.borderColor}`,
                          marginTop:
                            isMobile === false && id === 1
                              ? '-30px'
                              : isMobile && id === 1
                              ? '-10px'
                              : null
                        }}
                        onClick={() => {
                          if (btnOnClick[id]) {
                            btnOnClick[id]();
                          }
                        }}
                      >
                        <Space align="start">
                          {btnProps?.icon ? (
                            <Image src={btnProps?.icon} />
                          ) : null}
                          <CustomText
                            font="bold"
                            color="white"
                            size={18}
                            className={
                              isMobile && id === 6
                                ? `${styles.btn_container_text_tab5}`
                                : `${styles.btn_container_text}`
                            }
                          >
                            {btnProps.title}
                          </CustomText>
                        </Space>
                      </div>
                    </Fade>
                  ) : (
                    ''
                  )}
                </Space> */}
              </div>
            </div>
          );
        })}
      </Carousel>
      {openAboutNeevModal ? (
        <Modal
          open={openAboutNeevModal}
          onCancel={toggleOpenAboutNeevModal}
          centered
          // width={1090}
          width={isMobile ? '100%' : '70%'}
          // height={isMobile ? '100%' : '95%'}
          bodyStyle={{ height: '70%' }}
          footer={null}
          closable={false}
        >
          <div
            className={`${styles.modal_container}`}
            style={{
              minHeight: isMobile ? 700 : '',
              height: isMobile ? 700 : ''
            }}
          >
            <Row
              gutter={16}
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: isMobile ? '10px' : '',
                marginBottom: isMobile ? '20px' : ''
              }}
            >
              <Col span={isMobile ? 24 : 8}>
                <Card className={`${styles.seedNeev_card}`}>
                  <Row gutter={16}>
                    <Col span={4}>
                      <div className={`${styles.icon_bg}`}>
                        <Image
                          alt="icon1"
                          src={icon1}
                          preview={false}
                          // style={{ width: '24px', height: '24px' }}
                        />
                      </div>
                    </Col>
                    <Col span={20}>
                      <div className={`${styles.sn_title}`}>
                        TPL Business Functions & Leadership Engagement
                      </div>
                      <div className={`${styles.sn_subtitle}`}>
                        Extensive discussions, presentations and demos from
                        Oracle and SAP
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <Card className={`${styles.seedNeev_card}`}>
                  <Row gutter={16}>
                    <Col span={4}>
                      <div className={`${styles.icon_bg}`}>
                        <Image
                          alt="icon2"
                          src={icon2}
                          preview={false}
                          // style={{ width: '24px', height: '24px' }}
                        />
                      </div>
                    </Col>
                    <Col span={20}>
                      <div className={`${styles.sn_title}`}>OEM Engagement</div>
                      <div className={`${styles.sn_subtitle}`}>
                        {`OEMs have interacted with TPL's relevant BUs to
                        understand current state and present product
                        functionalities`}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <Card className={`${styles.seedNeev_card}`}>
                  <Row gutter={16}>
                    <Col span={4}>
                      <div className={`${styles.icon_bg}`}>
                        <Image
                          alt="icon3"
                          src={icon3}
                          preview={false}
                          // style={{ width: '24px', height: '24px' }}
                        />
                      </div>
                    </Col>
                    <Col span={20}>
                      <div className={`${styles.sn_title}`}>TCS Engagement</div>
                      <div className={`${styles.sn_subtitle}`}>
                        {`Engaged in discussions and demos from Oracle and SAP to
                        understand OEM's offerings and provide recommendation to
                        TPL`}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginBottom: '5px' }}>
              <Card>
                {' '}
                <Image
                  alt="seedMap"
                  src={seedMap}
                  preview={false}
                  style={{ width: '100%', height: '100%' }}
                />
              </Card>
            </Row>
            <div className="d-flex justify-content-end">
              <CustomButton
                size="large"
                onClick={toggleOpenAboutNeevModal}
                className="text-right"
              >
                Close
              </CustomButton>
            </div>
          </div>
        </Modal>
      ) : null}

      {openAboutWatchVideoModal ? (
        <Modal
          open={openAboutWatchVideoModal}
          onCancel={toggleOpenAboutWatchVideoModal}
          centered
          //width={1090}
          width={isMobile ? '100%' : '95%'}
          //height={isMobile ? '100%' : '95%'}
          bodyStyle={{ height: '95%' }}
          footer={null}
          closable={false}
        >
          <div>
            <video
              controls
              src={`${project_neev}#t=0.001`}
              style={{ width: '100%', height: '515px', borderRadius: 8 }}
            />
            <div className="d-flex justify-content-end mt-2">
              <CustomButton
                size="large"
                onClick={toggleOpenAboutWatchVideoModal}
                className="text-right"
              >
                Close
              </CustomButton>
            </div>
          </div>
        </Modal>
      ) : null}

      {openMDWatchVideoModal ? (
        <Modal
          open={openMDWatchVideoModal}
          onCancel={toggleOpenMDWatchVideoModal}
          centered
          // width={1090}
          width={isMobile ? '100%' : '95%'}
          //height={isMobile ? '100%' : '95%'}
          bodyStyle={{ height: '95%' }}
          footer={null}
          closable={false}
        >
          <div>
            <video
              controls
              src={`${VinayakPai}#t=0.001`}
              style={{ width: '100%', height: '515px', borderRadius: 8 }}
            />

            <div className="d-flex justify-content-end  mt-2">
              <CustomButton
                size="large"
                onClick={toggleOpenMDWatchVideoModal}
                className=" text-right"
              >
                Close
              </CustomButton>
            </div>
          </div>
        </Modal>
      ) : null}
      {openSAPConcurVideoModal ? (
        <Modal
          open={openSAPConcurVideoModal}
          onCancel={toggleOpenSAPConcurVideoModal}
          centered
          // width={1090}
          width={isMobile ? '100%' : '95%'}
          // height={isMobile ? '100%' : '75%'}
          bodyStyle={{ height: '95%' }}
          footer={null}
          closable={false}
        >
          <div>
            <video
              controls
              src={`${SAPConcur}#t=0.001`}
              style={{ width: '100%', height: '515px', borderRadius: 8 }}
            />

            <div className="d-flex justify-content-end  mt-2">
              <CustomButton
                size="large"
                onClick={toggleOpenSAPConcurVideoModal}
                className=" text-right"
              >
                Close
              </CustomButton>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default BannersConcurSAC;
