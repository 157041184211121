import React from 'react';
import Layout from 'common/CustomLayout';
import AdminConsolePage from './components/adminConsolePage';
import { TabTitle } from 'utilities/helpers';

const AdminConsole = () => {
  TabTitle('Neev - Admin Console');
  return (
    <Layout>
      <AdminConsolePage />
    </Layout>
  );
};

export default AdminConsole;
