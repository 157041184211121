import { useEffect, useState } from 'react';
import one from '../../images/1.png';
import two from '../../images/2.png';
import three from '../../images/3.png';
import four from '../../images/4.png';
import five from '../../images/5.png';
import six from '../../images/6.png';
import seven from '../../images/7.png';
import eight from '../../images/8.jpg';
//import calendar from '../../images/calendar.svg';
//import header from '../../images/header.svg';
import { Col, Image, Row, Space, Card, DatePicker } from 'antd';
import moment from 'moment';
import styles from './hof.module.less';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import { sizeChecker } from 'utilities/helpers';
import AkshayGarg from '../../images/Oct/Akshay Garg.jpeg';
import AkshayVilli from '../../images/Oct/Akshay Villivalam.png';
import Amit from '../../images/Oct/Amit Mehta.jpg';
import Amruta from '../../images/Oct/Amruta Lokhande.png';
import Animay from '../../images/Oct/Animay Kumar 2.png';
import ArunKumar from '../../images/Oct/Arunkumar Suryanarayan Rao.jpg';
import Harsh from '../../images/Oct/Harsh Shinde.jpg';
import Karthik from '../../images/Oct/Karthikeyan Rajendran.jpg';
import Mayura from '../../images/Oct/Mayura Prabhudesai.png';
import Ravi from '../../images/Oct/Ravishankar Iyer.jpg';
import Ajinkya from '../../images/Dec/Ajinkya.png';
import Ashok from '../../images/Dec/Ashok.png';
import Mirza from '../../images/Dec/Mirza.png';
import Roselin from '../../images/Dec/Roselin.png';
import Vedika from '../../images/Dec/Vedika.png';
import background from '../../images/background.svg';

import RajMohan from '../../images/ConcurWinners/A Raj Mohan_ConcurImage.jpg 1.png';
import BhushanChaudhari from '../../images/ConcurWinners/Bhushan Chaudhari_Concur Image.jpg';
import GaneshwarPal from '../../images/ConcurWinners/Concur Photo Frame Ganeshwar Pal.jpg_page-0001.jpg';
import KhumanSahu from '../../images/ConcurWinners/Khuman Sahu_ConcurImage 1.jpg';
import MadhaviChinthala from '../../images/ConcurWinners/Madhavi Chinthala_Concurimage 4.jpg';
import MohammadAbbas from '../../images/ConcurWinners/Mohammad Abbas Bhat_ConcurImage.jpg';
import NirliptaSwain from '../../images/ConcurWinners/Nirlipta Swain_ConcurImage.jpg.jpg';
import Sandeep from '../../images/ConcurWinners/Sandeep_ConcurImage.jpg.png';
import SomaKotesh from '../../images/ConcurWinners/Soma Kotesh_Concurimage.jpg';
import VNagendra from '../../images/ConcurWinners/‘V.Nagendra Prasad_ConcurImage.jpg';
import SrinivasKumar from '../../images/Jan/SrinivasKumar.png';
import BrijeshKumar from '../../images/Jan/BrijeshKumar.jpg';
import SomveerSingh from '../../images/Jan/SomveerSingh.png';
import GeetVaishnav from '../../images/Jan/GeetVaishnav.jpg';

import Balasubramanya from '../../images/Feb24/Balasubramanya.jpg';
import HasnainAnsari from '../../images/Feb24/Hasnain Ansari - Survey.jpg';
import ManjunathMallappaBudihal from '../../images/Feb24/Manjunath Mallappa Budihal- SCM.png';
import PraveshKasliwal from '../../images/Feb24/Pravesh Kasliwal - Finance & Treasury.jpg';
import ShrutiJha from '../../images/Feb24/Shruti Jha- SCM.jpg';
import SwapnilRameshRaoHukum from '../../images/Feb24/Swapnil Ramesh Rao Hukum- F&A.jpg';
import VaishaliAgrawal from '../../images/Feb24/Vaishali Agrawal.jpeg';
import ZaidShaikh from '../../images/Feb24/Zaid Shaikh.jpg';

const winnersSep = [
  {
    id: 1,
    title: 'NEEV Champs',
    description1: `Congratulations to the NEEV Champs for acting as the prime movers of the SAP Implementation.`,
    description2: `They have shown exceptional skills, dedication, and determination in driving innovation and success!`,
    type: 'Quiz',
    Winners: [
      {
        id: 1,
        image: seven,
        name: 'Prasad Kulkarni - TPL',
        role: 'Technical Lead',
        email: 'prasadkulkarni@tataprojects.com',
        description: ''
      },
      {
        id: 2,
        image: six,
        name: 'Pradeep Konda - TPL',
        role: 'Technical Lead',
        email: 'pradeepkumark@tataprojects.com',
        description: ''
      },
      {
        id: 3,
        image: one,
        name: 'Anmol Malhotra - TCS',
        role: 'Director',
        email: 'anmolm-v@tataprojects.com',
        description: ''
      },
      {
        id: 4,
        image: five,
        name: 'Neeraj Manthanwar - TCS',
        role: 'Technical Lead',
        email: 'neerajm1-v@tataprojects.com',
        description: ''
      },
      {
        id: 5,
        image: three,
        name: 'Gaurav Jain - SAP',
        role: 'Functional Lead',
        email: 'gauravjain-v@tataprojects.com',
        description: ''
      },
      {
        id: 6,
        image: eight,
        name: 'Raju Dandu - SAP',
        role: 'Technical Lead',
        email: 'raju.dandu@sap.com',
        description: ''
      },
      {
        id: 7,
        image: four,
        name: 'Mahek Mehta - Deloitte',
        role: 'Technical Lead',
        email: 'mahekm-v@tataprojects.com',
        description: ''
      },
      {
        id: 8,
        image: two,
        name: 'Arka Choudhary - Deloitte',
        role: 'Engagement Manager',
        email: 'arkac-v@tataprojects.com',
        description: ''
      }
    ]
  }
];
const winnersOct = [
  {
    id: 1,
    title: 'NEEV Champs',
    description1: `Congratulations to the NEEV Champs for acting as the prime movers of the SAP Implementation.`,
    description2: `They have shown exceptional skills, dedication, and determination in driving innovation and success!`,
    type: 'Quiz',
    Winners: [
      {
        id: 1,
        image: Amruta,
        name: 'Amruta Lokhande– TPL​',
        role: '',
        email: 'amrutal@tataprojects.com',
        description: ''
      },
      {
        id: 2,
        image: Animay,
        name: 'Animay Kumar– TPL​',
        role: '',
        email: 'animaykumar@tataprojects.com​',
        description: ''
      },
      {
        id: 3,
        image: AkshayVilli,
        name: 'Akshay Villivalam– TPL​',
        role: '',
        email: 'akshayv@tataprojects.com',
        description: ''
      },
      {
        id: 4,
        image: Mayura,
        name: `Mayura Prabhudesai - TPL`,
        role: '',
        email: 'mayurap@tataprojects.com​',
        description: ''
      },
      {
        id: 5,
        image: Amit,
        name: 'Amit Mehta – TCS​ ​',
        role: '',
        email: 'amitm-v@tataprojects.com',
        description: ''
      },
      {
        id: 6,
        image: AkshayGarg,
        name: 'Akshay Garg – TCS​​',
        role: '',
        email: 'akshayg-v@tataprojects.com',
        description: ''
      },
      {
        id: 7,
        image: Harsh,
        name: 'Harsh Shinde - Deloitte​',
        role: '',
        email: 'harshs-v@tataprojects.com​',
        description: ''
      },
      {
        id: 8,
        image: Ravi,
        name: 'Ravishankar Iyer– Deloitte​​',
        role: '',
        email: 'ravishankariyer-v@tataprojects.com',
        description: ''
      },
      {
        id: 9,
        image: ArunKumar,
        name: 'Arunkumar Rao– SAP​​',
        role: '',
        email: 'arunr-v@tataprojects.com​​',
        description: ''
      },
      {
        id: 10,
        image: Karthik,
        name: 'Karthikeyan Rajendran– SAP​​',
        role: '',
        email: 'karthikeyanr-v@tataprojects.com​',
        description: ''
      }
    ]
  }
];
const winnersDec = [
  {
    id: 1,
    title: 'Concur Photo Frame Contest Winners!',
    description1: '',
    description2: '',
    type: 'Concur',
    Winners: [
      {
        id: 1,
        image: Sandeep,
        name: 'Sandeep P',
        role: 'Finance & Accounts',
        email: '',
        description: ''
      },
      {
        id: 2,
        image: GaneshwarPal,
        name: 'Ganeshwar Pal',
        role: 'Materials',
        email: '',
        description: ''
      },
      {
        id: 3,
        image: NirliptaSwain,
        name: 'Nirlipta Swain',
        role: 'Information Technology',
        email: '',
        description: ''
      },
      {
        id: 4,
        image: MohammadAbbas,
        name: 'Mohammad Abbas Bhat',
        role: 'Management Trainee',
        email: '',
        description: ''
      },
      {
        id: 5,
        image: MadhaviChinthala,
        name: 'C Madhavi',
        role: 'Finance & Accounts',
        email: '',
        description: ''
      },
      {
        id: 6,
        image: BhushanChaudhari,
        name: 'Bhushan Yograj Choudhari',
        role: 'Quantity Survey',
        email: '',
        description: ''
      },
      {
        id: 7,
        image: RajMohan,
        name: 'Raj Mohan A',
        role: 'Business Excellence',
        email: '',
        description: ''
      },
      {
        id: 8,
        image: VNagendra,
        name: 'Nagendra Prasad',
        role: 'Quality Services',
        email: '',
        description: ''
      },
      {
        id: 9,
        image: KhumanSahu,
        name: 'Khuman Singh Sahu',
        role: 'Finance & Accounts',
        email: '',
        description: ''
      },
      {
        id: 10,
        image: SomaKotesh,
        name: 'Soma Kotesh',
        role: 'Finance & Accounts',
        email: '',
        description: ''
      }
    ]
  },
  {
    id: 2,
    title: 'NEEV Quiz Winners #1',
    description1: '',
    description2: '',
    type: 'Quiz',
    Winners: [
      {
        id: 1,
        image: Roselin,
        name: 'Roselin M - (SCM, TPL)',
        role: '',
        email: '', //'roselinm@tataprojects.com',
        description: ''
      },
      {
        id: 2,
        image: Ashok,
        name: 'Ashok Kumar S - (SCM, TPL)',
        role: '',
        email: '', // 'ashokks@tataprojects.com',
        description: ''
      },
      {
        id: 3,
        image: Vedika,
        name: 'Vedika Bagdi - (MT, TPL)',
        role: '',
        email: '', //'vedikab@tataprojects.com',
        description: ''
      },
      {
        id: 4,
        image: Mirza,
        name: 'Mirza Mohd Fauwaad Beig - (Finance, TPL)',
        role: '',
        email: '', //'fauwaadbaig@tataprojects.com',
        description: ''
      },
      {
        id: 5,
        image: Ajinkya,
        name: 'Ajinkya Meshram - (MT, TPL)',
        role: '',
        email: '', // 'ajinkyakm@tataprojects.com',
        description: ''
      }
    ]
  }
];
const winnersJan = [
  {
    id: 1,
    title: 'SAP Concur Quiz Winners!',
    description1: '',
    description2: '',
    type: 'Quiz',
    Winners: [
      {
        id: 1,
        image: BrijeshKumar,
        name: `Brijesh Kumar`,
        role: '',
        email: null,
        description: `Construction (OGH)`
      },
      {
        id: 2,
        image: GeetVaishnav,
        name: 'Geet Vaishnav',
        role: '',
        email: null,
        description: 'MT (HYD)'
      },
      {
        id: 3,
        image: SomveerSingh,
        name: 'Somveer Singh',
        role: '',
        email: null,
        description: 'MT (OGH)'
      },
      {
        id: 4,
        image: SrinivasKumar,
        name: `Srinivas Kumar G`,
        role: '',
        email: null,
        description: 'Construction (NTPC)'
      }
    ]
  }
];
const winnersFeb = [
  {
    id: 1,
    title: 'NEEV Knowledge Quiz Winners #2',
    description1: '',
    description2: '',
    type: 'Quiz',
    Winners: [
      {
        id: 1,
        image: ManjunathMallappaBudihal,
        name: `Manjunath Mallappa Budihal`,
        role: '',
        email: null,
        description: `SCM`
      },
      {
        id: 2,
        image: VaishaliAgrawal,
        name: `Vaishali Agrawal`,
        role: '',
        email: null,
        description: `F&A`
      },
      {
        id: 3,
        image: ShrutiJha,
        name: `Shruti Jha`,
        role: '',
        email: null,
        description: `SCM`
      },
      {
        id: 4,
        image: Balasubramanya,
        name: `Balasubramanya K`,
        role: '',
        email: null,
        description: `SCM`
      },
      {
        id: 5,
        image: ZaidShaikh,
        name: `Shaikh Mohammed Zaid`,
        role: '',
        email: null,
        description: `F&A`
      },
      {
        id: 6,
        image: SwapnilRameshRaoHukum,
        name: `Swapnil Ramesh Rao Hukum`,
        role: '',
        email: null,
        description: `F&A`
      },
      {
        id: 7,
        image: HasnainAnsari,
        name: 'Mohammad Hasnain Ansari',
        role: '',
        email: null,
        description: 'Survey'
      },
      {
        id: 8,
        image: PraveshKasliwal,
        name: `Pravesh Kasliwal`,
        role: '',
        email: null,
        description: `F&A`
      }
    ]
  }
];
const HoFPage = () => {
  const isMobile = sizeChecker('xs');

  const [selectedMonth, setSelectedMonth] = useState('02');
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Set the initial data based on the default selected month
    if (selectedMonth === '02') {
      setData(winnersFeb);
    } else if (selectedMonth === '01') {
      setData(winnersJan);
    } else if (selectedMonth === '09') {
      setData(winnersSep);
    } else if (selectedMonth === '10') {
      setData(winnersOct);
    } else if (selectedMonth === '12') {
      setData(winnersDec);
    } else {
      setData([]);
    }
    // Add more conditions for other months if needed
  }, [selectedMonth, winnersSep, winnersOct, winnersJan, winnersFeb]);

  console.log('data', data);
  console.log('selectedMonth', selectedMonth);

  const handleMonthChange = (date, dateString) => {
    const selected = dateString.split('-')[1];
    setSelectedMonth(selected);
  };

  // const disabledDate = (current) => {
  //   const currentYear = moment().year();
  //   const currentMonth = current.month() + 1;
  //   return (
  //     ![9, 10, 12].includes(currentMonth) || current.year() !== currentYear
  //   );
  // };

  const disabledDate = (current) => {
    // Disable all months except September, October, December of 2023, and January of 2024
    const allowedMonths = [8, 9, 11, 12]; // 0-based index for months
    const allowedMonths2nd = [0, 1]; // 0-based index for months
    const currentMonth = current.month();
    const currentYear = current.year();

    return (
      (currentYear === 2023 && !allowedMonths.includes(currentMonth)) ||
      (currentYear === 2024 && !allowedMonths2nd.includes(currentMonth))
      //currentMonth !== 0)
    );
  };
  return (
    <div className={styles.container} style={{ width: '100%' }}>
      <CustomContainer containerClassname={styles.top_container}>
        <CustomText
          size={isMobile ? 26 : 24}
          color="white"
          lineHeight="normal"
          // className={`${styles.top_container_text}`}
        >
          Meet the people who did it! You can do it too.
          {/* Acknowledge the extraordinary commitment and achievements of our
          dedicated team members. */}
        </CustomText>
      </CustomContainer>
      <CustomContainer
        title="NEEV Champs"
        containerClassname={styles.winners_container}
      >
        {!isMobile && (
          <DatePicker
            className={styles.calendar_btn}
            onChange={handleMonthChange}
            picker="month"
            defaultValue={moment('2024-02', 'YYYY-MM')}
            disabledDate={disabledDate}
            allowClear={false} // Disable the clear button
          />
        )}
        <Space
          direction="vertical"
          size={isMobile ? 30 : 34}
          align="center"
          //className="w-100"
          style={{ width: '100%' }}
          className={styles.cardWidth}
        >
          <Space
            direction="vertical"
            size={isMobile ? 10 : 20}
            align="center"
            //className="w-100"
            style={{ width: '100%' }}
          >
            {isMobile && (
              <DatePicker
                className={styles.calendar_btn}
                onChange={handleMonthChange}
                picker="month"
                defaultValue={moment('2023-12', 'YYYY-MM')}
                disabledDate={disabledDate}
                allowClear={false} // Disable the clear button
              />
            )}
            {isMobile && (
              <>
                <br />
              </>
            )}
          </Space>
          {data?.map((o) => {
            const { id, title, description1, description2, Winners, type } = o;
            return (
              <Space
                key={id}
                direction="vertical"
                size={isMobile ? 10 : 10}
                align="center"
                //className="w-100"
                style={{ width: '100%' }}
              >
                <CustomText
                  font="bold"
                  size={isMobile ? 26 : 36}
                  color={'primary'}
                  letterSpacing={isMobile ? 0 : -0.72}
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {title}
                </CustomText>
                <CustomText
                  font="medium"
                  size={isMobile ? 16 : 18}
                  className="text-center"
                >
                  <div className="text-center">{description1}</div>{' '}
                  <div className="text-center"> {description2}</div>
                </CustomText>
                {type === 'Concur' ? (
                  <Card
                    style={{
                      borderRadius: '16px',
                      backgroundImage: `url(${background})`
                    }}
                  >
                    <Row gutter={[32, 42]}>
                      {Winners?.map((o) => {
                        const { id, image, name, role } = o;

                        return (
                          <Col
                            key={id}
                            xs={24}
                            sm={6}
                            className={styles.card_container}
                          >
                            <Space
                              direction="vertical"
                              size={10}
                              align="center"
                            >
                              <div className={styles.ConcurWinners_container}>
                                <Image
                                  alt={`${name}_${id}`}
                                  src={image}
                                  preview={false}
                                  width="100%"
                                />
                              </div>
                              <Space
                                direction="vertical"
                                size={0}
                                align="center"
                              >
                                <CustomText
                                  font="medium"
                                  color="#101828"
                                  size={12}
                                  className="d-block text-center"
                                >
                                  {name}
                                </CustomText>
                                <CustomText
                                  font="medium"
                                  color="#101828"
                                  size={12}
                                  className="d-block text-center"
                                >
                                  {`(${role})`}
                                </CustomText>
                              </Space>
                            </Space>
                          </Col>
                        );
                      })}
                    </Row>
                  </Card>
                ) : (
                  <Card
                    style={{
                      borderRadius: '16px',
                      backgroundImage: `url(${background})`,
                      width: '100%'
                    }}
                  >
                    <Row gutter={[32, 42]}>
                      {Winners?.map((o) => {
                        const { id, image, name, email, description } = o;

                        return (
                          <Col
                            key={id}
                            xs={24}
                            sm={6}
                            className={styles.card_container}
                          >
                            <Space
                              direction="vertical"
                              size={10}
                              align="center"
                            >
                              <div className={styles.name_container}>
                                <Image
                                  alt={`${name}_${id}`}
                                  src={image}
                                  preview={false}
                                  width="100%"
                                />
                                {/*  <CustomText
                                  font="bold"
                                  color="primary"
                                  size={20}
                                >
                                  {name
                                    .split(' ')
                                    .slice(0, 2)
                                    .map((o) => o.slice(0, 1))
                                    .join('')}
                                </CustomText> */}
                              </div>
                              <Space
                                direction="vertical"
                                size={0}
                                align="center"
                              >
                                <CustomText
                                  font="medium"
                                  color="#101828"
                                  size={12}
                                  className="d-block text-center"
                                >
                                  {name}
                                </CustomText>
                                <a
                                  href="https://login.live.com/"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: '#2973c6',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    fontWeight: 500
                                  }}
                                >
                                  {email}
                                </a>
                                <CustomText
                                  font="medium"
                                  color="#101828"
                                  size={12}
                                  className="d-block text-center"
                                >
                                  {description}
                                </CustomText>
                              </Space>
                            </Space>
                          </Col>
                        );
                      })}
                    </Row>
                  </Card>
                )}
              </Space>
            );
          })}
        </Space>
      </CustomContainer>
    </div>
  );
};

export default HoFPage;
