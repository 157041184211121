import React from 'react';
import { Col, Row, Space } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import styles from './functions.module.less';
import Paper from '@/common/Paper';
//images
import file from '../../images/functions/file.svg';
import fileCheck from '../../images/functions/file-check.svg';
import zapFast from '../../images/functions/zap-fast.svg';
import compass from '../../images/functions/compass.svg';
import filterFunnel from '../../images/functions/filter-funnel.svg';
import arrowsUp from '../../images/functions/arrows-up.svg';
import reverseLeft from '../../images/functions/reverse-left.svg';
import lineChartUp from '../../images/functions/line-chart-up.svg';
import file2 from '../../images/functions/file-2.svg';
import truck from '../../images/functions/truck.svg';
import arrowCircleLeft from '../../images/functions/arrow-circle-left.svg';
import dataflow from '../../images/functions/dataflow.svg';
import database from '../../images/functions/database.svg';
import coinsHand from '../../images/functions/coins-hand.svg';
import presentationChart from '../../images/functions/presentation-chart.svg';
import globe from '../../images/functions/globe.svg';
import fileSearch from '../../images/functions/file-search.svg';
import fleetManagement from '../../images/functions/fleet_management.svg';
import SectionTitle from '@/common/SectionTitle';
import plant_machinery from '../../images/functions/plant_machinery.svg';

const data = [
  {
    id: 1,
    span: { xs: 24, sm: 8 },
    titleProps: {
      title: 'Project Management',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: [
      {
        image: file,
        text: 'Project Planning & Scheduling'
      },
      {
        image: fileCheck,
        text: 'Billing'
      },
      {
        image: zapFast,
        text: 'Budgeting, Risk & Opportunities, Costing and CTCs'
      },
      {
        image: compass,
        text: 'Monitoring and Reporting'
      }
    ]
  },
  {
    id: 2,
    span: { xs: 24, sm: 8 },
    titleProps: {
      title: 'Source to Pay',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: [
      {
        image: filterFunnel,
        text: 'Sourcing'
      },
      {
        image: arrowsUp,
        text: 'Procurement'
      },
      {
        image: reverseLeft,
        text: 'Receiving'
      },
      {
        image: lineChartUp,
        text: 'Invoice Processing'
      },
      {
        image: file2,
        text: 'Vendor Management'
      },
      {
        image: truck,
        text: 'Logistics'
      }
    ]
  },
  {
    id: 3,
    span: { xs: 24, sm: 8 },
    titleProps: {
      title: 'Finance & Accounting',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: [
      {
        image: arrowCircleLeft,
        text: 'Accounts Receivable, Accounts Payable & Fixed Assets Accounting'
      },
      {
        image: dataflow,
        text: 'Master Data Management'
      },
      {
        image: database,
        text: 'General Ledger Transaction Processing'
      },
      {
        image: coinsHand,
        text: 'Taxation'
      },
      {
        image: presentationChart,
        text: 'Strategic Planning, Budget & Forecast'
      }
    ]
  },
  {
    id: 4,
    span: { xs: 24, sm: 8 },
    titleProps: {
      title: 'Travel & Expense Management',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: [
      {
        image: globe,
        text: 'Travel & Reimbursements'
      },
      {
        image: fileSearch,
        text: 'Project Imprest'
      }
    ]
  },
  {
    id: 5,
    span: { xs: 24, sm: 8 },
    titleProps: {
      title: 'Enterprise Asset Management',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: [
      {
        image: plant_machinery,
        text: 'Plant & Machinery'
      },
      {
        image: fleetManagement,
        text: 'Fleet Management'
      }
    ]
  }
  /*  {
    id: 6,
    span: {xs: 24, sm: 8},
    titleProps: {
      title: 'Others',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: []
  } */
];

const Functions = () => {
  return (
    <CustomContainer
      id="functions"
      title="Functions"
      containerClassname={styles.container}
    >
      <Space direction="vertical" size={32}>
        <Space direction="vertical" size={20}>
          <SectionTitle title="The Scope of Project NEEV" />
          <CustomText size={18} color={2}>
            {`The new ERP seeks to structurally overhaul key functions and processes across the company with simplicity and standardisation as twin objectives. This will unlock greater collaboration, productivity and visibility.`}
          </CustomText>
        </Space>
        <Row gutter={[10, 10]}>
          {data.map((item) => {
            const { id, span, titleProps, data } = item;

            return (
              <Col key={id} {...span}>
                <Paper titleProps={titleProps} data={data} />
              </Col>
            );
          })}
        </Row>
      </Space>
    </CustomContainer>
  );
};

export default Functions;
