import React from 'react';
import styles from './mediaConsurSAC.module.less';
// import CustomText from '@/common/CustomText';
import { Space } from 'antd';
import CustomText from '@/common/CustomText';
//import { sizeChecker } from 'utilities/helpers';

function UserSupportSPOCDetails() {
  //const isMobile = sizeChecker('xs');
  return (
    <Space direction="vertical" size={20}>
      <Space direction="vertical" size={0}>
        <div className={styles.training_cal_style}>Dear All,</div>
        <div className={styles.training_cal_style}>
          Thank you for attending the SAC Planning Webinar.
        </div>
        <div className={styles.training_cal_style}>
          We have put together a support team to ensure you have adequate
          support on SAC planning to prepare the AOP for FY25. The details are
          as follows:
        </div>
      </Space>
      <table
        style={{ width: '100%', borderCollapse: 'collapse' }}
        className={styles.table_style1}
      >
        <tr>
          <th className={styles.tableHeader_style10} colSpan="3">
            Level1
          </th>
          <th className={styles.tableHeader_style10}>Level2</th>
        </tr>
        <tr>
          <th className={styles.tableHeader_style1}>SBU</th>
          <th className={styles.tableHeader_style2}>Location</th>
          <th className={styles.tableHeader_style3}>SPOC Details</th>
          <th className={styles.tableHeader_style3}>
            SPOC Details (For all Business Lines)
          </th>
        </tr>
        <tr>
          <td className={styles.tableHeader_style6}>PMU & Artson</td>
          <td className={styles.tableHeader_style6}>Hyderabad</td>
          <td className={styles.tableHeader_style6}>
            <div>Naveen Kumar Sharma</div>
            <div>
              <a
                href="https://login.live.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#008CD2',
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}
              >
                naveens1@tataprojects.com
              </a>
            </div>
            <div>(+91 – 9660931020)</div>{' '}
          </td>
          <td rowSpan="9" className={styles.tableHeader_style9}>
            <CustomText size={13} font="bold" color={31}>
              For Functional Queries:
            </CustomText>
            <div style={{ marginTop: '10px' }}>
              <div>Manish Daftri</div>
              <div>
                <a
                  href="https://login.live.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#008CD2',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 500
                  }}
                >
                  manishd@tataprojects.com
                </a>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <div>Prashant Rughani </div>
              <div>
                <a
                  href="https://login.live.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#008CD2',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 500
                  }}
                >
                  prashantrughani@tataprojects.com
                </a>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <CustomText size={13} font="bold" color={31}>
                For Technical Queries:
              </CustomText>
              <div>
                <a
                  href="https://login.live.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#008CD2',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: 500
                  }}
                >
                  Sac-Hypercare@tataprojects.com
                </a>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td rowSpan="2" className={styles.tableHeader_style6}>
            T&D and IB
          </td>
          <td rowSpan="2" className={styles.tableHeader_style6}>
            Noida
          </td>
          <td className={styles.tableHeader_style6}>
            <div>Vinay Rawat</div>
            <div>
              <a
                href="https://login.live.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#008CD2',
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}
              >
                vinayr@tataprojects.com
              </a>
            </div>
            <div>(+91 – 9560116659)</div>
          </td>
        </tr>
        <tr>
          <td className={styles.tableHeader_style6}>
            <div>Suresh Singhvi</div>
            <div>
              <a
                href="https://login.live.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#008CD2',
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}
              >
                sureshsinghvi@tataprojects.com
              </a>
            </div>
            <div>(+91 – 9911840036)</div>
          </td>
        </tr>
        <tr>
          <td className={styles.tableHeader_style6}>OGH</td>
          <td className={styles.tableHeader_style6}>Mumbai</td>
          <td className={styles.tableHeader_style6}>
            <div>Piyush Singhvi</div>
            <div>
              <a
                href="https://login.live.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#008CD2',
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}
              >
                piyushs@tataprojects.com
              </a>
            </div>
            <div>(+91 – 8560869889)</div>{' '}
          </td>
        </tr>

        <tr>
          <td className={styles.tableHeader_style6}>TSI</td>
          <td className={styles.tableHeader_style6}>Mumbai & Noida</td>
          <td className={styles.tableHeader_style6}>
            <div>Manish Kumar Agarwal (TSI)</div>
            <div>
              <a
                href="https://login.live.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#008CD2',
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}
              >
                manishagarwal@tataprojects.com
              </a>
            </div>
            <div>(+91 – 7678172174)</div>{' '}
          </td>
        </tr>

        <tr>
          <td rowSpan="2" className={styles.tableHeader_style6}>
            HCI
          </td>
          <td rowSpan="2" className={styles.tableHeader_style6}>
            Mumbai
          </td>
          <td className={styles.tableHeader_style6}>
            <div>Yatin Gaitonde</div>
            <div>
              <a
                href="https://login.live.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#008CD2',
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}
              >
                yatin@tataprojects.com
              </a>
            </div>
            <div>(+91 – 9619552056)</div>
          </td>
        </tr>
        <tr>
          <td className={styles.tableHeader_style6}>
            <div>Suvarna Chouguale</div>
            <div>
              <a
                href="https://login.live.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#008CD2',
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}
              >
                suvarnac@tataprojects.com
              </a>
            </div>
            <div>(+91 – 9833713913)</div>
          </td>
        </tr>
        <tr>
          <td className={styles.tableHeader_style6}>Services, Fleet, TMU</td>
          <td className={styles.tableHeader_style6}>Mumbai</td>
          <td className={styles.tableHeader_style6}>
            <div>Prashant Rughani</div>
            <div>
              <a
                href="https://login.live.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#008CD2',
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}
              >
                prashantrughani@tataprojects.com
              </a>
            </div>
            <div>(+91 – 8657008190)</div>{' '}
          </td>
        </tr>

        <tr>
          <td className={styles.tableHeader_style6}>Corporate</td>
          <td className={styles.tableHeader_style6}>Mumbai</td>
          <td className={styles.tableHeader_style6}>
            <div>Prashant Rughani</div>
            <div>
              <a
                href="https://login.live.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#008CD2',
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}
              >
                prashantrughani@tataprojects.com
              </a>
            </div>
            <div>(+91 – 8657008190)</div>{' '}
          </td>
        </tr>
      </table>
    </Space>
  );
}
export default UserSupportSPOCDetails;
