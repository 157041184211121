import React, { useState } from 'react';
import styles from './mediaConsurSAC.module.less';
// import CustomText from '@/common/CustomText';
import { Space, Image, Row, Col } from 'antd';
import CustomText from '@/common/CustomText';

import PeopleIcon from '../../images/FAQ/PeopleIcon.svg';

import ProcessIcon from '../../images/FAQ/ProcessIcon.svg';
import TechoIcon from '../../images/FAQ/TechoIcon.svg';
import Paper from '@/common/PaperFAQs';
import { sizeChecker } from 'utilities/helpers';

const data = [
  {
    key: 1,
    name: 'Travel FAQs',
    children: [
      {
        key: 1,
        title: 'People',
        subTitle: 'FAQs',
        icon: PeopleIcon,
        bg: '#009900'
      },
      {
        key: 2,
        title: 'Process',
        subTitle: 'FAQs',
        icon: ProcessIcon,
        bg: '#0EBCA8'
      },
      {
        key: 3,
        title: 'Technology',
        subTitle: 'FAQs',
        icon: TechoIcon,
        bg: '#008CD2'
      }
    ]
  },
  {
    key: 2,
    name: 'Expense FAQs',
    children: [
      {
        key: 1,
        title: 'People',
        subTitle: 'FAQs',
        icon: PeopleIcon,
        bg: '#009900'
      },
      {
        key: 2,
        title: 'Process',
        subTitle: 'FAQs',
        icon: ProcessIcon,
        bg: '#0EBCA8'
      },
      {
        key: 3,
        title: 'Technology',
        subTitle: 'FAQs',
        icon: TechoIcon,
        bg: '#008CD2'
      }
    ]
  },
  {
    key: 3,
    name: 'Site Imprest FAQs',
    children: [
      {
        key: 1,
        title: 'People',
        subTitle: 'FAQs',
        icon: PeopleIcon,
        bg: '#009900'
      },
      {
        key: 2,
        title: 'Process',
        subTitle: 'FAQs',
        icon: ProcessIcon,
        bg: '#0EBCA8'
      },
      {
        key: 3,
        title: 'Technology',
        subTitle: 'FAQs',
        icon: TechoIcon,
        bg: '#008CD2'
      }
    ]
  }
];

const TravelPeopleQA = [
  {
    text: 'Can Consultants use SAP Concur to book their travel?',
    description:
      'Yes, SAP Concur will be available to all TPL on-roll employees and Consultants,except those from SBG Services, to book their travel and submit expenses from 1st January 2024.​'
  },
  {
    text: `What is the minimum duration which all employees are expected to submit their travel requests? `,
    description: (
      <div>
        Yes, all employees should adhere to the following timelines:
        <ul>
          <li>
            {`SAP Finance and Controlling (FICO) is a central component for the
        finance module of the ERP solution from SAP`}
          </li>
          <li>
            {`FI consists of General Ledger, Accounts Payable, Accounts
          Receivable, Bank Accounting`}
          </li>
          <li>
            {`CO consists of Profit Center Accounting, Overhead Cost,
        Controlling, Product Cost Controlling`}
          </li>
        </ul>
      </div>
    )
  },
  {
    text: `Can I use the self-book feature to book my travel and hotel stay?`,
    description: (
      <Space direction="vertical" size={8}>
        <div>
          All employees will have the option to use the self-book feature to
          book their travel i.e., flight/train/bus tickets.
        </div>
        <div>
          Employees from Grade G4 and above will have the option to book hotel
          themselves. They will be required to use their personal credit card
          only to reserve the hotel stay. However, the final payment will be at
          the hotel and the bill can be settled using any means of payment –
          credit card / debit card / cash.
        </div>
        <div>
          For all other employees (Grade G3 and below) online hotel reservation
          will not applicable. Employees will have to select the accommodation
          segment to place the request for hotel reservation through the travel
          agency.
        </div>
      </Space>
    )
  },
  {
    text: 'Is it mandatory to use the self-book feature to make travel bookings?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          The self-book feature has been introduced to provide each employee the
          flexibility to manage their travel themselves. Going forward, it is
          recommended to use the self-book feature for all domestic travel
          requests (single or round trip).
        </div>
        <div>
          Please note, all domestic multi-city travel, group travel and
          international travel bookings will continue to be managed by the
          empaneled Travel Agency
        </div>
      </Space>
    )
  },
  {
    text: 'Will my travel request be sent to the Reporting Manager for an approval?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Yes, every project related travel request raised on SAP Concur will
          require an approval from the concerned Project Manager while all
          non-project related travel requests will require an approval from the
          Reporting Manager.
        </div>
        <div>
          Please note – all travel requests for Grade B and C will be
          self-approved.
        </div>
      </Space>
    )
  }
];
const TravelProcessQA = [
  {
    text: 'How can I set up my Concur Profile?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You can set up your Concur profile by clicking on the Profile setting
          option on the top right corner of your screen once you have logged in
          on SAP Concur.
        </div>
        <div>
          Please refer to the
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Set%20up%20Your%20Profile%20in%20SAP%20Concur_System%20Video.mp4'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            ‘Set up your Profile in SAP Concur’
          </a>
          video and the{' '}
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Set%20up%20Your%20Profile%20in%20SAP%20Concur_System%20Navigation%20QRG.doc'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            Quick Reference Guide{' '}
          </a>
          to learn more.
        </div>
      </Space>
    )
  },
  {
    text: 'How can I raise my travel request on Concur?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You can raise a travel request by logging on to SAP Concur. Once
          logged in, on the SAP Concur Homepage, click on ‘Start a Request’ to
          raise the travel request.
        </div>
        <div>
          Please refer to the{' '}
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Create%20a%20Travel%20Request.mp4'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            ‘Create a Travel Request’
          </a>{' '}
          video and the
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Create%20a%20Travel%20Request_System%20Navigation%20QRG.docx'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            Quick Reference Guide
          </a>{' '}
          to learn more
        </div>
      </Space>
    )
  },
  {
    text: 'How can I use the self-booking feature to book my travel on Concur?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You can use the self-booking feature to book your travel by logging on
          to SAP Concur. Once your travel request has been approved by your
          Reporting Manager / Project Manager, click on the ‘Authorization
          Requests’ on your SAP Concur Homepage to view the approved travel
          request. On the ‘Authorization Request’ page, click on the approved
          travel request and click on ‘Book Travel’ to proceed with your travel
          booking.
        </div>
        <div>
          Please refer to the{' '}
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Make%20a%20Self%20Travel%20Booking_System%20Video.mp4'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            ‘Make a Self Travel Booking’
          </a>{' '}
          video and the
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Make%20a%20Self%20Travel%20Booking_System%20Navigation%20QRG.doc'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            Quick Reference Guide
          </a>{' '}
          to learn more.
        </div>
      </Space>
    )
  },
  {
    text: 'How can I modify/cancel my travel request on Concur?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You will be required to log in on SAP Concur to modify or cancel your
          travel request. Once you’ve logged in, on your SAP Concur Homepage,
          click on ‘Start a Request’ to create a request to modify or cancel the
          travel request.
        </div>
        <div>
          For detailed steps, please refer to{' '}
          <a
            href={
              ' https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Modify%20or%20Cancel%20a%20Booked%20Travel%20Request_System%20video.mp4'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            ‘Modify or Cancel a Booked Travel Request’
          </a>{' '}
          video and the
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Modify%20or%20Cancel%20a%20Booked%20Travel%20Request_System%20Navigation%20QRG.doc'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            Quick Reference Guide
          </a>{' '}
          to learn more.
        </div>
      </Space>
    )
  }
];
const TravelTechnologyQA = [
  {
    text: 'How should I download the Concur Mobile App?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You can download the SAP Concur Mobile App by searching for ‘SAP
          Concur’ on the Playstore / App Store available on your mobile.
        </div>
      </Space>
    )
  },
  {
    text: 'Can I use the Concur Mobile App to book my travel?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You can use the SAP Concur mobile app to raise a Travel Request and
          submit to Project Manager / Reporting Manager for approval. However,
          travel booking cannot happen through the SAP Concur mobile app. You
          will be required to log in on SAP Concur through a desktop or mobile
          web browser to do your travel booking.
        </div>
      </Space>
    )
  },
  {
    text: 'Can I use the mobile web browser to book my travel?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Yes, you can access the SAP Concur website{' '}
          <a
            href={'https://concursolutions.com'}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            https://concursolutions.com{' '}
          </a>
          from your mobile to book your travel.
        </div>
      </Space>
    )
  },
  {
    text: 'Can I use the Concur Mobile App to approve travel requests?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Yes, you can use the SAP Concur Mobile App to approve the travel
          requests.
        </div>
      </Space>
    )
  }
];
const ExpensePeopleQA = [
  {
    text: 'Can I submit both travel and non-travel related expenses on Concur?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Yes, you can submit both travel and non-travel related expenses on SAP
          Concur
        </div>
      </Space>
    )
  },
  {
    text: `Will the Company cover the travel related expenses incurred if self-booked
      using Concur?
      `,
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You will be required submit the travel expenses incurred e.g. meals
          etc. for reimbursement, even if you are using the self-service feature
          to book your travel.
        </div>
        <div>
          For Grades G3 and below, as the hotel bookings will be managed by the
          Travel agency and hence the cost will be covered by the Company at the
          time of booking itself.
        </div>
        <div>
          However, for Grades G4 and above, employees will be required to
          self-pay the hotel charges and submit the expenses incurred for
          reimbursement.
        </div>
      </Space>
    )
  },
  {
    text: 'How do I know if I am eligible to request for a travel advance?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Travel and stay for all employees at Grade G3 and below will be
          Company paid. If you are from Grade G3 and below, you may request for
          a travel advance as per the grade and eligibility for food
          reimbursements only.
        </div>
        <div>
          Please note – employees must settle the claims by submitting the
          receipts within 15 days of travel. In case the travel advance is more
          than the amount claimed, you must return the balance amount within 7
          days.
        </div>
      </Space>
    )
  },
  {
    text: 'In how many days do I need to settle the travel advances?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          All employees should settle advances within 7 days and claim travel
          expenses within 15 days of the last travel date. Please refer to the
          policies section within the TPL Hub to find the latest policies.
        </div>
      </Space>
    )
  }
];
const ExpenseProcessQA = [
  {
    text: `Will my travel / non travel related expenses incurred be auto-populated on
      Concur?`,
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Yes, all expenses related to travel and accommodation will be
          pre-filled at the time of submitting your expenses.
        </div>
        <div>
          However, any miscellaneous expenses incurred by you will not be
          pre-filled. You will be required to create an expense report, add the
          details of the expenses incurred during the travel along with the
          expense receipt for a reimbursement.
        </div>
      </Space>
    )
  },
  {
    text: `How can I create and submit my travel / non-travel expense report on
    Concur?
        `,
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You will be required to log in on SAP Concur to create travel and
          non-travel expense reports.
        </div>
        <div>
          Please refer to{' '}
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Create%20a%20Travel%20Expense%20Report_System%20video.mp4'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            ‘Create a Travel Expense Report’
          </a>{' '}
          video,
          <a
            href={
              ' https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Create%20a%20Non-Travel%20Expense%20Report_System%20video.mp4'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            ‘Create a Non-Travel Expense ’
          </a>{' '}
          video and the{' '}
          <a
            href={
              ' https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Create%20a%20Travel%20Expense%20Report_System%20Navigation%20QRG.doc'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            Quick Reference Guide
          </a>{' '}
          to learn more.
        </div>
      </Space>
    )
  },
  {
    text: 'How can view and approve/reject expense reports on Concur?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You will be required to log in on SAP Concur to view and
          approve/reject expense reports. Once you have logged in, click on
          Requested Approvals to view and approve/reject expense reports.
        </div>
        <div>
          Please refer to{' '}
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Travel%20&%20Expense%20Management/Approve%20or%20Reject%20a%20Travel%20or%20Expense%20Request_System%20video.mp4'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            ‘Approve or Reject a Travel or Expense Request’
          </a>{' '}
          video and the{' '}
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Approve%20or%20Reject%20a%20Travel%20or%20Expense%20Request_System%20Navigation%20QRG.doc'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            Quick Reference Guide
          </a>{' '}
          to learn more.to learn more.
        </div>
      </Space>
    )
  },
  {
    text: 'How do I delete or modify an expense report on Concur?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          You will not be able to modify or delete any expense report once
          submitted by you for approval.
        </div>
      </Space>
    )
  }
];
const ExpenseTechnologyQA = [
  {
    text: 'Can I use the Concur Mobile app to upload the expense receipts?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Yes, you can use the SAP Concur Mobile app to upload the expense
          receipts
        </div>
      </Space>
    )
  },
  {
    text: 'Can I use the Concur Mobile App to create and submit an expense report?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          No, you cannot use the SAP Concur mobile app to create an expense
          report. You will have to log in on SAP Concur through the web URL
          <a
            href={'https://concursolutions.com'}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            https://concursolutions.com{' '}
          </a>
          to submit an expense report.
        </div>
      </Space>
    )
  },
  {
    text: 'Can I use the Concur Mobile App to approve or reject expenses?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Yes, you can use the SAP Concur mobile app to approve or reject the
          expense reports.
        </div>
      </Space>
    )
  }
];
const SiteImprestPeopleQA = [
  {
    text: 'What is the monthly cash advance limit fixed in SAP Concur?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          The new monthly cash advance limit fixed is INR 1 Lakh. Please refer
          to the policies section within the TPL Hub to find the latest policy.
        </div>
      </Space>
    )
  },
  {
    text: `Who can raise a request for site cash advance on Concur?
        `,
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Only the designated Site HR will be able to raise a request on SAP
          Concur to request for a cash advance.
        </div>
      </Space>
    )
  },
  {
    text: `Can I request for a site advance if I have a balance of more than INR 1
      Lakh?`,
    description: (
      <Space direction="vertical" size={8}>
        <div>
          No, the maximum advance limit at any point in time is set at INR 1
          Lakh. In case there is a balance of more than INR 1 Lakh, you will not
          be eligible for an advance.
        </div>
        <div>
          Please refer to the policies section within the TPL Hub to find the
          latest policy
        </div>
      </Space>
    )
  },
  {
    text: `there an approval required for refilling of the Site Imprest card on a
    monthly basis?`,
    description: (
      <Space direction="vertical" size={8}>
        <div>
          No, the replenishment request does not require an approval. However,
          Advance and Replenishment limit at any given point cannot exceed INR 1
          Lakh and it is mandatory to clear existing advances before submitting
          a new request.
        </div>
      </Space>
    )
  }
];
const SiteImprestProcessQA = [
  {
    text: 'How can access my Imprest profile on Concur?',
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Once you logged in on SAP Concur, on the SAP Concur homepage, click on
          the profile icon to change the profile setting to ‘Act as a delegate
          for another user who has granted you this permission’ and search by
          your name or ID.
        </div>
        <div>
          Select your name or ID and you will be able to view the Site Imprest
          homepage.
        </div>
      </Space>
    )
  },
  {
    text: `What is the process to request for cash advance?
          `,
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Once you are on the Site Imprest homepage, click on ‘Start a Request’
          to raise the request for a cash advance.
        </div>
        <div>
          For more details, please refer to
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Site%20Imprest/Request%20for%20Cash%20Advance%20for%20Site%20Imprest%20Expenses_System%20Video.mp4'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            ‘Request for Cash Advance for Site Imprest Expenses’
          </a>{' '}
          and the
          <a
            href={
              'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Site%20Imprest/Request%20for%20Cash%20Advance%20for%20Site%20Imprest%20Expenses_System%20Navigation%20Video.doc'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            {' '}
            Quick Reference Guide
          </a>{' '}
          to learn more.
        </div>
      </Space>
    )
  },
  {
    text: `What is the process to claim site expenses on Concur?`,
    description: (
      <Space direction="vertical" size={8}>
        <div>
          Once you are on the Site Imprest homepage, Click on ‘New and Start
          Report’ to submit the expense report.
        </div>
        <div>
          For more details, please refer to{' '}
          <a
            href={
              ' https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/Concur%20System%20Videos/Site%20Imprest/Claim%20Site%20Imprest%20Expenses_System%20Video.mp4'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            ‘Claim Site Imprest Expenses’
          </a>{' '}
          video and the
          <a
            href={
              ' https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Site%20Imprest/Claim%20Site%20Imprest%20Expenses_System%20Navigation%20QRG.doc'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              marginLeft: '5px',
              marginRight: '5px'
            }}
          >
            Quick Reference Guide{' '}
          </a>{' '}
          to learn more
        </div>
      </Space>
    )
  }
];
const FAQs = () => {
  const isMobile = sizeChecker('xs');

  const [activeSection, setActiveSection] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [hoveredTab, setHoveredTab] = useState(null);
  const [hoveredSection, setHoveredSection] = useState(null);

  // const [answersVisible, setAnswersVisible] = useState(Array(6).fill(false));
  // const [answersVisible, setAnswersVisible] = useState(
  //     Array(questions.length).fill(false)
  //   );
  const handleTabClick = (sectionKey, tabKey) => {
    setActiveSection(sectionKey);
    setActiveTab(tabKey);
  };

  //   const toggleAnswer = (index) => {
  //     setAnswersVisible((prevVisibility) => {
  //       const newVisibility = [...prevVisibility];
  //       newVisibility[index] = !newVisibility[index];
  //       return newVisibility;
  //     });
  //   };
  //   const toggleAnswer = (index) => {
  //     setAnswersVisible((prevVisibility) => {
  //       const newVisibility = [...prevVisibility];
  //       newVisibility[index] = !newVisibility[index];
  //       return newVisibility;
  //     });
  //   };

  const renderTabContent = () => {
    if (!activeSection || !activeTab) {
      return '';
    }

    const section = data.find((section) => section.key === activeSection);

    if (!section) {
      return '';
    }

    const tab = section.children.find((tab) => tab.key === activeTab);

    if (!tab) {
      return '';
    }
    console.log('activeSection', activeSection);
    console.log('actTab', activeTab);
    switch (section?.key) {
      case 1:
        switch (tab?.key) {
          case 1:
            return (
              <>
                <div style={{ marginTop: '20px' }}>
                  <Paper data={TravelPeopleQA} />
                </div>
              </>
            );

          case 2:
            return (
              <>
                <div style={{ marginTop: '20px' }}>
                  <Paper data={TravelProcessQA} />
                </div>
              </>
            );

          case 3:
            return (
              <div style={{ marginTop: '20px' }}>
                <Paper data={TravelTechnologyQA} />
              </div>
            );

          default:
            return '';
        }
      // if (tab?.title === 'People') {
      //   return (
      //     <>
      //       <div style={{ marginTop: '20px' }}>
      //         <Paper data={TravelPeopleQA} />
      //       </div>
      //     </>
      //   );
      // } else if (tab?.title === 'Process') {
      //   return (
      //     <>
      //       <div style={{ marginTop: '20px' }}>
      //         <Paper data={TravelProcessQA} />
      //       </div>
      //     </>
      //   );
      // } else if (tab?.title === 'Technology') {
      //   return (
      //     <div style={{ marginTop: '20px' }}>
      //       <Paper data={TravelTechnologyQA} />
      //     </div>
      //   );
      // }
      // break;
      case 2:
        if (tab?.title === 'People') {
          return (
            <>
              <div style={{ marginTop: '20px' }}>
                <Paper data={ExpensePeopleQA} />
              </div>
            </>
          );
        } else if (tab?.title === 'Process') {
          return (
            <>
              <div style={{ marginTop: '20px' }}>
                <Paper data={ExpenseProcessQA} />
              </div>
            </>
          );
        } else if (tab?.title === 'Technology') {
          return (
            <div style={{ marginTop: '20px' }}>
              <Paper data={ExpenseTechnologyQA} />
            </div>
          );
        }
        break;
      case 3:
        if (tab?.title === 'People') {
          return (
            <>
              <div style={{ marginTop: '20px' }}>
                <Paper data={SiteImprestPeopleQA} />
              </div>
            </>
          );
        } else if (tab?.title === 'Process') {
          return (
            <>
              <div style={{ marginTop: '20px' }}>
                <Paper data={SiteImprestProcessQA} />
              </div>
            </>
          );
        } else if (tab?.title === 'Technology') {
          return <></>;
        }
        break;
      default:
        return '';
    }
  };

  return (
    <Space direction="vertical" size={45} style={{ width: '100%' }}>
      {data.map((section) => (
        <div key={section.key}>
          <div
            style={{ textAlign: isMobile ? 'left' : 'center' }}
            className={styles.faq_container}
          >
            <CustomText font="bold" size={30} style={{ color: '#323282' }}>
              {section.name}
            </CustomText>
          </div>
          {/*  <Space
            direction={isMobile ? 'vertical' : 'horizontal'}
            size={15}
            style={{ width: '100%', textAlign: 'center' }}
          > */}
          <Row gutter={[25, 35]}>
            {section.children.map((tab) => (
              <Col key={tab.key} sx={24} sm={8}>
                <div
                  key={tab.key}
                  onMouseEnter={() => {
                    setHoveredTab(tab.key);
                    setHoveredSection(section.key);
                  }}
                  onMouseLeave={() => {
                    setHoveredTab(null);
                    setHoveredSection(null);
                  }}
                  // style={{
                  //   border:
                  //     activeSection === section.key && activeTab === tab.key
                  //       ? `2px solid ${tab.bg}`
                  //       : null,
                  //   cursor: 'pointer',
                  //   backgroundColor:
                  //     activeSection === section.key && activeTab === tab.key
                  //       ? `rgba(${parseInt(tab.bg.slice(-6, -4), 16)}, ${parseInt(
                  //           tab.bg.slice(-4, -2),
                  //           16
                  //         )}, ${parseInt(tab.bg.slice(-2), 16)}, 0.5)` // Set opacity to 0.5
                  //       : tab.bg
                  // }}

                  style={{
                    border:
                      activeSection === section.key && activeTab === tab.key
                        ? `2px solid ${tab.bg}`
                        : null,
                    cursor: 'pointer',
                    backgroundColor:
                      (activeSection === section.key &&
                        activeTab === tab.key) ||
                      (hoveredSection === section.key && hoveredTab === tab.key)
                        ? `rgba(${parseInt(
                            tab.bg.slice(-6, -4),
                            16
                          )}, ${parseInt(tab.bg.slice(-4, -2), 16)}, ${parseInt(
                            tab.bg.slice(-2),
                            16
                          )}, 0.7)`
                        : tab.bg,
                    transition: 'background-color 0.3s'
                  }}
                  onClick={() => handleTabClick(section.key, tab.key)}
                  className={styles.faq_card_container}
                >
                  <div>
                    <Image
                      alt="icon"
                      src={tab.icon}
                      preview={false}
                      className={`${styles.faq_icon_size}`}
                    />
                  </div>
                  <div style={{ marginTop: '5px' }}>
                    <div className={styles.faq_text}>{tab.title}</div>
                    <div className={styles.faq_subtext}>{tab.subTitle}</div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {/* </Space> */}
          {activeSection === section.key && activeTab && (
            <div>{renderTabContent()}</div>
          )}
        </div>
      ))}
    </Space>
  );
};

export default FAQs;
