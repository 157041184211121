import { useEffect } from 'react';
import HomePage from './components/HomePage';
import Layout from 'common/CustomLayout';
import { getPolls, postPollsResponse, getPollsResult } from './redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { TabTitle } from 'utilities/helpers';
//import ReactGA from 'react-ga';

const Home = () => {
  TabTitle('Welcome to Neev - Home');

  const dispatch = useDispatch();

  // ReactGA.event({
  //   category: 'User',
  //   action: 'Login'
  // });

  const {
    pollsData,
    postPollsResponseLoading,
    pollsResultData,
    getPollsResultLoading
  } = useSelector((state) => state.home);

  useEffect(() => {
    handleGetPolls();
  }, []);

  const handleGetPolls = () => {
    const payload = {
      // page: 1,
      // per_page: 100
    };

    dispatch(getPolls(payload));
  };

  useEffect(() => {
    pollsData?.length === 0 ? handleGetPollsResult() : '';
  }, [pollsData?.length === 0]);

  const handleGetPollsResult = () => {
    const payload = {};
    pollsData?.length === 0 ? dispatch(getPollsResult(payload)) : '';
  };

  const pollsVoteSubmitHandler = (payload) => {
    console.log('payload', payload);
    dispatch(postPollsResponse({ payload })).then(function (response) {
      if (response?.payload?.success) {
        handleGetPolls();
        // message.success(response.payload.message);
        // history.push('/view-past-gift');
      }
    });
  };
  return (
    <Layout>
      <HomePage
        pollsData={pollsData}
        pollsVoteSubmitHandler={pollsVoteSubmitHandler}
        postPollsResponseLoading={postPollsResponseLoading}
        pollsResultData={pollsResultData}
        getPollsResultLoading={getPollsResultLoading}
      />
    </Layout>
  );
};

export default Home;
