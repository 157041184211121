import React, { useEffect, useState } from 'react';
import Layout from 'common/CustomLayout';
import styles from './index.module.less';
import { Space, Spin, DatePicker, Col, Row } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import { getLoginStats } from '../../redux/slice';
import CustomText from '@/common/CustomText';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' // as const,
    },
    title: {
      display: true
      //text: 'Chart.js Bar Chart'
    }
  }
};

const Logins_statistics = () => {
  const dispatch = useDispatch();
  const { loginStatsData, getLoginStatsLoading } = useSelector(
    (state) => state.adminConsole
  );
  console.log('loginStatsData', loginStatsData);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const payload = {
      month: selectedMonth, // Subtract 1 from selectedMonth
      year: selectedYear
    };
    dispatch(getLoginStats(payload));
  }, [selectedMonth, selectedYear]);

  const data = {
    labels: loginStatsData?.map((item) => item?.name),

    datasets: [
      {
        label: 'All Logins',
        data: loginStatsData?.map((item) => item?.all),
        backgroundColor: '#323282',
        barThickness: 30
      },
      {
        label: 'Unique',
        data: loginStatsData?.map((item) => item?.unique),
        backgroundColor: '#090',
        barThickness: 30
      }
    ]
  };

  const handleMonthChange = (date) => {
    setSelectedMonth(date.month() + 1);
    setSelectedYear(date.year());
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('month');
  };

  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <Spin spinning={getLoginStatsLoading}>
          <div className={styles.left_card_container}>
            <Space direction="vertical" size={14} style={{ width: '100%' }}>
              <Row>
                <Col span={12}>
                  <CustomText
                    font="bold"
                    size={30}
                    color="primary"
                    letterSpacing={-0.72}
                  >
                    Logins
                  </CustomText>
                </Col>
                <Col
                  span={12}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <DatePicker.MonthPicker
                    defaultValue={moment()}
                    onChange={handleMonthChange}
                    disabledDate={disabledDate}
                  />
                  <CustomButton
                    size="large"
                    type="default"
                    onClick={() => history.back()}
                  >
                    Back
                  </CustomButton>
                </Col>
              </Row>
              <Bar options={options} data={data} width={'8%'} height={'3%'} />
            </Space>
          </div>
        </Spin>
      </CustomContainer>
    </Layout>
  );
};

export default Logins_statistics;
