import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
//import { LogoutOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Avatar, Divider, Drawer, Image, Space } from 'antd';
import tataProjectsLogo from '../../images/tata-projects-logo.svg';
import neevLogo from '../../images/neev_logo.svg';
import logoutIcon from '../../images/logout.svg';
//import team from '../../images/menu_icons/users.svg';
import CustomText from 'common/CustomText';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { logout } from 'features/login/redux/slice';
import styles from './left_drawer.module.less';
import { menuData, menuDataAdmin } from 'utilities/helpers';
import { Link } from 'react-router-dom';

const LeftDrawer = ({ open, onClose }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const user_details = LocalStorage.getItem('neev_login_user');

  const [selectedMenuKey] = useState(
    history.location.pathname.replace('/', '')
  );

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: () => {
        dispatch(logout());
        return true;
      }
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="left"
      width={280}
      headerStyle={{ display: 'none' }}
      bodyStyle={{ padding: '42px 16px 0' }}
      footerStyle={{ padding: '0 16px 32px', border: 'none' }}
      className={styles.container}
      footer={
        <div className={styles.footer_container}>
          {/* <Link
            to={`/team`}
            className={`${styles.menu_item}${
              'team' === selectedMenuKey ? ` ${styles.selected}` : ''
            }`}
          >
            <div className={styles.menu_item_icon}>
              <Image alt="neev-team" src={team} preview={false} />
            </div>
            <CustomText
              font="medium"
              size={16}
              color={'team' === selectedMenuKey ? 'secondary' : 1}
              className={styles.menu_item_label}
            >
              Meet the NEEV Team
            </CustomText>
          </Link> */}
          <Divider style={{ margin: '20px 0' }} />
          <div className={styles.user_container}>
            <Space size={12}>
              <Avatar className={`cs-medium ${styles.avatar}`}>
                {(user_details?.first_name || 'U').slice(0, 1)}
              </Avatar>
              <Space direction="vertical" size={0}>
                <CustomText font="bold" size={15}>
                  {user_details?.user_details?.first_name}
                </CustomText>
                <CustomText color={3}>
                  {user_details?.user_details?.job_title ||
                  user_details?.user_details?.email
                    ? '@' +
                      user_details?.user_details?.email.slice(
                        0,
                        user_details?.user_details?.email.indexOf('@')
                      )
                    : ''}
                </CustomText>
              </Space>
            </Space>
            <div
              className={styles.logout_btn_container}
              onClick={signOutClickHandler}
            >
              <Image alt="logout" src={logoutIcon} preview={false} />
            </div>
          </div>
        </div>
      }
    >
      <Space direction="vertical" size={30} className="w-100">
        <Space
          size={10}
          className="d-flex justify-content-between align-items-end"
        >
          <Image
            alt="tata-pojects-logo"
            src={tataProjectsLogo}
            preview={false}
          />
          <Image alt="neev-logo" src={neevLogo} preview={false} />
        </Space>
        <div className={styles.menu_container}>
          <Space direction="vertical" size={20} className="w-100">
            {user_details?.user_details?.user_role === 'user'
              ? menuData?.map((item, index) => {
                  const { key, icon, label, mobile } = item;
                  let isSelected = key === selectedMenuKey;

                  if (!mobile) return null;

                  return (
                    <Link
                      key={index}
                      to={`/${key}`}
                      className={`${styles.menu_item}${
                        isSelected ? ` ${styles.selected}` : ''
                      }`}
                    >
                      <div className={styles.menu_item_icon}>
                        <Image alt={label} src={icon} preview={false} />
                      </div>
                      <CustomText
                        font="medium"
                        size={16}
                        color={isSelected ? 'secondary' : 1}
                        className={styles.menu_item_label}
                      >
                        {label}
                      </CustomText>
                    </Link>
                  );
                })
              : menuDataAdmin?.map((item, index) => {
                  const { key, icon, label, mobile } = item;
                  let isSelected = key === selectedMenuKey;

                  if (!mobile) return null;

                  return (
                    <Link
                      key={index}
                      to={`/${key}`}
                      className={`${styles.menu_item}${
                        isSelected ? ` ${styles.selected}` : ''
                      }`}
                    >
                      <div className={styles.menu_item_icon}>
                        <Image alt={label} src={icon} preview={false} />
                      </div>
                      <CustomText
                        font="medium"
                        size={16}
                        color={isSelected ? 'secondary' : 1}
                        className={styles.menu_item_label}
                      >
                        {label}
                      </CustomText>
                    </Link>
                  );
                })}
          </Space>
        </div>
      </Space>
    </Drawer>
  );
};

export default LeftDrawer;
