import React from 'react';
import { Image, Layout } from 'antd';
import tataWhite from '../../images/tata-projects-logo-white.svg';
import CustomText from '@/common/CustomText';
import moment from 'moment';
import styles from './custom_footer.module.less';
import { sizeChecker } from 'utilities/helpers';

const { Footer } = Layout;

const CustomFooter = () => {
  const isMobile = sizeChecker('xs');
  return (
    <Footer className={styles.footer}>
      <div className={`df-jb-ac ${styles.wrapper}`}>
        {!isMobile ? (
          <Image alt="tata-logo-white" src={tataWhite} preview={false} />
        ) : null}
        <CustomText color="white">
          {`© ${moment().format(
            'YYYY'
          )} Tata Projects Limited. All rights reserved.`}
        </CustomText>
      </div>
    </Footer>
  );
};

export default CustomFooter;
