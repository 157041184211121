import React, { useState } from 'react';
import { Form, Modal, Space, message } from 'antd';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import { useDispatch } from 'react-redux';
import CustomButton from '@/common/CustomButton';
import LocalStorage from '@/utilities/localStorage';
//import { get_quiz_report } from '../../redux/slice';

const ExportPoll = ({ isModalReport, setIsModalReport }) => {
  const [loading, setLoading] = useState(false);
  // console.log(quizzesData);
  const [form] = Form.useForm();

  const onFinish = () => {
    // dispatch(get_quiz_report({ quiz_id: quizzesData[0]?.id }));
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = LocalStorage.getItem('neev_login_user');
    const authToken = user?.accessToken;
    //console.log(authToken);
    //?quiz_id=${quizzesData[0]?.id}
    fetch(`${apiUrl}/admin/export_polls_report`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Accept: 'application/csv',
        Authorization: `Bearer ${authToken}`
      }
    }).then((response) => {
      if (response?.status === 200) {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Report-export.csv`);

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
        setIsModalReport(false); //close the Modal...........
        setLoading(false);
      } else {
        message.success('No any response found for this poll');
        setIsModalReport(false);
        setLoading(false);
        console.log('responceNOT');
      }
    });
  };

  return (
    <div>
      <Modal
        title={<span>Please confirm</span>}
        open={isModalReport}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'500px'}
        onCancel={() => setIsModalReport(false)}
        // onOk={() => setIsModalReport(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <div className={`${styles.report_text}`}>
            Are you sure do you want to download report?
          </div>

          <FormItem>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'end',
                justifyItems: ' center',
                marginBottom: '-45px'
              }}
            >
              <CustomButton
                loading={loading}
                className={`${styles.ExceptionalApproval_button}`}
                htmlType="submit"
                size="small"
              >
                Download
              </CustomButton>

              <CustomButton
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setIsModalReport(false)}
              >
                Cancel
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </div>
  );
};

export default ExportPoll;
