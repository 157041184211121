import React from 'react';
import styles from './index.module.less';
import { Space, Image } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
//import CustomButton from '@/common/CustomButton';
import arrow_icon from '../../images/arrow.svg';

const AdminConsolePage = () => {
  const adminConsoleContent = [
    {
      id: 1,
      title: 'Manage Announcement',
      icon: arrow_icon,
      link: '/admin-manage-announcement'
    },
    {
      id: 2,
      title: 'Manage Events and Milestones',
      icon: arrow_icon,
      link: '/admin-manage-events-milestones'
    },
    {
      id: 3,
      title: 'Manage Poll',
      icon: arrow_icon,
      link: '/admin-manage-poll'
    },
    {
      id: 4,
      title: 'Export Queries and Suggestion',
      icon: arrow_icon,
      link: '/admin-export-queries-suggestion'
    },
    {
      id: 5,
      title: 'Export Footfall',
      icon: arrow_icon,
      link: '/admin-export-footfall'
    },
    {
      id: 6,
      title: 'Statistics',
      icon: arrow_icon,
      link: '/admin-statistics'
    }
  ];

  return (
    <CustomContainer title="Contact" containerClassname={styles.container}>
      <Space direction="vertical" size={14} style={{ width: '100%' }}>
        <CustomText
          font="bold"
          size={36}
          color="primary"
          letterSpacing={-0.72}
          style={{ marginBottom: '15px' }}
        >
          Admin Console
        </CustomText>

        {adminConsoleContent.map((item) => (
          <div className={styles.left_card_container} key={item?.id}>
            <Space
              direction="horizontal"
              size={2}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <CustomText className={styles.left_card_title}>
                {item.title}
              </CustomText>
              <div className={styles.take_quiz_btn}>
                <a href={item.link}>
                  <Image src={item.icon} alt="" preview={false} />
                </a>
              </div>
            </Space>
          </div>
        ))}
      </Space>
    </CustomContainer>
  );
};

export default AdminConsolePage;
