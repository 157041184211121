import React from 'react';
import { Image, Space } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import styles from './benefits.module.less';
import benefits from '../../images/benefits.svg';
import SectionTitle from '@/common/SectionTitle';

const Benefits = () => {
  return (
    <CustomContainer
      id="benefits"
      title="Benefits"
      containerClassname={styles.container}
    >
      <Space direction="vertical" size={32}>
        <Space direction="vertical" size={20}>
          <SectionTitle title="Moving to New Ways of Working" />
          <CustomText size={18} color={2}>
            {`The goal of Project NEEV is to enable the creation of a company
            that delivers on its purpose and potential to customers, employees
            and other stakeholders—with remarkable consistency. This requires
            the implementation of an ERP that helps us work together, eliminate
            manual labor and get a real-time view of performance.`}
          </CustomText>
        </Space>
        <div className="df-jc-ac">
          <Image alt="Benefits" src={benefits} preview={false} />
        </div>
      </Space>
    </CustomContainer>
  );
};

export default Benefits;
