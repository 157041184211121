import { Grid } from 'antd';
import CustomText from '@/common/CustomText';
import LocalStorage from './localStorage';

import home from '../common/CustomLayout/images/menu_icons/home.svg';
import queries from '../common/CustomLayout/images/menu_icons/queries.svg';
import knowledge_hub from '../common/CustomLayout/images/menu_icons/knowledge_hub.svg';
import feel_the_change from '../common/CustomLayout/images/menu_icons/feel_the_change.svg';
import hof from '../common/CustomLayout/images/menu_icons/hof.svg';

const { useBreakpoint } = Grid;

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('neev_login_user') || '';
  const user = loginUser || { user_details: null };
  return {
    ...user,
    loggedIn: loginUser !== ''
  };
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const menuData = [
  {
    label: 'Home',
    key: 'home',
    path: '/home',
    icon: home,
    desktop: true,
    mobile: true
  },
  {
    label: 'Knowledge Hub',
    key: 'knowledgeHub',
    path: '/knowledgeHub',
    icon: knowledge_hub,
    desktop: true,
    mobile: true
  },
  {
    label: 'Change Enablement', // 'Feel the Change',
    key: 'change-enablement',
    path: '/change-enablement',
    icon: feel_the_change,
    desktop: true,
    mobile: true
  },
  {
    label: 'SAP ERP',
    key: 'sap-erp',
    path: '/sap-erp',
    icon: feel_the_change,
    desktop: true,
    mobile: true
  },
  {
    label: 'HXM',
    key: 'hxm',
    path: '/hxm',
    icon: knowledge_hub,
    desktop: true,
    mobile: true
  },
  {
    label: 'Quizzes', // 'Feel the Change',
    key: 'quizzes',
    path: '/quizzes',
    icon: queries,
    desktop: true,
    mobile: true
  },
  {
    label: 'Hall of Fame',
    key: 'hallOfFame',
    path: '/hallOfFame',
    icon: hof,
    desktop: true,
    mobile: true
  },
  {
    label: 'Queries & Suggestions',
    key: 'ideas/list',
    path: '/ideas/list',
    icon: queries,
    desktop: true,
    mobile: true
  },
  {
    label: 'Meet the NEEV Team',
    key: 'team',
    path: '/team',
    icon: queries,
    desktop: true,
    mobile: true
  }
];

export const menuDataAdmin = [
  {
    label: 'Home',
    key: 'home',
    path: '/home',
    icon: home,
    desktop: true,
    mobile: true
  },
  {
    label: 'Knowledge Hub',
    key: 'knowledgeHub',
    path: '/knowledgeHub',
    icon: knowledge_hub,
    desktop: true,
    mobile: true
  },
  {
    label: 'Change Enablement', // 'Feel the Change',
    key: 'change-enablement',
    path: '/ediachange-enablement',
    icon: feel_the_change,
    desktop: true,
    mobile: true
  },
  {
    label: 'SAP ERP',
    key: 'sap-erp',
    path: '/sap-erp',
    icon: feel_the_change,
    desktop: true,
    mobile: true
  },
  {
    label: 'HXM',
    key: 'hxm',
    path: '/hxm',
    icon: knowledge_hub,
    desktop: true,
    mobile: true
  },
  {
    label: 'Quizzes',
    key: 'admin-quizzes',
    path: '/admin-quizzes',
    icon: queries,
    desktop: true,
    mobile: true
  },
  {
    label: 'Hall of Fame',
    key: 'hallOfFame',
    path: '/hallOfFame',
    icon: hof,
    desktop: true,
    mobile: true
  },
  {
    label: 'Queries & Suggestions',
    key: 'ideas/list',
    path: '/ideas/list',
    icon: queries,
    desktop: true,
    mobile: true
  },
  {
    label: 'Meet NEEV Team',
    key: 'team',
    path: '/team',
    icon: queries,
    desktop: true,
    mobile: true
  },
  {
    label: 'Admin',
    key: 'admin-console',
    path: '/admin-console',
    icon: queries,
    desktop: true,
    mobile: true
  }
];

export const smoothScrollTo = (id, block) => {
  const node = document.getElementById(id);
  if (node && node.scrollIntoView) {
    node.scrollIntoView({
      behavior: 'smooth',
      block: block || 'center'
    });
  }
};

export const randomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const BoldText = ({ children, color, size }) => {
  return (
    <CustomText font="bold" color={color || 2} size={size || 18}>
      {children}
    </CustomText>
  );
};

export const sizeChecker = (size) => {
  const screens = useBreakpoint();

  return screens[size];
};

export const categories = [
  {
    id: '',
    category_name: 'All'
  },
  {
    id: 'query',
    category_name: 'Queries'
  },
  {
    id: 'suggestion',
    category_name: 'Suggestions'
  }
];

export const TabTitle = (newTitle) => {
  return (document.title = newTitle);
};

export const redirectURL = (url) => {
  switch (url) {
    case '/home':
      return 'Welcome to Neev - Home';
    case '/knowledgeHub':
      return 'Neev - Knowledge Hub';
    case '/change-enablement':
      return 'Neev - Change Enablement';
    case '/quizzes':
      return 'Neev - Quizzes';
    case '/hallOfFame':
      return 'Neev - Hall of Fame';
    case '/ideas/list':
      return 'Neev - Queries & Suggestions';
    case '/team':
      return 'Neev - Meet the Neev Team';
    case '/admin-console':
      return 'Neev - Admin Console';
    case '/admin-quizzes':
      return 'Neev - Quizzes';
    case '/admin-statistics':
      return 'Neev - Statistics';
    case '/admin-logins-statistics':
      return 'Neev - Logins Statistics';
    case '/admin-quizzes-statistics':
      return 'Neev - Queries Statistics';
    case '/admin-polls-statistics':
      return 'Neev - Polls Statistics';
    case '/admin-quiz-statistics':
      return 'Neev - Quiz Statistics';
    case '/admin-manage-poll':
      return 'Neev - Manage Poll';
    case '/sap-erp':
      return 'Neev - SAP ERP';
    case '/hxm':
      return 'Neev - HXM';
    default:
      return url;
  }
};
