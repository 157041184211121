import HxmPage from './components';
import Layout from 'common/CustomLayout';
import { TabTitle } from 'utilities/helpers';

const Hxm = () => {
  TabTitle('Neev - HXM');
  return (
    <Layout>
      <HxmPage />
    </Layout>
  );
};

export default Hxm;
