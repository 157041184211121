import { Space, Radio, Row, Col, Empty, Spin, Image, Pagination } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './quiz.module.less';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryList } from 'features/ideas/redux/slice';
import { get_quizzes } from 'features/quizzes/redux/slice';
import moment from 'moment';
import ellipse from '../../images/ellipse.svg';
import { history } from 'app/history';

const QuizzesPage = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.ideas);
  const { quizzesData, get_quizzesLoading, pagination } = useSelector(
    (state) => state.quizzes
  );

  const [categoryId, setCategoryId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(getCategoryList());
  }, []);
  useEffect(() => {
    const payload = {
      category_id: categoryId ? categoryId : '',
      page: page,
      per_page: pageSize
    };
    dispatch(get_quizzes(payload));
  }, [categoryId, page, pageSize]);

  // console.log('quizzesData', quizzesData);
  // console.log('categoryId', categoryId);
  return (
    <>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <Spin spinning={get_quizzesLoading}>
          <Space direction="vertical" size={30} style={{ width: '100%' }}>
            <div className={styles.left_card_container}>
              <Space direction="vertical" size={2}>
                <CustomText
                  font="bold"
                  size={36}
                  color="primary"
                  letterSpacing={-0.72}
                >
                  Quiz
                </CustomText>
                <CustomText
                  font="bold"
                  size={16}
                  color="1"
                  letterSpacing={-0.72}
                >
                  Filter by Tags
                </CustomText>
                <Radio.Group
                  className={styles.category_container}
                  onChange={({ target: { value } }) => setCategoryId(value)}
                  buttonStyle="solid"
                >
                  {categories.map((item) => {
                    const { id, category_name } = item;
                    return (
                      <Radio.Button
                        key={id}
                        value={id}
                        style={{ height: 'fit-content' }}
                      >
                        {category_name}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </Space>
            </div>
            <Space direction="vertical" size={10} style={{ width: '100%' }}>
              {quizzesData?.length ? (
                quizzesData?.map((item) => {
                  const {
                    closed_date,
                    id,
                    is_closed,
                    quiz_desc,
                    quiz_name,
                    quiz_cat,
                    total_question,
                    quiz_taken
                  } = item;

                  return (
                    <div key={id} className={styles.left_card_container}>
                      <Space
                        direction="vertical"
                        size={15}
                        style={{ width: '100%' }}
                      >
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Col span={17}>
                            <CustomText
                              font="bold"
                              size={18}
                              color="black"
                              letterSpacing={-0.72}
                            >
                              {quiz_name}
                            </CustomText>
                          </Col>
                          <Col
                            span={
                              quiz_taken === true || is_closed === true ? 2 : 7
                            }
                          >
                            {quiz_taken === true && quiz_taken === true ? (
                              <span>
                                <Image
                                  alt="ellipse"
                                  src={ellipse}
                                  preview={false}
                                  width="5%"
                                />
                                <CustomText
                                  className={styles.closed_date_style}
                                  style={{ marginLeft: '6px' }}
                                >
                                  Completed
                                </CustomText>
                              </span>
                            ) : is_closed === true && quiz_taken === false ? (
                              <span>
                                <Image
                                  alt="ellipse"
                                  src={ellipse}
                                  preview={false}
                                  width="5%"
                                />
                                <CustomText
                                  className={styles.closed_date_style}
                                  style={{ marginLeft: '6px' }}
                                >
                                  Ended
                                </CustomText>
                              </span>
                            ) : (
                              <span>
                                <CustomText
                                  className={styles.closed_date_style}
                                  style={{ marginRight: '10px' }}
                                >
                                  {total_question} Questions
                                </CustomText>
                                <Image
                                  alt="ellipse"
                                  src={ellipse}
                                  preview={false}
                                  width="2%"
                                />
                                <CustomText
                                  className={styles.closed_date_style}
                                  style={{ marginLeft: '10px' }}
                                >
                                  {`Last date ${moment(closed_date).format(
                                    'MMMM DD YYYY'
                                  )}`}
                                </CustomText>
                              </span>
                            )}
                          </Col>
                        </Row>
                        <CustomText className={styles.quiz_cat_style}>
                          {quiz_cat?.category_name}
                        </CustomText>
                        <CustomText className={styles.quiz_desc_style}>
                          {quiz_desc}
                        </CustomText>
                        {is_closed === true && quiz_taken === false ? (
                          ''
                        ) : (
                          <div className={styles.take_quiz_btn}>
                            <CustomButton
                              className={styles.post_btn_style}
                              onClick={() => {
                                quiz_taken === true
                                  ? history.push(`/quiz-score/${id}`)
                                  : history.push(`/quize-instruction/${id}`);
                              }}
                            >
                              {quiz_taken === true
                                ? 'View Score'
                                : 'Take the Quiz'}
                            </CustomButton>
                          </div>
                        )}
                      </Space>
                    </div>
                  );
                })
              ) : (
                <Empty className="w-100" />
              )}
              <Pagination
                size="small"
                total={pagination?.total_records}
                current={page}
                pageSize={pageSize}
                showSizeChanger={true}
                onChange={(page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }}
                style={{ marginTop: '10px', textAlign: 'center' }}
                className="text-right"
              />
            </Space>
          </Space>
        </Spin>
      </CustomContainer>
    </>
  );
};

export default QuizzesPage;
