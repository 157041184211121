import React from 'react';
import { Modal, Tooltip, Table, Row, Col } from 'antd';
import styles from './index.module.less';
//import SearchBar from 'common/CostomSearch/searchBar';
import SearchBar from '@/common/CostomSearch/searchBarNew';
import CustomText from '@/common/CustomText';
import { sizeChecker } from 'utilities/helpers';

const SponsorModal = ({
  networkData,
  page,
  setPage,
  pageSize,
  setPageSize,
  sponsorModal,
  setSponsorModal,
  setSearchRequest,
  loading,
  pagination
}) => {
  const isMobile = sizeChecker('xs');
  console.log('networkData', networkData);
  const networkDataNew = networkData?.map((item) => ({
    id: item.id,
    bu: item?.BU,
    sbg: item?.SBG,
    sbu: item?.SBU,
    //email: item?.Email,
    ChangeSponsor: item?.['Change Sponsor'],
    CoSponsors: item?.['Change Co-Sponsors']
  }));

  const columns = [
    {
      title: 'SBG',
      dataIndex: 'sbg',
      key: 'sbg',
      sorter: (a, b) => a?.sbg?.localeCompare(b?.sbg),
      render: (sbg) => {
        return (
          <>
            <Tooltip
              title={sbg}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {sbg}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Change Sponsor',
      dataIndex: 'ChangeSponsor',
      key: 'ChangeSponsor',
      sorter: (a, b) => a?.ChangeSponsor?.localeCompare(b?.ChangeSponsor),
      render: (ChangeSponsor) => {
        return (
          <>
            {' '}
            <Tooltip
              title={ChangeSponsor}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {ChangeSponsor}
            </Tooltip>
          </>
        );
      }
    },

    {
      title: 'SBU',
      dataIndex: 'sbu',
      key: 'sbu',
      sorter: (a, b) => a?.sbu?.localeCompare(b?.sbu),
      render: (sbu) => {
        return (
          <>
            <Tooltip
              title={sbu}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {sbu}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'BU',
      dataIndex: 'bu',
      key: 'bu',
      sorter: (a, b) => a?.bu?.localeCompare(b?.bu),
      render: (bu) => {
        return (
          <>
            <Tooltip
              title={bu}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {bu}
            </Tooltip>
          </>
        );
      }
    },

    {
      title: 'Change Co-Sponsors',
      dataIndex: 'CoSponsors',
      key: 'CoSponsors',
      sorter: (a, b) => a?.CoSponsors?.localeCompare(b?.CoSponsors),
      render: (CoSponsors) => {
        return (
          <>
            <Tooltip
              title={CoSponsors}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {CoSponsors}
            </Tooltip>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Modal
        title={
          <CustomText font="bold" size={20} color={'primary'}>
            {`Change Sponsor & Co-Sponsor`}
          </CustomText>
        }
        open={sponsorModal}
        // className={`${styles.check_circle_icon}`}
        width={'90%'}
        onCancel={() => {
          setSponsorModal(false);
          setPage(1);
          setSearchRequest('');
        }}
        onOk={() => {
          setSponsorModal(false);
          setPage(1);
          setSearchRequest('');
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div
          className={`${styles.custom_table_container}${
            !networkData?.length ? ` ${styles.no_data}` : ''
          }`}
        >
          <div className={styles.search_container}>
            <Row>
              <Col xs={24} sm={8}>
                <SearchBar
                  onChange={(e) => {
                    setSearchRequest(e.target.value);
                    // setTriggerFilter(true);
                  }}
                  className={` ${styles.searchbar}`}
                  inputProps={{
                    placeholder: 'Search by name'
                  }}
                />
              </Col>
            </Row>
          </div>
          <Table
            showSorterTooltip={false}
            columns={columns}
            dataSource={networkDataNew}
            loading={loading}
            pagination={{
              current: page,
              pageSize: pageSize,
              //showSizeChanger: true,
              size: 'small',
              total: pagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
            scroll={{ x: isMobile ? true : false }}
          />
        </div>
      </Modal>
    </>
  );
};

export default SponsorModal;
