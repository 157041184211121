import React from 'react';
import { Image, Space } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import styles from './digital_core.module.less';
import digital_core from '../../images/digital_core.svg';
import SectionTitle from '@/common/SectionTitle';

const DigitalCore = () => {
  return (
    <CustomContainer
      id="digital_core"
      title="Digital Core"
      containerClassname={styles.container}
    >
      <Space direction="vertical" size={30}>
        <SectionTitle title="Building a Digital Core" />
        <div className="df-jc-ac">
          <Image alt="Digital Core" src={digital_core} preview={false} />
        </div>
      </Space>
    </CustomContainer>
  );
};

export default DigitalCore;
