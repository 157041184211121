import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_events, postCreateUpdateEvents } from '../../redux/slice';
import styles from './index.module.less';
import { Space, Spin, Popconfirm, Pagination } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import LocalStorage from 'utilities/localStorage';

const ManageEventsMilestonesPage = () => {
  const dispatch = useDispatch();
  const {
    eventsData,
    get_eventsLoading,
    //postCreateUpdateEventsLoading,
    pagination
  } = useSelector((state) => state.adminConsole);

  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  //console.log('eventsData', eventsData);
  console.log('pagination', pagination);

  const user = LocalStorage.getItem('neev_login_user');

  const handleGetRequests = () => {
    const payload = {
      page: page,
      per_page: pageSize
    };
    dispatch(get_events({ role: user?.user_details?.user_role, payload }));
  };

  useEffect(() => {
    handleGetRequests();
  }, [page, pageSize]);

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  const handleDeleteRequest = (id) => {
    const payload = { id: id?.id, is_active: false };
    // console.log('payload', payload);
    dispatch(postCreateUpdateEvents(payload)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };

  return (
    <CustomContainer title="Contact" containerClassname={styles.container}>
      <Spin spinning={get_eventsLoading}>
        <Space direction="vertical" size={14} style={{ width: '100%' }}>
          <Space
            direction="horizontal"
            size={6}
            style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/admin-console`)}
            >
              Back
            </CustomButton>

            <CustomButton
              type="primary"
              htmlType="submit"
              className={styles.post_btn_style}
              onClick={() => history.push(`/admin-create_events_milestones`)}
            >
              Create Event or Milestones
            </CustomButton>
          </Space>

          {eventsData?.map((item) => {
            const { id, event_name, type } = item;
            return (
              <div className={styles.event_card_container} key={id}>
                <Space
                  direction="horizontal"
                  size={2}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <div className={styles.left_chip}>{type}</div>
                  <div className={styles.edit_container}>
                    <EditOutlined
                      onClick={() =>
                        history.push(`/admin-create_events_milestones/${id}`)
                      }
                      className={`${styles.edit_icon_style}`}
                    />

                    <Popconfirm
                      title={`Are you sure do you want to delete this ${type} ?`}
                      open={visibility(id)}
                      onConfirm={() => {
                        handleDeleteRequest({
                          id: id
                        });
                      }}
                      onCancel={() => {
                        setShowDisablePopup({});
                      }}
                      okText="Delete"
                      cancelText="No"
                      okButtonProps={{ type: 'danger' }}
                    >
                      <a>
                        <DeleteOutlined
                          className={`${styles.delete_icon_style}`}
                          onClick={() => {
                            setShowDisablePopup({ [id]: true });
                          }}
                        />
                      </a>
                    </Popconfirm>
                  </div>
                </Space>
                <Space style={{ marginTop: '5px' }}>
                  <CustomText className={styles.event_card_title}>
                    {event_name}
                  </CustomText>
                </Space>
              </div>
            );
          })}
          <Pagination
            size="small"
            total={pagination?.total_records}
            current={page}
            pageSize={pageSize}
            showSizeChanger={true}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            style={{ marginTop: '10px', textAlign: 'center' }}
            className="text-right"
          />
        </Space>
      </Spin>
    </CustomContainer>
  );
};

export default ManageEventsMilestonesPage;
