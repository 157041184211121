import React, { useState } from 'react';
import styles from './mediaConsurSAC.module.less';
// import CustomText from '@/common/CustomText';
import { Space, Row, Col } from 'antd';
import CustomText from '@/common/CustomText';

import PeopleIcon from '../../images/FAQ/PeopleIcon.svg';

import ProcessIcon from '../../images/FAQ/ProcessIcon.svg';
import TechoIcon from '../../images/FAQ/TechoIcon.svg';
//import Paper from '@/common/PaperFAQs';
import { sizeChecker } from 'utilities/helpers';

const data = [
  {
    key: 1,
    name: 'Travel User Guides',
    children: [
      {
        key: 1,
        title: 'Log in and Navigate SAP Concur',
        subTitle: 'FAQs',
        icon: PeopleIcon,
        bg: '#009900',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Log%20in%20and%20Navigate%20SAP%20Concur_System%20Navigation%20QRG.pdf'
      },
      {
        key: 2,
        title: 'Set up Your Profile in SAP Concur',
        subTitle: 'FAQs',
        icon: ProcessIcon,
        bg: '#0EBCA8',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Set%20up%20Your%20Profile%20in%20SAP%20Concur_System%20Navigation%20QRG.pdf'
      },
      {
        key: 3,
        title: 'Create a Travel Request',
        subTitle: 'FAQs',
        icon: TechoIcon,
        bg: '#008CD2',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Create%20a%20Travel%20Request_System%20Navigation%20QRG.pdf'
      },
      {
        key: 4,
        title: 'Make a self-travel booking',
        subTitle: 'FAQs',
        icon: TechoIcon,
        bg: '#009900',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Make%20a%20Self%20Travel%20Booking_System%20Navigation%20QRG.pdf'
      },
      {
        key: 5,
        title: 'Approve or Reject Travel Request ',
        subTitle: 'FAQs',
        icon: TechoIcon,
        bg: '#0EBCA8',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Approve%20or%20Reject%20a%20Travel%20or%20Expense%20Request_System%20Navigation%20QRG.pdf'
      },
      {
        key: 6,
        title: 'Modify or Cancel a Booked Travel Request ',
        subTitle: 'FAQs',
        icon: TechoIcon,
        bg: '#008CD2',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Modify%20or%20Cancel%20a%20Booked%20Travel%20Request_System%20Navigation%20QRG.pdf'
      },
      {
        key: 7,
        title: 'Assign a Delegate in SAP Concur',
        subTitle: 'FAQs',
        icon: TechoIcon,
        bg: '#009900',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Assign%20a%20Delegate%20in%20SAP%20Concur_System%20Navigation%20QRG.pdf'
      }
    ]
  },
  {
    key: 2,
    name: 'Expense User Guides',
    children: [
      {
        key: 1,
        title: 'Create a Travel Expense Report',
        subTitle: 'FAQs',
        icon: PeopleIcon,
        bg: '#009900',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Create%20a%20Travel%20Expense%20Report_System%20Navigation%20QRG.pdf'
      },
      {
        key: 2,
        title: 'Create a Non-Travel Expense Report',
        subTitle: 'FAQs',
        icon: ProcessIcon,
        bg: '#0EBCA8',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Travel%20and%20Expense%20Management/Create%20a%20Non-Travel%20Expense%20Report_System%20Navigation%20QRG.pdf'
      }
    ]
  },
  {
    key: 3,
    name: 'Site Imprest User Guides',
    children: [
      {
        key: 1,
        title: 'Request for Cash Advance',
        subTitle: 'FAQs',
        icon: PeopleIcon,
        bg: '#009900',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Site%20Imprest/Request%20for%20Cash%20Advance%20for%20Site%20Imprest%20Expenses_System%20Navigation%20QRG.pdf'
      },
      {
        key: 2,
        title: 'Claim Site Imprest Expense',
        subTitle: 'FAQs',
        icon: ProcessIcon,
        bg: '#0EBCA8',
        link: 'https://tataprojects4.sharepoint.com/sites/NEEVOCMTeam/Shared%20Documents/Concur/Concur%20E-Learning%20Toolkit/User%20Guides/Site%20Imprest/Claim%20Site%20Imprest%20Expenses_System%20Navigation%20QRG.pdf'
      }
    ]
  }
];

const UserGuides = () => {
  const isMobile = sizeChecker('xs');

  const [activeSection, setActiveSection] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [hoveredTab, setHoveredTab] = useState(null);
  const [hoveredSection, setHoveredSection] = useState(null);

  const handleTabClick = (sectionKey, tabKey) => {
    setActiveSection(sectionKey);
    setActiveTab(tabKey);
  };

  // const renderTabContent = () => {
  //   if (!activeSection || !activeTab) {
  //     return '';
  //   }

  //   const section = data.find((section) => section.key === activeSection);

  //   if (!section) {
  //     return '';
  //   }

  //   const tab = section.children.find((tab) => tab.key === activeTab);

  //   if (!tab) {
  //     return '';
  //   }
  //   console.log('activeSection', activeSection);
  //   console.log('actTab', activeTab);
  //   switch (section?.key) {
  //     case 1:
  //       switch (tab?.key) {
  //         case 1:
  //           return (
  //             <>
  //               <div style={{ marginTop: '20px' }}>
  //                 <Paper data={TravelPeopleQA} />
  //               </div>
  //             </>
  //           );

  //         case 2:
  //           return (
  //             <>
  //               <div style={{ marginTop: '20px' }}>
  //                 <Paper data={TravelProcessQA} />
  //               </div>
  //             </>
  //           );

  //         case 3:
  //           return (
  //             <div style={{ marginTop: '20px' }}>
  //               <Paper data={TravelTechnologyQA} />
  //             </div>
  //           );

  //         default:
  //           return '';
  //       }
  //     // if (tab?.title === 'People') {
  //     //   return (
  //     //     <>
  //     //       <div style={{ marginTop: '20px' }}>
  //     //         <Paper data={TravelPeopleQA} />
  //     //       </div>
  //     //     </>
  //     //   );
  //     // } else if (tab?.title === 'Process') {
  //     //   return (
  //     //     <>
  //     //       <div style={{ marginTop: '20px' }}>
  //     //         <Paper data={TravelProcessQA} />
  //     //       </div>
  //     //     </>
  //     //   );
  //     // } else if (tab?.title === 'Technology') {
  //     //   return (
  //     //     <div style={{ marginTop: '20px' }}>
  //     //       <Paper data={TravelTechnologyQA} />
  //     //     </div>
  //     //   );
  //     // }
  //     // break;
  //     case 2:
  //       if (tab?.title === 'People') {
  //         return (
  //           <>
  //             <div style={{ marginTop: '20px' }}>
  //               <Paper data={ExpensePeopleQA} />
  //             </div>
  //           </>
  //         );
  //       } else if (tab?.title === 'Process') {
  //         return (
  //           <>
  //             <div style={{ marginTop: '20px' }}>
  //               <Paper data={ExpenseProcessQA} />
  //             </div>
  //           </>
  //         );
  //       } else if (tab?.title === 'Technology') {
  //         return (
  //           <div style={{ marginTop: '20px' }}>
  //             <Paper data={ExpenseTechnologyQA} />
  //           </div>
  //         );
  //       }
  //       break;
  //     case 3:
  //       if (tab?.title === 'People') {
  //         return (
  //           <>
  //             <div style={{ marginTop: '20px' }}>
  //               <Paper data={SiteImprestPeopleQA} />
  //             </div>
  //           </>
  //         );
  //       } else if (tab?.title === 'Process') {
  //         return (
  //           <>
  //             <div style={{ marginTop: '20px' }}>
  //               <Paper data={SiteImprestProcessQA} />
  //             </div>
  //           </>
  //         );
  //       } else if (tab?.title === 'Technology') {
  //         return <></>;
  //       }
  //       break;
  //     default:
  //       return '';
  //   }
  // };

  return (
    <Space direction="vertical" size={45} style={{ width: '100%' }}>
      {data.map((section) => (
        <div key={section.key}>
          <div
            style={{ textAlign: isMobile ? 'left' : 'center' }}
            className={styles.faq_container}
          >
            <CustomText font="bold" size={30} style={{ color: '#323282' }}>
              {section.name}
            </CustomText>
          </div>
          {/*  <Space
            direction={isMobile ? 'vertical' : 'horizontal'}
            size={15}
            style={{ width: '100%', textAlign: 'center' }}
          > */}
          <Row gutter={[25, 35]}>
            {section.children.map((tab) => (
              <Col key={tab.key} sx={24} sm={section.key === 1 ? 8 : 12}>
                <a href={tab?.link} target="_blank" rel="noopener noreferrer">
                  <div
                    onMouseEnter={() => {
                      setHoveredTab(tab.key);
                      setHoveredSection(section.key);
                    }}
                    onMouseLeave={() => {
                      setHoveredTab(null);
                      setHoveredSection(null);
                    }}
                    style={{
                      border:
                        activeSection === section.key && activeTab === tab.key
                          ? `2px solid ${tab.bg}`
                          : null,
                      cursor: 'pointer',
                      backgroundColor:
                        (activeSection === section.key &&
                          activeTab === tab.key) ||
                        (hoveredSection === section.key &&
                          hoveredTab === tab.key)
                          ? `rgba(${parseInt(
                              tab.bg.slice(-6, -4),
                              16
                            )}, ${parseInt(
                              tab.bg.slice(-4, -2),
                              16
                            )}, ${parseInt(tab.bg.slice(-2), 16)}, 0.7)`
                          : tab.bg,
                      transition: 'background-color 0.3s'
                    }}
                    onClick={() => handleTabClick(section.key, tab.key)}
                    className={styles.UserGuides_card_container}
                  >
                    <div style={{ marginTop: '5px' }}>
                      <div className={styles.UserGuides_text}>{tab.title}</div>
                    </div>
                  </div>
                </a>
              </Col>
            ))}
            {/* </Space> */}
          </Row>
          {activeSection === section.key && activeTab && (
            <div>{''}</div> /* renderTabContent() */
          )}
        </div>
      ))}
    </Space>
  );
};

export default UserGuides;
