import { sizeChecker } from 'utilities/helpers';
import CustomText from '../CustomText';

const SectionTitle = ({ title }) => {
  const isMobile = sizeChecker('xs');
  return (
    <CustomText
      font="bold"
      size={isMobile ? 26 : 36}
      color={'primary'}
      letterSpacing={isMobile ? 0 : -0.72}
    >
      {title}
    </CustomText>
  );
};

export default SectionTitle;
