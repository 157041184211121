import apiClient from 'utilities/apiClient';

export const quizzes = (payload) => {
  return apiClient.get(apiClient.Urls.quizzes, payload, true);
};

export const quizScoreAdmin = (payload) => {
  return apiClient.get(apiClient.Urls.quizScoreAdmin, payload, true);
};

export const get_quiz_user_resAdmin = (payload) => {
  return apiClient.get(apiClient.Urls.quiz_user_resAdmin, payload, true);
};

export const post_create_quiz = (payload) => {
  return apiClient.post(
    apiClient.Urls.post_create_quiz,
    payload,
    true
    //'file'
  );
};
export const quiz_report = (payload) => {
  return apiClient.get(apiClient.Urls.get_quiz_report, payload, true);
};
