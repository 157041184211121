import React, { useState } from 'react';
import { DatePicker, Space, Radio, message } from 'antd';
import { history } from 'app/history';
import Layout from 'common/CustomLayout';
import styles from './index.module.less';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import LocalStorage from '@/utilities/localStorage';
import moment from 'moment';

const type = [
  {
    id: 1,
    type_option: 'All Logins'
  },
  {
    id: 2,
    type_option: 'Unique Logins'
  }
];

const ExportFootfall = () => {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [formValues, setFormValues] = useState({});

  const handlePollsOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const handleExport = () => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = LocalStorage.getItem('neev_login_user');
    const authToken = user?.accessToken;

    const payload = {
      is_success: true,
      is_unique: formValues?.typeOption === 1 ? false : true,
      from_date: startDate?.format('YYYY-MM-DD'),
      to_date: endDate?.format('YYYY-MM-DD')
    };

    // const fromDate = startDate?.format('YYYY-MM-DD');
    // const toDate = endDate?.format('YYYY-MM-DD');
    //const url = `/export_login_report?from_date=${fromDate}&to_date=${toDate}`;
    const url = `/admin/export_login_report?from_date=${payload?.from_date}&to_date=${payload?.to_date}&is_success=${payload?.is_success}&is_unique=${payload?.is_unique}`;
    fetch(`${apiUrl}${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Accept: 'application/csv',
        Authorization: `Bearer ${authToken}`
      }
    }).then((response) => {
      setLoading(false);
      response
        .blob()
        .then((blob) => {
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'exported_file.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          setStartDate(null);
          setEndDate(null);
        })
        .catch((error) => {
          setLoading(false);
          console.error('Export failed:', error);
        });
    });
  };

  const disabledDate = (current) => {
    // Disable future dates
    return current && current > moment().endOf('day');
  };

  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <div className={styles.left_card_container}>
          <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <CustomText
              font="bold"
              size={36}
              color="primary"
              letterSpacing={-0.72}
            >
              Export Footfall
            </CustomText>
            <Space
              direction="vertical"
              size={34}
              className={styles.footfall_container}
            >
              <div>
                <Radio.Group
                  className={styles.latestPoll_radioButton}
                  onChange={(e) =>
                    handlePollsOnChange('typeOption', e.target.value)
                  }
                >
                  {type?.map((item, i) => (
                    <Space
                      key={i}
                      direction="horizontal"
                      size={10}
                      // className={styles.datepicker_container}
                    >
                      <Radio value={item?.id}>
                        <CustomText
                          size={18}
                          font="bold"
                          color={6}
                          className={styles.type_option_style}
                        >
                          {item?.type_option}
                        </CustomText>
                      </Radio>
                    </Space>
                  ))}
                </Radio.Group>
              </div>
              <div>
                <DatePicker.RangePicker
                  value={[startDate, endDate]}
                  onChange={(dates) => {
                    setStartDate(dates?.[0]);
                    setEndDate(dates?.[1]);
                  }}
                  disabledDate={disabledDate} // Disable future dates
                  style={{ width: '90%' }}
                />
              </div>
              <Space
                direction="horizontal"
                size={14}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end'
                }}
              >
                <CustomButton
                  size="large"
                  type="default"
                  onClick={() => history.back()}
                >
                  Cancel
                </CustomButton>

                <CustomButton
                  size="large"
                  type="primary"
                  htmlType="submit"
                  //onClick={handleExport}
                  onClick={() => {
                    if (startDate && endDate) {
                      handleExport();
                    } else {
                      // Handle validation error, e.g. show an error message
                      message.error('Please select a date range');
                    }
                  }}
                  loading={loading}
                >
                  Export
                </CustomButton>
              </Space>
            </Space>
          </Space>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default ExportFootfall;
