import CustomContainer from '@/common/CustomContainer';
import styles from './ideas.module.less';
import { Col, Empty, Row, Space, Spin, Tabs, Pagination } from 'antd';
import CustomButton from '@/common/CustomButton';
import IdeaCard from '../IdeaCard';
import { history } from '@/app/history';
import CustomText from '@/common/CustomText';
import { sizeChecker, categories } from 'utilities/helpers';
import { useEffect } from 'react';

const IdeasPage = ({
  ideas,
  getIdeasLoading,
  // categories,
  handlePostLikeIdea,
  handlePostDislikeIdea,
  handleGetIdeasByCategoryId,
  setCategory_id_State,
  pagination,
  page,
  pageSize,
  setPage,
  setPageSize
}) => {
  const isMobile = sizeChecker('xs');
  // let categoriesToRender = [
  //   {
  //     id: '',
  //     category_name: 'All'
  //   },
  //   ...(categories || [])
  // ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <CustomContainer containerClassname={styles.container}>
      <Space direction="vertical" size={isMobile ? 20 : 16} className="w-100">
        {isMobile ? (
          <CustomText font="medium" size={26} color={10}>
            Queries & Suggestions
          </CustomText>
        ) : null}
        <Space gutter={5} className={styles.share_btn_container}>
          <CustomButton
            className={styles.post_btn_style}
            type=""
            onClick={() => history.push(`/ideas/create/${1}`)}
          >
            Post your Queries {/* Share your thoughts */}
          </CustomButton>
          <CustomButton
            className={styles.post_btn_style}
            type=""
            onClick={() => history.push(`/ideas/create/${2}`)}
          >
            Post your Suggestions {/* Share your thoughts */}
          </CustomButton>
        </Space>
        <div className={styles.content}>
          <div className={styles.categories_container}>
            <Tabs
              defaultActiveKey=""
              onChange={(activeKey) => {
                console.log('activeKey', activeKey);
                setCategory_id_State(activeKey);
                handleGetIdeasByCategoryId(activeKey);
              }}
              tabBarGutter={16}
              className={styles.tab_container}
            >
              {categories?.map(({ id, category_name }) => {
                return (
                  <Tabs.TabPane tab={category_name} key={id}>
                    <Spin spinning={getIdeasLoading}>
                      <div className={styles.ideas_container}>
                        <Row gutter={[20, 20]}>
                          {ideas?.length ? (
                            ideas?.map((idea) => {
                              return (
                                <Col span={8} key={idea.id} xs={24} sm={8}>
                                  <IdeaCard
                                    item={idea}
                                    handlePostLikeIdea={handlePostLikeIdea}
                                    handlePostDislikeIdea={
                                      handlePostDislikeIdea
                                    }
                                    clickable={true}
                                  />
                                </Col>
                              );
                            })
                          ) : (
                            <Empty className="w-100" />
                          )}
                        </Row>
                      </div>
                    </Spin>
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
            <Pagination
              size="small"
              total={pagination?.total_records}
              current={page}
              pageSize={pageSize}
              showSizeChanger={true}
              onChange={(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }}
              style={{ marginTop: '10px', textAlign: 'center' }}
              className="text-right"
            />
          </div>
        </div>
      </Space>
    </CustomContainer>
  );
};

export default IdeasPage;
