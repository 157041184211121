import apiClient from 'utilities/apiClient';

export const quizzes = (payload) => {
  return apiClient.get(apiClient.Urls.quizzes, payload, true);
};

export const quizScore = (payload) => {
  return apiClient.get(apiClient.Urls.quizScore, payload, true);
};

export const quizScoreAnswers = (payload) => {
  return apiClient.get(apiClient.Urls.quizScoreAnswers, payload, true);
};

export const saveQuizResponse = (payload) => {
  return apiClient.post(
    apiClient.Urls.save_quiz_response,
    payload,
    true
    // 'file'
  );
};
