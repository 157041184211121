import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_poll, postCreateUpdatePoll } from '../../redux/slice';
import styles from './index.module.less';
import { Space, Row, Col, Form, Input, Radio, DatePicker } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import Layout from 'common/CustomLayout';
import FormItem from 'common/FormItem';
import { history } from '@/app/history';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
//import PollQues from './PollQues';

//const { TextArea } = Input;

const type = [
  {
    id: 1,
    type_option: 'Single Select'
  },
  {
    id: 2,
    type_option: 'Multiple Select'
  }
];

const CreateUpdatePoll = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const [formValues, setFormValues] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [options, setOptions] = useState(['']);

  console.log('formValues', formValues);
  console.log('options', options);

  const { pollData, postCreateUpdatePollLoading } = useSelector(
    (state) => state.adminConsole
  );

  // console.log('announcementsData', announcementsData);
  // console.log('id', id);

  useEffect(() => {
    const payload = { id: id ? id : '' };
    id === undefined ? '' : dispatch(get_poll(payload));
  }, [id]);

  useEffect(() => {
    if (id && pollData[0]) {
      const { pollopt, poll_question, launch_date, closed_date, type } =
        pollData[0];

      form.setFieldsValue({
        name_title: poll_question,
        startDate: moment(launch_date),
        endDate: moment(closed_date),
        typeOption: type === 'single' ? 1 : 2
      });

      const options = pollopt?.map((option) => ({
        id: option?.id,
        option: option?.poll_option
      }));

      setOptions(options);
    }
  }, [pollData[0], id]);

  const handlePollsOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const disabledStartDate = (current) => {
    // Disable past dates
    return current && current < moment().startOf('day');
  };

  const disabledEndDate = (current) => {
    return (
      current && (!startDate || current < moment(startDate).startOf('day'))
    );
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    form.setFieldsValue({ endDate: undefined });
  }, [startDate]);

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleAddOption = () => {
    setOptions([...options, '']);
  };

  const handleDeleteOption = (index) => {
    if (index === 0) {
      return; // Do not delete the first option
    }
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    if (id === undefined) {
      updatedOptions[index] = value;
    } else {
      updatedOptions[index] = { option: value };
    }
    setOptions(updatedOptions);
  };
  console.log('typeOption', formValues?.typeOption);
  const onFinish = () => {
    // const isValid = question.options.every(
    //   (option) => option.value !== null && option.value.trim() !== ''
    // );

    // if (!isValid) {
    //   // Display an error message or handle the validation failure as needed
    //   message.error('Please fill in all  option fields.');
    //   return;
    // }
    const payload = {
      type: formValues?.typeOption === 1 ? 'single' : 'multiple',
      poll_question: formValues?.name_title,
      is_active: true,
      launch_date: moment(startDate?._d)?.format('YYYY-MM-DD'),
      closed_date: moment(endDate?._d)?.format('YYYY-MM-DD'),
      poll_options_list: options?.map((option) => ({
        poll_option: option,
        is_active: true
      }))
    };
    const byIdPaylod = {
      id: id,
      type:
        id && pollData[0] && formValues?.typeOption === undefined
          ? pollData[0]?.type
          : formValues?.typeOption === 1
          ? 'single'
          : 'multiple',
      poll_question:
        id && pollData[0] && formValues?.name_title === undefined
          ? pollData[0]?.poll_question
          : formValues?.name_title,
      is_active: true,
      launch_date:
        id && pollData[0] && startDate === null
          ? pollData[0]?.launch_date
          : moment(startDate?._d)?.format('YYYY-MM-DD'),
      closed_date:
        id && pollData[0] && endDate === null
          ? pollData[0]?.closed_date
          : moment(endDate?._d)?.format('YYYY-MM-DD'),
      poll_options_list: options?.map((opt) => ({
        id: opt?.id,
        poll_option: opt?.option,
        is_active: true
      }))
    };

    console.log('payload', payload);
    dispatch(
      postCreateUpdatePoll(id === undefined ? payload : byIdPaylod)
    ).then(({ payload }) => {
      if (payload?.success) {
        history.push('/admin-manage-poll');
      }
    });
  };

  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <div className={styles.create_card_container}>
          <Space direction="vertical" size={14} style={{ width: '100%' }}>
            <CustomText
              font="bold"
              size={36}
              color="primary"
              letterSpacing={-0.72}
            >
              {id === undefined ? 'Create Poll' : 'Edit Poll'}
            </CustomText>
            <Form
              form={form}
              name="idea-create"
              layout="vertical"
              onFinish={onFinish}
            >
              <FormItem
                label=""
                name="typeOption"
                rules={[
                  {
                    required: true,
                    message: `Please enter your type`
                  }
                ]}
              >
                <Radio.Group
                  className={styles.latestPoll_radioButton}
                  onChange={(e) =>
                    handlePollsOnChange('typeOption', e.target.value)
                  }
                >
                  {type?.map((item, i) => (
                    <Space
                      key={i}
                      direction="horizontal"
                      size={10}
                      // className={styles.datepicker_container}
                    >
                      <Radio value={item?.id}>
                        <CustomText
                          size={16}
                          font="bold"
                          color={6}
                          // className={styles.type_option_style}
                        >
                          {item?.type_option}
                        </CustomText>
                      </Radio>
                    </Space>
                  ))}
                </Radio.Group>
              </FormItem>
              <Space
                direction="horizontal"
                size={20}
                className={styles.datepicker_container}
              >
                <FormItem
                  label="Select Start date for the Poll"
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the start date for the poll!'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={handleStartDateChange}
                    disabledDate={disabledStartDate}
                    placeholder="Start Date"
                    // onChange={(_, dateValue) =>
                    //   handleFormDataOnChange('startDate', dateValue)
                    // }
                  />
                </FormItem>
                <FormItem
                  label="Select Last date for the Poll"
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the last date for the poll!'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={handleEndDateChange}
                    // disabledDate={disabledEndDate}

                    disabledDate={(current) =>
                      disabledEndDate(current, !!startDate)
                    }
                    placeholder="End Date"
                    // onChange={(_, dateValue) =>
                    //   handleFormDataOnChange('endDate', dateValue)
                    // }
                  />
                </FormItem>
              </Space>
              <FormItem
                label={<div className={`${styles.title_style}`}>Name</div>}
                name="name_title"
                rules={[
                  {
                    required: true,
                    message: `Please enter name`
                  }
                ]}
              >
                <Input
                  className={styles.event_input_style}
                  placeholder={`Please enter name`}
                  onChange={(e) =>
                    handlePollsOnChange('name_title', e.target.value)
                  }
                />
              </FormItem>

              <Row gutter={[8, 8]}>
                {options?.map((option, optionIndex) => (
                  <Col span={8} key={optionIndex}>
                    <FormItem
                      //label="Enter Options"
                      label={
                        <div className={`${styles.title_style}`}>
                          Options {optionIndex + 1}
                        </div>
                      }
                      //    name="quizOptions"
                      rules={[
                        {
                          required: false,
                          message: 'Please enter the question!'
                        }
                      ]}
                      // className={`${styles.input_styles}`}
                    >
                      <Input
                        className={styles.event_input_style}
                        style={{ width: '90%' }}
                        type="text"
                        value={option?.option}
                        onChange={(e) =>
                          handleOptionChange(optionIndex, e.target.value)
                        }
                      />
                      {optionIndex !== 0 ? (
                        <DeleteOutlined
                          style={{ color: 'red', marginLeft: '5px' }}
                          onClick={() => handleDeleteOption(optionIndex)}
                        />
                      ) : (
                        ''
                      )}
                    </FormItem>
                  </Col>
                ))}
                <FormItem className={styles.addOption_btn_style}>
                  <CustomButton
                    className={styles.addOption_btn}
                    type="button"
                    onClick={handleAddOption}
                  >
                    <PlusCircleOutlined />
                  </CustomButton>
                </FormItem>
              </Row>

              <Row gutter={12} className={styles.btn_container}>
                <Col xs={12}>
                  <FormItem className="text-right">
                    <CustomButton
                      size="large"
                      type="default"
                      onClick={() => history.back()}
                    >
                      Back
                    </CustomButton>
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem className="text-left">
                    <CustomButton
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={postCreateUpdatePollLoading}
                    >
                      Save
                    </CustomButton>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Space>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default CreateUpdatePoll;
