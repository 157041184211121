import React from 'react';
import styles from './mediaConsurSAC.module.less';

import { Space, Image } from 'antd';

//import { sizeChecker } from 'utilities/helpers';
import p1 from '../../images/processFlows/p1.svg';
import p2 from '../../images/processFlows/p2.svg';

function ProcessFlowsSAC() {
  //const isMobile = sizeChecker('xs');
  return (
    <Space direction="vertical" size={40}>
      <Image
        loading="lazy"
        className={styles.galleryImages}
        src={p1}
        preview={false}
        // alt={`Image`}
      />
      <Image
        loading="lazy"
        className={styles.galleryImages}
        src={p2}
        preview={false}
        // alt={`Image`}
      />
    </Space>
  );
}
export default ProcessFlowsSAC;
