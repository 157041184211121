import ConcurSACPage from './components/ConcurSACPage';
import Layout from 'common/CustomLayout';
import { TabTitle } from 'utilities/helpers';

const ConcurSAC = () => {
  TabTitle('Neev - SAP ERP');

  return (
    <Layout>
      <ConcurSACPage />
    </Layout>
  );
};

export default ConcurSAC;
