import React, { useEffect, useState, useCallback } from 'react';
import styles from './quiz.module.less';
import {
  Space,
  Radio,
  Row,
  Col,
  Empty,
  Spin,
  //Image,
  Pagination,
  Popconfirm
} from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryList } from 'features/ideas/redux/slice';
import { get_quizzes, postCreateQuiz } from '../../redux/slice';
//import edit_icon from '../../images/edit_icon.svg';
//import delete_icon from '../../images/delete_icon.svg';
import { history } from 'app/history';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const AdminQuizzesPage = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.ideas);
  const { quizzesData, get_quizzesLoading, pagination } = useSelector(
    (state) => state.quizzesAdmin
  );
  //console.log('pagination ', pagination);
  const [categoryId, setCategoryId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  useEffect(() => {
    dispatch(getCategoryList());
  }, []);
  useEffect(() => {
    // const payload = {
    //   category_id: categoryId ? categoryId : '',
    //   page: page,
    //   per_page: pageSize
    // };
    // dispatch(get_quizzes(payload));
    handleGetRequests();
  }, [categoryId, page, pageSize]);

  const handleGetRequests = () => {
    const payload = {
      category_id: categoryId ? categoryId : '',
      page: page,
      per_page: pageSize
    };
    dispatch(get_quizzes(payload));
  };
  // console.log('quizzesData', quizzesData);
  // console.log('categoryId', categoryId);

  const handleDeleteRequest = (quizId) => {
    const payload = { id: quizId.id, is_active: false };
    // console.log('payload', payload);
    dispatch(postCreateQuiz(payload)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };
  return (
    <>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <Spin spinning={get_quizzesLoading}>
          <Space direction="vertical" size={30} style={{ width: '100%' }}>
            <div className={styles.left_card_container}>
              <Space direction="vertical" size={2} style={{ width: '100%' }}>
                <Space
                  direction="horizontal"
                  size={2}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <CustomText
                    font="bold"
                    size={36}
                    color="primary"
                    letterSpacing={-0.72}
                  >
                    Quiz
                  </CustomText>
                  <div className={styles.take_quiz_btn}>
                    <CustomButton
                      className={styles.create_quiz_btn}
                      onClick={() => history.push(`/admin-create_quiz`)}
                    >
                      Create Quiz
                    </CustomButton>
                  </div>
                </Space>
                <CustomText
                  font="bold"
                  size={16}
                  color="1"
                  letterSpacing={-0.72}
                >
                  Filter by Tags
                </CustomText>
                <Radio.Group
                  className={styles.category_container}
                  onChange={({ target: { value } }) => setCategoryId(value)}
                  buttonStyle="solid"
                >
                  {categories.map((item) => {
                    const { id, category_name } = item;
                    return (
                      <Radio.Button
                        key={id}
                        value={id}
                        style={{ height: 'fit-content' }}
                      >
                        {category_name}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </Space>
            </div>
            <Space direction="vertical" size={10} style={{ width: '100%' }}>
              {quizzesData?.length ? (
                quizzesData?.map((item) => {
                  const {
                    // closed_date,
                    id,
                    // is_closed,
                    quiz_desc,
                    quiz_name,
                    quiz_cat,
                    // total_question,
                    // quiz_taken,
                    quiz_attented
                  } = item;

                  return (
                    <div key={id} className={styles.left_card_container}>
                      <Space
                        direction="vertical"
                        size={15}
                        style={{ width: '100%' }}
                      >
                        <Space
                          direction="horizontal"
                          size={2}
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div className={styles.quiz_attented_container}>
                            <CustomText
                              size={12}
                              className={styles.quiz_attented_style}
                            >
                              {quiz_attented} Answers
                            </CustomText>
                          </div>
                          <div className={styles.edit_container}>
                            <EditOutlined
                              onClick={() =>
                                history.push(`/admin-edit_quiz/${id}`)
                              }
                              className={`${styles.edit_icon_style}`}
                            />
                            {/* <Image
                              alt="edit"
                              src={edit_icon}
                              preview={false}
                              width="36%"
                              onClick={() =>
                                history.push(`/admin-edit_quiz/${id}`)
                              }
                            /> */}
                            <Popconfirm
                              title="Are you sure do you want to delete this quiz?"
                              open={visibility(id)}
                              onConfirm={() => {
                                handleDeleteRequest({
                                  id: id
                                });
                              }}
                              onCancel={() => {
                                setShowDisablePopup({});
                              }}
                              okText="Delete"
                              cancelText="No"
                              okButtonProps={{ type: 'danger' }}
                            >
                              {/* <Image
                                  alt="delete"
                                  src={delete_icon}
                                  preview={false}
                                  width="36%"
                                  style={{ marginLeft: '10px' }}
                                  onClick={() => {
                                    setShowDisablePopup({ [id]: true });
                                  }}
                                /> */}
                              <a>
                                <DeleteOutlined
                                  className={`${styles.delete_icon_style}`}
                                  onClick={() => {
                                    setShowDisablePopup({ [id]: true });
                                  }}
                                />
                              </a>
                            </Popconfirm>
                            {/* <Image
                              alt="delete"
                              src={delete_icon}
                              preview={false}
                              width="36%"
                              style={{ marginLeft: '10px' }}
                            /> */}
                          </div>
                        </Space>

                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Col span={24}>
                            <CustomText
                              font="bold"
                              size={18}
                              color="black"
                              letterSpacing={-0.72}
                            >
                              {quiz_name}
                            </CustomText>
                          </Col>
                        </Row>
                        <CustomText className={styles.quiz_cat_style}>
                          {quiz_cat?.category_name}
                        </CustomText>
                        <CustomText className={styles.quiz_desc_style}>
                          {quiz_desc}
                        </CustomText>

                        <div className={styles.take_quiz_btn}>
                          <CustomButton
                            className={styles.post_btn_style}
                            onClick={() =>
                              history.push(`/admin-quizdetails/${id}`)
                            }
                          >
                            View
                          </CustomButton>
                        </div>
                      </Space>
                    </div>
                  );
                })
              ) : (
                <Empty className="w-100" />
              )}

              <Pagination
                size="small"
                total={pagination?.total_records}
                current={page}
                pageSize={pageSize}
                showSizeChanger={true}
                onChange={(page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }}
                style={{ marginTop: '10px', textAlign: 'center' }}
                className="text-right"
              />
            </Space>
          </Space>
        </Spin>
      </CustomContainer>
    </>
  );
};

export default AdminQuizzesPage;
