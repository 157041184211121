import React, { useState, useEffect } from 'react';
import Layout from 'common/CustomLayout';
import { Space, Row, Spin, Image } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './quiz.module.less';
import check_icon from '../../images/check_icon.svg';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { get_quizzes } from 'features/quizzes/redux/slice';
import StartQuiz from 'features/quizzes/components/startQuiz';
import moment from 'moment';

const instruction = [
  {
    id: 1,
    icon: check_icon,
    title: 'Each question has only one correct answer.'
  },
  {
    id: 2,
    icon: check_icon,
    title: 'Read each question carefully before selecting your answer.'
  },
  {
    id: 3,
    icon: check_icon,
    title: 'Click on the corresponding option to choose your answer.'
  },
  {
    id: 4,
    icon: check_icon,
    title: 'Answer all questions before proceeding to the next or submitting.'
  },
  {
    id: 5,
    icon: check_icon,
    title: `If there are multiple questions, click on ‘’Next’’. Once you've answered all the questions, click the "Submit" button.`
  },
  {
    id: 6,
    icon: check_icon,
    title: 'Your score will be displayed at the end of the quiz.'
  },
  {
    id: 7,
    icon: check_icon,
    title: 'Enjoy the quiz and put your knowledge to the test!'
  }
];

//const quizStartTime = new Date(); // moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');

const QuizeInstruction = () => {
  const [startQuizVisible, setStartQuizVisible] = useState(false);
  const [quizStartTime, setQuizStartTime] = useState(null);

  const dispatch = useDispatch();
  const params = useParams();
  console.log(params);
  const { id } = params;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const retake = searchParams.get('retake');
  console.log('ASASFFF', retake);
  const { quizzesData, get_quizzesLoading } = useSelector(
    (state) => state.quizzes
  );
  console.log('quizzesData', quizzesData?.[0]?.quiz_taken);
  useEffect(() => {
    const payload = {
      quiz_id: id ? id : '',
      retake: retake === 'true' ? true : false
    };

    dispatch(get_quizzes(payload));
  }, [id]);
  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <Spin spinning={get_quizzesLoading}>
          <Space direction="vertical" size={30} style={{ width: '100%' }}>
            <div className={styles.left_card_container1}>
              <Space direction="vertical" size={30} style={{ width: '100%' }}>
                {!startQuizVisible && (
                  <>
                    {' '}
                    <Row className={styles.row_direction}>
                      <CustomText
                        font="bold"
                        size={32}
                        color="primary"
                        letterSpacing={-0.72}
                      >
                        {quizzesData && quizzesData[0]?.quiz_cat?.category_name}
                      </CustomText>
                      <CustomButton
                        className={styles.post_btn_style1}
                        onClick={() => history.push(`/quizzes`)}
                      >
                        Back
                      </CustomButton>
                    </Row>
                    <div className={styles.left_card_container2}>
                      <Space
                        direction="vertical"
                        size={12}
                        style={{ width: '100%' }}
                      >
                        <CustomText
                          font="bold"
                          size={22}
                          color="primary"
                          letterSpacing={-0.72}
                        >
                          Quiz Instructions
                        </CustomText>
                        {instruction?.map((item) => {
                          const { id, icon, title } = item;
                          return (
                            <Space key={id} size={6}>
                              <Image
                                alt="ellipse"
                                src={icon}
                                preview={false}
                                // width="2%"
                              />{' '}
                              <span className={styles.instrc_text}>
                                {title}
                              </span>
                            </Space>
                          );
                        })}
                        <div className={styles.btn_StartQuiz_cont}>
                          <CustomButton
                            className={styles.post_btn_StartQuiz}
                            onClick={() => {
                              setStartQuizVisible(true);
                              setQuizStartTime(
                                moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                              );
                              // history.push(`/start-quiz`);
                            }}
                          >
                            Start Quiz
                          </CustomButton>
                        </div>
                      </Space>
                    </div>
                  </>
                )}
                {startQuizVisible && (
                  <StartQuiz
                    quizzesData={quizzesData}
                    quizStartTime={quizStartTime}
                  />
                )}
              </Space>
            </div>
          </Space>
        </Spin>
      </CustomContainer>
    </Layout>
  );
};

export default QuizeInstruction;
