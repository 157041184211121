import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Segmented } from 'antd';
import styles from './glossary.module.less';
import CustomText from '@/common/CustomText';

const data = [
  {
    letter: 'A',
    function: [
      { name: 'A2P', fullForm: 'Allocation to Pay' },
      { name: 'AA', fullForm: 'Asset Accounting' },
      { name: 'AP', fullForm: 'Accounts Payable' },
      { name: 'AR', fullForm: 'Accounts Receivable' },
      { name: 'AUC', fullForm: 'Asset Under Construction' },
      { name: 'AOP', fullForm: 'Annual Operating Planning' },
      { name: 'ASN', fullForm: 'Advanced Shipment Notice' },
      { name: 'AUC', fullForm: 'Asset under construction' },
      {
        name: 'A-Trade / RXIL / MI Exchange',
        fullForm: 'MSME Platform (used by Treasury)'
      }
    ]
  },
  {
    letter: 'B',
    function: [
      { name: 'B&I', fullForm: 'Building & Infrastructure Vertical' },
      { name: 'BI', fullForm: 'Business Intelligence' },
      { name: 'BoM', fullForm: 'Bill of Material' },
      { name: 'BoQ', fullForm: 'Bill Of Quantities' },
      { name: 'BP', fullForm: 'Business Partner' },
      { name: 'BPML', fullForm: 'Business Process Master List' },
      { name: 'BRS', fullForm: 'Bank Reconciliation Statement' },
      { name: 'BW', fullForm: 'Buisness Warehouse' },
      { name: 'BGMS', fullForm: 'Bank Guarantee Management System' }
    ]
  },
  {
    letter: 'C',
    function: [
      { name: 'CCA', fullForm: 'Cost Center Accounting' },
      { name: 'CM', fullForm: 'Cash Management' },
      { name: 'CO', fullForm: 'Controlling' },
      { name: 'COA', fullForm: 'Chart Of Accounts' },
      { name: 'CPM', fullForm: 'Commercial Project Management' },
      { name: 'CRM', fullForm: 'Customer Relation Management' },
      { name: 'CTC', fullForm: 'Cost to Completion' },
      { name: 'CWIP', fullForm: 'Capital Work In Progress' }
    ]
  },
  {
    letter: 'D',
    function: [
      { name: 'DMS', fullForm: 'Document Management System (SAP)' },
      { name: 'DOA', fullForm: 'Delegation of Authority' },
      { name: 'DLP', fullForm: 'Defect Liability Period' },
      { name: 'DPR', fullForm: 'Daily Progress Report' }
    ]
  },
  {
    letter: 'E',
    function: [
      { name: 'E&I', fullForm: 'Energy & Industrial Vertical' },
      { name: 'EPPM', fullForm: 'Enterprise Portfolio and Project Management' },
      { name: 'ERM', fullForm: 'Employee Relationship Management' },
      { name: 'ERP', fullForm: 'Enterprise Resource Planning' },
      { name: 'ERS', fullForm: 'Evaluated Receipt Settlement' },
      { name: 'ETO', fullForm: 'Engineer-To-Order' }
    ]
  },
  {
    letter: 'F',
    function: [
      { name: 'FG', fullForm: 'Finished Goods' },
      { name: 'FI', fullForm: 'Financial Accounting' },
      { name: 'FICO', fullForm: 'Financials and Controlling' },
      { name: 'FSCM', fullForm: 'Financial Supply Chain Management' },
      { name: 'FCTC', fullForm: 'Future Cost to Completion' },
      { name: 'FQP', fullForm: 'Field Quality Plan' }
    ]
  },
  {
    letter: 'G',
    function: [
      { name: 'GL', fullForm: 'General Ledger' },
      { name: 'GR', fullForm: 'Goods Receipt' },
      { name: 'GRC', fullForm: 'Governance Risk & Compliance' },
      { name: 'GRN', fullForm: 'Goods Received Note' },
      { name: 'GST', fullForm: 'Goods and Service Tax' },
      { name: 'GUI', fullForm: 'Graphical User Interface' }
    ]
  },
  {
    letter: 'I',
    function: [
      { name: 'IBD', fullForm: 'In Bound Delivery' },
      { name: 'IIR', fullForm: 'Inward Invoice Receipt' },
      { name: 'IM', fullForm: 'Investment Management' },
      { name: 'IO', fullForm: 'Internal Order' },
      { name: 'IR', fullForm: 'Invoice Receipt' },
      { name: 'IRN', fullForm: 'Invoice Reference Number' },
      {
        name: 'IRISO',
        fullForm: 'Internal Requisition to Internal Sales Order'
      }
    ]
  },
  {
    letter: 'J',
    function: [{ name: 'JV/JE', fullForm: 'Journal Voucher / Journal Entry' }]
  },
  {
    letter: 'K',
    function: [{ name: 'KDS', fullForm: 'Key Data Structure' }]
  },
  {
    letter: 'L',
    function: [
      { name: 'LC/BG', fullForm: 'Letters of Credit/ Bank Guarantee' },
      { name: 'LOA', fullForm: 'Letter of Acceptance' },
      { name: 'LOI', fullForm: 'Letter of Intent' }
    ]
  },
  {
    letter: 'M',
    function: [
      { name: 'MM', fullForm: 'Material Management' },
      { name: 'MRP', fullForm: 'Materials Requirement Planning' },
      { name: 'MRIV', fullForm: 'Material Received Invoice Verification' },
      {
        name: 'MT940',
        fullForm:
          'a specific SWIFT message type used by the SWIFT network to send and receive end-of-day bank account statements.'
      }
    ]
  },
  {
    letter: 'N',
    function: [{ name: 'NFA', fullForm: 'Note For Approval' }]
  },
  {
    letter: 'O',
    function: [{ name: 'OBD', fullForm: 'Out Bound Delivery' }]
  },
  {
    letter: 'P',
    function: [
      { name: 'P2P', fullForm: 'Procure to Pay' },
      { name: 'PA', fullForm: 'Personnel Administration' },
      { name: 'PA', fullForm: 'Profitability Analysis' },
      { name: 'PC', fullForm: 'Product Costing' },
      { name: 'PCA', fullForm: 'Profit Center Accounting' },
      { name: 'PGI', fullForm: 'Post Goods Issue' },
      { name: 'PLM', fullForm: 'Product Life-cycle Management' },
      { name: 'PM', fullForm: 'Plant Maintenance' },
      { name: 'PO', fullForm: 'Purchase Order' },
      { name: 'POCM', fullForm: 'Percentage of Completion Method' },
      { name: 'PP', fullForm: 'Production Planning' },
      { name: 'PPM', fullForm: 'Portfolio Project Management' },
      { name: 'PR', fullForm: 'Purchase Requisition' },
      { name: 'PRI', fullForm: 'Project Release Indent' },
      { name: 'Price Control S', fullForm: 'Standard Price' },
      { name: 'Price Control V', fullForm: 'Moving Average' },
      { name: 'PS', fullForm: 'Project System' },
      { name: 'PV', fullForm: 'Production Version' },
      { name: 'PY', fullForm: 'Payer' },
      { name: 'PMG', fullForm: 'Project Management Group' }
    ]
  },
  {
    letter: 'Q',
    function: [{ name: 'QM', fullForm: 'Quality Management' }]
  },
  {
    letter: 'R',
    function: [
      { name: 'RFQ', fullForm: 'Request For Quotation' },
      {
        name: 'RICEFW',
        fullForm:
          'Reports, Interface, conversion, Enhancements, Forms and Workflow'
      },
      { name: 'RIT', fullForm: 'Remittance In Transit' },
      { name: 'RM', fullForm: 'Raw Material' },
      { name: 'RPT', fullForm: 'Related Party Transaction' },
      {
        name: 'RPT',
        fullForm: 'Related Party Transaction'
      }
    ]
  },
  {
    letter: 'S',
    function: [
      { name: 'S2P', fullForm: 'Source to Pay' },
      { name: 'SAC', fullForm: 'SAP Analytics Cloud' },
      { name: 'SCM', fullForm: 'Supply Chain Management' },
      { name: 'SD', fullForm: 'Sales and Distribution' },
      { name: 'SDD', fullForm: 'Solution Design Document' },
      { name: 'SES', fullForm: 'Service Entry Sheet' },
      { name: 'SFG', fullForm: 'Semi-Finished Goods' },
      { name: 'SH', fullForm: 'Ship to party' },
      { name: 'SIT', fullForm: 'System Integration Testing' },
      { name: 'SLP', fullForm: 'Supplier Lifecycle & Performance (Ariba)' },
      { name: 'SO', fullForm: 'Sales Order' },
      { name: 'SOD', fullForm: 'Segregation of Duties' },
      { name: 'SP', fullForm: 'Sold to party' },
      { name: 'SRM', fullForm: 'Supplier Relationship Management' },
      { name: 'STO', fullForm: 'Stock Transport Order' },
      { name: 'SDR', fullForm: 'Scrap Disbursal Request' },
      { name: 'STO', fullForm: 'Stock Transfer Order' },
      { name: 'SCF', fullForm: 'Supply Chain Finance' }
    ]
  },
  {
    letter: 'T',
    function: [
      { name: 'Tcode', fullForm: 'Transaction Code' },
      { name: 'TCS', fullForm: 'Tax Collected at Source' },
      { name: 'TDS', fullForm: 'Tax Deducted at source' },
      { name: 'TMU', fullForm: 'The Manufacturing Unit' },
      { name: 'TR', fullForm: 'Treasury' }
    ]
  },
  {
    letter: 'U',
    function: [
      { name: 'UAT', fullForm: 'User Acceptance Testing' },
      { name: 'UBR', fullForm: 'Unbilled Revenue' }
    ]
  },
  {
    letter: 'V',
    function: [
      { name: 'VIM', fullForm: 'Vendor Invoice Management' },
      { name: 'VTC', fullForm: 'Variation to Contract' },
      {
        name: 'VFS',
        fullForm: 'Vendor Financing Scheme'
      }
    ]
  },
  {
    letter: 'W',
    function: [
      { name: 'WBS', fullForm: 'Work Breakdown Structure' },
      { name: 'WBSE', fullForm: 'Work Breakdown Structure Element' },
      { name: 'WF', fullForm: 'Work Flow' },
      { name: 'WM', fullForm: 'Warehouse Management' }
    ]
  }
];

const AlphabetSelector = () => {
  const [selectedLetter, setSelectedLetter] = useState('A');

  const handleChange = (letter) => {
    setSelectedLetter(letter);
  };

  const alphabetOptions = data.map((item) => item.letter);
  const selectedData = data.find((item) => item.letter === selectedLetter);

  return (
    <>
      <Row className={`mb-4 ${styles.content_holder}`}>
        <Col xs={24} className={`df-jc-ac`} xl={3}>
          <div className={styles.letterCapital}>{selectedLetter}</div>
        </Col>
        <Col xs={24} xl={21}>
          <Row gutter={[8, 34]} className="ml-3 mt-2 p-3">
            {selectedData && selectedData.function.length > 0 ? (
              selectedData.function.map((func, index) => (
                <Col key={index} xs={24} xl={8}>
                  <CustomText font="bold" size={20} color={'primary'}>
                    {func.name}
                  </CustomText>
                  <br />
                  <CustomText font="bold" size={14} color={1}>
                    {func.fullForm}
                  </CustomText>
                </Col>
              ))
            ) : (
              <p>No functions available for this letter.</p>
            )}
          </Row>
        </Col>
      </Row>

      <div className="df-jc-ac">
        <div className={styles.buttonHolder}>
          <Segmented
            options={alphabetOptions}
            value={selectedLetter}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default AlphabetSelector;
