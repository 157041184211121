import { useState } from 'react';
import { Fade } from 'react-reveal';
import { Image, Space } from 'antd';
import styles from './anchor_buttons.module.less';
import CustomText from 'common/CustomText';
import { sizeChecker, smoothScrollTo } from 'utilities/helpers';
import SAP from '../../images/anchorButtons/SAP 2.svg';
import Bespoke from '../../images/anchorButtons/Bespoke 2.svg';
import Glossary from '../../images/anchorButtons/Glossary 2.svg';
import Key from '../../images/anchorButtons/Key 2.svg';
import LearningBytes from '../../images/anchorButtons/Learning Bytes 2.svg';
import Processes from '../../images/anchorButtons/Processes 2.svg';

const data = [
  { id: 'SAP_Modules', label: 'SAP Modules', icon: SAP },
  { id: 'process', label: 'Processes Covered', icon: Processes },
  { id: 'Key_Concepts', label: 'Key Concepts', icon: Key },
  { id: 'Learning_Bytes', label: 'Learning Bytes', icon: LearningBytes },
  { id: 'bespoke', label: 'Bespoke Applications', icon: Bespoke },
  { id: 'Glossary', label: 'Glossary', icon: Glossary }
];

const AnchorButtons = () => {
  const isMobile = sizeChecker('xs');
  const [show, setShow] = useState({});

  return (
    <div className={styles.container}>
      <Space direction="vertical" size={15} align="start">
        {data.map(({ id, label, icon }) => {
          return (
            <div
              key={id}
              className={styles.item_container}
              onMouseEnter={() => setShow({ [id]: true })}
              onMouseLeave={() => setShow({})}
              {...(isMobile
                ? {
                    onClick: () => {
                      setShow({ [id]: true });
                    }
                  }
                : {})}
            >
              <Image
                alt="circle"
                src={icon}
                preview={false}
                className={`${styles.anchor_icon_size}`}
                onClick={() => smoothScrollTo(id, 'start')}
              />
              {show[id] ? (
                <Fade right>
                  <div
                    className={styles.tooltip_container}
                    onClick={() => smoothScrollTo(id, 'start')}
                  >
                    <CustomText font="medium" size={12} color={10}>
                      {label}
                    </CustomText>
                  </div>
                </Fade>
              ) : null}
            </div>
          );
        })}
      </Space>
    </div>
  );
};

export default AnchorButtons;
