import QuizzesPage from './components/QuizzesPage';
import Layout from 'common/CustomLayout';
import { TabTitle } from 'utilities/helpers';

const Quizzes = () => {
  TabTitle('Neev - Quiz');
  return (
    <Layout>
      <QuizzesPage />
    </Layout>
  );
};

export default Quizzes;
