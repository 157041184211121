import { useEffect, useState, useMemo } from 'react';
import { Form, Input, DatePicker, Select, Space, message } from 'antd';
import Layout from 'common/CustomLayout';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './quiz.module.less';
import { useDispatch, useSelector } from 'react-redux';
import FormItem from 'common/FormItem';
import { getCategoryList } from 'features/ideas/redux/slice';
import { postCreateQuiz } from '../../redux/slice';
import { debounce } from 'lodash';
import Questions from './Questions';
import moment from 'moment';
import { history } from 'app/history';

const { Option } = Select;
const { TextArea } = Input;

const CreateQuiz = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { categories } = useSelector((state) => state.ideas);
  const [value, setValue] = useState(null);
  const [formData1, setFormData1] = useState({});
  const [tagSearch, setTagSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [questions, setQuestions] = useState([
    {
      question: '',
      options: [
        { label: 'A', value: '', selected: false },
        { label: 'B', value: '', selected: false }
      ]
    }
  ]);

  useEffect(() => {
    const payload = {};
    if (tagSearch) payload['search'] = tagSearch;
    dispatch(getCategoryList(payload));
  }, [tagSearch]);

  const disabledStartDate = (current) => {
    // Disable past dates
    return current && current < moment().startOf('day');
  };

  const disabledEndDate = (current) => {
    // Disable dates before the selected start date
    // return (
    //   current &&
    //   (current < startDate ||
    //     (isStartDateSelected && current < moment().startOf('day')))
    // );
    return (
      current && (!startDate || current < moment(startDate).startOf('day'))
    );
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    form.setFieldsValue({ endDate: undefined });
  }, [startDate]);

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (tagSearch) => {
      setTagSearch(tagSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const onFinish = (values) => {
    const isValid = questions.every((question) => {
      return (
        question.question !== null &&
        question.question.trim() !== '' &&
        question.options.some((option) => option.selected) &&
        question.options.every(
          (option) => option.value !== null && option.value.trim() !== ''
        )
      );
    });

    if (!isValid) {
      // Display an error message or handle the validation failure as needed
      message.error('Please fill in all question and option fields.');
      return;
    }

    const payload = {
      quiz_name: values?.quizName,
      quiz_desc: formData1?.quizDescription,
      launch_date: moment(startDate?._d)?.format('YYYY-MM-DD'),
      closed_date: moment(endDate?._d)?.format('YYYY-MM-DD'),
      category_id: formData1?.quizTags,
      is_allowed_certificate: value === 'Yes' ? true : false,
      is_active: true,
      quiz_question_list: questions.map((question) => ({
        quiz_question: question.question,
        is_active: true,
        options_list: question.options.map((option) => ({
          quiz_option: option.value,
          is_correct: option.selected === undefined ? false : option.selected,
          is_active: true
        }))
      }))
    };
    dispatch(postCreateQuiz(payload))?.then(function (response) {
      if (response?.payload?.success) {
        history.push(`/admin-quizzes`);
      } else {
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
  };

  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <div className={styles.createQuiz_card_container}>
          <Space direction="vertical" size={30} style={{ width: '100%' }}>
            <CustomText
              font="bold"
              size={36}
              color="primary"
              letterSpacing={-0.72}
            >
              Create Quiz
            </CustomText>
            <Form
              form={form}
              name="createQuiz"
              onFinish={onFinish}
              layout="vertical"
              className={styles.form_container}
            >
              <FormItem
                label="Name of Quiz"
                name="quizName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the name of the quiz!'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  className={styles.input_container_style}
                  placeholder="Please input the name of the quiz!"
                  onChange={(e) =>
                    handleFormDataOnChange('quizName', e.target.value)
                  }
                />
              </FormItem>
              <FormItem
                label="Add description of your Quiz"
                name="quizDescription"
                rules={[
                  {
                    required: true,
                    message: 'Please add a description of the quiz!'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <TextArea
                  className={styles.input_container_style}
                  placeholder="Please add a description of the quiz!"
                  onChange={(e) =>
                    handleFormDataOnChange('quizDescription', e.target.value)
                  }
                />
              </FormItem>

              <Space
                direction="horizontal"
                size={20}
                className={styles.datepicker_container}
              >
                <FormItem
                  label="Select Start date for the Quiz"
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the start date for the quiz!'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={handleStartDateChange}
                    disabledDate={disabledStartDate}
                    placeholder="Start Date"
                    // onChange={(_, dateValue) =>
                    //   handleFormDataOnChange('startDate', dateValue)
                    // }
                  />
                </FormItem>
                <FormItem
                  label="Select Last date for the Quiz"
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the last date for the quiz!'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={handleEndDateChange}
                    // disabledDate={disabledEndDate}

                    disabledDate={(current) =>
                      disabledEndDate(current, !!startDate)
                    }
                    placeholder="End Date"
                    // onChange={(_, dateValue) =>
                    //   handleFormDataOnChange('endDate', dateValue)
                    // }
                  />
                </FormItem>
              </Space>
              <FormItem
                label="Add Tags"
                name="quizTags"
                rules={[
                  {
                    required: true,
                    message: 'Please select at least one tag for the quiz!'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  // mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select tags"
                  showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  onSearch={debounceFetcher}
                  onChange={(value) =>
                    handleFormDataOnChange('quizTags', value)
                  }
                  filterOption={(input, option) => {
                    return (
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                  }}
                >
                  {categories?.map((item, index) => (
                    <Option
                      title={item.category_name}
                      key={index}
                      value={item?.id}
                    >
                      <span
                        style={{
                          fontSize: '12px',
                          textTransform: 'uppercase',
                          fontFamily: 'CerebriSans SemiBold'
                        }}
                      >
                        <span style={{ marginLeft: '10px' }}>
                          {item?.category_name}
                        </span>
                      </span>
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <Questions
                value={value}
                setValue={setValue}
                setQuestions={setQuestions}
                questions={questions}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end'
                }}
              >
                <CustomButton
                  className={styles.back_btn_style}
                  onClick={() => history.push(`/admin-quizzes`)}
                >
                  Back
                </CustomButton>
                <FormItem>
                  <CustomButton
                    type="primary"
                    htmlType="submit"
                    className={styles.post_btn_style}
                  >
                    Save
                  </CustomButton>
                </FormItem>
              </div>
            </Form>
          </Space>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default CreateQuiz;
