import React from 'react';
import { Image } from 'antd';
import styles from './timeline.module.less';
import timeline from '../../images/Timeline-neev.svg';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import { sizeChecker } from 'utilities/helpers';

const Timeline = () => {
  const isMobile = sizeChecker('xs');
  return (
    <CustomContainer
      id="timeline"
      title="Timeline"
      containerClassname={styles.container}
      contentClassname={styles.content}
    >
      <CustomText
        font="bold"
        size={isMobile ? 26 : 36}
        color={10}
        className={styles.title}
      >
        NEEV Roadmap
      </CustomText>
      <div className={styles.image_container}>
        <Image alt="NEEV Roadmap" src={timeline} preview={false} />
      </div>
    </CustomContainer>
  );
};

export default Timeline;
