import { Layout } from 'antd';
import CustomHeader from '../CustomHeader';
import styles from './custom_layout.module.less';
import NotificationBar from '../NotificationBar';
import CustomFooter from '../CustomFooter';
import LocalStorage from 'utilities/localStorage';

const { Content } = Layout;

const CustomLayoutPage = ({ children, header = true }) => {
  const user = LocalStorage.getItem('neev_login_user');
  return (
    <Layout className={styles.container}>
      {header ? <CustomHeader /> : null}
      <Layout className={styles.content_layout}>
        {user?.user_details?.user_role === 'user' ||
        user?.user_details?.user_role === 'admin' ? (
          <NotificationBar />
        ) : null}
        <Content className={styles.content}>{children}</Content>
      </Layout>
      <CustomFooter />
    </Layout>
  );
};

export default CustomLayoutPage;
