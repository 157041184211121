import React, { useEffect, useState } from 'react';
import Layout from 'common/CustomLayout';
import styles from './index.module.less';
import { Space, Spin, Progress, Row, Col, Pagination } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import { useSelector, useDispatch } from 'react-redux';
import { getQuizStats } from '../../redux/slice';
import CustomText from '@/common/CustomText';
import { UserOutlined } from '@ant-design/icons';
import CustomButton from '@/common/CustomButton';
import { sizeChecker } from 'utilities/helpers';

const getCircleColor = (quizScore) => {
  if (quizScore === 100) {
    return '#52c41a'; // Green color
  } else if (quizScore < 50) {
    return '#fadb14'; // Yellow color
  } else {
    return '#323282'; // Default color (Blue)
  }
};

const Quizzes_statistics = () => {
  const isMobile = sizeChecker('xs');

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { quizStatsData, getQuizStatsLoading, pagination } = useSelector(
    (state) => state.adminConsole
  );
  console.log('quizStatsData', quizStatsData);
  useEffect(() => {
    const payload = {
      page: page,
      per_page: pageSize
    };
    dispatch(getQuizStats(payload));
  }, [page, pageSize]);
  /* {
  "id": 9,
  "quiz_name": "Test Quiz month",
  "quiz_attented": 0,
  "total_users": 14,
  "percent": 0
} */
  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <Spin spinning={getQuizStatsLoading}>
          <div className={styles.left_card_container}>
            <Space direction="vertical" size={24} style={{ width: '100%' }}>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignContent: 'center'
                }}
              >
                <CustomText
                  font="bold"
                  size={36}
                  color="primary"
                  letterSpacing={-0.72}
                >
                  Quizzes
                </CustomText>
                <CustomButton
                  size="large"
                  type="default"
                  onClick={() => history.back()}
                >
                  Back
                </CustomButton>
              </Row>
              <Row gutter={[8, 30]}>
                {quizStatsData?.map((item) => {
                  return (
                    <Col
                      span={isMobile ? 24 : 6}
                      key={item?.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        <Progress
                          type="circle"
                          width={200}
                          percent={(item?.percent / 100) * 100}
                          format={() => (
                            <React.Fragment>
                              <span
                                style={{
                                  fontSize: '24px',
                                  fontWeight: 'bold'
                                }}
                              >{`${item?.quiz_attented}`}</span>
                              <UserOutlined
                                style={{
                                  fontSize: '24px',
                                  marginLeft: '6px'
                                }}
                              />
                            </React.Fragment>
                          )}
                          strokeColor={getCircleColor(item?.percent)} //{color}
                          style={{
                            //  marginTop: '20px',
                            height: '200px',
                            color: 'green'
                          }}
                        />
                      </div>
                      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <CustomText
                          font="bold"
                          size={14}
                          color="primary"
                          letterSpacing={-0.72}
                        >
                          {item?.quiz_name}
                        </CustomText>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <Pagination
                size="small"
                total={pagination?.total_records}
                current={page}
                pageSize={pageSize}
                showSizeChanger={true}
                onChange={(page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }}
                style={{ marginTop: '10px', textAlign: 'center' }}
                className="text-right"
              />
            </Space>
          </div>
        </Spin>
      </CustomContainer>
    </Layout>
  );
};

export default Quizzes_statistics;
