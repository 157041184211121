import React from 'react';
import { Space } from 'antd';
import CustomContainer from '@/common/CustomContainer';
//import CustomText from '@/common/CustomText';
import styles from './userManual.module.less';
//import tcs from '../../images/partners/tcs.png';
import CustomButton from '@/common/CustomButton';
import { sizeChecker } from 'utilities/helpers';

const UserManual = () => {
  const isMobile = sizeChecker('xs');

  const downloadZipFolder = () => {
    const downloadUrl = '/ECTestScripts.zip'; // '/templates/template.xlsx';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'ECTestScripts.zip'; //'template.xlsx';
    link.click();
  };

  return (
    <CustomContainer
      id="userManual"
      title="userManual"
      containerClassname={styles.container}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Space
        direction={isMobile ? 'vertical' : 'horizontal'}
        size={isMobile ? 20 : 20}
        className="w-100 justify-content-center"
      >
        <a
          href={'/SF_EC_UserManual.pdf'}
          rel="noopener noreferrer"
          target="_blank"
        >
          <CustomButton className={styles.userManual_btn}>
            SF EC User Manual
          </CustomButton>
        </a>
        <a onClick={downloadZipFolder}>
          <CustomButton className={styles.userManual_btn}>
            EC Test Scripts{' '}
          </CustomButton>
        </a>
        <a
          href={
            'https://tataprojects.enable-now.cloud.sap/wa/trg_tpl/~tag/published/index.html?show=group!GR_13264F2C6B4E65BB'
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          <CustomButton className={styles.userManual_btn}>
            Employee Self-Service Training
          </CustomButton>
        </a>
        <a
          href={
            'https://tataprojects.enable-now.cloud.sap/wa/trg_tpl/~tag/published/index.html?show=group!GR_255B4B38357423BB'
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          <CustomButton className={styles.userManual_btn}>
            Manager Self-Service Training
          </CustomButton>
        </a>
      </Space>
    </CustomContainer>
  );
};

export default UserManual;
