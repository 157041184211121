import { Col /*, Image*/, Radio, Row, Space } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomCard from '@/common/CustomCard';
import CustomButton from '@/common/CustomButton';
import Share from '@/common/Share';
import WinnerCard from '@/common/WinnerCard';
import styles from './quiz.module.less';
// images
import one from '../../images/1.jpg';
import two from '../../images/2.jpg';
import three from '../../images/3.jpg';
import four from '../../images/4.jpg';
import five from '../../images/5.jpg';
import six from '../../images/6.jpg';

const winners = [
  {
    id: 1,
    image: one,
    name: 'Priyanka',
    role: 'Director',
    description: 'For her outstanding contributions to S2P process engineering'
  },
  {
    id: 2,
    image: two,
    name: 'Swetha',
    role: 'Engagement Manager',
    description: 'For her outstanding contributions to S2P process engineering'
  },
  {
    id: 3,
    image: three,
    name: 'Siraj Shekar',
    role: 'Functional Lead',
    description: 'For his outstanding contributions to S2P process engineering'
  },
  {
    id: 4,
    image: four,
    name: 'Prasanth',
    role: 'Director',
    description: 'For his outstanding contributions to S2P process engineering'
  },
  {
    id: 5,
    image: five,
    name: 'Rajesh',
    role: 'Engagement Manager',
    description: 'For his outstanding contributions to S2P process engineering'
  },
  {
    id: 6,
    image: six,
    name: 'krishore',
    role: 'Director',
    description: 'For his outstanding contributions to S2P process engineering'
  }
];

const QuizPage = () => {
  return (
    <>
      <CustomContainer title="Winners" containerClassname={styles.container}>
        <Space direction="vertical" size={32}>
          <CustomText
            font="bold"
            size={36}
            color="primary"
            letterSpacing={-0.72}
          >
            Winners of the Quiz Last Week are...
          </CustomText>
          <Row gutter={32}>
            <Col span={15}>
              <Row gutter={[32, 32]}>
                {winners.map((item) => {
                  return (
                    <Col key={item.id} span={8}>
                      <WinnerCard item={item} award={true} />
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col span={9}>
              <Row gutter={[15, 15]}>
                <Col span={24}>
                  <CustomCard
                    title="Share an idea"
                    contentClassName={styles.right_card_container}
                  >
                    <Space direction="vertical" size={10}>
                      <CustomText font="medium" size={12} color={5}>
                        Have an idea or suggestion for the new ERP? Share it
                        with the team and be a part of the transformation
                        journey.
                      </CustomText>
                      <CustomButton type="primary" size="small">
                        Share an idea
                      </CustomButton>
                    </Space>
                  </CustomCard>
                </Col>
                <Col span={12}>
                  <CustomCard title="Latest Poll">
                    <div className={styles.right_card_container}>
                      <Space direction="vertical" size={13}>
                        <CustomText font="medium" size={12} color={5}>
                          Do you think this portal will enable you to contribute
                          to our SAP implementation?
                        </CustomText>
                        <Radio.Group>
                          <Radio value={1}>
                            <CustomText font="medium" color={6}>
                              Yes
                            </CustomText>
                          </Radio>
                          <Radio value={2}>
                            <CustomText font="medium" color={6}>
                              No
                            </CustomText>
                          </Radio>
                        </Radio.Group>
                        <CustomButton type="primary" size="small">
                          Vote Now
                        </CustomButton>
                      </Space>
                    </div>
                  </CustomCard>
                </Col>
                <Col span={12}>
                  <CustomCard title="Latest Quiz">
                    <div className={styles.right_card_container}>
                      <Space direction="vertical" size={10}>
                        <CustomText font="medium" size={16}>
                          SAP 101 Quiz
                        </CustomText>
                        <CustomText font="medium" size={12} color={5}>
                          Participate in the quiz to win attractive rewards. The
                          quiz closes on August 18.
                        </CustomText>
                        <CustomButton type="primary" size="small">
                          Take the Quiz
                        </CustomButton>
                      </Space>
                    </div>
                  </CustomCard>
                </Col>
              </Row>
            </Col>
          </Row>
        </Space>
      </CustomContainer>
      <Share
        bgTitle="Suggest"
        title="Need something in the new ERP?"
        description="Share your suggestions with the implementation team"
        btnText="Share your thoughts"
      />
    </>
  );
};

export default QuizPage;
