import React from 'react';
import Layout from 'common/CustomLayout';
import ManageEventsMilestonesPage from './ManageEventsMilestonesPage';
const ManageEventsMilestones = () => {
  return (
    <Layout>
      <ManageEventsMilestonesPage />
    </Layout>
  );
};

export default ManageEventsMilestones;
