import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  quizzes,
  quizScoreAdmin,
  post_create_quiz,
  get_quiz_user_resAdmin,
  quiz_report
} from './api';
import { history } from '@/app/history';

const initialState = {
  get_quizzesLoading: false,
  get_quiz_user_scoreLoading: false,
  get_quiz_user_score_responsesLoading: false,
  postCreateQuizLoading: false,
  get_quiz_reportLoading: false,
  quizzesData: [],
  quiz_user_score: [],
  pagination: [],
  quiz_user_responses: [],
  user_details: {}
};

const actions = {
  // GET_POLLS: 'get_quizze/GET_POLLS',
  GET_QUIZ_USER_SCORE: 'get_quiz_user_score/GET_QUIZ_USER_SCORE',
  POST_CREATE_QUIZ: 'postCreateQuiz/POST_CREATE_QUIZ',
  GET_QUIZZES: 'get_quizzes/GET_QUIZZES',
  GET_QUIZ_USER_RESPONSES: 'get_quiz_user_responses/GET_QUIZ_USER_RESPONSES',
  GET_QUIZ_REPORT: 'get_quiz_report/GET_QUIZ_REPORT'
};

export const get_quizzes = createAsyncThunk(
  actions.GET_QUIZZES,
  async (payload) => {
    const response = await quizzes(payload);

    return response;
  }
);

export const get_quiz_user_score = createAsyncThunk(
  actions.GET_QUIZ_USER_SCORE,
  async (payload) => {
    const response = await quizScoreAdmin(payload);

    return response;
  }
);

export const get_quiz_user_responses = createAsyncThunk(
  actions.GET_QUIZ_USER_RESPONSES,
  async (payload) => {
    const response = await get_quiz_user_resAdmin(payload);

    return response;
  }
);

export const postCreateQuiz = createAsyncThunk(
  actions.POST_CREATE_QUIZ,
  async (payload) => {
    const response = await post_create_quiz(payload);
    return response;
  }
);

export const get_quiz_report = createAsyncThunk(
  actions.GET_QUIZ_REPORT,
  async (payload) => {
    const response = await quiz_report(payload);
    return response;
  }
);

export const quizzesAdminSlice = createSlice({
  name: 'quizzesAdmin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(get_quizzes.pending, (state) => {
        state.get_quizzesLoading = true;
      })
      .addCase(get_quizzes.fulfilled, (state, action) => {
        state.get_quizzesLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.quizzesData = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.quizzesData = [];
          message.error(msg);
        }
      })
      .addCase(get_quizzes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_quizzesLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_quiz_user_score.pending, (state) => {
        state.get_quiz_user_scoreLoading = true;
      })
      .addCase(get_quiz_user_score.fulfilled, (state, action) => {
        state.get_quiz_user_scoreLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.quiz_user_score = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.quiz_user_score = [];
          message.error(msg);
        }
      })
      .addCase(get_quiz_user_score.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_quiz_user_scoreLoading = false;

        message.error(msg);
      });
    builder
      .addCase(postCreateQuiz.pending, (state) => {
        state.postCreateQuizLoading = true;
      })
      .addCase(postCreateQuiz.fulfilled, (state, action) => {
        state.postCreateQuizLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          message.error(msg);
        }
      })
      .addCase(postCreateQuiz.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postCreateQuizLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_quiz_user_responses.pending, (state) => {
        state.get_quiz_user_score_responsesLoading = true;
      })
      .addCase(get_quiz_user_responses.fulfilled, (state, action) => {
        state.get_quiz_user_score_responsesLoading = false;
        const {
          success,
          data,
          user_details,
          pagination,
          message: msg
        } = action.payload;

        if (success) {
          state.quiz_user_responses = data ? data : [];
          state.user_details = user_details ? user_details : {};
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.quiz_user_score = [];
          message.error(msg);
        }
      })
      .addCase(get_quiz_user_responses.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_quiz_user_score_responsesLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_quiz_report.pending, (state) => {
        state.get_quiz_reportLoading = true;
      })
      .addCase(get_quiz_report.fulfilled, (state, action) => {
        state.get_quiz_reportLoading = false;
        const { success, fileUrl, message: msg } = action.payload;

        if (success) {
          message.success(msg);
          // Create a link element
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = `Report-export.csv`; // Replace with the desired filename and extension

          // Append the link to the document body
          document.body.appendChild(link);

          // Trigger the click event to start the download
          link.click();

          // Remove the link from the document body
          document.body.removeChild(link);
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          message.error(msg);
        }
      })
      .addCase(get_quiz_report.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_quiz_reportLoading = false;

        message.error(msg);
      });
  }
});

export default quizzesAdminSlice.reducer;
