import { useEffect, useState, useMemo } from 'react';
import { Form, Input, DatePicker, Select, Space, message } from 'antd';
import Layout from 'common/CustomLayout';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './quiz.module.less';
import { useDispatch, useSelector } from 'react-redux';
import FormItem from 'common/FormItem';
import { getCategoryList } from 'features/ideas/redux/slice';
import { postCreateQuiz, get_quizzes } from '../../redux/slice';
import { debounce } from 'lodash';
import QuizQuestions from './QuizQuestions';
import moment from 'moment';
import { history } from 'app/history';
import { useParams } from 'react-router-dom';

const { Option } = Select;
const { TextArea } = Input;

const EditQuiz = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const params = useParams();
  const { id } = params;

  const { quizzesData, postCreateQuizLoading } = useSelector(
    (state) => state.quizzesAdmin
  );

  const { categories } = useSelector((state) => state.ideas);
  const [value, setValue] = useState(null);
  const [formData1, setFormData1] = useState({});
  const [tagSearch, setTagSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [questions, setQuestions] = useState([
    { question: '', options: [{ label: 'A', value: '', selected: false }] }
  ]);

  console.log('quizzesData[0]', quizzesData[0]);

  useEffect(() => {
    if (id && quizzesData[0]) {
      const {
        launch_date,
        closed_date,
        quiz_cat,
        quiz_desc,
        quiz_name,
        is_allowed_certificate
      } = quizzesData[0];
      form.setFieldsValue({
        quizName: quiz_name,
        quizDescription: quiz_desc,
        startDate: moment(launch_date),
        endDate: moment(closed_date),
        quizTags: quiz_cat?.id,
        is_allowed_certificate: is_allowed_certificate ? 'Yes' : 'No'
      });

      const initializedQuestions = quizzesData[0]?.quizques?.map((ques) => ({
        id: ques?.id,
        is_active: ques?.is_active,
        question: ques.quiz_question,
        options: ques.quesopt.map((opt, index) => ({
          label: String.fromCharCode(65 + index),
          value: opt.quiz_option,
          selected: opt.is_correct,
          id: opt.id,
          is_active: opt.is_active
        }))
      }));
      // console.log('Initialized Questions:', initializedQuestions);
      setQuestions(initializedQuestions);
      setValue(quizzesData[0]?.is_allowed_certificate ? 'Yes' : 'No');
    }
  }, [id, quizzesData[0]]);

  useEffect(() => {
    const payload = {
      quiz_id: id ? id : ''
    };
    dispatch(get_quizzes(payload));
  }, [id]);

  useEffect(() => {
    const payload = {};
    if (tagSearch) payload['search'] = tagSearch;
    dispatch(getCategoryList(payload));
  }, [tagSearch]);

  const disabledStartDate = (current) => {
    // Disable past dates
    return current && current < moment().startOf('day');
  };

  const disabledEndDate = (current) => {
    // Disable dates before the selected start date
    return (
      current && (current < startDate || current < moment().startOf('day'))
    );
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (tagSearch) => {
      setTagSearch(tagSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    // console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };
  // console.log('startDate', startDate);
  // console.log('endDate', endDate);
  // console.log(
  //   'quizzesData[0]?.launch_date:',
  //   moment(quizzesData[0]?.launch_date)?.format('YYYY-MM-DD')
  // );
  // console.log('date if NOT null:', moment(startDate?._d)?.format('YYYY-MM-DD'));
  const onFinish = (values) => {
    const isValid = questions.every((question) => {
      return (
        question.question !== null &&
        question.question.trim() !== '' &&
        question.options.some((option) => option.selected) &&
        question.options.every(
          (option) => option.value !== null && option.value.trim() !== ''
        )
      );
    });

    if (!isValid) {
      // Display an error message or handle the validation failure as needed
      message.error('Please fill in all question and option fields.');

      return;
    }

    //console.log('values', values);

    const payload = {
      id: id === undefined ? quizzesData[0]?.id : id,
      quiz_name:
        values?.quizName === undefined
          ? quizzesData[0]?.quiz_name
          : values?.quizName,
      quiz_desc:
        formData1?.quizDescription === undefined
          ? quizzesData[0]?.quiz_desc
          : formData1?.quizDescription,
      launch_date:
        startDate === undefined || startDate === null
          ? moment(quizzesData[0]?.launch_date)?.format('YYYY-MM-DD')
          : moment(startDate?._d)?.format('YYYY-MM-DD'),
      closed_date:
        endDate === undefined || endDate === null
          ? moment(quizzesData[0]?.closed_date)?.format('YYYY-MM-DD')
          : moment(endDate?._d)?.format('YYYY-MM-DD'),
      category_id:
        formData1?.quizTags === undefined
          ? quizzesData[0]?.quiz_cat?.id
          : formData1?.quizTags,
      is_active: true,
      is_allowed_certificate: value === 'Yes' ? true : false,
      quiz_question_list: questions?.map((question) => ({
        id: question?.id === undefined ? null : question?.id,
        quiz_question:
          question.question === undefined ? null : question.question,
        is_active:
          question?.is_active === undefined ? true : question?.is_active,
        options_list: question.options.map((option) => ({
          quiz_option: option.value === undefined ? null : option.value,
          is_correct: option.selected === undefined ? false : option.selected,
          is_active: option?.is_active === undefined ? true : option?.is_active,
          id: option?.id === undefined ? null : option?.id
        }))
      }))
    };
    console.log('payload', payload);
    dispatch(postCreateQuiz(payload))?.then(function (response) {
      // console.log('response', response);
      if (response?.payload?.success) {
        history.push(`/admin-quizzes`);
      } else {
        console.log('response?.payload?.error', response?.payload?.error);
      }
    });
  };

  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <div className={styles.createQuiz_card_container}>
          <Space direction="vertical" size={30} style={{ width: '100%' }}>
            <CustomText
              font="bold"
              size={36}
              color="primary"
              letterSpacing={-0.72}
            >
              Edit Quiz
            </CustomText>
            <Form
              form={form}
              name="createQuiz"
              onFinish={onFinish}
              layout="vertical"
              className={styles.form_container}
            >
              <FormItem
                label="Name of Quiz"
                name="quizName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the name of the quiz!'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  className={styles.input_container_style}
                  placeholder="Please input the name of the quiz!"
                  onChange={(e) =>
                    handleFormDataOnChange('quizName', e.target.value)
                  }
                />
              </FormItem>
              <FormItem
                label="Add description of your Quiz"
                name="quizDescription"
                rules={[
                  {
                    required: true,
                    message: 'Please add a description of the quiz!'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <TextArea
                  className={styles.input_container_style}
                  placeholder="Please add a description of the quiz!"
                  onChange={(e) =>
                    handleFormDataOnChange('quizDescription', e.target.value)
                  }
                />
              </FormItem>

              <Space
                direction="horizontal"
                size={20}
                className={styles.datepicker_container}
              >
                <FormItem
                  label="Select Start date for the Quiz"
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the start date for the quiz!'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={handleStartDateChange}
                    disabledDate={disabledStartDate}
                    placeholder="Start Date"
                    // onChange={(_, dateValue) =>
                    //   handleFormDataOnChange('startDate', dateValue)
                    // }
                  />
                </FormItem>
                <FormItem
                  label="Select Last date for the Quiz"
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the last date for the quiz!'
                    }
                  ]}
                  className={`${styles.input_styles}`}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={handleEndDateChange}
                    disabledDate={disabledEndDate}
                    placeholder="End Date"
                    // onChange={(_, dateValue) =>
                    //   handleFormDataOnChange('endDate', dateValue)
                    // }
                  />
                </FormItem>
              </Space>
              <FormItem
                label="Add Tags"
                name="quizTags"
                rules={[
                  {
                    required: true,
                    message: 'Please select at least one tag for the quiz!'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  // mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select tags"
                  showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  onSearch={debounceFetcher}
                  onChange={(value) =>
                    handleFormDataOnChange('quizTags', value)
                  }
                  filterOption={(input, option) => {
                    return (
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                  }}
                >
                  {categories?.map((item, index) => (
                    <Option
                      title={item.category_name}
                      key={index}
                      value={item?.id}
                    >
                      <span
                        style={{
                          fontSize: '12px',
                          textTransform: 'uppercase',
                          fontFamily: 'CerebriSans SemiBold'
                        }}
                      >
                        <span style={{ marginLeft: '10px' }}>
                          {item?.category_name}
                        </span>
                      </span>
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <QuizQuestions
                setValue={setValue}
                value={value}
                setQuestions={setQuestions}
                questions={questions}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end'
                }}
              >
                <CustomButton
                  className={styles.back_btn_style}
                  onClick={() => history.push(`/admin-quizzes`)}
                >
                  Back
                </CustomButton>
                <FormItem>
                  <CustomButton
                    type="primary"
                    htmlType="submit"
                    className={styles.post_btn_style}
                    loading={postCreateQuizLoading}
                  >
                    Save
                  </CustomButton>
                </FormItem>
              </div>
            </Form>
          </Space>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default EditQuiz;
