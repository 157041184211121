import React from 'react';
import { DatePicker, Space, message } from 'antd';
import { history } from 'app/history';
import Layout from 'common/CustomLayout';
import styles from './index.module.less';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import LocalStorage from '@/utilities/localStorage';
import moment from 'moment';

const ExportQueriesSuggestion = () => {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleExport = () => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const user = LocalStorage.getItem('neev_login_user');
    const authToken = user?.accessToken;

    const fromDate = startDate?.format('YYYY-MM-DD');
    const toDate = endDate?.format('YYYY-MM-DD');
    const url = `/admin/export_query_suggestion_report?from_date=${fromDate}&to_date=${toDate}`;
    fetch(`${apiUrl}${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Accept: 'application/csv',
        Authorization: `Bearer ${authToken}`
      }
    }).then((response) => {
      setLoading(false);
      response
        .blob()
        .then((blob) => {
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'exported_file.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          setStartDate(null); // Clear the startDate
          setEndDate(null); // Clear the endDate
        })
        .catch((error) => {
          setLoading(false);
          console.error('Export failed:', error);
        });
    });
  };

  const disabledDate = (current) => {
    // Disable future dates
    return current && current > moment().endOf('day');
  };

  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <div className={styles.left_card_container}>
          <Space direction="vertical" size={34} style={{ width: '100%' }}>
            <CustomText
              font="bold"
              size={36}
              color="primary"
              letterSpacing={-0.72}
            >
              Export Queries and Suggestion
            </CustomText>
            <div>
              <DatePicker.RangePicker
                value={[startDate, endDate]}
                onChange={(dates) => {
                  setStartDate(dates?.[0]);
                  setEndDate(dates?.[1]);
                }}
                disabledDate={disabledDate} // Disable future dates
                style={{ width: '90%' }}
                required // Add required attribute
              />
            </div>
            <Space
              direction="horizontal"
              size={14}
              style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
            >
              <CustomButton
                size="large"
                type="default"
                onClick={() => history.back()}
              >
                Cancel
              </CustomButton>

              <CustomButton
                size="large"
                type="primary"
                htmlType="submit"
                onClick={() => {
                  if (startDate && endDate) {
                    handleExport();
                  } else {
                    // Handle validation error, e.g. show an error message
                    message.error('Please select a date range');
                  }
                }}
                loading={loading}
              >
                Export
              </CustomButton>
            </Space>
          </Space>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default ExportQueriesSuggestion;
