import apiClient from 'utilities/apiClient';

export const ideas = (payload) => {
  return apiClient.get(apiClient.Urls.ideas, payload, true);
};

export const createUpdateIdea = (payload) => {
  return apiClient.post(
    apiClient.Urls.create_update_idea,
    payload,
    true,
    'file'
  );
};

export const create_comment_ideas = (payload) => {
  return apiClient.post(
    apiClient.Urls.create_update_comment_idea,
    payload,
    true
    // 'file'
  );
};

export const likeDislikeIdea = (payload) => {
  console.log('payloadLLLLLLLLLLLLL', payload);
  return apiClient.post(apiClient.Urls.like_dislike_idea, payload, true);
};

export const getCategories = (payload = null) => {
  return apiClient.get(apiClient.Urls.get_categories, payload, true);
};

export const getSubCategories = (payload = null) => {
  return apiClient.get(apiClient.Urls.get_sub_categories, payload, true);
};
