import { Typography } from 'antd';
import styles from './custom_text.module.less';

const { Text } = Typography;

const CustomText = ({
  children,
  className,
  font = 'regular',
  color = 1,
  size = 14,
  letterSpacing = 0,
  lineHeight,
  style,
  ...rest
}) => {
  return (
    <Text
      className={`cs-${font} text-color-${color} ${styles.container}${
        className ? ` ${className}` : ''
      }`}
      style={{
        ...style,
        ...{ fontSize: size, letterSpacing },
        ...(lineHeight ? { lineHeight } : {})
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default CustomText;
