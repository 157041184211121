import React from 'react';
//import CustomText from '@/common/CustomText';
import { Space, Image } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import styles from './factorsState.module.less';
//import SectionTitle from '@/common/SectionTitle';
import factors_state from '../../images/factorsState/factorsState.svg';
function FactorsState() {
  return (
    <CustomContainer
      id="factorsState"
      title="FactorsState"
      containerClassname={styles.container}
    >
      <Space
        direction="vertical"
        size={30}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* <SectionTitle title="Building a Digital Core" /> */}
        <div className="df-jc-ac">
          <Image alt="Digital Core" src={factors_state} preview={false} />
        </div>
      </Space>
    </CustomContainer>
  );
}
export default FactorsState;
