import { useState } from 'react';
import React from 'react';
import { Space, Row, Col, Image } from 'antd'; //Collapse,Image
import styles from './keyConcepts.module.less';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import { DownOutlined } from '@ant-design/icons';
import WBSVideo from '../../images/keyConcepts/WBSVideo.mp4';
import videoThumbnail from '../../images/keyConcepts/SBU_images/videoThumbnail.svg';
import { sizeChecker } from 'utilities/helpers';
import PM_OGH from '../../images/keyConcepts/SBU_images/pa0.png'; //PM_OGH.svg;
import T_D from '../../images/keyConcepts/SBU_images/pa1.png';
import Buildings from '../../images/keyConcepts/SBU_images/pa2.png';
import O_M from '../../images/keyConcepts/SBU_images/pa3.png';
import Roads from '../../images/keyConcepts/SBU_images/pa4.png';
import Bridges from '../../images/keyConcepts/SBU_images/pa6.png';
import Railways from '../../images/keyConcepts/SBU_images/pa5.png';
import Airports from '../../images/keyConcepts/SBU_images/pa7.png';
import Metros from '../../images/keyConcepts/SBU_images/pa8.png';
import SmartCitiesCAPEX from '../../images/keyConcepts/SBU_images/pa9.png';
import SmartCitiesOPEX from '../../images/keyConcepts/SBU_images/pa10.png';
import NuclearProjects from '../../images/keyConcepts/SBU_images/pa11.png';
import TMU from '../../images/keyConcepts/SBU_images/pa13.png';
import Indirectcost from '../../images/keyConcepts/SBU_images/pa12.png';
//import DLP from '../../images/keyConcepts/SBU_images/DLP.svg';

const SBU_data = [
  {
    key: '1',
    label: 'P&M and OGH (E&I)',
    children: [
      {
        key: 1,
        title: 'Project Management1',
        img: PM_OGH
      }
    ]
  },
  {
    key: '2',
    label: 'T&D  (E&I)',
    children: [
      {
        key: 1,
        title: 'Project Management2',
        img: T_D
      }
    ]
  },
  {
    key: '3',
    label: 'Buildings, Factories, and Data centers (B&I)',
    children: [
      {
        key: 1,
        title: 'Project Management-3',
        img: Buildings
      }
    ]
  },
  {
    key: '4',
    label: 'O&M (B&I)',
    children: [
      {
        key: 1,
        title: 'Project Management-4',
        img: O_M
      }
    ]
  },
  {
    key: '5',
    label: 'Roads (B&I)',
    children: [
      {
        key: 1,
        title: 'Project Management-5',
        img: Roads
      }
    ]
  },
  {
    key: '6',
    label: 'Bridges (B&I)',
    children: [
      {
        key: 1,
        title: 'Project Management-6',
        img: Bridges
      }
    ]
  },
  {
    key: '7',
    label: 'Railways (B&I)',
    children: [
      {
        key: 1,
        title: 'Project Management-7',
        img: Railways
      }
    ]
  },
  {
    key: '8',
    label: 'Airports (B&I)',
    children: [
      {
        key: 1,
        title: 'Project Management-6',
        img: Airports
      }
    ]
  },
  {
    key: '9',
    label: 'Metros (UG / Elevated)',
    children: [
      {
        key: 1,
        title: 'Project Management-6',
        img: Metros
      }
    ]
  },
  {
    key: '10',
    label: 'Smart Cities – CAPEX (B&I)',
    children: [
      {
        key: 1,
        title: 'Project Management-6',
        img: SmartCitiesCAPEX
      }
    ]
  },
  {
    key: '11',
    label: 'Smart Cities – OPEX (B&I)',
    children: [
      {
        key: 1,
        title: 'Project Management-6',
        img: SmartCitiesOPEX
      }
    ]
  },
  {
    key: '12',
    label: 'Nuclear Projects (B&I)',
    children: [
      {
        key: 1,
        title: 'Project Management-6',
        img: NuclearProjects
      }
    ]
  },
  {
    key: '13',
    label: 'TMU',
    children: [
      {
        key: 1,
        title: 'Project Management-6',
        img: TMU
      }
    ]
  },
  {
    key: '14',
    label: 'Indirect cost & Contingency',
    children: [
      {
        key: 1,
        title: 'Project Management-6',
        img: Indirectcost
      }
    ]
  },
  {
    key: '15',
    label: 'DLP',
    children: [
      {
        key: 1,
        title: 'Coming Soon...',
        img: null //DLP
      }
    ]
  }
];

//const { Panel } = Collapse;

const KeyConcepts = () => {
  const isMobile = sizeChecker('xs');

  const [isVisible, setIsVisible] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [hoveredTab, setHoveredTab] = useState(null);

  const handleButtonClick = () => {
    //console.log('isVisible:', isVisible);
    setIsVisible(!isVisible);
    setActiveButton(null);
  };

  const handleClick = (index) => {
    setActiveButton(index);
  };

  return (
    <>
      <div className={`${styles.keyConcepts_container}`}>
        <CustomButton
          className={`${styles.WBS_btn}`}
          onClick={handleButtonClick}
        >
          <span className={`${styles.WBS_btn_text}`}>
            Work Breakdown Structure
          </span>
          <span className={`${styles.WBS_btn_icon}`}>
            <DownOutlined />
          </span>
        </CustomButton>
        <div className={`${styles.WBS_content_margin}`}>
          {' '}
          {isVisible && (
            <Space direction="vertical" size={15} style={{ width: '100%' }}>
              {' '}
              <Space direction="vertical" size={10} style={{ width: '100%' }}>
                <CustomText
                  size={16}
                  style={{ color: '#616161', lineHight: '28px' }}
                >
                  {`The Work Breakdown Structure (WBS) is an SAP project management
              tool, typically created using the Project System (PS) module. It
              is structured hierarchically, where the hierarchical structure
              represents the relationships between different project elements.
              Each level of the WBS contains elements that describes a specific
              aspect of the project and are usually assigned unique identifiers
              and descriptions. WBS elements serve as a basis for planning,
              scheduling, and controlling project activities and can be linked
              to other SAP modules like Commercial Project Management (CPM),
              Production Planning (PP), Sales Distribution (SD), Controlling
              (CO), Materials Management (MM) etc. for comprehensive project
              management.`}
                  <b>
                    {' '}
                    WBS forms the backbone of project planning, execution,
                    monitoring, and controlling, ensuring efficient and
                    transparent management of complex projects.
                  </b>
                </CustomText>

                <CustomText
                  size={16}
                  style={{ color: '#616161', lineHight: '28px' }}
                >
                  {` The WBS allows for the allocation
              of costs, resources, and responsibilities to specific elements and
              facilitates reporting and analysis by enabling project managers to
              track milestones and timelines by making adjustments as necessary
              to ensure project success.`}
                </CustomText>
              </Space>
              <Space direction="vertical" size={10} style={{ width: '100%' }}>
                <CustomText
                  size={18}
                  font="bold"
                  style={{ color: '#000000', lineHight: '28px' }}
                >
                  Check out the video below for quick overview on WBS{' '}
                </CustomText>
                <Col xs={24} sm={16}>
                  <video
                    poster={videoThumbnail}
                    className={styles.custom_video}
                    controls={true}
                    src={WBSVideo}
                    width="100%"
                    height="85%"
                  />
                </Col>
              </Space>
              <Space direction="vertical" size={20} style={{ width: '100%' }}>
                <CustomText
                  size={18}
                  font="bold"
                  style={{ color: '#000000', lineHight: '28px' }}
                >
                  Click on the relevant SBU tabs to view standardized BU wise
                  WBS Structures. There will also be a separate WBS for Indirect
                  Cost (IDC) and Defect Liability Period (DLP)
                </CustomText>
              </Space>
              <Space
                direction="vertical"
                size={20}
                style={{ width: '100%' }}
                //className={`${styles.flex_style}`}
              >
                <Row gutter={[6, 6]}>
                  {SBU_data.map((item, index) => (
                    <Col key={item.key} span={isMobile ? 24 : 6}>
                      {/* sm={5} xl={5} */}
                      <div
                        onClick={() => handleClick(index)}
                        className={
                          activeButton === index ||
                          activeButton === item.key ||
                          hoveredTab === item.key
                            ? ` ${styles.selected}`
                            : `${styles.keyConcepts_SBUmenu}`
                        }
                        onMouseEnter={() => {
                          setHoveredTab(item.key);
                        }}
                        onMouseLeave={() => {
                          setHoveredTab(null);
                        }}
                      >
                        {item.label}
                      </div>
                    </Col>
                  ))}
                </Row>

                <div style={{ marginTop: '0px' }}>
                  {activeButton !== null && (
                    <>
                      {activeButton === 14 ? (
                        <p>{SBU_data[activeButton].children[0].title}</p>
                      ) : (
                        ''
                      )}
                      <Image
                        src={SBU_data[activeButton].children[0].img}
                        // alt={'img'}
                        className={styles.imageVisible}
                        preview={true}
                      />
                    </>
                  )}
                </div>
              </Space>
            </Space>
          )}
        </div>
      </div>
    </>
  );
};

export default KeyConcepts;
