import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Input, Radio, Row, Spin } from 'antd';
//import { InfoCircleOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import CustomText from 'common/CustomText';
import {
  getCategoryList,
  getSubCategoryList,
  postIdea
} from 'features/ideas/redux/slice';
import styles from './idea_create.module.less';
import CustomContainer from '@/common/CustomContainer';
import { history } from '@/app/history';
import { sizeChecker } from 'utilities/helpers';

const { TextArea } = Input;

const IdeaCreatePage = ({ id_type }) => {
  const dispatch = useDispatch();

  const {
    postIdeaLoading,
    categories,
    sub_categories,
    getSubCategoryListLoading
  } = useSelector((state) => state.ideas);
  const [form] = Form.useForm();
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {
    dispatch(getCategoryList());
  }, []);

  useEffect(() => {
    if (categoryId) {
      dispatch(getSubCategoryList(categoryId));
    }
  }, [categoryId]);

  // const normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e?.fileList;
  // };

  const onFinish = (values) => {
    const { category_id, idea_title, sub_category_id } = values; //idea_docs,
    let formData = new FormData();
    formData.append('is_active', true);
    formData.append('category_id', category_id);
    formData.append('idea_title', idea_title);
    formData.append('sub_category_id', sub_category_id || 3);
    // formData.append('idea_docs', idea_docs ? idea_docs[0].originFileObj : []);
    formData.append('type', id_type === '1' ? 'query' : 'suggestion');
    dispatch(postIdea(formData)).then(({ payload }) => {
      if (payload?.success) {
        // window.location.href = '/ideas/list';
        history.push('/ideas/list');
      }
    });
  };

  const isMobile = sizeChecker('xs');

  return (
    <CustomContainer containerClassname={styles.container}>
      {isMobile ? (
        <CustomText font="medium" size={26} color={10} className={styles.title}>
          Share your thoughts
        </CustomText>
      ) : null}
      <Form
        form={form}
        name="idea-create"
        layout="vertical"
        onFinish={onFinish}
      >
        <FormItem
          label={
            <div className={`${styles.title_style}`}>Choose a category</div>
          }
          name="category_id"
          rules={[
            {
              required: true,
              message: 'Please choose a category'
            }
          ]}
        >
          <Radio.Group
            className={styles.category_container}
            onChange={({ target: { value } }) => setCategoryId(value)}
            buttonStyle="solid"
          >
            {categories.map((item) => {
              const { id, category_name } = item;
              return (
                <Radio.Button
                  key={id}
                  value={id}
                  style={{ height: 'fit-content' }}
                >
                  {category_name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </FormItem>

        {categoryId ? (
          <FormItem
            label={
              <div className={`${styles.title_style}`}>
                Choose a sub-category
              </div>
            }
            name="sub_category_id"
            rules={[
              {
                required: true,
                message: 'Please choose a sub-category'
              }
            ]}
          >
            <Spin spinning={getSubCategoryListLoading}>
              <Radio.Group
                className={styles.category_container}
                buttonStyle="solid"
              >
                {sub_categories.map((item) => {
                  const { id, sub_category_name } = item;
                  return (
                    <Radio.Button
                      key={id}
                      value={id}
                      style={{ height: 'fit-content' }}
                    >
                      {sub_category_name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Spin>
          </FormItem>
        ) : null}

        <FormItem
          label={
            <div className={`${styles.title_style}`}>
              Type your {id_type === '1' ? 'queries' : 'suggestions'} below
            </div>
          }
          name="idea_title"
          rules={[
            {
              required: true,
              message: `Please enter your ${
                id_type === '1' ? 'queries' : 'suggestions'
              }`
            }
          ]}
        >
          <TextArea rows={6} className={styles.form_group} />
        </FormItem>
        {/* 
        <FormItem label="Upload any documents to help us understand the queries or suggestions better">
          <FormItem
            name="idea_docs"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger
              name="files"
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess('ok');
                }, 0);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InfoCircleOutlined />
              </p>
              <Space>
                <CustomText font="medium" color="primary">
                  Click to upload
                </CustomText>
                <CustomText color={2}>or drag and drop</CustomText>
              </Space>
            </Upload.Dragger>
          </FormItem>
        </FormItem> */}

        <Row gutter={12} className={styles.btn_container}>
          <Col xs={12}>
            <FormItem className="text-right">
              <CustomButton
                size="large"
                type="default"
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem className="text-left">
              <CustomButton
                size="large"
                type="primary"
                htmlType="submit"
                loading={postIdeaLoading}
              >
                Submit
              </CustomButton>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomContainer>
  );
};

export default IdeaCreatePage;
