import { Image, Space } from 'antd';
import CustomText from '../CustomText';
import styles from './winner_card.module.less';

const WinnerCard = ({
  item,
  award = false,
  containerStyle = {},
  bodyStyle = {}
}) => {
  const { id, image, name, role, description } = item;

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.image_container}>
        <Image alt={`${name}_${id}`} src={image} preview={false} />
      </div>
      <div className={styles.body} style={bodyStyle}>
        {award ? (
          <div className={styles.award}>
            <Image alt="award" src={'images/award.svg'} preview={false} />
          </div>
        ) : null}
        <Space direction="vertical" size={4}>
          <CustomText font="medium" size={20} color="primary">
            {name}
          </CustomText>
          <CustomText font="medium" size={15}>
            {role}
          </CustomText>
          <CustomText>{description}</CustomText>
        </Space>
      </div>
    </div>
  );
};

export default WinnerCard;
