import { useState } from 'react';
import { Carousel, Col, Image, Row, Space } from 'antd';
import { Fade } from 'react-reveal';
import styles from './bannersHxm.module.less';
import one from '../../../home/images/banners/new1.svg';

import playCircle from '../../../home/images/play-circle.svg';
//import neev_logo from '../../images/neev_logo.svg';
import CustomText from 'common/CustomText';
//import CustomButton from 'common/CustomButton';
import { sizeChecker } from 'utilities/helpers';
import { history } from '@/app/history';

//import img1 from '../../images/banners/newBanners/img1.svg';
import img2 from '../../images/banners/newBanners/img2.png';
import bg3 from '../../images/banners/newBanners/bg3.svg';
import bg1 from '../../images/banners/newBanners/bg1.svg';
import mobileBg1 from '../../images/banners/newBanners/mobileBg1.svg';
import mobileBg3 from '../../images/banners/newBanners/mobileBg3.svg';
const data = [
  {
    id: 1,
    topTitle: '', //'1st March 2024',
    titleProps: '', //
    //  {
    //   text: 'Employee Central Module ',
    //   font: 'medium'
    // },
    description: '', // 'GO-LIVE',
    subDescription: ``,
    btnProps: null, //
    //  {
    //   icon: playCircle,
    //   title: '',
    //   bg: 'transparent',
    //   borderColor: '#FFF'
    // },
    image: null, //img1,
    bg: bg1,
    mbBg: mobileBg1 //mb1
  },
  {
    id: 2,
    topTitle: 'Project NEEV',
    titleProps: {
      text: 'Integrated HXM Track Kick Off Workshop',
      font: 'medium'
    },
    description:
      'Building & planning activities were carried out to identify key milestones, enablers and potential risks which need to be addressed',
    subDescription: '', // `- Vinayak Pai, MD`,
    btnProps: {
      icon: playCircle,
      title: 'Watch Video',
      bg: 'transparent',
      borderColor: '#FFF'
    },
    image: img2, // md_desk,
    bg: one,
    mbBg: img2
  },
  {
    id: 3,
    topTitle: '', //'1st March 2024',
    titleProps: '', //
    //  {
    //   text: 'Employee Central Module ',
    //   font: 'medium'
    // },
    description: '', // 'GO-LIVE',
    subDescription: ``,
    btnProps: null, //
    //  {
    //   icon: playCircle,
    //   title: '',
    //   bg: 'transparent',
    //   borderColor: '#FFF'
    // },
    image: null, //img1,
    bg: bg3,
    mbBg: mobileBg3 //mb1
  }
];

const BannersHxm = () => {
  const isMobile = sizeChecker('xs');

  const [slide, setSlide] = useState(0);
  // const [openAboutNeevModal, setOpenAboutNeevModal] = useState(false);
  // const [openAboutWatchVideoModal, setOpenAboutWatchVideoModal] =
  //   useState(false);
  //const [isHovered, setIsHovered] = useState(false);
  // const [openMDWatchVideoModal, setOpenMDWatchVideoModal] = useState(false);
  // const [openSAPConcurVideoModal, setOpenSAPConcurVideoModal] = useState(false);

  // const handleMouseToggle = () => {
  //   setIsHovered(!isHovered);
  // };

  // const toggleOpenAboutNeevModal = () => {
  //   setOpenAboutNeevModal(!openAboutNeevModal);
  // };

  // const toggleOpenAboutWatchVideoModal = () => {
  //   setOpenAboutWatchVideoModal(!openAboutWatchVideoModal);
  // };

  // const toggleOpenMDWatchVideoModal = () => {
  //   setOpenMDWatchVideoModal(!openMDWatchVideoModal);
  // };
  // const toggleOpenSAPConcurVideoModal = () => {
  //   setOpenSAPConcurVideoModal(!openSAPConcurVideoModal);
  // };

  const btnOnClick = {
    // 1: toggleOpenSAPConcurVideoModal,
    // 2: toggleOpenMDWatchVideoModal //toggleOpenAboutNeevModal,
    //  3: toggleOpenAboutNeevModal, //() => history.push('/feelTheChange'),
    // // 3: () => history.push('/ideas/list'),
    // 6: toggleOpenAboutWatchVideoModal
  };

  return (
    <div id="banners" className={styles.container}>
      <Carousel
        effect="fade"
        className={styles.carousel_container}
        autoplay
        autoplaySpeed={5000}
        beforeChange={(currentSlide) => {
          setSlide(currentSlide + 1);
        }}
        afterChange={(currentSlide) => {
          setSlide(currentSlide);
        }}
      >
        {data.map((item, index) => {
          const {
            id,
            titleProps,
            description,
            //subDescription,
            //btnProps,
            bg,
            mbBg,
            image,
            //imageProps,
            topTitle
          } = item;

          return (
            <div key={id} className={styles.carousel_item_container}>
              <div
                className={styles.outer_container}
                style={{
                  background: `url(${
                    isMobile && (id === 1 || id === 3) ? mbBg : bg
                  }) no-repeat center`,
                  cursor: id === 1 || id === 3 ? 'pointer' : null
                }}
                onClick={() => {
                  if (id === 1) {
                    if (btnOnClick[id]) {
                      btnOnClick[id]();
                    }
                  }
                  if (id === 3) {
                    history.push('/quizzes');
                  }
                }}
              >
                <Space
                  direction="vertical"
                  size={isMobile ? 20 : 48}
                  className={styles.inner_container}
                  {...(isMobile ? { align: 'start' } : {})} /* 'center'  */
                >
                  <Space
                    direction={isMobile ? 'vertical' : 'horizontal'}
                    size={isMobile ? 20 : 24}
                    style={{ width: '100%' }}
                  >
                    <Row
                      gutter={20}
                      style={{
                        display: isMobile ? 'flex' : '',
                        flexDirection: isMobile ? 'column-reverse' : '',
                        gap:
                          isMobile && id === 2 //||
                            ? '20px'
                            : isMobile
                            ? '40px'
                            : ''
                      }}
                    >
                      <Col span={isMobile ? 24 : 10}>
                        {id === 2 || id === 1 ? (
                          // || id === 3 || id === 6
                          <div className={`${styles.tab2_text_container}`}>
                            <div
                              style={{
                                marginTop: '35px',
                                marginBottom: '15px'
                              }}
                            >
                              <Fade left when={slide === index} duration={2000}>
                                <CustomText
                                  size={isMobile && 16}
                                  color="white"
                                  className={`${styles.toptitle_style}`}
                                >
                                  {topTitle}
                                </CustomText>
                              </Fade>
                            </div>
                            <div style={{ marginBottom: '25px' }}>
                              <Fade top when={slide === index} duration={2000}>
                                <CustomText
                                  className={`${styles.titleProps_text}`}
                                  font="medium"
                                  size={isMobile && 28}
                                  color="white"
                                  {...(isMobile
                                    ? {}
                                    : {
                                        // letterSpacing: -1.08,
                                        // lineHeight: '42px'
                                      })}
                                >
                                  {titleProps.text}
                                </CustomText>
                              </Fade>
                            </div>
                            <Fade left when={slide === index} duration={2000}>
                              <CustomText
                                size={isMobile && 16}
                                color="white"
                                className={`${
                                  id === 1
                                    ? styles.descriptionText_1
                                    : styles.titleProps_descriptionText
                                }`}
                              >
                                {description}
                              </CustomText>
                            </Fade>

                            {/* {id === 2 && (
                              <Fade
                                bottom
                                when={slide === index}
                                duration={2000}
                              >
                                <div
                                  onMouseEnter={handleMouseToggle}
                                  onMouseLeave={handleMouseToggle}
                                  className={`${styles.btn_container_tab1} cursor-pointer`}
                                  style={{
                                    backgroundColor: isHovered
                                      ? '#090'
                                      : btnProps.bg,
                                    border: `2px solid ${btnProps.borderColor}`
                                  }}
                                  onClick={() => {
                                    if (btnOnClick[id]) {
                                      btnOnClick[id]();
                                    }
                                  }}
                                >
                                  <Space align="start">
                                    {btnProps?.icon ? (
                                      <Image src={btnProps?.icon} />
                                    ) : null}
                                    <CustomText
                                      font="bold"
                                      color="white"
                                      size={18}
                                      className={
                                        (isMobile && id === 2) || id === 3
                                          ? `${styles.btn_container_text_tab5}`
                                          : `${styles.btn_container_text}`
                                      }
                                    >
                                      {btnProps.title}
                                    </CustomText>
                                  </Space>
                                </div>
                              </Fade>
                            )} */}
                          </div>
                        ) : null}
                        {/*  (
                        <div
                          className={
                            id === 5
                              ? `${styles.tab2_text_container4}`
                              : id === 1 && isMobile
                              ? `${styles.tab1_text_container3}`
                              : `${styles.tab2_text_container3}`
                          }
                        >
                          <div
                            style={{
                              marginBottom:
                                isMobile && id === 5
                                  ? '10px'
                                  : isMobile === false && id === 1
                                  ? '10px'
                                  : '25px'
                            }}
                          >
                            <Fade top when={slide === index} duration={2000}>
                              <CustomText
                                className={`${styles.titleProps_text3}`}
                                font="medium"
                                size={isMobile && 28}
                                color={id === 1 ? '#323282' : 'white'}
                                style={{
                                  color: id === 1 ? '#323282' : 'white'
                                }}
                                {...(isMobile
                                  ? {}
                                  : {
                                      letterSpacing: -1.08,
                                      lineHeight: '42px'
                                    })}
                              >
                                {titleProps.text}
                              </CustomText>
                            </Fade>
                          </div>
                          <Fade left when={slide === index} duration={2000}>
                            <CustomText
                              size={isMobile && 16}
                              color="white"
                              className={
                                id === 1
                                  ? ''
                                  : `${styles.titleProps_descriptionText3}`
                              }
                            >
                              {id === 1 ? (
                                <div
                                  style={{
                                    color: 'black',
                                    fontSize: isMobile ? '19px' : '16px',
                                    fontFamily: 'CerebriSans Regular',
                                    lineHeight: '0px !important',
                                    marginTop: '20px'
                                  }}
                                >
                                  <b style={{ fontSize: '18px' }}>
                                    Click the play button{' '}
                                  </b>{' '}
                                  to view a 3-minute video which gives you an
                                  overview of SAP Concur
                                  <div
                                    style={{
                                      fontSize: '18px',
                                      marginTop: '15px',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    // Going LIVE on 1<sup>st</sup> January 2024 
                                  </div>
                                </div>
                              ) : (
                                description
                              )}
                            </CustomText>
                          </Fade>
                        </div>
                        ) */}
                      </Col>
                      <Col span={isMobile ? 24 : 14}>
                        {id === 2 ? (
                          <Fade right when={slide === index} duration={2000}>
                            <div>
                              <Image
                                alt="md_desk"
                                src={image}
                                preview={false}
                                //style={{ width: '110%', height: '110%' }}
                                className={`${styles.banners_image_md}`}
                              />
                            </div>
                          </Fade>
                        ) : null}
                        {/* 
                         : id === 1 ? (
                          <Fade right when={slide === index} duration={2000}>
                            <div>
                              <Image
                                alt="md_desk"
                                src={image}
                                preview={false}
                                style={{ width: '80%', height: '80%' }}
                                className={`${styles.banners_image_md}`}
                              />
                            </div>
                          </Fade>
                        ) 
                        */}
                      </Col>
                    </Row>
                  </Space>
                </Space>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default BannersHxm;
