import React, { useEffect, useState } from 'react';
import Layout from 'common/CustomLayout';
import { Space, Spin, Input, Pagination } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './quiz.module.less';
//import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_quiz_score_answers } from '../../redux/slice';
//import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';

const QuizScoreAnswers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const {
    user_details,
    quiz_score_answers,
    get_quiz_score_answersLoading,
    pagination
  } = useSelector((state) => state.quizzes);

  const [questions, setQuestions] = useState([
    { question: '', options: [{ label: 'A', value: '' }] }
  ]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  //console.log('user_details', user_details);

  useEffect(() => {
    const payload = {
      quiz_id: id ? id : '',
      page: page,
      per_page: pageSize
    };

    dispatch(get_quiz_score_answers(payload));
  }, [id, page, pageSize]);

  useEffect(() => {
    const initializedQuestions = quiz_score_answers?.map((ques) => ({
      question: ques?.quiz_question,
      options: ques?.quesopt.map((opt, index) => ({
        label: String.fromCharCode(65 + index),
        value: opt?.quiz_option,
        is_correct: opt?.is_correct,
        id: opt?.id,
        user_response: opt?.my_response
      }))
    }));
    console.log('Initialized Questions:', initializedQuestions);
    setQuestions(initializedQuestions);
  }, [quiz_score_answers]);
  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <Spin spinning={get_quiz_score_answersLoading}>
          <Space direction="vertical" size={30} style={{ width: '100%' }}>
            <div className={styles.left_card_container1}>
              <Space direction="vertical" size={30} style={{ width: '100%' }}>
                <div className={styles.back_btn_container}>
                  <CustomText
                    font="bold"
                    size={32}
                    color="black"
                    letterSpacing={-0.72}
                  >
                    {user_details?.display_name}
                  </CustomText>
                  <CustomButton
                    className={styles.post_btn_style1}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </CustomButton>
                </div>
                <div key={id} className={styles.left_card_container2}>
                  <Space
                    direction="vertical"
                    size={15}
                    style={{ width: '100%' }}
                  >
                    {questions?.map((quest, questionIndex) => (
                      <Space
                        key={questionIndex}
                        direction="vertical"
                        size={10}
                        style={{ width: '100%' }}
                      >
                        <CustomText size={16} font="bold">
                          {quest?.question}
                        </CustomText>
                        <Space
                          direction="vertical"
                          size={10}
                          style={{ width: '100%' }}
                        >
                          {quest?.options?.map((option, optionIndex) => (
                            <Space.Compact key={optionIndex}>
                              <Input
                                size="small"
                                className={styles.input_option1}
                                style={{
                                  width: '15%',
                                  border: option?.is_correct
                                    ? '1px solid green'
                                    : option?.user_response
                                    ? '1px solid #FF0000'
                                    : '1px solid #e9e9e9',
                                  backgroundColor: option?.is_correct
                                    ? '#009900'
                                    : option?.user_response
                                    ? '#FF0000'
                                    : 'white',
                                  color:
                                    option?.is_correct || option?.user_response
                                      ? 'white'
                                      : '#8F95A3',
                                  fontWeight: 600,
                                  fontSize: '14px'
                                }}
                                defaultValue={option.label}
                                // disabled
                              />
                              <Input
                                className={styles.input_option2}
                                style={{
                                  width: '84%',
                                  border: option?.is_correct
                                    ? '1px solid green'
                                    : option?.user_response
                                    ? '1px solid #FF0000'
                                    : '1px solid #e9e9e9',
                                  fontWeight: 600,
                                  fontSize: '14px',
                                  color: '#8F95A3'
                                }}
                                value={option?.value}
                                // disabled
                              />
                              {option?.user_response && (
                                <span
                                  style={{
                                    marginLeft: '10px',
                                    width: '10%'
                                  }}
                                >
                                  <StarFilled style={{ color: 'gold' }} />
                                  <span
                                    style={{
                                      fontSize: '9px',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Yours
                                  </span>
                                </span>
                              )}
                            </Space.Compact>
                          ))}
                        </Space>
                      </Space>
                    ))}
                  </Space>
                  <Pagination
                    size="small"
                    total={pagination?.total_records}
                    current={page}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    onChange={(page, pageSize) => {
                      setPage(page);
                      setPageSize(pageSize);
                    }}
                    style={{ marginTop: '10px', textAlign: 'center' }}
                    className="text-right"
                  />
                </div>
              </Space>
            </div>
          </Space>
        </Spin>
      </CustomContainer>
    </Layout>
  );
};

export default QuizScoreAnswers;
