import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_events, postCreateUpdateEvents } from '../../redux/slice';
import styles from './index.module.less';
import { Space, Row, Col, Form, Input, Radio, DatePicker } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import Layout from 'common/CustomLayout';
import FormItem from 'common/FormItem';
import { history } from '@/app/history';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import LocalStorage from 'utilities/localStorage';

//const { TextArea } = Input;

const type = [
  {
    id: 1,
    type_option: 'Events'
  },
  {
    id: 2,
    type_option: 'Milestones'
  }
];

const CreateUpdateEventsMilestones = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const [formValues, setFormValues] = useState({});
  const [startDate, setStartDate] = useState(null);
  //const [endDate, setEndDate] = useState(null);

  const {
    eventsData,
    // get_announcementsLoading,
    postCreateUpdateEventsLoading
  } = useSelector((state) => state.adminConsole);

  // console.log('eventsData', eventsData);
  // console.log('id', id);

  useEffect(() => {
    if (id !== undefined && eventsData[0]) {
      const { type, event_name, event_location, start_date } = eventsData[0];
      form.setFieldsValue({
        typeOption: type === 'event' ? 1 : 2,
        name_title: event_name,
        location: event_location,
        startDate: start_date === null ? null : moment(start_date)
        // endDate: moment(end_date)
      });
    }
  }, [eventsData[0]]);

  const user = LocalStorage.getItem('neev_login_user');

  useEffect(() => {
    const payload = { id: id ? id : '' };
    id === undefined
      ? ''
      : dispatch(get_events({ role: user?.user_details?.user_role, payload })); //dispatch(get_events(payload));
  }, [id]);

  const handlePollsOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  // const disabledStartDate = (current) => {
  //   //Disable past dates
  //   return current && current < moment().startOf('day');
  // };

  // const disabledEndDate = (current) => {
  //   // Disable dates before the selected start date
  //   return (
  //     current && (!startDate || current < moment(startDate).startOf('day'))
  //   );
  // };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  // useEffect(() => {
  //   form.setFieldsValue({ endDate: undefined });
  // }, [startDate]);

  // const handleEndDateChange = (date) => {
  //   setEndDate(date);
  // };
  console.log('startDate', startDate);
  // console.log('endDate', endDate);
  const onFinish = () => {
    const payload = {
      type: formValues?.typeOption === 1 ? 'event' : 'milestone',
      event_name: formValues?.name_title,
      event_location: formValues?.location,
      start_date:
        startDate === null ? null : moment(startDate?._d)?.format('YYYY-MM-DD')
      //end_date: moment(endDate?._d)?.format('YYYY-MM-DD')
    };

    const byIdPaylod = {
      id: id,
      type:
        id !== undefined && formValues?.typeOption === undefined
          ? eventsData[0]?.type
          : formValues?.typeOption === 1
          ? 'event'
          : 'milestone',
      event_name:
        id !== undefined && formValues?.name_title === undefined
          ? eventsData[0]?.event_name
          : formValues?.name_title,
      event_location:
        id !== undefined && formValues?.location === undefined
          ? eventsData[0]?.event_location
          : formValues?.location,
      start_date:
        id !== undefined && startDate === null
          ? eventsData[0]?.start_date
          : moment(startDate?._d)?.format('YYYY-MM-DD')
      // end_date:
      //   id !== undefined && endDate === null
      //     ? eventsData[0]?.end_date
      //     : moment(endDate?._d)?.format('YYYY-MM-DD')
    };
    console.log('byIdPaylod', byIdPaylod);
    dispatch(
      postCreateUpdateEvents(id === undefined ? payload : byIdPaylod)
    ).then(({ payload }) => {
      if (payload?.success) {
        history.push('/admin-manage-events-milestones');
      }
    });
  };
  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <div className={styles.create_card_container}>
          <Space direction="vertical" size={14} style={{ width: '100%' }}>
            <CustomText
              font="bold"
              size={36}
              color="primary"
              letterSpacing={-0.72}
            >
              {id === undefined
                ? 'Create Events and Milestones'
                : 'Edit Events and Milestones'}
            </CustomText>
            <Form
              form={form}
              name="idea-create"
              layout="vertical"
              onFinish={onFinish}
            >
              <FormItem
                label=""
                name="typeOption"
                rules={[
                  {
                    required: true,
                    message: `Please enter your type`
                  }
                ]}
              >
                <Radio.Group
                  className={styles.latestPoll_radioButton}
                  onChange={(e) =>
                    handlePollsOnChange('typeOption', e.target.value)
                  }
                >
                  {type?.map((item, i) => (
                    <Space
                      key={i}
                      direction="horizontal"
                      size={10}
                      // className={styles.datepicker_container}
                    >
                      <Radio value={item?.id}>
                        <CustomText
                          size={18}
                          font="bold"
                          color={6}
                          className={styles.type_option_style}
                        >
                          {item?.type_option}
                        </CustomText>
                      </Radio>
                    </Space>
                  ))}
                </Radio.Group>
              </FormItem>
              <FormItem
                label={<div className={`${styles.title_style}`}>Name</div>}
                name="name_title"
                rules={[
                  {
                    required: true,
                    message: `Please enter name`
                  }
                ]}
              >
                <Input
                  className={styles.event_input_style}
                  placeholder={`Please enter name`}
                  onChange={(e) =>
                    handlePollsOnChange('name_title', e.target.value)
                  }
                />
              </FormItem>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <FormItem
                    label={
                      <div className={`${styles.title_style}`}>Start Date</div>
                    }
                    name="startDate"
                    rules={[
                      {
                        required: false,
                        message: `Enter start date`
                      }
                    ]}
                  >
                    <DatePicker
                      className={styles.datePicker_style}
                      placeholder={`Enter start date`}
                      onChange={handleStartDateChange}
                      //disabledDate={disabledStartDate}
                    />
                  </FormItem>
                </Col>
                {/*  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <FormItem
                    label={
                      <div className={`${styles.title_style}`}>End Date</div>
                    }
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: `Enter end date`
                      }
                    ]}
                  >
                    <DatePicker
                      className={styles.datePicker_style}
                      placeholder={`Enter end date`}
                      onChange={handleEndDateChange}
                      disabledDate={(current) =>
                        disabledEndDate(current, !!startDate)
                      }
                    />
                  </FormItem>
                </Col> */}
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <FormItem
                    label={
                      <div className={`${styles.title_style}`}>Location</div>
                    }
                    name="location"
                    rules={[
                      {
                        required: false,
                        message: `Enter location`
                      }
                    ]}
                  >
                    <Input
                      className={styles.event_input_style}
                      placeholder={`Enter location`}
                      onChange={(e) =>
                        handlePollsOnChange('location', e.target.value)
                      }
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12} className={styles.btn_container}>
                <Col xs={12}>
                  <FormItem className="text-right">
                    <CustomButton
                      size="large"
                      type="default"
                      onClick={() => history.back()}
                    >
                      Back
                    </CustomButton>
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem className="text-left">
                    <CustomButton
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={postCreateUpdateEventsLoading}
                    >
                      Save
                    </CustomButton>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Space>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default CreateUpdateEventsMilestones;
