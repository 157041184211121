import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_announcements, postCreateuAnnouncement } from '../../redux/slice';
import styles from './index.module.less';
import { Space, Row, Col, Form, Input } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import Layout from 'common/CustomLayout';
import FormItem from 'common/FormItem';
import { history } from '@/app/history';
import { useParams } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';

const { TextArea } = Input;

const CreateUpdateAnnouncement = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const {
    announcementsData,
    // get_announcementsLoading,
    postCreateuAnnouncementLoading
  } = useSelector((state) => state.adminConsole);

  // console.log('announcementsData', announcementsData);
  // console.log('id', id);

  useEffect(() => {
    if (id !== undefined) {
      form.setFieldsValue({
        idea_title: announcementsData[0]?.announcement_name
      });
    }
  }, [announcementsData]);

  const user = LocalStorage.getItem('neev_login_user');

  useEffect(() => {
    const payload = { id: id ? id : '' };
    id === undefined
      ? ''
      : dispatch(
          get_announcements({ role: user?.user_details?.user_role, payload })
        );
  }, [id]);

  const onFinish = (values) => {
    const { idea_title } = values;

    const payload = {
      announcement_name: idea_title,
      is_active: true
    };
    const byIdPaylod = {
      id: id,
      announcement_name: idea_title,
      is_active: true
    };
    //console.log('payload', payload);
    dispatch(
      postCreateuAnnouncement(id === undefined ? payload : byIdPaylod)
    ).then(({ payload }) => {
      if (payload?.success) {
        history.push('/admin-manage-announcement');
      }
    });
  };
  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <div className={styles.left_card_container}>
          <Space direction="vertical" size={14} style={{ width: '100%' }}>
            <CustomText
              font="bold"
              size={36}
              color="primary"
              letterSpacing={-0.72}
            >
              {id === undefined ? 'Create Announcement' : 'Edit Announcement'}
            </CustomText>
            <Form
              form={form}
              name="idea-create"
              layout="vertical"
              onFinish={onFinish}
            >
              <FormItem
                label={
                  <div className={`${styles.title_style}`}>
                    Type Announcement
                  </div>
                }
                name="idea_title"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your announcement'
                  }
                ]}
              >
                <TextArea
                  rows={6}
                  className={styles.form_group}
                  placeholder={`Enter your announcement`}
                />
              </FormItem>
              <Row gutter={12} className={styles.btn_container}>
                <Col xs={12}>
                  <FormItem className="text-right">
                    <CustomButton
                      size="large"
                      type="default"
                      onClick={() => history.back()}
                    >
                      Back
                    </CustomButton>
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem className="text-left">
                    <CustomButton
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={postCreateuAnnouncementLoading}
                    >
                      Save
                    </CustomButton>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Space>
        </div>
      </CustomContainer>
    </Layout>
  );
};

export default CreateUpdateAnnouncement;
