import apiClient from 'utilities/apiClient';

export const polls = (payload) => {
  return apiClient.get(apiClient.Urls.polls, payload, true);
};

export const pollsResult = (payload) => {
  return apiClient.get(apiClient.Urls.pollsResult, payload, true);
};

export const savePollResponse = (payload) => {
  return apiClient.post(
    apiClient.Urls.save_poll_response,
    payload,
    true
    // 'file'
  );
};
