import React, { useState, useEffect } from 'react';
import Layout from 'common/CustomLayout';
import { Space, Row, Spin, Col, Empty, Pagination } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import styles from './quiz.module.less';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_quizzes, get_quiz_user_score } from '../../redux/slice';
import moment from 'moment';
import Report from './Report';

// const quiz_user_score = [
//   {
//     id: 10,
//     quiz_id: 9,
//     quiz_name: 'SAP 109 Quiz',
//     quiz_score: 50,
//     quiz_submit_date: '15-Nov-2023 04:13:02 PM',
//     is_active: true
//   },
//   {
//     id: 11,
//     quiz_id: 19,
//     quiz_name: 'SAP 109 Quiz',
//     quiz_score: 65,
//     quiz_submit_date: '15-Nov-2023 04:13:02 PM',
//     is_active: true
//   },
//   {
//     id: 12,
//     quiz_id: 20,
//     quiz_name: 'SAP 109 Quiz',
//     quiz_score: 50,
//     quiz_submit_date: '15-Nov-2023 04:13:02 PM',
//     is_active: true
//   },
//   {
//     id: 13,
//     quiz_id: 93,
//     quiz_name: 'SAP 109 Quiz',
//     quiz_score: 80,
//     quiz_submit_date: '15-Nov-2023 04:13:02 PM',
//     is_active: true
//   }
// ];

const Quiz_detailsView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const {
    quizzesData,
    get_quizzesLoading,
    quiz_user_score,
    pagination,
    get_quiz_user_scoreLoading
  } = useSelector((state) => state.quizzesAdmin);

  const [buttonPosition, setButtonPosition] = useState();

  const [showScore, setShowScore] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalReport, setIsModalReport] = useState(false);

  const handleButtonClick = () => {
    setShowScore(true);
    setButtonPosition(`calc(100% + ${getButtonOffset()}px)`);
  };

  const getButtonOffset = () => {
    if (window.innerWidth >= 1600) {
      return 480;
    } else if (window.innerWidth >= 1400) {
      return 400;
    } else if (window.innerWidth >= 1200) {
      return 325;
    } else if (window.innerWidth >= 1100) {
      return 267;
    } else if (window.innerWidth >= 992) {
      return 200;
    } else if (window.innerWidth >= 768) {
      return 240;
    } else if (window.innerWidth >= 576) {
      return 200;
    } else {
      return 160;
    }
  };

  //console.log('quizzesData', quizzesData);
  console.log('quiz_user_score', quiz_user_score);

  useEffect(() => {
    const payload = {
      quiz_id: id ? id : ''
    };
    dispatch(get_quizzes(payload));
  }, [id]);
  useEffect(() => {
    const payload = {
      quiz_id: id ? id : '',
      page: page,
      per_page: pageSize
    };
    dispatch(get_quiz_user_score(payload));
  }, [id, page, pageSize]);

  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <Spin spinning={get_quizzesLoading}>
          <Space direction="vertical" size={30} style={{ width: '100%' }}>
            <div className={styles.left_card_container1}>
              <Space direction="vertical" size={20} style={{ width: '100%' }}>
                {' '}
                <div className={styles.back_container}>
                  <CustomButton
                    className={styles.post_btn_style1}
                    onClick={() => history.push(`/admin-quizzes`)}
                  >
                    Back
                  </CustomButton>
                  <CustomButton
                    className={styles.create_quiz_btn}
                    onClick={() => setIsModalReport(true)}
                  >
                    Report
                  </CustomButton>
                </div>
                {/* {showScore && (
                  <div>
                    {quiz_user_score?.map((score, index) => (
                      <div key={index}>
                        <CustomText>{score.user_name}</CustomText>
                        <CustomText>{score.score}</CustomText>
                      </div>
                    ))}
                  </div>
                )} */}
                <div key={id} className={styles.left_card_container2}>
                  <Space
                    direction="vertical"
                    size={15}
                    style={{ width: '100%' }}
                  >
                    <Space
                      direction="horizontal"
                      size={2}
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div className={styles.quiz_attented_container}>
                        <CustomText
                          size={12}
                          className={styles.quiz_attented_style}
                        >
                          {quizzesData[0]?.quiz_attented} Answers
                        </CustomText>
                      </div>
                      <div className={styles.edit_container}>
                        <CustomText className={styles.closed_date_style}>
                          {moment(quizzesData[0]?.closed_date).format(
                            'MMMM DD YYYY'
                          )}
                        </CustomText>
                      </div>
                    </Space>

                    <Row
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Col span={24}>
                        <CustomText
                          font="bold"
                          size={18}
                          color="black"
                          letterSpacing={-0.72}
                        >
                          {quizzesData[0]?.quiz_name}
                        </CustomText>
                      </Col>
                    </Row>
                    <CustomText className={styles.quiz_cat_style}>
                      {quizzesData[0]?.quiz_cat?.category_name}
                    </CustomText>
                    <CustomText className={styles.quiz_desc_style}>
                      {quizzesData[0]?.quiz_desc}
                    </CustomText>
                  </Space>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <hr style={{ flex: 1, marginRight: '0px' }} />
                  <CustomButton
                    className={styles.view_card_btn}
                    // onClick={() => history.push(`/admin-quizzes`)}
                    onClick={handleButtonClick}
                    style={{
                      transition: 'transform 0.5s',
                      transform: `translateX(${buttonPosition})`,
                      position: 'absolute',
                      left: '50%'
                    }}
                  >
                    {showScore ? 'View' : 'View'}
                  </CustomButton>
                  <hr style={{ flex: 1, marginLeft: '0px' }} />
                </div>
                {showScore && (
                  <Spin spinning={get_quiz_user_scoreLoading}>
                    {quiz_user_score?.length ? (
                      <Row gutter={[16, 16]}>
                        {quiz_user_score?.map((item) => {
                          const {
                            id,
                            //quiz_name,
                            quiz_score,
                            quiz_submit_date,
                            user_details
                          } = item;

                          return (
                            <Col span={12} key={id}>
                              <div className={styles.view_card_container}>
                                <a>
                                  <Space
                                    direction="vertical"
                                    size={5}
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                      const user_id = user_details?.id;
                                      const id = quizzesData[0]?.id;
                                      history.push(
                                        `/admin-quiz-score-details/${id}/${user_id}`
                                      );
                                    }}
                                  >
                                    <Space
                                      direction="horizontal"
                                      size={2}
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                      }}
                                    >
                                      <CustomText
                                        font="bold"
                                        size={18}
                                        color="black"
                                        letterSpacing={-0.72}
                                      >
                                        {user_details?.display_name}
                                      </CustomText>

                                      <CustomText
                                        size={10}
                                        className={styles.closed_date_style}
                                      >
                                        {moment(quiz_submit_date).format(
                                          'MMMM DD YYYY'
                                        )}
                                      </CustomText>
                                    </Space>

                                    <CustomText
                                      className={styles.score_text_style}
                                      size={16}
                                    >
                                      Scored {quiz_score}%
                                    </CustomText>
                                  </Space>
                                </a>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    ) : (
                      <Empty className="w-100" />
                    )}
                  </Spin>
                )}
                <Pagination
                  size="small"
                  total={pagination?.total_records}
                  current={page}
                  pageSize={pageSize}
                  showSizeChanger={true}
                  onChange={(page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                  }}
                  style={{ marginTop: '10px', textAlign: 'center' }}
                  className="text-right"
                />
              </Space>
            </div>
          </Space>
        </Spin>
        {isModalReport ? (
          <Report
            isModalReport={isModalReport}
            setIsModalReport={setIsModalReport}
            quizzesData={quizzesData}
          />
        ) : null}
      </CustomContainer>
    </Layout>
  );
};

export default Quiz_detailsView;
