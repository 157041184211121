import React, { useState, useEffect } from 'react';
import {
  Col,
  Divider,
  Image,
  Row,
  Space,
  Radio,
  Progress,
  Spin,
  //Checkbox,
  Form
} from 'antd';
import moment from 'moment';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomCard from '@/common/CustomCard';
import CustomButton from '@/common/CustomButton';
import styles from './about.module.less';
import calendar from '../../images/calendar-check.svg';
// import { groupBy } from 'lodash';
// import { BoldText } from 'utilities/helpers';
import { InfoCircleOutlined } from '@ant-design/icons';
import SectionTitle from '@/common/SectionTitle';
import mission from '../../images/about/mission.svg';
import purpose from '../../images/about/purpose.svg';
import vision from '../../images/about/vision.svg';
import { StarFilled } from '@ant-design/icons';
import { sizeChecker } from 'utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { get_events, get_milestones } from '../../../adminConsole/redux/slice';
import LocalStorage from 'utilities/localStorage';
// import FormItem from 'common/FormItem';
// import DemoComponent from './Demo1';

const About = ({
  pollsData,
  pollsVoteSubmitHandler,
  postPollsResponseLoading,
  pollsResultData
  // getPollsResultLoading
}) => {
  // const groupedEvents = groupBy(events, 'type');
  const isMobile = sizeChecker('xs');
  const [form] = Form.useForm();

  // console.log('pollsData', pollsData?.length);
  // console.log('pollsResultData', pollsResultData);
  // console.log('getPollsResultLoading', getPollsResultLoading);

  const [pollsValues, setPollsValues] = useState({
    pollsOption: [] // Initialize with an empty array for selected options
  });

  console.log('pollsValues.pollsOption', pollsValues.pollsOption);

  const dispatch = useDispatch();

  const {
    eventsData,
    get_eventsLoading,
    milestonesData,
    get_milestonesLoading
  } = useSelector((state) => state.adminConsole);

  //console.log('eventsData', eventsData);

  const user = LocalStorage.getItem('neev_login_user');

  const handleGetRequests = () => {
    const payload = {
      page: 1,
      per_page: 100,
      type: user?.user_details?.user_role === 'user' ? 'event' : ''
    };
    const payloadMilestones = {
      page: 1,
      per_page: 100,
      type: user?.user_details?.user_role === 'user' ? 'milestone' : ''
    };
    dispatch(
      get_milestones({ role: user?.user_details?.user_role, payloadMilestones })
    );
    dispatch(get_events({ role: user?.user_details?.user_role, payload }));
  };

  useEffect(() => {
    handleGetRequests();
  }, []);

  const handlePollsOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setPollsValues({
      ...pollsValues,
      [slug]: value
    });
    // form.resetFields();
  };

  // const onFinish = () => {
  //   console.log('Form submitted!');
  //   // console.log('pollsData[0]?.type', pollsData[0]?.type);
  //   const payload = {
  //     poll_id: pollsData[0]?.id,
  //     poll_option_id:
  //       pollsData[0]?.type === 'multiple'
  //         ? pollsValues?.pollsOption
  //         : [pollsValues?.pollsOption]
  //   };
  //   console.log('payload', payload);
  //   pollsVoteSubmitHandler(payload);
  //   //form.resetFields();
  //   // setPollsValues({
  //   //   ...pollsValues,
  //   //   pollsOption: []
  //   // });
  // };

  const events = [
    {
      id: 1,
      title: 'Key Events',
      team: eventsData?.filter((item) => item?.type === 'event')
    }
  ];
  const Milestones = [
    {
      id: 1,
      title: 'Upcoming Milestones',
      team: milestonesData?.filter((item) => item?.type === 'milestone')
    }
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (questionId, optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const existingQuestionIndex = prevSelectedOptions.findIndex(
        (item) => item.questionId === questionId
      );

      if (existingQuestionIndex !== -1) {
        // Question already exists in selectedOptions
        const existingOptionIndex =
          prevSelectedOptions[existingQuestionIndex].selectedOptions.indexOf(
            optionId
          );

        if (existingOptionIndex !== -1) {
          // Option already selected, remove it
          prevSelectedOptions[existingQuestionIndex].selectedOptions.splice(
            existingOptionIndex,
            1
          );
        } else {
          // Option not selected, add it
          prevSelectedOptions[existingQuestionIndex].selectedOptions.push(
            optionId
          );
        }
      } else {
        // Question not in selectedOptions, add it with the selected option
        prevSelectedOptions.push({
          questionId,
          selectedOptions: [optionId]
        });
      }

      return [...prevSelectedOptions];
    });
  };

  const handleSubmit = () => {
    console.log('Selected Options@@@@@@@@@@@:', selectedOptions);
    const payload = {
      poll_id:
        pollsData[0]?.type === 'multiple'
          ? selectedOptions[0]?.questionId
          : pollsData[0]?.id,
      poll_option_id:
        pollsData[0]?.type === 'multiple'
          ? selectedOptions[0]?.selectedOptions
          : [pollsValues?.pollsOption]
    };
    console.log('payload', payload);
    pollsVoteSubmitHandler(payload);
    setSelectedOptions([]); // Clear selected options after submission
  };
  return (
    <Spin spinning={get_eventsLoading || get_milestonesLoading}>
      <CustomContainer
        id="about"
        title="About"
        containerClassname={styles.container}
      >
        <Row gutter={[32, 25]}>
          <Col xs={24} sm={15}>
            <Space direction="vertical" size={5}>
              <SectionTitle title="About Project NEEV" />
              <CustomText size={18} color={2}>
                {` Project NEEV is a step towards Tata Project’s ambition to be a
              Technology Led EPC Company. The aim of the project is to build a
              digital core accelerating TPL’s ability to ensure predictable
              outcomes through digitizing and standardizing existing process,
              building better controls and compliance and overcoming existing
              Technology Challenges. Building a strong core, the project takes
              advantage of the full power of the SAP suite of products with S4
              HANA at the center and enabled by additional modules such as
              Ariba, Concur, SAC Planning, Master Data Governance, VIM etc. The
              aim is to modernize TPL’s current ecosystem and to deliver a
              tightly coupled digital core to support different functions
              through seamless digitized processes. With the new stack coming
              in, the legacy ERP system would be replaced with a single, highly
              integrated SAP ecosystem.`}
              </CustomText>
              {/*  {openShowMore === true ? ( */}
              <CustomText size={18} color={2}>
                {`The Project is being executed out of Delphi Building office in
              Mumbai with a dedicated workspace. Over 150+ TPL and partner team
              members (TCS, SAP Labs & Deloitte) with right kind of skillsets
              have been onboarded for the implementation. Multiple TPL team
              members across Finance, Source to Pay and Projects functions have
              been moved to the project to drive the project bringing in process
              and business expertise.`}
              </CustomText>
              {/*   ) : null}
            <a
              onClick={() => setOpenShowMore(!openShowMore)}
              className={`${styles.show_more}`}
            >
              {openShowMore ? 'Read less' : 'Read more'}
            </a> */}
            </Space>

            <Space
              direction="horizontal"
              size={5}
              style={{ marginTop: '30px' }}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <Image alt="icon" src={mission} preview={false} />
                  <div className={`${styles.mission_title}`}>Mission</div>
                  <div className={`${styles.mission_subtitle}`}>
                    To transform TPL into a tech led EPC company
                  </div>
                </Col>
                <Col span={8}>
                  <Image alt="icon" src={vision} preview={false} />
                  <div className={`${styles.mission_title}`}>Vision</div>
                  <div
                    className={`${styles.mission_subtitle}`}
                  >{`To overcome existing process, compliance and technology challenges`}</div>
                </Col>

                <Col span={8}>
                  <Image alt="icon" src={purpose} preview={false} />
                  <div className={`${styles.mission_title}`}>Purpose</div>
                  <div
                    className={`${styles.mission_subtitle}`}
                  >{`To accelerate TPL’s ability to ensure predictable outcomes`}</div>
                </Col>
              </Row>
              {/*  {missionContaint?.map((item) => (
              <Space direction="vertical" size={5} key={item?.id}>
                <Image alt="icon" src={item?.icon} preview={false} />
                <CustomText size={18} color={10}>
                  {item?.title}
                </CustomText>
                <CustomText size={14} color={2}>
                  {item?.subTitle}
                </CustomText>
              </Space>
            ))} */}
            </Space>
          </Col>
          <Col xs={24} sm={9}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <CustomCard contentClassName={styles.events_container}>
                  {events?.map((item) => {
                    const { id, title, team } = item;
                    return (
                      <div key={id}>
                        <div className={styles.title}>
                          <CustomText font="bold" color={4} size={16}>
                            {title}
                          </CustomText>
                        </div>
                        <div className={styles.events_inner_container}>
                          {team?.map((event) => {
                            const {
                              id,
                              event_name,
                              event_location,
                              start_date
                            } = event;

                            return (
                              <div key={id} className={styles.event_container}>
                                <Row gutter={8}>
                                  <Col span={2}>
                                    <Image
                                      alt="calendar"
                                      src={calendar}
                                      preview={false}
                                    />
                                  </Col>
                                  <Col span={20}>
                                    <Space direction="vertical" size={0}>
                                      <CustomText font="bold" color={3}>
                                        {event_name}
                                      </CustomText>
                                      <Space
                                        split={<Divider type="vertical" />}
                                        size={0}
                                      >
                                        {start_date === null ? (
                                          ''
                                        ) : (
                                          <CustomText color={3}>
                                            {moment(start_date).format(
                                              'MMM DD, YYYY'
                                            )}
                                          </CustomText>
                                        )}
                                        <CustomText color={3}>
                                          {event_location}
                                        </CustomText>
                                      </Space>
                                    </Space>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </CustomCard>
              </Col>
              <Col span={24}>
                <CustomCard contentClassName={styles.events_container}>
                  {Milestones?.map((item) => {
                    const { id, title, team } = item;
                    return (
                      <div key={id}>
                        <div className={styles.title}>
                          <CustomText font="bold" color={4} size={16}>
                            {title}
                          </CustomText>
                        </div>
                        <div className={styles.events_inner_container}>
                          {team?.map((event) => {
                            const {
                              id,
                              event_name,
                              event_location,
                              start_date
                            } = event;

                            return (
                              <div key={id} className={styles.event_container}>
                                <Row gutter={8}>
                                  <Col span={2}>
                                    <Image
                                      alt="calendar"
                                      src={calendar}
                                      preview={false}
                                    />
                                  </Col>
                                  <Col span={20}>
                                    <Space direction="vertical" size={0}>
                                      <CustomText font="bold" color={3}>
                                        {event_name}
                                      </CustomText>
                                      <Space
                                        split={<Divider type="vertical" />}
                                        size={0}
                                      >
                                        {start_date === null ? (
                                          ''
                                        ) : (
                                          <CustomText color={3}>
                                            {moment(start_date).format(
                                              'MMM DD, YYYY'
                                            )}
                                          </CustomText>
                                        )}
                                        <CustomText color={3}>
                                          {event_location}
                                        </CustomText>
                                      </Space>
                                    </Space>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </CustomCard>
              </Col>
              <Col span={24}>
                <CustomCard
                  title="Latest Poll"
                  className={styles.latestPoll_title}
                >
                  {pollsData?.length === 0 ? (
                    pollsResultData?.length === 0 ? (
                      <div className={styles.poll_container}>
                        <Space direction="vertical" size={13} style={{}}>
                          <CustomText font="medium" size={12} color={5}>
                            {' '}
                            <InfoCircleOutlined /> No pending polls to display.
                            Check back later.
                          </CustomText>
                        </Space>
                      </div>
                    ) : (
                      pollsResultData?.map((item, i) => {
                        const { poll_question, pollopt } = item;
                        return (
                          <div className={styles.poll_container} key={i}>
                            <Space
                              direction="vertical"
                              size={13}
                              //style={{ width: '100%' }}
                              className={styles.poll_result}
                            >
                              <CustomText font="bold" color={3}>
                                {poll_question}
                              </CustomText>
                              {pollopt?.map((item, i) => {
                                const {
                                  my_poll_response,
                                  poll_option,
                                  user_percentage
                                } = item;
                                return (
                                  <Row key={i}>
                                    <Col span={8}>
                                      <span
                                        style={{
                                          marginRight: '5px',
                                          color: '#344054',
                                          fontFamily:
                                            'CerebriSans Regular !important',
                                          letterSpacing: '0px',
                                          fontSize: '14px',
                                          lineHeight: '1px',
                                          fontWeight: 600
                                        }}
                                      >
                                        {poll_option}:
                                      </span>
                                    </Col>
                                    <Col span={11}>
                                      <Progress
                                        size="small"
                                        percent={parseInt(user_percentage)}
                                        status="active"
                                      />
                                    </Col>
                                    <Col span={5}>
                                      {my_poll_response && (
                                        <span
                                          style={{
                                            marginLeft: '10px'
                                          }}
                                        >
                                          <StarFilled
                                            style={{ color: 'gold' }}
                                          />
                                          <span
                                            style={{
                                              fontSize: isMobile
                                                ? '8px'
                                                : '10px',
                                              fontWeight: 'bold'
                                            }}
                                          >
                                            Yours
                                          </span>
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </Space>
                          </div>
                        );
                      })
                    )
                  ) : (
                    <div className={styles.poll_container}>
                      <Form
                        form={form}
                        name="idea-create"
                        layout="vertical"
                        // onFinish={onFinish}
                        onFinish={handleSubmit}
                      >
                        {pollsData?.map((question) => (
                          <div key={question.id}>
                            {question?.type === 'multiple' ? (
                              <Space direction="vertical" size={13}>
                                <CustomText font="bold" size={14} color={6}>
                                  {question.poll_question}
                                </CustomText>

                                {question?.pollopt?.map((option) => (
                                  <Row
                                    className={styles.radioButton_margin}
                                    key={option.id}
                                  >
                                    <input
                                      className={styles.checkbox_style}
                                      type="checkbox"
                                      onChange={() =>
                                        handleCheckboxChange(
                                          question?.id,
                                          option?.id
                                        )
                                      }
                                      checked={selectedOptions?.some(
                                        (item) =>
                                          item?.questionId === question?.id &&
                                          item?.selectedOptions?.includes(
                                            option?.id
                                          )
                                      )}
                                    />

                                    <CustomText font="medium" color={6}>
                                      {option.poll_option}
                                    </CustomText>
                                  </Row>
                                ))}
                              </Space>
                            ) : (
                              <div>
                                <CustomText font="bold" size={14} color={6}>
                                  {question?.poll_question}
                                </CustomText>

                                <Radio.Group
                                  className={styles.latestPoll_radioButton}
                                  onChange={(e) =>
                                    handlePollsOnChange(
                                      'pollsOption',
                                      e.target.value
                                    )
                                  }
                                >
                                  {question?.pollopt?.map((item, i) => (
                                    <Row
                                      className={styles.radioButton_margin}
                                      key={i}
                                    >
                                      <Radio value={item?.id}>
                                        <CustomText font="medium" color={6}>
                                          {item?.poll_option}
                                        </CustomText>
                                      </Radio>
                                    </Row>
                                  ))}
                                </Radio.Group>
                              </div>
                            )}
                          </div>
                        ))}
                        <CustomButton
                          type="primary"
                          size="small"
                          htmlType="submit"
                          // onClick={pollsVoteHandler}
                          //onClick={handleSubmit}
                          loding={postPollsResponseLoading}
                          style={{ marginTop: '10px' }}
                        >
                          Vote Now
                        </CustomButton>
                        {/* <button type="button" onClick={handleSubmit}>
                            Submit
                          </button> */}
                      </Form>
                    </div>
                  )}
                </CustomCard>
              </Col>
            </Row>
            {/*  <DemoComponent /> */}
          </Col>
        </Row>
      </CustomContainer>
    </Spin>
  );
};

export default About;
/*  (
                    pollsData?.map((pl, i) => (
                      <Form
                        key={i}
                        form={form}
                        name="idea-create"
                        layout="vertical"
                        onFinish={onFinish}
                      >
                        <div className={styles.poll_container} key={i}>
                          <Space direction="vertical" size={13}>
                            <FormItem className="text-left">
                              {pl?.type === 'multiple' ? (
                                <div>
                                  <CustomText font="bold" size={14} color={6}>
                                    {pl?.poll_question}
                                  </CustomText>
                                  <div
                                    className={styles.latestPoll_radioButton}
                                  >
                                    {pl?.pollopt?.map((item, i) => (
                                      <Row
                                        className={styles.radioButton_margin}
                                        key={i}
                                      >
                                        <Checkbox
                                          onChange={(e) =>
                                            handlePollsOnChange(
                                              'pollsOption',
                                              e.target.checked
                                                ? [
                                                    ...(pollsValues?.pollsOption ||
                                                      []), // Ensure pollsOption is an array
                                                    item?.id
                                                  ]
                                                : (
                                                    pollsValues?.pollsOption ||
                                                    []
                                                  ).filter(
                                                    (optionId) =>
                                                      optionId !== item?.id
                                                  )
                                            )
                                          }
                                        >
                                          <CustomText font="medium" color={6}>
                                            {item?.poll_option}
                                          </CustomText>
                                        </Checkbox>
                                      </Row>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <CustomText font="bold" size={14} color={6}>
                                    {pl?.poll_question}
                                  </CustomText>

                                  <Radio.Group
                                    className={styles.latestPoll_radioButton}
                                    onChange={(e) =>
                                      handlePollsOnChange(
                                        'pollsOption',
                                        e.target.value
                                      )
                                    }
                                  >
                                    {pl?.pollopt?.map((item, i) => (
                                      <Row
                                        className={styles.radioButton_margin}
                                        key={i}
                                      >
                                        <Radio value={item?.id}>
                                          <CustomText font="medium" color={6}>
                                            {item?.poll_option}
                                          </CustomText>
                                        </Radio>
                                      </Row>
                                    ))}
                                  </Radio.Group>
                                </div>
                              )}
                            </FormItem>

                            <FormItem className="text-left">
                              <CustomButton
                                type="primary"
                                size="small"
                                htmlType="submit"
                                // onClick={pollsVoteHandler}
                                loding={postPollsResponseLoading}
                              >
                                Vote Now
                              </CustomButton>
                            </FormItem>
                          </Space>
                        </div>
                      </Form>
                    ))
                  ) */
