import React, { useState, useEffect } from 'react';
//import CustomText from '@/common/CustomText';
import { Space, Image, Row, Col } from 'antd';

// import CustomContainer from '@/common/CustomContainer';
import styles from './currentFutureState.module.less';
//import SectionTitle from '@/common/SectionTitle';
import CurrentState from '../../images/currentFutureState/01.svg';
import FutureState from '../../images/currentFutureState/02.svg';
import { DoubleRightOutlined, ArrowDownOutlined } from '@ant-design/icons';
import bgBanner from '../../images/currentFutureState/bgBanner.png';
import { Fade } from 'react-reveal';
import { sizeChecker } from 'utilities/helpers';

function CurrentFutureState() {
  const isMobile = sizeChecker('xs');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const element = document.getElementById('futureState'); // Add an id to the FutureState Image element

      if (element) {
        const elementPosition = element.offsetTop;

        // Adjust this value based on when you want the animation to start
        const triggerPoint = elementPosition - window.innerHeight + 100;

        setIsVisible(scrollPosition > triggerPoint);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Initial check on mount
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div
      style={{
        background: `url(${bgBanner}) no-repeat center`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div className={styles.container}>
        <Space direction="vertical" size={30}>
          <Row>
            <Col xs={24} sm={11}>
              <Image alt="Digital Core" src={CurrentState} preview={false} />
            </Col>
            <Col xs={24} sm={2} className={styles.currentFuture_iconContainer}>
              {isMobile ? (
                <Fade top when={isVisible} duration={2000}>
                  <ArrowDownOutlined
                    className={styles.currentFuture_icon}
                    style={{ fontSize: '46px' }}
                  />
                </Fade>
              ) : (
                <DoubleRightOutlined
                  className={styles.currentFuture_icon}
                  style={{ fontSize: '46px' }}
                />
              )}
            </Col>
            <Col xs={24} sm={11} id="futureState">
              <Fade right when={isVisible} duration={2000}>
                <Image alt="Digital Core" src={FutureState} preview={false} />
              </Fade>
            </Col>
          </Row>
        </Space>
      </div>
    </div>
  );
}
export default CurrentFutureState;
