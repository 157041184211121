import { configureStore } from '@reduxjs/toolkit';
import customLayoutReducer from 'common/CustomLayout/redux/slice';
import loginReducer from 'features/login/redux/slice';
import ideasReducer from 'features/ideas/redux/slice';
import homeSliceReducer from '@/features/home/redux/slice';
import quizzesReducer from 'features/quizzes/redux/slice';
import quizzesAdminReducer from 'features/adminQuizzes/redux/slice';
import adminConsoleReducer from 'features/adminConsole/redux/slice';
import networksReducer from 'features/change_enablement/redux/slice';

export const store = configureStore({
  reducer: {
    customLayout: customLayoutReducer,
    login: loginReducer,
    ideas: ideasReducer,
    home: homeSliceReducer,
    quizzes: quizzesReducer,
    quizzesAdmin: quizzesAdminReducer,
    adminConsole: adminConsoleReducer,
    networks: networksReducer
  }
});
