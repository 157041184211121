import React from 'react';
//import CustomText from '@/common/CustomText';
import { Space, Image } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import styles from './digital.module.less';
//import SectionTitle from '@/common/SectionTitle';
import digital_core from '../../images/digital/digital1.svg';

function DigitalHXM() {
  return (
    <CustomContainer
      id="digital"
      title="Digital"
      containerClassname={styles.container}
    >
      <Space
        direction="vertical"
        size={30}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* <SectionTitle title="Building a Digital Core" /> */}
        <div className="df-jc-ac">
          <Image alt="Digital Core" src={digital_core} preview={false} />
        </div>
      </Space>
    </CustomContainer>
  );
}
export default DigitalHXM;
