import React, { useEffect, useState } from 'react';
import styles from './startquiz.module.less';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import Layout from 'common/CustomLayout';
import { Space, Row, Spin, Progress, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import CustomContainer from '@/common/CustomContainer';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_quiz_user_score, get_quizzes } from 'features/quizzes/redux/slice';
import LocalStorage from 'utilities/localStorage';

const getCircleColor = (quizScore) => {
  if (quizScore === 100) {
    return '#52c41a'; // Green color
  } else if (quizScore < 50) {
    return '#fadb14'; // Yellow color
  } else {
    return '#323282'; // Default color (Blue)
  }
};

const QuizScore = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const {
    get_quizzesLoading,
    get_quiz_user_scoreLoading,
    quiz_user_score,
    quizzesData
  } = useSelector((state) => state.quizzes);

  console.log('quiz_user_score', quiz_user_score);
  const color = getCircleColor(quiz_user_score?.[0]?.quiz_score);
  const [downloadReports, setDownloadReports] = useState(false);
  useEffect(() => {
    const payload = {
      quiz_id: id
    };
    dispatch(get_quizzes(payload));

    //console.log('payload', payload);
  }, [id]);
  useEffect(() => {
    const payload = {
      quiz_id: id
    };
    dispatch(get_quiz_user_score(payload));
  }, []);

  const onCertificateDownload = () => {
    setDownloadReports(true);
    const user = LocalStorage.getItem('neev_login_user');
    const authToken = user?.accessToken;
    fetch(
      `${process.env.REACT_APP_API_URL}/user/getDownloadQuizCertificate?quiz_id=${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `certificate.pdf`);
        document.body.appendChild(link);
        link.click();
        setDownloadReports(false);
        link.parentNode.removeChild(link);
      });
  };

  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <Spin spinning={get_quizzesLoading}>
          <Spin spinning={get_quiz_user_scoreLoading}>
            <Space direction="vertical" size={20} style={{ width: '100%' }}>
              <div className={styles.left_card_container1}>
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                  <Row
                    className={styles.row_direction1}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <CustomText
                      font="bold"
                      size={32}
                      color="primary"
                      letterSpacing={-0.72}
                    >
                      Quiz
                    </CustomText>
                    <CustomButton
                      className={styles.post_btn_style1}
                      onClick={() => history.push(`/quizzes`)}
                    >
                      Back
                    </CustomButton>
                  </Row>
                </Space>
                <div
                  className={styles.left_card_container1}
                  style={{ marginTop: '10px' }}
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: '100%' }}
                  >
                    <Row
                      //className={styles.row_direction1}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}
                    >
                      <CustomText
                        font="bold"
                        size={32}
                        color="primary"
                        letterSpacing={-0.72}
                      >
                        {quizzesData &&
                          quizzesData?.[0]?.quiz_cat?.category_name}
                      </CustomText>
                    </Row>
                    <Row
                      //className={styles.row_direction1}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}
                    >
                      <Progress
                        type="circle"
                        width={200}
                        percent={(quiz_user_score?.[0]?.quiz_score / 100) * 100}
                        format={() =>
                          `${
                            quiz_user_score?.[0]?.quiz_score
                              ? Math.round(
                                  (quiz_user_score?.[0]?.quiz_score / 100) * 100
                                )
                              : 0
                          }%`
                        }
                        strokeColor={color}
                        style={{
                          //  marginTop: '20px',
                          height: '200px',
                          color: 'green'
                        }}
                      />
                    </Row>
                    <Row
                      //className={styles.row_direction1}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}
                    >
                      {quiz_user_score?.[0]?.is_allowed_certificate &&
                      quiz_user_score?.[0]?.quiz_score >= 80 ? (
                        <>
                          <CustomText
                            font="bold"
                            letterSpacing={-0.72}
                            className={styles.score_text}
                            size={20}
                          >
                            Congratulations! You scored{' '}
                            {quiz_user_score
                              ? quiz_user_score?.[0]?.quiz_score
                              : 0}
                            % Download your Certificate
                          </CustomText>
                        </>
                      ) : (
                        <CustomText
                          font="bold"
                          letterSpacing={-0.72}
                          className={styles.score_text}
                          size={20}
                        >
                          You scored{' '}
                          {quiz_user_score
                            ? quiz_user_score?.[0]?.quiz_score
                            : 0}
                        </CustomText>
                      )}
                    </Row>

                    <Row
                      className={styles.row_direction1}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {quiz_user_score?.[0]?.is_allowed_certificate &&
                      quiz_user_score?.[0]?.quiz_score >= 80 ? (
                        <Row>
                          <CustomButton
                            className={styles.post_btn_style1}
                            onClick={() =>
                              history.push(`/quiz-score-answers/${id}`)
                            }
                          >
                            View Answers
                          </CustomButton>

                          <CustomButton
                            onClick={onCertificateDownload}
                            className={`${styles.post_btn_style1} ml-2`}
                          >
                            <DownloadOutlined />
                            Certificate
                          </CustomButton>
                        </Row>
                      ) : quiz_user_score?.[0]?.is_allowed_certificate &&
                        quiz_user_score?.[0]?.quiz_score < 80 ? (
                        <CustomButton
                          className={styles.post_btn_style1}
                          onClick={() =>
                            history.push(`/quize-instruction/${id}?retake=true`)
                          }
                        >
                          Retake
                        </CustomButton>
                      ) : null}
                    </Row>
                  </Space>
                </div>
                <Modal open={downloadReports} footer={false} closable={false}>
                  <p style={{ textAlign: 'center' }}>
                    Downloading your certificate, please wait
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <Spin />
                  </p>
                </Modal>
              </div>
            </Space>
          </Spin>
        </Spin>
      </CustomContainer>
    </Layout>
  );
};

export default QuizScore;
