import AdminQuizzesPage from './components/AdminQuizzesPage';
import Layout from 'common/CustomLayout';
import { TabTitle } from 'utilities/helpers';

const AdminQuizzes = () => {
  TabTitle('Neev - Admin Quiz');
  return (
    <Layout>
      <AdminQuizzesPage />
    </Layout>
  );
};

export default AdminQuizzes;
