import React from 'react';
import { Image, Space, Collapse } from 'antd';
import styles from './paper.module.less';
import CustomText from '../CustomText';

const { Panel } = Collapse;

const Paper = ({
  titleProps,
  containerStyle = {},
  gap = 20,
  bodyStyle = {},
  data = [],
  children = null
}) => {
  const {
    title,
    titleColor = 'white',
    description = '',
    direction = 'column',
    justify = 'center',
    align = 'center',
    style = { backgroundColor: '#FFF' }
  } = titleProps;

  // Check if the title is the specific one where you want to enable collapse functionality
  const allowedTitles = [
    'SAP S/4 HANA CORE ERP',
    'SAP ARIBA',
    'SAP SAC & SAP CONCUR',
    'SAP ANALYTICS SUITE'
  ];

  const enableCollapse = allowedTitles.includes(title);

  const customExpandIcon = ({ isActive }) => {
    return isActive ? (
      <Image
        className={styles.expand_icon}
        src={'images/minus-circle.svg'}
        preview={false}
      />
    ) : (
      <Image
        className={styles.expand_icon}
        src={'images/plus-circle.svg'}
        preview={false}
      />
    );
  };

  return (
    <div className={styles.container} style={containerStyle}>
      <Space direction="vertical" size={gap} className="w-100">
        <div
          className={`d-flex flex-${direction} justify-content-${justify} align-items-${align} ${styles.title_container}`}
          style={style}
        >
          <CustomText
            font="medium"
            size={22}
            color={titleColor}
            letterSpacing={-0.44}
          >
            {title}
          </CustomText>
          {description && <CustomText size={18}>{description}</CustomText>}
        </div>
        {enableCollapse ? (
          <Collapse
            className={styles.collapse}
            expandIcon={customExpandIcon}
            ghost
          >
            {data.map((item, index) => {
              const { text, description } = item;

              return (
                <Panel
                  header={
                    <CustomText font="bold" size={16}>
                      {text}
                    </CustomText>
                  }
                  key={index}
                >
                  <div style={{ marginTop: '-15px' }} className="ml-4">
                    {description && (
                      <CustomText size={14.5}>{description}</CustomText>
                    )}
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        ) : (
          <Space direction="vertical" size={16} style={bodyStyle}>
            {data.map((item, index) => {
              const { image, text } = item;

              return (
                <Space key={index} size={12} align="start">
                  <div className={styles.image_container}>
                    <Image
                      alt={text}
                      src={image || 'images/check-circle.svg'}
                      preview={false}
                    />
                  </div>
                  <CustomText font="bold" size={16}>
                    {text}
                  </CustomText>
                </Space>
              );
            })}
          </Space>
        )}
        {children}
      </Space>
    </div>
  );
};

export default Paper;
