import React from 'react';
import { Image, Space } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import styles from './partners.module.less';
import tcs from '../../images/partners/tcs.png';
import sap from '../../images/partners/sap.png';
import deloitte from '../../images/partners/deloitte.png';
import { sizeChecker } from 'utilities/helpers';
import avaali from '../../images/partners/avaali.png';

const partners = [
  { id: 1, image: tcs, alt: 'tcs', label: 'Implementation Partner' },
  { id: 2, image: sap, alt: 'sap', label: 'Product SME' },
  { id: 3, image: deloitte, alt: 'deloitte', label: 'Project PMO' },
  { id: 4, image: avaali, alt: 'avaali', label: 'VIM Implementation' }
];

const Partners = () => {
  const isMobile = sizeChecker('xs');
  return (
    <CustomContainer
      id="partners"
      title="Partners"
      containerClassname={styles.container}
    >
      <Space direction="vertical" size={32} align="center" className="w-100">
        <CustomText
          font={isMobile ? 'bold' : 'bold'}
          size={isMobile ? 26 : 36}
          color={'primary'}
        >
          Our Implementation Partners
        </CustomText>
      </Space>
      <Space
        style={{ marginTop: '30px' }}
        direction={isMobile ? 'vertical' : 'horizontal'}
        size={isMobile ? 20 : 120}
        className="w-100 justify-content-center"
      >
        {partners.map((item) => {
          const { id, image, alt, label } = item;

          return (
            <Space
              key={id}
              direction="vertical"
              size={id === 4 ? 9 : id === 1 ? 19 : id === 2 ? 30 : 23}
              align="center"
              className="w-100"
            >
              <div
                className={
                  id === 4
                    ? styles.image_container_avaali
                    : id === 1
                    ? styles.image_container_tcs
                    : styles.image_container
                }
              >
                <Image alt={alt} src={image} preview={false} />
              </div>

              <CustomText font="medium" size={isMobile ? 16 : 19}>
                {label}
              </CustomText>
            </Space>
          );
        })}
      </Space>
    </CustomContainer>
  );
};

export default Partners;
