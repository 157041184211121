import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  ideas,
  createUpdateIdea,
  likeDislikeIdea,
  getCategories,
  getSubCategories,
  create_comment_ideas
} from './api';
import { history } from '@/app/history';

const initialState = {
  ideas: [],
  pagination: [],
  getIdeasLoading: false,
  postIdeaLoading: false,
  postLikeIdeaLoading: false,
  postDislikeIdeaLoading: false,
  categories: [],
  getCategoryListLoading: false,
  sub_categories: [],
  getSubCategoryListLoading: false,
  idea: null,
  getIdeaByIdLoading: false,
  postCommentIdeasLoading: false
};

const actions = {
  GET_IDEAS: 'ideas/GET_IDEAS',
  POST_IDEA: 'ideas/POST_IDEA',
  POST_LIKE_IDEA: 'ideas/POST_LIKE_IDEA',
  POST_DISLIKE_IDEA: 'ideas/POST_DISLIKE_IDEA',
  GET_CATEGORY_LIST: 'ideas/GET_CATEGORY_LIST',
  GET_SUB_CATEGORY_LIST: 'ideas/GET_SUB_CATEGORY_LIST',
  GET_IDEA_BY_ID: 'ideas/GET_IDEA_BY_ID',
  POST_COMMENT_IDEA: 'postCommentIdeas/POST_COMMENT_IDEA'
};

export const postCommentIdeas = createAsyncThunk(
  actions.POST_COMMENT_IDEA,
  async (payload) => {
    const response = await create_comment_ideas(payload);

    return response;
  }
);

export const getIdeas = createAsyncThunk(actions.GET_IDEAS, async (payload) => {
  const response = await ideas(payload);

  return response;
});

export const postIdea = createAsyncThunk(actions.POST_IDEA, async (payload) => {
  const response = await createUpdateIdea(payload);

  return response;
});

export const postLikeIdea = createAsyncThunk(
  actions.POST_LIKE_IDEA,
  async (idea_id) => {
    let payload = {
      is_liked: true,
      idea_id
    };

    const response = await likeDislikeIdea(payload);

    return response;
  }
);

export const postDislikeIdea = createAsyncThunk(
  actions.POST_DISLIKE_IDEA,
  async (idea_id) => {
    let payload = {
      is_liked: false,
      idea_id,
      is_active: true
    };

    const response = await likeDislikeIdea(payload);

    return response;
  }
);

export const getCategoryList = createAsyncThunk(
  actions.GET_CATEGORY_LIST,
  async () => {
    const response = await getCategories();

    return response;
  }
);

export const getSubCategoryList = createAsyncThunk(
  actions.GET_SUB_CATEGORY_LIST,
  async (category_id) => {
    const response = await getSubCategories({ category_id });

    return response;
  }
);

export const getIdeaById = createAsyncThunk(
  actions.GET_IDEA_BY_ID,
  async (id) => {
    const response = await ideas({ id });

    return response;
  }
);

export const ideasSlice = createSlice({
  name: 'ideas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIdeas.pending, (state) => {
        state.getIdeasLoading = true;
      })
      .addCase(getIdeas.fulfilled, (state, action) => {
        state.getIdeasLoading = false;
        const { success, data, message: msg, pagination } = action.payload;

        if (success) {
          const bgColors = ['#F1FFF1', '#E8F3FF'];
          let biArrData = [];
          const biArrDataLength = Math.ceil(data.length / 2);
          for (let index = 0; index < biArrDataLength; index++) {
            let modifiedIndex = index * 2;
            biArrData.push(data.slice(modifiedIndex, modifiedIndex + 2));
          }
          biArrData = biArrData.map((item, ind) => {
            let alternateCheck = ind % 2 === 0;
            const bgColorsCopy = [...bgColors];
            const bgColorsArr = alternateCheck
              ? bgColors
              : bgColorsCopy.reverse();

            return item.map((o, i) => ({ ...o, bgColor: bgColorsArr[i] }));
          });
          const dataWithBgColor = biArrData.flat();

          state.ideas = dataWithBgColor;
          state.pagination = pagination;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.ideas = [];
          message.error(msg);
        }
      })
      .addCase(getIdeas.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getIdeasLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postIdea.pending, (state) => {
        state.postIdeaLoading = true;
      })
      .addCase(postIdea.fulfilled, (state, action) => {
        state.postIdeaLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postIdea.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postIdeaLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postLikeIdea.pending, (state) => {
        state.postLikeIdeaLoading = true;
      })
      .addCase(postLikeIdea.fulfilled, (state, action) => {
        state.postLikeIdeaLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postLikeIdea.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postLikeIdeaLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postDislikeIdea.pending, (state) => {
        state.postDislikeIdeaLoading = true;
      })
      .addCase(postDislikeIdea.fulfilled, (state, action) => {
        state.postDislikeIdeaLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postDislikeIdea.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postDislikeIdeaLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getCategoryList.pending, (state) => {
        state.getCategoryListLoading = true;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.getCategoryListLoading = false;

        const { success, data, message: msg } = action.payload;
        state.categories = success ? data : [];
        if (!success) {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
        }
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getCategoryListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getSubCategoryList.pending, (state) => {
        state.getSubCategoryListLoading = true;
      })
      .addCase(getSubCategoryList.fulfilled, (state, action) => {
        state.getSubCategoryListLoading = false;

        const { success, data, message: msg } = action.payload;
        state.sub_categories = success ? data : [];
        if (!success) {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
        }
      })
      .addCase(getSubCategoryList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getSubCategoryListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getIdeaById.pending, (state) => {
        state.getIdeaByIdLoading = true;
      })
      .addCase(getIdeaById.fulfilled, (state, action) => {
        state.getIdeaByIdLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.idea = data?.[0] || null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.idea = null;
          message.error(msg);
        }
      })
      .addCase(getIdeaById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getIdeaByIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postCommentIdeas.pending, (state) => {
        state.postCommentIdeasLoading = true;
      })
      .addCase(postCommentIdeas.fulfilled, (state, action) => {
        state.postCommentIdeasLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postCommentIdeas.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postCommentIdeasLoading = false;

        message.error(msg);
      });
  }
});

export default ideasSlice.reducer;
