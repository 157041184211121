import { Image, Space } from 'antd';
import styles from './idea_card.module.less';
import CustomText from '@/common/CustomText';
import moment from 'moment';
import { LikeOutlined } from '@ant-design/icons'; //LikeFilled,
//import fold from '../../images/fold.svg';
import { useNavigate } from 'react-router-dom';
//import { useState } from 'react';
import suggestion from '../../images/suggestion.svg';
import query from '../../images/query.svg';
import CustomButton from '@/common/CustomButton';
import thumbsUp from '../../images/thumbsUp.svg';

const IdeaCard = ({
  item,
  handlePostLikeIdea,
  handlePostDislikeIdea,
  //containerStyle,
  clickable = false
}) => {
  const navigate = useNavigate();
  //const [ellipsis] = useState(true);
  const {
    id,
    idea_cby,
    createdAt,
    category,
    idea_title,
    liked_by_user,
    idea_likes_count,
    bgColor,
    type,
    idea_comments_count
  } = item;

  return (
    <div
      className={`${styles.container}${clickable ? ` cursor-pointer` : ''}`}
      /*  style={{ backgroundColor: bgColor || '#F1FFF1', ...containerStyle }} */
      style={{ backgroundColor: '#F5F5F5' }}
    >
      {/* <div className={styles.fold_image}>
        <Image alt="fold" src={fold} preview={false} />
      </div> */}
      <Space direction="vertical" size={12} className={`${styles.card_width}`}>
        {/* className="w-100%" */}
        <div style={{ margin: '0px', padding: '0px' }}>
          <Image
            alt="query"
            src={type === 'query' ? query : suggestion}
            preview={false}
          />
        </div>
        <div className="df-jb-ac">
          <CustomText font="bold" size={18} color="black">
            {idea_cby?.display_name || ''}
            <div className="df-jb-ac">
              <CustomText className={`${styles.email_style}`} size={11}>
                {idea_cby?.email || ''}
              </CustomText>
            </div>
          </CustomText>
          <CustomText color={11} size={11}>
            {moment(createdAt).format('MMM DD, YYYY')}
          </CustomText>
        </div>
        <Space
          direction="vertical"
          size={4}
          className={`${styles.card_dimension}`}
        >
          <CustomText font="medium" size={16} color="#344054">
            {category?.category_name || ''}
          </CustomText>
          <p
            className={`${styles.card_description}`}
            // size={36}
            // color={12}
            // style={ellipsis ? { width: 300 } : undefined}
            // ellipsis={true}
          >
            {idea_title || ''}
          </p>
        </Space>
        <Space className="df-jb-ac">
          <Space size={5} align="center">
            <span
              //className="cursor-pointer"
              className={`${styles.thumbUp_margin}`}
              onClick={(e) => {
                e.stopPropagation();
                if (liked_by_user) {
                  handlePostDislikeIdea(id);
                } else {
                  handlePostLikeIdea(id);
                }
              }}
            >
              {liked_by_user ? (
                /*  <LikeOutlined style={{ fontSize: 17, color: '#323282' }} /> */
                <Image alt="thumbsUp" src={thumbsUp} preview={false} />
              ) : (
                <LikeOutlined style={{ fontSize: 17, color: '#646464' }} />
              )}
            </span>
            <CustomText font="medium" size={13} color={12}>
              {`${idea_likes_count} upvotes`}
            </CustomText>
          </Space>
          <Space size={5} align="center">
            <CustomText
              font="medium"
              size={13}
              color={12}
              className={`${styles.comment_style}`}
              onClick={() => {
                if (clickable) {
                  navigate(`/ideas/${id}`, { state: { bgColor } });
                }
              }}
            >
              {`${idea_comments_count} Comments`}
            </CustomText>
          </Space>
          <div>
            <CustomButton
              className={`${styles.view_style}`}
              onClick={() => {
                if (clickable) {
                  navigate(`/ideas/${id}`, { state: { bgColor } });
                }
              }}
            >
              view
            </CustomButton>
          </div>
        </Space>
      </Space>
    </div>
  );
};

export default IdeaCard;
