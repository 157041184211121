import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { polls, savePollResponse, pollsResult } from './api';
import { history } from '@/app/history';

const initialState = {
  getPollsLoading: false,
  getPollsResultLoading: false,
  postPollsResponseLoading: false,
  pollsData: [],
  pollsResultData: []
};

const actions = {
  GET_POLLS: 'getPolls/GET_POLLS',
  GET_POLLS_RESULT: 'getPollsResult/GET_POLLS_RUSELT',
  POST_POLLS_RESPONSE: 'postPollsResponse/POST_POLLS_RESPONSE'
};

export const getPolls = createAsyncThunk(actions.GET_POLLS, async (payload) => {
  const response = await polls(payload);

  return response;
});

export const getPollsResult = createAsyncThunk(
  actions.GET_POLLS_RESULT,
  async (payload) => {
    const response = await pollsResult(payload);

    return response;
  }
);

export const postPollsResponse = createAsyncThunk(
  actions.POST_POLLS_RESPONSE,
  async ({ payload }) => {
    const response = await savePollResponse(payload);
    return response;
  }
);

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPolls.pending, (state) => {
        state.getPollsLoading = true;
      })
      .addCase(getPolls.fulfilled, (state, action) => {
        state.getPollsLoading = false;
        const { success, data } = action.payload;

        if (success) {
          state.pollsData = data ? data : [];
        } else {
          state.pollsData = [];
          //message.error(msg);
        }
      })
      .addCase(getPolls.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getPollsLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getPollsResult.pending, (state) => {
        state.getPollsResultLoading = true;
      })
      .addCase(getPollsResult.fulfilled, (state, action) => {
        state.getPollsResultLoading = false;
        const { success, data, message: msg } = action.payload;

        if (success) {
          state.pollsResultData = data ? data : [];
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.pollsResultData = [];
          message.error(msg);
        }
      })
      .addCase(getPollsResult.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getPollsResultLoading = false;

        message.error(msg);
      });
    builder
      .addCase(postPollsResponse.pending, (state) => {
        state.postPollsResponseLoading = true;
      })
      .addCase(postPollsResponse.fulfilled, (state, action) => {
        state.postPollsResponseLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postPollsResponse.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postPollsResponseLoading = false;

        message.error(msg);
      });
  }
});

export default homeSlice.reducer;
