import KnowledgeHubPage from './components/KnowledgeHubPage';
import Layout from 'common/CustomLayout';
import { TabTitle } from 'utilities/helpers';

const KnowledgeHub = () => {
  TabTitle('Neev - Knowledge Hub');
  return (
    <Layout>
      <KnowledgeHubPage />
    </Layout>
  );
};

export default KnowledgeHub;
