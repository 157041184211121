import React from 'react';
import styles from './custom_container.module.less';
// import CustomText from '../CustomText';

const CustomContainer = ({
  // title = '',
  children,
  containerClassname = '',
  contentClassname = '',
  ...rest
}) => {
  return (
    <div className={`${styles.container} ${containerClassname}`} {...rest}>
      {/* {title ? (
        <div className={styles.title_container}>
          <CustomText
            font="bold"
            size={146}
            color="primary"
            className={styles.title}
          >
            {title}
          </CustomText>
        </div>
      ) : null} */}
      <div className={`${styles.content} ${contentClassname}`}>{children}</div>
    </div>
  );
};

export default CustomContainer;
