import React, { useState, useEffect } from 'react';
import { Space, Image, Row, Col } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import styles from './employeeCentral.module.less';
//import digital_core from '../../images/employeeCentral/employeeCentral.svg';
import LeftState from '../../images/employeeCentral/Central_Go_Live01_left.jpg';
import RightState from '../../images/employeeCentral/Central_Go_Live02_right.jpg';
import { Fade } from 'react-reveal';
//import { sizeChecker } from 'utilities/helpers';

function EmployeeCentral() {
  //const isMobile = sizeChecker('xs');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const element = document.getElementById('LeftState'); // Add an id to the FutureState Image element

      if (element) {
        const elementPosition = element.offsetTop;

        // Adjust this value based on when you want the animation to start
        const triggerPoint = elementPosition - window.innerHeight + 100;

        setIsVisible(scrollPosition > triggerPoint);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Initial check on mount
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <CustomContainer
      id="digital"
      title="Digital"
      containerClassname={styles.container}
    >
      <Space
        direction="vertical"
        size={30}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div className={styles.container}>
          <Space direction="vertical" size={30}>
            <Row>
              <Col xs={24} sm={12}>
                <Image alt="Digital Core" src={LeftState} preview={false} />
              </Col>

              <Col xs={24} sm={12} id="LeftState">
                <Fade right when={isVisible} duration={2000}>
                  <Image alt="Digital Core" src={RightState} preview={false} />
                </Fade>
              </Col>
            </Row>
          </Space>
        </div>
      </Space>
    </CustomContainer>
  );
}
export default EmployeeCentral;
