import React from 'react';
import CustomText from '@/common/CustomText';
import { Space, Row, Col, Image } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import styles from './overview.module.less';
import v1 from '../../images/overview/v1.svg';
import v2 from '../../images/overview/v2.svg';
import img1 from '../../images/overview/img1.svg';
import Ritesh from '../../images/overview/Ritesh_photo.jpg';
import { sizeChecker } from 'utilities/helpers';
//import mission1 from '../../images/overview/mission.svg';
import mission from '../../../home/images/about/mission.svg';
import purpose from '../../../home/images/about/purpose.svg';
import vision from '../../../home/images/about/vision.svg';

function OverviewHxm() {
  const isMobile = sizeChecker('xs');
  return (
    <CustomContainer
      id="overview"
      title="Overview"
      containerClassname={styles.container}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Space direction="vertical" size={30} style={{ marginLeft: '0px' }}>
        <Row gutter={50}>
          <Col xs={24} sm={10}>
            <Space direction="vertical" size={25} style={{ marginTop: '30px' }}>
              <Row>
                <Col span={4}>
                  <Image alt="icon" src={mission} preview={false} />
                </Col>
                <Col span={20}>
                  <div className={`${styles.mission_title}`}>Mission</div>
                  <CustomText size={17} color={2} font="regular" lineHeight={1}>
                    To transform TPL into a tech led EPC company
                  </CustomText>
                </Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Image alt="icon" src={vision} preview={false} />
                </Col>
                <Col span={20}>
                  <div className={`${styles.mission_title}`}>Vision</div>
                  <CustomText size={17} color={2} font="regular" lineHeight={1}>
                    {`To overcome existing process, compliance and technology challenges`}
                  </CustomText>
                </Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Image alt="icon" src={purpose} preview={false} />
                </Col>
                <Col span={20}>
                  <div className={`${styles.mission_title}`}>Purpose</div>
                  <CustomText size={17} color={2} font="regular" lineHeight={1}>
                    {`To accelerate TPL’s ability to ensure predictable outcomes`}
                  </CustomText>
                </Col>
              </Row>
            </Space>
            {/*  <div
            // className={styles.centerAling}
            >
              <Image
                alt="mission"
                src={mission1}
                preview={false}
                // className={styles.overviewImage}
              />
            </div> */}
          </Col>
          <Col xs={24} sm={14} className={styles.over_right_textContainer}>
            <CustomText
              font="bold"
              size={isMobile ? 26 : 36}
              color={'primary'}
              letterSpacing={isMobile ? 0 : -0.72}
              className={
                isMobile ? `${styles.centerAling_containerMobile}` : null
              }
            >
              Overview
            </CustomText>
            <Row>
              <Col
                xs={24}
                sm={8}
                className={
                  isMobile
                    ? `${styles.overviewImage_containerMobile}`
                    : `${styles.overviewImage_container}`
                }
              >
                <div>
                  <Image
                    alt="overviewImage"
                    src={img1}
                    preview={false}
                    className={styles.overviewImage}
                  />
                </div>
              </Col>
              <Col xs={24} sm={16}>
                <Space
                  direction="vertical"
                  size={15}
                  style={{ marginLeft: '0px' }}
                >
                  <CustomText size={17} color={2} font="regular">
                    {`Experience the power of a scalable cloud based HR system that will stand out as a transformative solution for Tata Projects Limited. SuccessFactors will streamline HR processes and overcome the challenges posed by disintegrated HR systems in the current landscape.`}
                  </CustomText>

                  <CustomText size={17} color={2} font="regular">
                    {`An integrated system that will foster efficiency allowing TPL to make data-driven decisions with real-time insights by breaking down silos and reducing administrative burdens.`}
                  </CustomText>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={60}>
          <Col xs={24} sm={18}>
            <div className={styles.box}>
              <img src={v1} alt="Top Left Image" className={styles.image} />
              <img
                src={v2}
                alt="Bottom Right Image"
                className={styles.bottom_image}
              />
              <Space direction="vertical" size={10}>
                {/* <div className={styles.overview_leftText}> */}
                <CustomText size={17} color={2} font="regular">
                  {`Our pursuit of becoming a technology-driven EPC company is fueled by our steadfast dedication to innovation and advancement. As we embark on this transformative journey, we undertake the integration of HXM capabilities, utilizing SAP SuccessFactors, within our digital core. This unified system will usher in a paradigm shift in our approach to human experience management, facilitating a cohesive platform that guarantees employee contentment and fosters professional development. Together, we are constructing a future where people and technology together propel us towards excellence.`}
                </CustomText>
                {/*   <div className={styles.overview_leftText}>
                  {`This unified system will usher in a paradigm shift in our approach to human experience management, facilitating a cohesive platform that guarantees employee contentment and fosters professional development.`}
                </div>
                <div className={styles.overview_leftText}>
                  {`Together, we are constructing a future where people and technology together propel us towards excellence.`}
                </div> */}
                <CustomText size={17} color={2} font="regular">
                  {`#DigitalTransformation #TechnoHumanExperience #DigitalCore`}
                </CustomText>
              </Space>
            </div>
          </Col>
          <Col xs={24} sm={6} className={styles.centerAling}>
            <Space direction="vertical" size={7}>
              <Image alt="Ritesh" src={Ritesh} preview={false} />
              <Space
                direction="vertical"
                size={0}
                style={{ marginLeft: '0px' }}
              >
                <CustomText
                  font="bold"
                  size={isMobile ? 16 : 20}
                  color={'primary'}
                  letterSpacing={isMobile ? 0 : -0.72}
                  className={styles.centerAling}
                >
                  Mr. Ritesh Pratap Singh
                </CustomText>
                <CustomText
                  size={isMobile ? 12 : 13}
                  color={2}
                  font="regular"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '-4px'
                  }}
                >
                  Chief Human Resources Officer
                </CustomText>
              </Space>
            </Space>
          </Col>
        </Row>
      </Space>
    </CustomContainer>
  );
}
export default OverviewHxm;
