import { Space, Avatar, Form, Input, Row, Col, Spin } from 'antd';
import styles from './idea_card.module.less';
import CustomText from '@/common/CustomText';
import moment from 'moment';
import { LikeOutlined, DeleteOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import LocalStorage from 'utilities/localStorage';

//import fold from '../../images/fold.svg';
//import { useNavigate } from 'react-router-dom';
//import { map } from 'lodash';

const { TextArea } = Input;

const IdeaCardDetails = ({
  item,
  handlePostLikeIdea,
  handlePostDislikeIdea,
  postCommentIdeasLoading,
  handlePostCommentIdea,
  //containerStyle,
  clickable = false
}) => {
  //const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    id,
    idea_cby,
    createdAt,
    category,
    idea_title,
    liked_by_user,
    idea_likes_count,
    // bgColor,
    idea_comments
  } = item;

  console.log('idea_comments', idea_comments);

  const user = LocalStorage.getItem('neev_login_user');
  const role = user?.user_details?.user_role;
  console.log('user', user?.user_details?.user_role);

  const onFinish = (values) => {
    const payload = {
      idea_id: id,
      comment: values.comments,
      is_active: true
    };

    handlePostCommentIdea(payload);
    form.resetFields();
  };

  const handleDeleteComment = (com_id) => {
    // console.log('id', id);
    // console.log('com_id', com_id);
    const payload = {
      idea_id: id,
      id: com_id,
      is_active: false
    };
    // console.log('DeleteCommentPayload', payload);
    handlePostCommentIdea(payload);
    // dispatch(postCommentIdeas(payload)).then(({ payload }) => {
    //   if (payload.success) {
    //     dispatch(getIdeaById(id));
    //   }
    // });
  };

  return (
    <div>
      <div
        className={`${styles.container}${clickable ? ` cursor-pointer` : ''}`}
        /* style={{ backgroundColor: bgColor || '#F1FFF1', ...containerStyle }} */
        style={{ backgroundColor: '#F5F5F5' }}
      >
        <Space direction="vertical" size={10} className="w-100">
          <div className="df-jb-ac">
            <CustomText font="bold" size={18} color="black">
              {idea_cby?.display_name || ''}
            </CustomText>
            <CustomText color={11} size={10}>
              {moment(createdAt).format('MMM DD, YYYY')}
            </CustomText>
          </div>
          <Space direction="vertical" size={4}>
            <CustomText font="medium" size={16} color="#344054">
              {category?.category_name || ''}
            </CustomText>
            <CustomText size={13} color="#646464">
              {idea_title || ''}
            </CustomText>
          </Space>
          <Space size={5} align="center" style={{ marginTop: '20px' }}>
            <span
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                if (liked_by_user) {
                  handlePostDislikeIdea(id);
                } else {
                  handlePostLikeIdea(id);
                }
              }}
            >
              {liked_by_user ? (
                <LikeOutlined
                  style={{ fontSize: 25, color: '#323282', outline: '#323282' }}
                />
              ) : (
                <LikeOutlined
                  style={{ fontSize: 25, color: '#646464', outline: '#646464' }}
                />
              )}
            </span>
            <CustomText font="medium" size={13} color={12}>
              {`${idea_likes_count} upvotes`}
            </CustomText>
          </Space>
          <Spin spinning={postCommentIdeasLoading}>
            <Space
              size={0}
              direction="vertical"
              style={{ marginTop: '10px', width: '100%' }}
            >
              <CustomText font="medium" size={18} color="#344054">
                Comments
              </CustomText>
              <Form
                form={form}
                name="idea-create"
                layout="horizontal"
                onFinish={onFinish}
                style={{ marginTop: '20px' }}
              >
                <Row gutter={6}>
                  <Col span={20}>
                    <FormItem
                      label=""
                      // {
                      //   <div className={`${styles.title_style}`}>
                      //     write your comments
                      //   </div>
                      // }
                      name="comments"
                      rules={[
                        {
                          required: true,
                          message: `write your comments`
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <TextArea
                        style={{ width: '100%' }}
                        className={styles.input_container_style}
                        placeholder="write your comments"
                      />
                    </FormItem>
                  </Col>
                  <Col span={2} className={styles.post_container}>
                    <FormItem>
                      <CustomButton
                        size="large"
                        type="primary"
                        htmlType="submit"
                        //loading={postCommentIdeasLoading}
                        className={styles.post_btn}
                      >
                        Post
                      </CustomButton>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
              <div style={{ marginTop: '0px' }}>
                {idea_comments?.map((item, index) => {
                  const { id, comment, createdAt, user } = item;
                  return (
                    <div key={index} className={styles.userComments_style}>
                      <Space size={15} direction="horizontal">
                        <Avatar
                          style={{
                            backgroundColor: '#323282',
                            verticalAlign: 'middle'
                          }}
                          size="middle"
                        >
                          {user?.display_name
                            ? user.display_name[0].toUpperCase()
                            : ''}
                        </Avatar>
                        <Space size={1} direction="vertical">
                          <Space size={50} direction="horizontal">
                            <CustomText font="medium" size={14} color="#344054">
                              {user?.display_name}{' '}
                            </CustomText>
                            <CustomText color={11} size={10}>
                              {moment(createdAt).format('MMM DD, YYYY')}
                            </CustomText>
                          </Space>
                          <CustomText
                            size={13}
                            color="#344054"
                            className={styles.comment_text}
                          >
                            {comment}
                            {role === 'admin' ? (
                              <span
                                style={{ marginLeft: '10px' }}
                                className="cursor-pointer"
                                onClick={() => handleDeleteComment(id)}
                              >
                                <DeleteOutlined
                                  className={styles.comment_delete}
                                />
                              </span>
                            ) : null}
                          </CustomText>
                        </Space>
                      </Space>
                    </div>
                  );
                })}
              </div>
            </Space>
          </Spin>
        </Space>
      </div>
    </div>
  );
};

export default IdeaCardDetails;
