import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_announcements, postCreateuAnnouncement } from '../../redux/slice';
import styles from './index.module.less';
import { Space, Spin, Popconfirm, Row, Col, Pagination } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import LocalStorage from 'utilities/localStorage';

const ManageAnnouncementPage = () => {
  const dispatch = useDispatch();
  const {
    announcementsData,
    get_announcementsLoading,
    postCreateuAnnouncementLoading,
    pagination
  } = useSelector((state) => state.adminConsole);

  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // console.log('announcementsData', announcementsData);

  const user = LocalStorage.getItem('neev_login_user');

  const handleGetRequests = () => {
    const payload = { page: page, per_page: pageSize };
    dispatch(
      get_announcements({ role: user?.user_details?.user_role, payload })
    );
  };

  useEffect(() => {
    handleGetRequests();
  }, [page, pageSize]);

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  const handleDeleteRequest = (quizId) => {
    const payload = { id: quizId.id, is_active: false };
    // console.log('payload', payload);
    dispatch(postCreateuAnnouncement(payload)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };

  return (
    <CustomContainer title="Contact" containerClassname={styles.container}>
      <Spin
        spinning={get_announcementsLoading || postCreateuAnnouncementLoading}
      >
        <Space direction="vertical" size={14} style={{ width: '100%' }}>
          <Space
            direction="horizontal"
            size={6}
            style={{ width: '100%', display: 'flex', justifyContent: 'end' }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/admin-console`)}
            >
              Back
            </CustomButton>

            <CustomButton
              type="primary"
              htmlType="submit"
              className={styles.post_btn_style}
              onClick={() => history.push(`/admin-create_announcement`)}
            >
              Create Announcement
            </CustomButton>
          </Space>

          {announcementsData?.map((item) => (
            <div className={styles.left_card_container} key={item?.id}>
              <Row
                direction="horizontal"
                size={2}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row'
                  // justifyContent: 'space-between'
                }}
              >
                <Col xs={22}>
                  <CustomText className={styles.left_card_title}>
                    {item.announcement_name}
                  </CustomText>
                </Col>
                <Col xs={2}>
                  <div className={styles.edit_container}>
                    <EditOutlined
                      onClick={() =>
                        history.push(`/admin-create_announcement/${item?.id}`)
                      }
                      className={`${styles.edit_icon_style}`}
                    />

                    <Popconfirm
                      title="Are you sure do you want to delete this announcement?"
                      open={visibility(item?.id)}
                      onConfirm={() => {
                        handleDeleteRequest({
                          id: item?.id
                        });
                      }}
                      onCancel={() => {
                        setShowDisablePopup({});
                      }}
                      okText="Delete"
                      cancelText="No"
                      okButtonProps={{ type: 'danger' }}
                    >
                      <a>
                        <DeleteOutlined
                          className={`${styles.delete_icon_style}`}
                          onClick={() => {
                            setShowDisablePopup({ [item?.id]: true });
                          }}
                        />
                      </a>
                    </Popconfirm>
                  </div>
                </Col>
              </Row>
            </div>
          ))}

          <Pagination
            size="small"
            total={pagination?.total_records}
            current={page}
            pageSize={pageSize}
            showSizeChanger={true}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            style={{ marginTop: '10px', textAlign: 'center' }}
            className="text-right"
          />
        </Space>
      </Spin>
    </CustomContainer>
  );
};

export default ManageAnnouncementPage;
