import { useState } from 'react';
import { Fade } from 'react-reveal';
import { Image, Space } from 'antd';
import styles from './anchor_buttons.module.less';
//import circle from '../../images/circle.svg';
import CustomText from 'common/CustomText';
import { sizeChecker, smoothScrollTo } from 'utilities/helpers';
import home from '../../images/anchor/home.svg';
import users from '../../images/anchor/users.svg';
import settings from '../../images/anchor/settings.svg';
import thumbs from '../../images/anchor/thumbs.svg';
import keyBenefits from '../../images/anchor/keyBenefits.svg';
import digitalCore from '../../images/anchor/digitalCore.svg';
import timeline from '../../images/anchor/timeline.svg';
import partners from '../../images/anchor/partners.svg';

const data = [
  { id: 'banners', label: 'Home', icon: home },
  { id: 'about', label: 'About', icon: users },
  { id: 'functions', label: 'Scope', icon: settings },
  { id: 'benefits', label: 'New Ways', icon: thumbs },
  { id: 'digital_core', label: 'Digital Core', icon: keyBenefits },
  { id: 'timeline', label: 'Timeline', icon: timeline },
  { id: 'partners2', label: 'Go Live', icon: digitalCore },
  { id: 'partners', label: 'Partners', icon: partners }
];

const AnchorButtons = () => {
  const isMobile = sizeChecker('xs');
  const [show, setShow] = useState({});

  return (
    <div className={styles.container}>
      <Space direction="vertical" size={15} align="start">
        {data.map(({ id, label, icon }) => {
          return (
            <div
              key={id}
              className={styles.item_container}
              onMouseEnter={() => setShow({ [id]: true })}
              onMouseLeave={() => setShow({})}
              {...(isMobile
                ? {
                    onClick: () => {
                      setShow({ [id]: true });
                    }
                  }
                : {})}
            >
              <Image
                alt="circle"
                src={icon}
                preview={false}
                className={`${styles.anchor_icon_size}`}
                onClick={() => smoothScrollTo(id, 'start')}
              />
              {show[id] ? (
                <Fade right>
                  <div
                    className={styles.tooltip_container}
                    onClick={() => smoothScrollTo(id, 'start')}
                  >
                    <CustomText font="medium" size={12} color={10}>
                      {label}
                    </CustomText>
                  </div>
                </Fade>
              ) : null}
            </div>
          );
        })}
      </Space>
    </div>
  );
};

export default AnchorButtons;
