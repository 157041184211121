import React from 'react';
import Layout from 'common/CustomLayout';
import styles from './index.module.less';
import { Space, Image } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import arrow_icon from '../../images/arrow.svg';

const adminConsoleContent = [
  {
    id: 1,
    title: 'Logins',
    icon: arrow_icon,
    link: '/admin-logins-statistics'
  },
  {
    id: 2,
    title: 'Quizzes',
    icon: arrow_icon,
    link: '/admin-quizzes-statistics'
  },
  {
    id: 3,
    title: 'Polls',
    icon: arrow_icon,
    link: '/admin-polls-statistics'
  }
];
const Statistics = () => {
  return (
    <Layout>
      <CustomContainer title="Contact" containerClassname={styles.container}>
        <Space direction="vertical" size={14} style={{ width: '100%' }}>
          {/*  <CustomText
            font="bold"
            size={36}
            color="primary"
            letterSpacing={-0.72}
            style={{ marginBottom: '15px' }}
          >
            Admin Console
          </CustomText> */}
          <div className={styles.back_container}>
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </div>
          {adminConsoleContent.map((item) => (
            <div className={styles.left_card_container} key={item?.id}>
              <Space
                direction="horizontal"
                size={2}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <CustomText className={styles.left_card_title}>
                  {item.title}
                </CustomText>
                <div className={styles.take_quiz_btn}>
                  <a href={item.link}>
                    <Image src={item.icon} alt="" preview={false} />
                  </a>
                </div>
              </Space>
            </div>
          ))}
        </Space>
      </CustomContainer>
    </Layout>
  );
};

export default Statistics;
