import React from 'react';
import BannersHxm from './BannersHxm';
import OverviewHxm from './OverviewHxm';
import DigitalHXM from './DigitalHXM';
import CurrentFutureState from './CurrentFutureState';
import FactorsState from './FactorsState';
import FactorsModules from './FactorsModules';
import EmployeeCentral from './EmployeeCentral';
import Partners from './Partners';
import ProposedPlan from './ProposedPlan';
import UserManual from './UserManual';
function HxmPage() {
  return (
    <div>
      <BannersHxm />
      <OverviewHxm />
      <CurrentFutureState />
      <UserManual />
      <DigitalHXM />
      <FactorsState />
      <FactorsModules />
      <EmployeeCentral />
      <ProposedPlan />
      <Partners />
    </div>
  );
}
export default HxmPage;
