import CustomText from '../CustomText';
import styles from './custom_card.module.less';

const CustomCard = ({
  children,
  className,
  contentClassName = '',
  style,
  title,
  topBorder,
  leftBorder,
  ...rest
}) => {
  return (
    <div
      className={`${styles.container}${className ? ` ${className}` : ''}${
        topBorder ? ` ${styles.top_border}` : ''
      }${leftBorder ? ` ${styles.left_border}` : ''}`}
      style={style}
      {...rest}
    >
      {title ? (
        <div className={`df-jb-ac ${styles.title_container}`}>
          <CustomText font="medium" color={4} size={18}>
            {title}
          </CustomText>
        </div>
      ) : null}
      <div className={contentClassName}>{children}</div>
    </div>
  );
};

export default CustomCard;
