import React, { useEffect, useState } from 'react';
import CustomText from '@/common/CustomText';
import { Space, Row, Col, Image } from 'antd';
import styles from './factorsModules.module.less';

//import SectionTitle from '@/common/SectionTitle';
import { sizeChecker } from 'utilities/helpers';
// import tickIcon from '../../images/factorsModules/tickIcon.svg';
// import greenTickIcon from '../../images/factorsModules/greenTickIcon.svg';
import employeeCentral from '../../images/factorsModules/EmployeeCentral.svg';
import talentAcquisition from '../../images/factorsModules/TalentAcquisition.svg';
import learning from '../../images/factorsModules/Learning.svg';
import compensation from '../../images/factorsModules/Compensation.svg';
import goalsManagement from '../../images/factorsModules/GoalsManagement.svg';
import careerDevelopmentPlanning from '../../images/factorsModules/CareerDevelopmentPlanning.svg';
import payroll from '../../images/factorsModules/Payroll.svg';
import employeeExperience from '../../images/factorsModules/EmployeeExperience.svg';
import { Fade } from 'react-reveal';

const FactorsData = [
  {
    key: 1,
    title: 'Employee Central',
    bg: '#667085'
  },
  {
    key: 2,
    title: 'Talent Acquisition',
    bg: '#667085'
  },
  {
    key: 3,
    title: 'Learning',
    bg: '#667085'
  },
  {
    key: 4,
    title: 'Compensation',
    bg: '#667085'
  },
  {
    key: 5,
    title: 'Performance & Goals Management',
    bg: '#667085'
  },
  {
    key: 6,
    title: 'Succession & Career Development Planning',
    bg: '#667085'
  },
  {
    key: 7,
    title: 'Payroll',
    bg: '#667085'
  },
  {
    key: 8,
    title: 'Employee Experience',
    bg: '#667085'
  }
];

function FactorsModules() {
  const isMobile = sizeChecker('xs');

  const [selectedTab, setSelectedTab] = useState(1);
  ///const [selectedChild, setSelectedChild] = useState(0);
  const [hoveredTab, setHoveredTab] = useState(null);
  const [imageVisible, setImageVisible] = useState(false);
  //const [imageVisible, setImageVisible] = useState(true);

  const handleImageVisible = () => {
    setImageVisible(!imageVisible);
  };
  useEffect(() => {
    // Trigger the animation when the selected tab changes
    handleImageVisible();
  }, [selectedTab]);

  const onClickDecider = (index) => {
    //, child = null
    // If child is provided, update selectedChild and do not change selectedTab

    // if (child !== null) {
    //   setSelectedChild(child);
    //   return;
    // }

    // If no child is provided, set selectedChild to the first child of the tab
    // const tab = FactorsData[index - 1];
    // if (tab && tab.children && tab.children.length > 0) {
    //   setSelectedChild(tab.children[0]);
    // } else {
    //   // If the title does not have children, set selectedChild to null
    //   setSelectedChild(null);
    // }

    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        // setSelectedChild(null); // Reset selectedChild when parent is clicked
        setSelectedTab(index);
        handleImageVisible();
        break;

      // Handle other cases if needed
      default:
        break;
    }
  };
  //console.log('selectedTab', selectedTab);

  const allContent = () => {
    switch (selectedTab) {
      case 1: // Check for selectedChild instead of selectedTab
        return (
          <>
            <Fade right when={imageVisible} duration={2000}>
              <Image
                src={employeeCentral}
                preview={false}
                alt={'img'}
                className={styles.imageVisible}
              />
            </Fade>
          </>
        );
      case 2:
        return (
          <>
            <Fade right when={imageVisible} duration={2000}>
              <Image
                src={talentAcquisition}
                preview={false}
                alt={'img'}
                className={styles.imageVisible}
              />
            </Fade>
          </>
        );
      case 3:
        return (
          <>
            <Fade right when={imageVisible} duration={2000}>
              <Image src={learning} preview={false} alt={'img'} />
            </Fade>
          </>
        );

      case 4:
        return (
          <>
            <Fade right when={imageVisible} duration={2000}>
              <Image src={compensation} preview={false} alt={'img'} />
            </Fade>
          </>
        );
      case 5:
        return (
          <>
            <Fade right when={imageVisible} duration={2000}>
              <Image src={goalsManagement} preview={false} alt={'img'} />
            </Fade>
          </>
        );
      case 6:
        return (
          <>
            <Fade right when={imageVisible} duration={2000}>
              <Image
                src={careerDevelopmentPlanning}
                preview={false}
                alt={'img'}
              />
            </Fade>
          </>
        );
      case 7:
        return (
          <>
            <Fade right when={imageVisible} duration={2000}>
              <Image src={payroll} preview={false} alt={'img'} />
            </Fade>
          </>
        );
      case 8:
        return (
          <>
            <Fade right when={imageVisible} duration={2000}>
              <Image src={employeeExperience} preview={false} alt={'img'} />
            </Fade>
          </>
        );
      default:
        return window?.scrollTo(0, document.body.scrollHeight);
    }
  };
  return (
    <div className={styles.factors_modules_bg}>
      <div className={styles.factors_container}>
        <div className={styles.factors_modules_container}>
          <CustomText
            font="bold"
            size={isMobile ? 26 : 36}
            color={'primary'}
            letterSpacing={isMobile ? 0 : -0.72}
            className={styles.sectionTitle_style}
          >
            Success Factors Modules
          </CustomText>
        </div>
        <Row gutter={[64, 30]}>
          <Col xs={24} sm={6} className={styles.remove_margin}>
            <Space direction="vertical" size={0} style={{ marginLeft: '0px' }}>
              {FactorsData?.map((item, index) => (
                <div
                  key={index}
                  //className={styles.factors_menu_style}
                  className={
                    selectedTab === index + 1
                      ? ` ${styles.selected}`
                      : `${styles.factors_menu_style}`
                  }
                  onMouseEnter={() => {
                    setHoveredTab(item.key);
                  }}
                  onMouseLeave={() => {
                    setHoveredTab(null);
                  }}
                  style={{
                    cursor: 'pointer',
                    backgroundColor:
                      selectedTab === item.key || hoveredTab === item.key
                        ? `#323282`
                        : '#667085',
                    transition: 'background-color 0.3s'
                  }}
                >
                  <div
                    // font="medium"
                    // size={16}
                    // color={selectedTab === index + 1 ? 'primary' : 7}
                    className={`cursor-pointer ${styles.left_text}`}
                    onClick={() => onClickDecider(index + 1)}
                  >
                    {item.title}
                    {/* <span style={{ fontSize: '14px' }}>{item?.subTitle}</span> */}
                  </div>
                  {/*  {selectedTab === index + 1 && (
                      <div style={{ marginLeft: '20px', marginTop: '5px' }}>
                        {item?.children?.map((child, childIndex) => (
                          <CustomText
                            key={childIndex}
                            font="medium"
                            size={13}
                            color={selectedChild === child ? 'secondary' : 1}
                            className={`cursor-pointer mb-3 ${
                              styles.left_text
                            }${
                              selectedChild === child
                                ? ` ${styles.selected}`
                                : ''
                            }`}
                            onClick={() => onClickDecider(index + 1, child)}
                          >
                            {child}
                            <br />
                          </CustomText>
                        ))}
                      </div>
                    )} */}
                </div>
              ))}
            </Space>
          </Col>
          <Col xs={24} sm={18}>
            <div className={styles.right_container}>{allContent()}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default FactorsModules;
