import React from 'react';
import { Image, Space } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import styles from './partners2.module.less';
import { sizeChecker } from 'utilities/helpers';
import partners2 from '../../images/partners2.svg';
import { history } from '@/app/history';
import LocalStorage from 'utilities/localStorage';

const Partners2 = () => {
  const isMobile = sizeChecker('xs');

  const handleReDirect = () => {
    history.push(`/knowledgeHub`);
    LocalStorage.setItem('knowledgeHub_redirect', JSON.stringify('true'));
  };

  return (
    <CustomContainer
      id="partners2"
      title="Partners2"
      containerClassname={styles.container}
    >
      <Space direction="vertical" size={32} align="center" className="w-100">
        <CustomText
          font="bold"
          size={isMobile ? 26 : 36}
          //color={10}
          className={styles.title}
        >
          <a onClick={() => handleReDirect()} className={styles.titleHover}>
            {' '}
            What Happens on April 01, 2024?
          </a>
        </CustomText>

        <div className={styles.image_container}>
          <a onClick={() => handleReDirect()}>
            <Image
              className={`${styles.image_blur}`}
              alt="partners"
              src={partners2}
              preview={false}
              // onClick={() => history.push('/knowledgeHub')}
            />
          </a>
        </div>
      </Space>
    </CustomContainer>
  );
};

export default Partners2;
{
  /* <CustomContainer
      id="partners"
      title="Partners"
      containerClassname={styles.container}
    >
      <Space direction="vertical" size={32} align="center" className="w-100">
        <CustomText font="medium" size={isMobile ? 20 : 36} color={'primary'}>
          Our Implementation Partners
        </CustomText>
      </Space>
      <Space
        style={{ marginTop: '30px' }}
        direction={isMobile ? 'vertical' : 'horizontal'}
        size={isMobile ? 20 : 120}
        className="w-100 justify-content-center"
      >
        {partners.map((item) => {
          const { id, image, alt, label } = item;

          return (
            <Space
              key={id}
              direction="vertical"
              size={20}
              align="center"
              className="w-100"
            >
              <div
                className={
                  id === 2 ? styles.image_container_sap : styles.image_container
                }
              >
                <Image alt={alt} src={image} preview={false} />
              </div>
              <CustomText font="medium" size={isMobile ? 16 : 20}>
                {label}
              </CustomText>
            </Space>
          );
        })}
      </Space>
    </CustomContainer> */
}
