import { useEffect, useState } from 'react';
import IdeasPage from './components/IdeasPage';
import Layout from 'common/CustomLayout';
import {
  getCategoryList,
  getIdeas,
  postDislikeIdea,
  postLikeIdea
} from './redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { TabTitle } from 'utilities/helpers';

const Ideas = () => {
  TabTitle('Neev - Queries & Suggestions');
  const dispatch = useDispatch();

  const [category_id_State, setCategory_id_State] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);

  const { ideas, getIdeasLoading, categories, pagination } = useSelector(
    (state) => state.ideas
  );

  useEffect(() => {
    handleGetCategories();
  }, []);
  useEffect(() => {
    handleGetIdeas();
  }, [page, pageSize]);

  const handleGetIdeas = () => {
    const payload = {
      page: page,
      per_page: pageSize,
      type: category_id_State ? category_id_State : ''
    };

    dispatch(getIdeas(payload));
  };

  console.log('category_id_State', category_id_State);
  const handleGetIdeasByCategoryId = (category_id) => {
    console.log('category_id_State1111', category_id_State);
    const payload = {
      page: 1,
      per_page: 100
    };

    if (category_id) payload['type'] = category_id;

    dispatch(getIdeas(payload));
  };

  const handleGetCategories = () => {
    dispatch(getCategoryList());
  };

  const handlePostLikeIdea = (id) => {
    dispatch(postLikeIdea(id)).then(({ payload }) => {
      if (payload.success) {
        //const payload = { page: 1, per_page: 100 };
        //dispatch(getIdeas(payload));
        handleGetIdeas();
      }
    });
  };

  const handlePostDislikeIdea = (id) => {
    dispatch(postDislikeIdea(id)).then(({ payload }) => {
      if (payload.success) {
        // const payload = { page: 1, per_page: 100 };
        //dispatch(getIdeas(payload));
        handleGetIdeas();
      }
    });
  };

  return (
    <Layout>
      <IdeasPage
        ideas={ideas}
        getIdeasLoading={getIdeasLoading}
        categories={categories}
        handlePostLikeIdea={handlePostLikeIdea}
        handlePostDislikeIdea={handlePostDislikeIdea}
        handleGetIdeasByCategoryId={handleGetIdeasByCategoryId}
        setCategory_id_State={setCategory_id_State}
        setPage={setPage}
        setPageSize={setPageSize}
        page={page}
        pageSize={pageSize}
        pagination={pagination}
      />
    </Layout>
  );
};

export default Ideas;
