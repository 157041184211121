import React from 'react';
import { Image, Space, Collapse } from 'antd';
import styles from './paper.module.less';
import CustomText from '../CustomText';

const { Panel } = Collapse;

const Paper = ({ gap = 0, data = [], children = null }) => {
  const customExpandIcon = ({ isActive }) => {
    console.log('ISActive', isActive);
    return isActive ? (
      <Image
        className={styles.expand_icon}
        src={'images/minus-circle.svg'}
        preview={false}
      />
    ) : (
      <Image
        className={styles.expand_icon}
        src={'images/plus-circle.svg'}
        preview={false}
      />
    );
  };

  return (
    <Space direction="vertical" size={gap} className="w-100">
      <Collapse className={styles.collapse} expandIcon={customExpandIcon} ghost>
        {data.map((item, index) => {
          console.log('item', item);
          const { text, description } = item;

          return (
            <Panel
              className={styles.collapse_header}
              header={
                <CustomText font="bold" size={16}>
                  {text}
                </CustomText>
              }
              key={index}
            >
              <div style={{ marginTop: '-15px' }} className="ml-4">
                {description && (
                  <CustomText size={14.5}>{description}</CustomText>
                )}
              </div>
            </Panel>
          );
        })}
      </Collapse>

      {children}
    </Space>
  );
};

export default Paper;
