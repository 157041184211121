import React, { useEffect } from 'react';
//import ReactGA from 'react-ga';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { history } from './app/history';
import Home from 'features/home';
import Quiz from 'features/quiz';
import HoF from 'features/hof';
import KnowledgeHub from 'features/knowledge_hub';
//import FeelTheChange from 'features/feel_the_change';
import ChangeEnablement from 'features/change_enablement';
import Team from 'features/team';
import Ideas from 'features/ideas';
import Idea from 'features/ideas/components/idea';
import IdeaCreate from 'features/ideas/components/idea-create';
import Login from './features/login';
import { getLoggedInUser } from 'utilities/helpers';
import Quizzes from 'features/quizzes';
import QuizeInstruction from 'features/quizzes/components/quizeInstruction';
import QuizScore from 'features/quizzes/components/quizScore';
import AdminQuizzes from 'features/adminQuizzes';
import Quiz_detailsView from 'features/adminQuizzes/components/quiz_detailsView';
import CreateQuiz from './features/adminQuizzes/components/createQuiz';
import EditQuiz from './features/adminQuizzes/components/editQuiz';
import QuizScoreDetails from './features/adminQuizzes/components/quizScoreDetails';
import AdminConsole from 'features/adminConsole';
import ManageAnnouncement from 'features/adminConsole/components/manageAnnouncement';
import CreateUpdateAnnouncement from '@/features/adminConsole/components/manageAnnouncement/CreateUpdateAnnouncement';
import ManageEventsMilestones from 'features/adminConsole/components/manageEventsMilestones';
import CreateUpdateEventsMilestones from '@/features/adminConsole/components/manageEventsMilestones/CreateUpdateEventsMilestones';
import ManagePoll from 'features/adminConsole/components/managePoll';
import CreateUpdatePoll from '@/features/adminConsole/components/managePoll/CreateUpdatePoll';
import ExportQueriesSuggestion from 'features/adminConsole/components/ExportQueriesSuggestion';
import ExportFootfall from 'features/adminConsole/components/ExportFootfall';
import Statistics from 'features/adminConsole/components/Statistics';
import Logins_statistics from '@/features/adminConsole/components/Statistics/Logins_statistics';
import Quizzes_statistics from '@/features/adminConsole/components/Statistics/Quizzes_statistics';
import Polls_statistics from '@/features/adminConsole/components/Statistics/Polls_statistics';
import ReactGA from 'react-ga4';
//import { initGA, logPageView, historyListener } from './analytics';
import QuizScoreAnswers from 'features/quizzes/components/quizScoreAnswers';
import { redirectURL } from 'utilities/helpers';
import ConcurSAC from './features/ConcurSAC';
import Hxm from './features/hxm';

const initGA = () => {
  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);
  // console.log('TRACKING_ID', TRACKING_ID);
};

const logPageView = () => {
  const pageTitle = redirectURL(history.location.pathname);
  ReactGA.send({
    hitType: 'pageview',
    page: history.location.pathname,
    title: pageTitle
  });
  //console.log('logPageView', pageTitle);
};

const historyListener = () => {
  history.listen(() => {
    logPageView();
  });
  //console.log('historyListener');
};

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_URL
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};

const pca = new PublicClientApplication(configuration);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    console.log('Login success event:', event);
    // Track user login event
    ReactGA.event({
      category: 'User',
      action: 'Login'
    });

    pca.setActiveAccount(event.payload.account);
  }
});

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return (
      <MsalProvider instance={pca}>
        <Spin spinning={loading}>{children}</Spin>
      </MsalProvider>
    );
  }

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <MsalProvider instance={pca}>{children}</MsalProvider>;
};

const AuthChecker = ({ children }) => {
  let location = useLocation();

  const { loggedIn } = getLoggedInUser();

  let goTo = '/home';

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return <MsalProvider instance={pca}>{children}</MsalProvider>;
};

function App() {
  let goTo = '/home';

  useEffect(() => {
    initGA();
    logPageView();
    historyListener();
  }, []);

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <Login />
            </AuthChecker>
          }
        />
        <Route
          path="home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="ideas/list"
          element={
            <PrivateRoute>
              <Ideas />
            </PrivateRoute>
          }
        />
        <Route
          path="ideas/:id"
          element={
            <PrivateRoute>
              <Idea />
            </PrivateRoute>
          }
        />
        <Route
          path="ideas/create/:id"
          element={
            <PrivateRoute>
              <IdeaCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="quiz"
          element={
            <PrivateRoute>
              <Quiz />
            </PrivateRoute>
          }
        />
        <Route
          path="hallOfFame"
          element={
            <PrivateRoute>
              <HoF />
            </PrivateRoute>
          }
        />
        <Route
          path="knowledgeHub"
          element={
            <PrivateRoute>
              <KnowledgeHub />
            </PrivateRoute>
          }
        />
        <Route
          path="change-enablement"
          element={
            <PrivateRoute>
              <ChangeEnablement />
            </PrivateRoute>
          }
        />
        <Route
          path="leaders-speak"
          element={
            <PrivateRoute>
              <ChangeEnablement props={'leadersSpeak'} />
            </PrivateRoute>
          }
        />

        <Route
          path="team"
          element={
            <PrivateRoute>
              <Team />
            </PrivateRoute>
          }
        />
        <Route
          path="quizzes"
          element={
            <PrivateRoute>
              <Quizzes />
            </PrivateRoute>
          }
        />
        <Route
          path="quize-instruction/:id"
          element={
            <PrivateRoute>
              <QuizeInstruction />
            </PrivateRoute>
          }
        />
        <Route
          path="quiz-score/:id"
          element={
            <PrivateRoute>
              <QuizScore />
            </PrivateRoute>
          }
        />
        <Route
          path="quiz-score-answers/:id"
          element={
            <PrivateRoute>
              <QuizScoreAnswers />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-quizzes"
          element={
            <PrivateRoute>
              <AdminQuizzes />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-quizdetails/:id"
          element={
            <PrivateRoute>
              <Quiz_detailsView />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-create_quiz"
          element={
            <PrivateRoute>
              <CreateQuiz />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-edit_quiz/:id"
          element={
            <PrivateRoute>
              <EditQuiz />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-quiz-score-details/:id/:user_id"
          element={
            <PrivateRoute>
              <QuizScoreDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-console"
          element={
            <PrivateRoute>
              <AdminConsole />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-manage-announcement"
          element={
            <PrivateRoute>
              <ManageAnnouncement />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-create_announcement"
          element={
            <PrivateRoute>
              <CreateUpdateAnnouncement />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-create_announcement/:id"
          element={
            <PrivateRoute>
              <CreateUpdateAnnouncement />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-manage-events-milestones"
          element={
            <PrivateRoute>
              <ManageEventsMilestones />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-create_events_milestones"
          element={
            <PrivateRoute>
              <CreateUpdateEventsMilestones />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-create_events_milestones/:id"
          element={
            <PrivateRoute>
              <CreateUpdateEventsMilestones />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-manage-poll"
          element={
            <PrivateRoute>
              <ManagePoll />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-create_poll"
          element={
            <PrivateRoute>
              <CreateUpdatePoll />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-create_poll/:id"
          element={
            <PrivateRoute>
              <CreateUpdatePoll />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-export-queries-suggestion"
          element={
            <PrivateRoute>
              <ExportQueriesSuggestion />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-export-footfall"
          element={
            <PrivateRoute>
              <ExportFootfall />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-statistics"
          element={
            <PrivateRoute>
              <Statistics />
            </PrivateRoute>
          }
        />

        <Route
          path="admin-logins-statistics"
          element={
            <PrivateRoute>
              <Logins_statistics />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-quizzes-statistics"
          element={
            <PrivateRoute>
              <Quizzes_statistics />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-polls-statistics"
          element={
            <PrivateRoute>
              <Polls_statistics />
            </PrivateRoute>
          }
        />
        <Route
          path="sap-erp"
          element={
            <PrivateRoute>
              <ConcurSAC />
            </PrivateRoute>
          }
        />
        <Route path="hxm" element={<Hxm />} />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
