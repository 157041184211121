import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_poll, postCreateUpdatePoll } from '../../redux/slice';
import styles from './index.module.less';
import { Space, Spin, Popconfirm, Pagination } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import ExportPoll from './ExportPoll';

const ManagePollPage = () => {
  const dispatch = useDispatch();
  const { pollData, get_pollLoading, postCreateUpdatePollLoading, pagination } =
    useSelector((state) => state.adminConsole);

  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [isModalReport, setIsModalReport] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  console.log('pollData', pollData);

  const handleGetRequests = () => {
    const payload = {
      page: page,
      per_page: pageSize
    };
    dispatch(get_poll(payload));
  };

  useEffect(() => {
    handleGetRequests();
  }, [page, pageSize]);

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  const handleDeleteRequest = (quizId) => {
    const payload = { id: quizId?.id, is_active: false };
    // console.log('payload', payload);
    dispatch(postCreateUpdatePoll(payload)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };

  return (
    <CustomContainer title="Contact" containerClassname={styles.container}>
      <Spin spinning={get_pollLoading || postCreateUpdatePollLoading}>
        <Space direction="vertical" size={14} style={{ width: '100%' }}>
          <Space
            direction="horizontal"
            size={6}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '10px'
            }}
          >
            <CustomButton
              className={styles.back_btn_style}
              onClick={() => history.push(`/admin-console`)}
            >
              Back
            </CustomButton>
            <CustomButton
              type="primary"
              htmlType="submit"
              className={styles.post_btn_style}
              onClick={() => setIsModalReport(true)}
            >
              Export Poll
            </CustomButton>
            <CustomButton
              type="primary"
              htmlType="submit"
              className={styles.post_btn_style}
              onClick={() => history.push(`/admin-create_poll`)}
            >
              Create Poll
            </CustomButton>
          </Space>

          {pollData?.map((item) => (
            <div className={styles.left_card_container} key={item?.id}>
              <Space
                direction="horizontal"
                size={2}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <CustomText className={styles.left_card_title}>
                  {item?.poll_question}
                </CustomText>
                <div className={styles.edit_container}>
                  <EditOutlined
                    onClick={() =>
                      history.push(`/admin-create_poll/${item?.id}`)
                    }
                    className={`${styles.edit_icon_style}`}
                  />

                  <Popconfirm
                    title="Are you sure do you want to delete this poll?"
                    open={visibility(item?.id)}
                    onConfirm={() => {
                      handleDeleteRequest({
                        id: item?.id
                      });
                    }}
                    onCancel={() => {
                      setShowDisablePopup({});
                    }}
                    okText="Delete"
                    cancelText="No"
                    okButtonProps={{ type: 'danger' }}
                  >
                    <a>
                      <DeleteOutlined
                        className={`${styles.delete_icon_style}`}
                        onClick={() => {
                          setShowDisablePopup({ [item?.id]: true });
                        }}
                      />
                    </a>
                  </Popconfirm>
                </div>
              </Space>
            </div>
          ))}
          <Pagination
            size="small"
            total={pagination?.total_records}
            current={page}
            pageSize={pageSize}
            showSizeChanger={true}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            style={{ marginTop: '10px', textAlign: 'center' }}
            className="text-right"
          />
        </Space>
        {isModalReport ? (
          <ExportPoll
            isModalReport={isModalReport}
            setIsModalReport={setIsModalReport}
          />
        ) : null}
      </Spin>
    </CustomContainer>
  );
};

export default ManagePollPage;
