import HoFPage from './components/HoFPage';
import Layout from 'common/CustomLayout';
import { TabTitle } from 'utilities/helpers';

const HoF = () => {
  TabTitle('Neev - Hall of Fame');
  return (
    <Layout>
      <HoFPage />
    </Layout>
  );
};

export default HoF;
