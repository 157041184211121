import apiClient from 'utilities/apiClient';

export const forAll = (role, type, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/${type}`,
    payload,
    true
  );
};
export const adminEvents = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/get_events`,
    payload,
    true
  );
};

export const adminMilestones = (role, payloadMilestones) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/get_events`,
    payloadMilestones,
    true
  );
};
export const adminQuizStats = (payload) => {
  return apiClient.get(apiClient.Urls.admin_quizStats, payload, true);
};
export const adminPollStats = (payload) => {
  return apiClient.get(apiClient.Urls.admin_pollStats, payload, true);
};
export const adminLoginStats = (payload) => {
  return apiClient.get(apiClient.Urls.admin_loginStats, payload, true);
};

export const adminAnnouncements = (payload) => {
  return apiClient.get(apiClient.Urls.adminAnnouncements, payload, true);
};

export const post_update_announcement = (payload) => {
  return apiClient.post(
    apiClient.Urls.update_announcement,
    payload,
    true
    //'file'
  );
};

// export const adminEvents = (payload) => {
//   return apiClient.get(apiClient.Urls.adminEvents, payload, true);
// };
export const post_update_events = (payload) => {
  return apiClient.post(
    apiClient.Urls.update_events,
    payload,
    true
    //'file'
  );
};
export const adminPoll = (payload) => {
  return apiClient.get(apiClient.Urls.adminPoll, payload, true);
};
export const post_update_poll = (payload) => {
  return apiClient.post(
    apiClient.Urls.update_poll,
    payload,
    true
    //'file'
  );
};
