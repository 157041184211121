import { useEffect } from 'react';
import { Col, Image, Row, Space } from 'antd';
import styles from './team.module.less';
import message from '../../images/message-chat-circle.svg';
import marker from '../../images/marker-pin.svg';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import { sizeChecker } from 'utilities/helpers';
import one from '../../images/1AnimayKumar.jpg';
import two from '../../images/2AnkushKak.jpg';
//import three from '../../images/3AmrutaLokhande.png';
import four from '../../images/4NehaKhanna.png';
import Madhur from '../../images/Madhur.png';
import Namrata from '../../images/Namrata Chahal.png';
import Brijendra from '../../images/Brijendra.png';

import five from '../../images/5RajendraInani.jpg';
import six from '../../images/6naga.jpg';
import seven from '../../images/7Hiren.jpg';
import eight from '../../images/8BharathKumarV.jpg';
import nine from '../../images/9DeepakSahani.jpg';
import ten from '../../images/10Manish.jpg';
import f_one from '../../images/F1PradeepKonda.jpg';
import f_two from '../../images/Jeyakumar.jpg';
import f_three from '../../images/F3naveen.jpg';
import f_four from '../../images/F4PrasadKulkarni.jpg';
import f_five from '../../images/F5Biswajit.jpeg';
import f_six from '../../images/F6ManishDaftari.jpg';
import f_seven from '../../images/F7AnkushKakCopy.jpg';
import f_eight from '../../images/F8SobhaMenon.jpg';
import c_one from '../../images/C1NehaKhannaCopy.png';
import c_two from '../../images/C2Sabreena.jpg';
import c_three from '../../images/C3PriyankaSatani.jpg';
import c_four from '../../images/C4TanviBalekundri.jpg';
import Amruta from '../../../hof/images/Oct/Amruta Lokhande.png';
import NaveenSharma from '../../images/Naveen Sharma.png.jpg';
import ZamirQadri from '../../images/Zamir Qadri.jpg';

const data = [
  {
    id: 1,
    title: 'Meet the PMO Team',
    team: [
      {
        id: 11,
        image: one,
        name: 'Animay Kumar',
        designation: `(SAP & Project Management)`,
        email: 'animaykumar@tataprojects.com'
      },
      {
        id: 12,
        image: Amruta,
        name: `Amruta Lokhande `,
        designation: '(Source to Pay)',
        email: 'amrutal@tataprojects.com'
      },
      {
        id: 13,
        image: two,
        name: `Ankush Kak `,
        designation: `(GRC and Master  Data Governance)`,
        email: 'ankushkak@tataprojects.com'
      },

      {
        id: 14,
        image: Madhur,
        name: `Madhur Maheshwari `,
        designation: '(EAM and Production Planning)',
        email: 'madhur@tataprojects.com'
      },
      {
        id: 15,
        image: Namrata,
        name: `Namrata Chahal `,
        designation: '(Treasury, Concur, EY & Taxation)',
        email: 'namratachahal-c@tataprojects.com'
      },
      {
        id: 16,
        image: four,
        name: 'Neha Khanna',
        designation: '(Organizational Change Management)',
        email: 'nehak@tataprojects.com'
      },
      {
        id: 17,
        image: ZamirQadri,
        name: `Zamir Qadri `,
        designation: '(Application Lead)',
        email: ' zamirahmedq@tataprojects.com'
      },
      {
        id: 18,
        image: f_three,
        name: 'Naveen Kumar Vaddaddi  ',
        designation: '(Integration & Interfaces)',
        email: 'naveenvaddadi@tataprojects.com'
      },
      {
        id: 19,
        image: NaveenSharma,
        name: `Naveen Sharma`,
        designation: '(Integration & Interfaces)',
        email: 'naveenrsharma@tataprojects.com'
      },
      {
        id: 20,
        image: Brijendra,
        name: `Brijendra Kumar Bharti `,
        designation: '(Project Planning & Control)',
        email: 'brijendrakumarbhartib@tataprojects.com'
      }
    ]
  },
  {
    id: 2,
    title: 'Project Systems POCs',
    team: [
      {
        id: 21,
        image: five,
        name: 'Rajendra Inani  ',
        designation: '(B&I)',
        email: 'rajendrai@tataprojects.com'
      },
      {
        id: 22,
        image: six,
        name: 'Nagalatchoumy Reddy D  ',
        designation: '(E&I)',
        email: 'naglaxmi@tataprojects.com'
      },
      {
        id: 23,
        image: seven,
        name: 'Hiren Heliya ',
        designation: '(E&I)',
        email: 'hirenh@tataprojects.com'
      },
      {
        id: 24,
        image: eight,
        name: 'Bharat Kumar V ',
        designation: '(B&I)',
        email: 'vbharathkumar@tataprojects.com'
      },
      {
        id: 25,
        image: nine,
        name: 'Deepak Sahani ',
        designation: '(Corporate Project Controls)',
        email: 'deepakshahani@tataprojects.com'
      },
      {
        id: 26,
        image: ten,
        name: 'Manish Tekriwal ',
        designation: '(Corporate F&A)',
        email: 'manishtekriwal@tataprojects.com'
      }
    ]
  },
  {
    id: 3,
    title: 'Functional POCs',
    team: [
      {
        id: 31,
        image: f_one,
        name: 'Pradeep Kumar Konda  ',
        designation: '(Finance)',
        email: 'pradeepkumark@tataprojects.com'
      },
      {
        id: 32,
        image: f_two,
        name: 'Jeyakumar Muthukrishnan  ',
        designation: '(Fleet Management)',
        email: 'jeyakumarm@tataprojects.com'
      },
      {
        id: 33,
        image: f_three,
        name: 'Naveen Kumar Vaddaddi  ',
        designation: '(Integration & Interfaces)',
        email: 'naveenvaddadi@tataprojects.com'
      },
      {
        id: 34,
        image: f_four,
        name: 'Prasad Kulkarni  ',
        designation: '(Procure-to-Pay)',
        email: 'prasadkulkarni@tataprojects.com'
      },
      {
        id: 35,
        image: f_five,
        name: 'Biswajit Sinha  ',
        designation: '(Production Planning)',
        email: 'biswajitsinha@tataprojects.com'
      },
      {
        id: 36,
        image: f_six,
        name: 'Manish Daftari  ',
        designation: '(SAC Planning)',
        email: 'manishd@tataprojects.com'
      },
      {
        id: 37,
        image: f_seven,
        name: 'Ankush Kak  ',
        designation: '(Data Migration)',
        email: 'ankushkak@tataprojects.com'
      },
      {
        id: 38,
        image: f_eight,
        name: 'Sobha Menon  ',
        designation: '(Data Migration)',
        email: 'sobham@tataprojects.com'
      }
    ]
  },
  {
    id: 4,
    title: 'Change Management POCs',
    team: [
      {
        id: 41,
        image: c_one,
        name: 'Neha Khanna ',
        designation: '(Lead Organizational Change Management)',
        email: 'nehak@tataprojects.com'
      },
      {
        id: 42,
        image: c_two,
        name: 'Sabreena Shah ',
        designation: '(Communications and Branding)',
        email: 'sabreenas@tataprojects.com'
      },
      {
        id: 43,
        image: c_three,
        name: 'Priyanka Satani ',
        designation: '(Learning and Development)',
        email: 'priyankas@tataprojects.com'
      },
      {
        id: 44,
        image: c_four,
        name: 'Tanvi Balakundri',
        designation: `(Communications, CIA and Analytics)`,
        email: 'tanvib@tataprojects.com'
      }
    ]
  }
];

const TeamPage = () => {
  const isMobile = sizeChecker('xs');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CustomContainer title="Contact" containerClassname={styles.container}>
      <Row gutter={[32, 25]} align="top">
        <Col xs={24} sm={16}>
          <Space direction="vertical" size={20}>
            {data.map((item) => {
              const { id, title, team } = item;

              return (
                <div key={id} className={styles.left_card_container}>
                  <Space direction="vertical" size={20} className="w-100">
                    <div className={styles.title_container}>
                      <CustomText
                        font="medium"
                        color="primary"
                        size={isMobile ? 20 : 23}
                        letterSpacing={isMobile ? -0.4 : -0.52}
                      >
                        {title}
                      </CustomText>
                    </div>
                    <Row gutter={[32, 32]}>
                      {team.map((o) => {
                        const { id, image, name, email, designation } = o;

                        return (
                          <Col
                            key={id}
                            xs={24}
                            sm={8}
                            className={styles.card_container}
                          >
                            <Space
                              direction="vertical"
                              size={10}
                              align="center"
                            >
                              <div className={styles.name_container}>
                                <Image
                                  alt={`${name}_${id}`}
                                  src={image}
                                  preview={false}
                                  width="100%"
                                />
                                {/*  <CustomText
                                  font="bold"
                                  color="primary"
                                  size={20}
                                >
                                  {name
                                    .split(' ')
                                    .slice(0, 2)
                                    .map((o) => o.slice(0, 1))
                                    .join('')}
                                </CustomText> */}
                              </div>
                              <Space
                                direction="vertical"
                                size={0}
                                align="center"
                              >
                                <CustomText
                                  font="medium"
                                  color="#101828"
                                  size={12}
                                  className="d-block text-center text-capitalize"
                                >
                                  {name}
                                </CustomText>
                                <CustomText
                                  font="medium"
                                  color="#101828"
                                  size={12}
                                  className="d-block text-center text-capitalize"
                                >
                                  {designation}
                                </CustomText>
                                <a
                                  href="https://login.live.com/"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: '#2973c6',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    fontWeight: 500
                                  }}
                                >
                                  {email}
                                </a>
                                {/*  <CustomText
                                  font="medium"
                                  color="#2364AD"
                                  size={12}
                                  className="d-block text-center"
                                >
                                  {email}
                                </CustomText> */}
                              </Space>
                            </Space>
                          </Col>
                        );
                      })}
                    </Row>
                  </Space>
                </div>
              );
            })}
          </Space>
        </Col>
        <Col xs={24} sm={8}>
          <div className={styles.right_card_container}>
            <Space direction="vertical" size={10}>
              <CustomText font="medium" color="white" size={22}>
                Connect with us!
              </CustomText>
              <Space direction="vertical" size={32}>
                <CustomText color="white" size={16}>
                  {`We would love to hear from you.
                   Our friendly team is here to support.`}
                </CustomText>
                <Space size={16} align="start">
                  <div className={styles.image_container}>
                    <Image alt="message" src={message} preview={false} />
                  </div>
                  <Space direction="vertical" size={0}>
                    <CustomText font="medium" color="white" size={18}>
                      Reach out to us
                    </CustomText>
                    {/*  <CustomText color="white" size={16}>
                      You can reach out to us at
                    </CustomText> */}
                    <CustomText color="white" font="regular" size={16}>
                      projectneev@tataprojects.com
                    </CustomText>
                  </Space>
                </Space>
                <Space size={14} align="start">
                  <div className={styles.image_container}>
                    <Image alt="marker" src={marker} preview={false} />
                  </div>
                  <Space direction="vertical" size={8}>
                    <CustomText font="medium" color="white" size={18}>
                      Drop by our office
                    </CustomText>
                    {/* <CustomText color="white" size={16}>
                      Catch up with us at our office
                    </CustomText> */}
                    <CustomText color="white" font="regular" size={16}>
                      {`5th Floor, Delphi Building, B-Wing, Hiranandani Gardens, Powai, Mumbai - 400076`}
                    </CustomText>
                  </Space>
                </Space>
              </Space>
            </Space>
          </div>
        </Col>
      </Row>
    </CustomContainer>
  );
};

export default TeamPage;
