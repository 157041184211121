import React from 'react';
import { Input, Space, Row, Col, Radio } from 'antd';
import styles from './quiz.module.less';
import FormItem from 'common/FormItem';
import CustomButton from '@/common/CustomButton';
import { DeleteOutlined } from '@ant-design/icons';

const QuizQuestions = ({ value, setValue, questions, setQuestions }) => {
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionSelect = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.forEach((option, index) => {
      option.selected = index === optionIndex;
    });
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex].value = value;
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const newOptionLabel = String.fromCharCode(
      65 + questions[questionIndex].options.length
    );
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.push({
      label: newOptionLabel,
      value: ''
    });
    setQuestions(updatedQuestions);
  };

  const handleDeleteOption = (questionIndex, optionIndex) => {
    console.log('questionIndex!!!:', questionIndex);
    console.log('optionIndex!!!:', optionIndex);
    console.log('questions!!!:', questions);
    if (optionIndex !== 0) {
      const updatedQuestions = [...questions];
      console.log('updatedQuestions!!!:', updatedQuestions);
      //updatedQuestions[questionIndex].options.splice(optionIndex, 1);
      updatedQuestions[questionIndex].options[optionIndex].is_active = false;
      console.log('updatedQuestions2222:', updatedQuestions);
      setQuestions(updatedQuestions);
    }
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      question: '',
      options: [{ label: 'A', value: '', selected: false }]
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleDeleteQuestion = (questionIndex) => {
    const updatedQuestions = [...questions];
    // updatedQuestions.splice(questionIndex, 1);
    updatedQuestions[questionIndex].is_active = false;
    setQuestions(updatedQuestions);
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        border: '1px solid #e5e5e5',
        // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        marginBottom: '20px'
      }}
    >
      {/* <form onSubmit={''}> */}
      {questions
        ?.filter((ques) => ques.is_active || ques.is_active === undefined)
        ?.map((question, questionIndex) => (
          <div key={questionIndex}>
            <FormItem
              label="Enter the Question"
              // name="quizQuestion"
              rules={[
                {
                  required: false,
                  message: 'Please enter the question!'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                className={styles.input_container_style}
                type="text"
                value={question.question}
                onChange={(event) =>
                  handleQuestionChange(questionIndex, event.target.value)
                }
              />
              {questionIndex !== 0 ? (
                <DeleteOutlined
                  style={{ color: 'red', marginLeft: '5px' }}
                  onClick={() => handleDeleteQuestion(questionIndex)}
                />
              ) : (
                ''
              )}
            </FormItem>
            <Row gutter={[8, 8]}>
              {question.options
                ?.filter(
                  (option) => option.is_active || option.is_active === undefined
                )
                ?.map((option, optionIndex) => (
                  <Col span={8} key={optionIndex}>
                    <Space.Compact key={optionIndex}>
                      <FormItem
                        label="Enter Options"
                        name="quizOptions"
                        rules={[
                          {
                            required: false,
                            message: 'Please enter the question!'
                          }
                        ]}
                        // className={`${styles.input_styles}`}
                      >
                        <Radio
                          className={
                            option.selected ? `${styles.green_radio}` : ''
                          }
                          onChange={() =>
                            handleOptionSelect(questionIndex, optionIndex)
                          }
                          checked={option.selected}
                        />
                        <Input
                          size="small"
                          className={styles.input_option1}
                          style={{
                            width: '20%',
                            color: '#8F95A3',
                            fontWeight: 'bold'
                          }}
                          defaultValue={option.label}
                          disabled
                        />
                        <Input
                          className={styles.input_option2}
                          style={{ width: '65%' }}
                          value={option.value}
                          onChange={(event) =>
                            handleOptionChange(
                              questionIndex,
                              optionIndex,
                              event.target.value
                            )
                          }
                        />
                        {optionIndex !== 0 ? (
                          <DeleteOutlined
                            style={{ color: 'red', marginLeft: '5px' }}
                            onClick={() =>
                              handleDeleteOption(questionIndex, optionIndex)
                            }
                          />
                        ) : (
                          ''
                        )}
                      </FormItem>
                    </Space.Compact>
                  </Col>
                ))}
              <FormItem className={styles.addOption_btn_style}>
                <CustomButton
                  className={styles.addOption_btn}
                  type="button"
                  onClick={() => handleAddOption(questionIndex)}
                >
                  Add Option
                </CustomButton>
              </FormItem>
            </Row>
          </div>
        ))}

      <FormItem>
        <CustomButton
          type="button"
          onClick={handleAddQuestion}
          className={styles.addOption_btn}
        >
          Add Question
        </CustomButton>
      </FormItem>
      <Col span={24}>
        <FormItem
          label="Is certificate Enabled?"
          name="is_allowed_certificate"
          rules={[
            {
              required: false,
              message: 'Please check'
            }
          ]}
        >
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={'Yes'}>Yes</Radio>
            <Radio value={'No'}>No</Radio>
          </Radio.Group>
        </FormItem>
      </Col>
    </Space>
  );
};

export default QuizQuestions;
