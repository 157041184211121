import React from 'react';
import { Space, Image } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import styles from './proposedPlan.module.less';
import table1 from '../../images/proposedPlan/table1.svg';

function ProposedPlan() {
  return (
    <CustomContainer
      id="factorsState"
      title="FactorsState"
      containerClassname={styles.container}
    >
      <Space
        direction="vertical"
        size={30}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div className="df-jc-ac">
          <Image alt="Digital Core" src={table1} preview={false} />
        </div>
      </Space>
    </CustomContainer>
  );
}
export default ProposedPlan;
