import React from 'react';
import Layout from 'common/CustomLayout';
import ManagePollPage from './ManagePollPage';
const ManagePoll = () => {
  return (
    <Layout>
      <ManagePollPage />
    </Layout>
  );
};

export default ManagePoll;
