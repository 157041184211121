import { useEffect } from 'react';
import Banners from '../Banners';
import About from '../About';
import Functions from '../Functions';
import Benefits from '../Benefits';
import DigitalCore from '../DigitalCore';
import Timeline from '../Timeline';
import Partners from '../Partners';
//import KeyBenefits from '../KeyBenefits';
import AnchorButtons from '../AnchorButtons';
import Partners2 from '../Partners2';

const HomePage = ({
  pollsData,
  pollsVoteSubmitHandler,
  postPollsResponseLoading,
  pollsResultData,
  getPollsResultLoading
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banners />
      <About
        pollsData={pollsData}
        pollsVoteSubmitHandler={pollsVoteSubmitHandler}
        postPollsResponseLoading={postPollsResponseLoading}
        pollsResultData={pollsResultData}
        getPollsResultLoading={getPollsResultLoading}
      />
      <Functions />
      <Benefits />
      {/* <KeyBenefits /> */}
      <DigitalCore />
      <Timeline />
      <Partners2 />
      <Partners />
      <AnchorButtons />
    </>
  );
};

export default HomePage;
