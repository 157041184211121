import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { quizzes, saveQuizResponse, quizScore, quizScoreAnswers } from './api';
//import { history } from '@/app/history';

const initialState = {
  get_quizzesLoading: false,
  get_quiz_user_scoreLoading: false,
  postQuizResponseLoading: false,
  get_quiz_score_answersLoading: false,
  quizzesData: [],
  quiz_user_score: [],
  quiz_score_answers: [],
  user_details: [],
  pagination: null
};

const actions = {
  // GET_POLLS: 'get_quizze/GET_POLLS',
  GET_QUIZ_USER_SCORE: 'get_quiz_user_score/GET_QUIZ_USER_SCORE',
  POST_QUIZ_RESPONSE: 'postQuizResponse/POST_QUIZ_RESPONSE',
  GET_QUIZZES: 'get_quizzes/GET_QUIZZES',
  GET_QUIZ_SCORE_ANSWERS: 'get_quiz_score_answers/GET_QUIZ_SCORE_ANSWERS'
};

export const get_quizzes = createAsyncThunk(
  actions.GET_QUIZZES,
  async (payload) => {
    const response = await quizzes(payload);

    return response;
  }
);

export const get_quiz_user_score = createAsyncThunk(
  actions.GET_QUIZ_USER_SCORE,
  async (payload) => {
    const response = await quizScore(payload);

    return response;
  }
);
export const get_quiz_score_answers = createAsyncThunk(
  actions.GET_QUIZ_SCORE_ANSWERS,
  async (payload) => {
    const response = await quizScoreAnswers(payload);

    return response;
  }
);

export const postQuizResponse = createAsyncThunk(
  actions.POST_QUIZ_RESPONSE,
  async ({ payload }) => {
    const response = await saveQuizResponse(payload);
    return response;
  }
);

export const quizzesSlice = createSlice({
  name: 'quizzes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(get_quizzes.pending, (state) => {
        state.get_quizzesLoading = true;
      })
      .addCase(get_quizzes.fulfilled, (state, action) => {
        state.get_quizzesLoading = false;
        const { success, data, pagination, message: msg } = action.payload;

        if (success) {
          state.quizzesData = data ? data : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.quizzesData = [];
          message.error(msg);
        }
      })
      .addCase(get_quizzes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_quizzesLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_quiz_user_score.pending, (state) => {
        state.get_quiz_user_scoreLoading = true;
      })
      .addCase(get_quiz_user_score.fulfilled, (state, action) => {
        state.get_quiz_user_scoreLoading = false;
        const { success, data, message: msg } = action.payload;

        if (success) {
          state.quiz_user_score = data ? data : [];
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.quiz_user_score = [];
          message.error(msg);
        }
      })
      .addCase(get_quiz_user_score.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_quiz_user_scoreLoading = false;

        message.error(msg);
      });
    builder
      .addCase(postQuizResponse.pending, (state) => {
        state.postQuizResponseLoading = true;
      })
      .addCase(postQuizResponse.fulfilled, (state, action) => {
        state.postQuizResponseLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postQuizResponse.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postQuizResponseLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_quiz_score_answers.pending, (state) => {
        state.get_quiz_score_answersLoading = true;
      })
      .addCase(get_quiz_score_answers.fulfilled, (state, action) => {
        state.get_quiz_score_answersLoading = false;
        const {
          success,
          data,
          user_details,
          pagination,
          message: msg
        } = action.payload;

        if (success) {
          state.quiz_score_answers = data ? data : [];
          state.user_details = user_details ? user_details : [];
          state.pagination = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.quiz_score_answers = [];
          state.user_details = [];
          message.error(msg);
        }
      })
      .addCase(get_quiz_score_answers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_quiz_score_answersLoading = false;

        message.error(msg);
      });
  }
});

export default quizzesSlice.reducer;
