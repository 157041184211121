import apiClient from 'utilities/apiClient';

export const ambassadors_lists = (payload) => {
  return apiClient.get(apiClient.Urls.ambassadors_lists, payload, true);
};
export const sponsorAndCoSponsor_lists = (payload) => {
  return apiClient.get(apiClient.Urls.sponsorAndCoSponsor_lists, payload, true);
};
export const champions_lists = (payload) => {
  return apiClient.get(apiClient.Urls.champions_lists, payload, true);
};
export const agents_lists = (payload) => {
  return apiClient.get(apiClient.Urls.agents_lists, payload, true);
};
