import React, { useState } from 'react';
import styles from './startquiz.module.less';
import { Space, Row, Radio } from 'antd';
import CustomText from '@/common/CustomText';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { get_quizzes } from 'features/quizzes/redux/slice';
import { postQuizResponse } from 'features/quizzes/redux/slice';

const { Group } = Radio;

const StartQuiz = ({ quizzesData, quizStartTime }) => {
  const questions = quizzesData?.[0]?.quizques;
  const dispatch = useDispatch();

  console.log('NEW', quizzesData);

  const { postQuizResponseLoading } = useSelector((state) => state.quizzes);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(
    Array(questions?.length).fill(null)
  );

  const handleOptionSelect = (questionIndex, optionIndex) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[questionIndex] = optionIndex;
    setSelectedOptions(updatedOptions);
  };

  const isNextDisabled = () => selectedOptions[currentQuestion] === null;

  const handleNextClick = () => {
    if (currentQuestion < questions?.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const payload = {
        quiz_id: quizzesData?.[0]?.id,
        quiz_option_id_list: selectedOptions,
        start_date: quizStartTime ? quizStartTime : null
      };
      dispatch(postQuizResponse({ payload })).then((response) => {
        if (response.payload.success) {
          history.push(`/quiz-score/${quizzesData?.[0]?.id}`);
        }
      });
      //   console.log('Submitted:', selectedOptions);
      //console.log('payload', payload);
    }
  };

  return (
    <Space direction="vertical" size={30} style={{ width: '100%' }}>
      <Row
        className={styles.row_direction1}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <CustomText font="bold" size={32} color="primary" letterSpacing={-0.72}>
          {quizzesData && quizzesData?.[0]?.quiz_cat?.category_name}
        </CustomText>
        <CustomText font="bold" size={18} color="primary" letterSpacing={-0.72}>
          {currentQuestion + 1}/{questions?.length}
        </CustomText>
      </Row>

      <CustomText
        font="bold"
        size={20}
        letterSpacing={-0.72}
        style={{ color: '#667085' }}
      >
        {questions?.[currentQuestion]?.quiz_question}
      </CustomText>
      <Group
        onChange={(e) => handleOptionSelect(currentQuestion, e.target.value)}
        value={selectedOptions[currentQuestion]}
      >
        {questions?.[currentQuestion]?.quesopt?.map((option) => (
          <Radio
            key={option.id}
            value={option.id}
            className={styles.option_text}
          >
            {option?.quiz_option}
          </Radio>
        ))}
      </Group>

      <CustomButton
        className={styles.post_btn_StartQuiz2}
        onClick={handleNextClick}
        loading={postQuizResponseLoading}
        disabled={isNextDisabled()}
      >
        {currentQuestion === questions?.length - 1 ? 'Submit' : 'Next'}
      </CustomButton>
    </Space>
  );
};

export default StartQuiz;
