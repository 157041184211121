import React from 'react';
import Layout from 'common/CustomLayout';
import ManageAnnouncementPage from './ManageAnnouncementPage';
const ManageAnnouncement = () => {
  return (
    <Layout>
      <ManageAnnouncementPage />
    </Layout>
  );
};

export default ManageAnnouncement;
