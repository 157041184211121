import ChangeEnablementPage from './components/ChangeEnablementPage';
import Layout from 'common/CustomLayout';
import { TabTitle } from 'utilities/helpers';

const ChangeEnablement = ({ props }) => {
  TabTitle('Neev - Change Enablement');
  return (
    <Layout>
      <ChangeEnablementPage props={props} />
    </Layout>
  );
};

export default ChangeEnablement;
