import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import IdeaPage from '../components/IdeaPage';
import Layout from 'common/CustomLayout';
import {
  getIdeaById,
  postDislikeIdea,
  postLikeIdea,
  postCommentIdeas
} from '../redux/slice';
import { Spin } from 'antd';

const Idea = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const { state } = useLocation();
  const { bgColor } = state;

  const { idea, getIdeaByIdLoading, postCommentIdeasLoading } = useSelector(
    (state) => state.ideas
  );

  useEffect(() => {
    handleGetIdeaById();
  }, [id]);

  const handleGetIdeaById = () => {
    dispatch(getIdeaById(id));
  };

  const handlePostLikeIdea = () => {
    dispatch(postLikeIdea(id)).then(({ payload }) => {
      if (payload.success) {
        dispatch(getIdeaById(id));
      }
    });
  };

  const handlePostDislikeIdea = () => {
    dispatch(postDislikeIdea(id)).then(({ payload }) => {
      if (payload.success) {
        dispatch(getIdeaById(id));
      }
    });
  };

  const handlePostCommentIdea = (payload) => {
    dispatch(postCommentIdeas(payload)).then(({ payload }) => {
      if (payload.success) {
        dispatch(getIdeaById(id));
      }
    });
  };
  return (
    <Layout>
      <Spin spinning={getIdeaByIdLoading}>
        <IdeaPage
          idea={idea ? { ...idea, bgColor } : null}
          handlePostLikeIdea={handlePostLikeIdea}
          handlePostDislikeIdea={handlePostDislikeIdea}
          postCommentIdeasLoading={postCommentIdeasLoading}
          handlePostCommentIdea={handlePostCommentIdea}
        />
      </Spin>
    </Layout>
  );
};

export default Idea;
