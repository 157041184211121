import React, { useEffect } from 'react';
import { Col, Image, Row, Space } from 'antd';
import styles from './notification_bar.module.less';
import announcement from '../../images/announcement.svg';
import CustomText from '@/common/CustomText';
import { useDispatch, useSelector } from 'react-redux';
import { get_announcements } from '../../../../features/adminConsole/redux/slice';
import LocalStorage from 'utilities/localStorage';

// const notifications = [
//   'Dedicated, world-class office for Project NEEV was inaugurated in Mumbai',
//   'To-be workshops in progress for all key functional areas',
//   'Townhalls on Project NEEV conducted in Mumbai, Pune, Noida and Hyderabad',
//   'WDFCC Roadshow - Nov 24, 2023',
//   'AMNS Hazira Roadshow - Nov 30, 2023',
//   'Kevadiya Roadshow - Dec 01, 2023',
//   'Lothal Roadshow - Dec 02, 2023',
//   'Anant University Roadshow - Dec 02, 2023'
// ];

const NotificationBar = ({ speed = 50000 }) => {
  const dispatch = useDispatch();
  const {
    announcementsData
    // get_announcementsLoading
  } = useSelector((state) => state.adminConsole);

  //console.log('announcementsData', announcementsData);

  const user = LocalStorage.getItem('neev_login_user');

  const handleGetRequests = () => {
    const payload = {
      // role: user?.user_details?.user_role === 'admin' ? 'admin' : 'user'
    };
    dispatch(
      get_announcements({ role: user?.user_details?.user_role, payload })
    );
  };

  useEffect(() => {
    handleGetRequests();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Row gutter={20} align="middle" className="h-100">
          <Col span={4}>
            <Space>
              <Image alt="notification" src={announcement} preview={false} />
              <CustomText
                // font="bold"
                color="white"
                style={{ fontSize: '13px' }}
              >
                Key Announcements
              </CustomText>
            </Space>
          </Col>
          <Col span={20} className={styles.right_container}>
            <Space
              split={<div className={styles.dot} />}
              size={10}
              className={styles.notification_text_container}
              style={{ '--speed': `${speed}ms` }}
            >
              {announcementsData?.map((o, i) => {
                return (
                  <CustomText
                    key={i}
                    color="white"
                    className={styles.notification_text}
                  >
                    {o?.announcement_name}
                  </CustomText>
                );
              })}
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NotificationBar;
