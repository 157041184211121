import React, { useState } from 'react';
import { Col, Image, Layout, Row, Space } from 'antd';
import styles from './custom_header.module.less';
import neevLogo from '../../images/neev_logo.svg';
//import tataProjectsLogo from '../../images/tata-projects-logo.svg';
import hamburgerMenu from '../../images/hamburger-menu.svg';
import { history } from 'app/history';
import { Link } from 'react-router-dom';
import { menuData, sizeChecker, menuDataAdmin } from 'utilities/helpers'; //getLoggedInUser,
import CustomText from 'common/CustomText';
// import { logout } from 'features/login/redux/slice';
// import { useMsal } from '@azure/msal-react';
// import { useDispatch } from 'react-redux';
import LocalStorage from 'utilities/localStorage';
import LeftDrawer from '../LeftDrawer';
import tataBlue from '../../images/tataBlue.png';

const { Header } = Layout;

const CustomHeader = () => {
  const isMobile = sizeChecker('xs');
  // const { instance } = useMsal();
  // const dispatch = useDispatch();
  // const { loggedIn } = getLoggedInUser();
  const user = LocalStorage.getItem('neev_login_user');

  //console.log('user?.user_details?.user_role', user?.user_details?.user_role);

  const [selectedMenuKey] = useState(
    history.location.pathname.replace('/', '')
  );
  const [openLeftDrawer, setOpenLeftDrawer] = useState(false);

  // const signOut = () => {
  //   instance.logoutRedirect({
  //     account: LocalStorage.homeAccountId,
  //     postLogoutRedirectUri: '/',
  //     mainWindowRedirectUri: '/',
  //     onRedirectNavigate: () => {
  //       dispatch(logout());
  //       return true;
  //     }
  //   });
  // };

  return (
    <div className={styles.container}>
      {/*  <div className={`${styles.top_container}`}>
        <div
          className={`d-flex justify-content-end align-items-center ${styles.inner_container}`}
        >
          <Space size={20}>
             // <CustomText font="medium" className="cursor-pointer">
             //  Viva Engage
           // </CustomText>
            <Link to={`/team`}>
              <CustomText
                font="medium"
                className={`${styles.hoverEffect_style} cursor-pointer`}
              >
                Meet the NEEV Team
              </CustomText>
            </Link>
            <div
              className={`${styles.signin} cursor-pointer`}
              onClick={() => {
                if (loggedIn) {
                  signOut();
                } else {
                  //signIn();
                }
              }}
            >
              <CustomText font="medium">
                {loggedIn ? 'Sign Out' : ''}
              </CustomText>
            </div>
          </Space>
        </div>
      </div> */}
      <Header className={styles.header_container}>
        <Row align="middle" className={styles.row_container}>
          <Col span={1} sm={2} xs={12} className="p-0">
            <span
              onClick={() => history.push('/home')}
              className="cursor-pointer"
            >
              <Image
                alt="tata-logo"
                src={neevLogo}
                preview={false}
                width={53}
                style={{ marginRight: '11px', alignItems: 'center' }}
              />
            </span>
          </Col>
          <Col span={19} sm={19} xs={0} className="p-0">
            <Space align="start" size={14} className={styles.menu_container}>
              {user?.user_details?.user_role === 'user'
                ? menuData.map((item, index) => {
                    const { key, label, desktop } = item;
                    let isSelected = key === selectedMenuKey;

                    if (!desktop) return null;

                    return (
                      <Link
                        key={index}
                        to={`/${key}`}
                        className={`${styles.menu_item}${
                          isSelected ? ` ${styles.selected}` : ''
                        }`}
                      >
                        <CustomText
                          font="bold"
                          // size={14}
                          className={styles.menu_item_label}
                        >
                          {label}
                        </CustomText>
                      </Link>
                    );
                  })
                : user?.user_details?.user_role === 'admin'
                ? menuDataAdmin.map((item, index) => {
                    const { key, label, desktop } = item;
                    let isSelected = key === selectedMenuKey;

                    if (!desktop) return null;

                    return (
                      <Link
                        key={index}
                        to={`/${key}`}
                        className={`${styles.menu_item}${
                          isSelected ? ` ${styles.selected}` : ''
                        }`}
                      >
                        <CustomText
                          font="bold"
                          // size={14}
                          className={styles.menu_item_label}
                        >
                          {label}
                        </CustomText>
                      </Link>
                    );
                  })
                : menuData.map((item, index) => {
                    const { key, label, desktop } = item;
                    let isSelected = key === selectedMenuKey;

                    if (!desktop) return null;

                    return (
                      <Link
                        key={index}
                        to={`/${key}`}
                        className={`${styles.menu_item}${
                          isSelected ? ` ${styles.selected}` : ''
                        }`}
                      >
                        <CustomText
                          font="bold"
                          // size={14}
                          className={styles.menu_item_label}
                        >
                          {label}
                        </CustomText>
                      </Link>
                    );
                  })}
            </Space>
          </Col>

          <Col
            span={4}
            sm={3}
            xs={12}
            className={`p-0 ${styles.right_container}`}
          >
            <div className={styles.right_logo}>
              <Image alt="neev-logo" src={tataBlue} preview={false} />
            </div>
            {isMobile && (
              <div
                className={styles.hamburger_menu}
                onClick={() => setOpenLeftDrawer(true)}
              >
                <Image
                  alt="hamburger-menu"
                  src={hamburgerMenu}
                  preview={false}
                />
              </div>
            )}
          </Col>
        </Row>
        {openLeftDrawer ? (
          <LeftDrawer
            open={openLeftDrawer}
            onClose={() => setOpenLeftDrawer(false)}
          />
        ) : null}
      </Header>
    </div>
  );
};

export default CustomHeader;
