import BannersConcurSAC from './BannersConcurSAC';
//import MediaConsurSAC from './MediaConsurSAC';
import MediaConsurSACNew from './MediaConsurSAC';
//import TabNavigation from './MediaConsurSAC/Demo';

function ConcurSACPage() {
  return (
    <div>
      <BannersConcurSAC />
      <MediaConsurSACNew />
    </div>
  );
}
export default ConcurSACPage;
