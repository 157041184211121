import { useState, useEffect } from 'react';
import React from 'react';
import { Col, Row, Space, Collapse, Image, Card, Divider } from 'antd'; //Collapse,Image
import styles from './knowledge_hub.module.less';
//import arrowLeft from '../../images/arrow-left.svg';
//import arrowRight from '../../images/arrow-right.svg';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import Paper from '@/common/Paper';
//import CustomButton from '@/common/CustomButton';
import { sizeChecker } from 'utilities/helpers';
import SectionTitle from '@/common/SectionTitle';
//import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
// import CustomButton from '@/common/CustomButton';
import LocalStorage from 'utilities/localStorage';
import AnchorButtons from '../AnchorButtons_knowHub';
//import { history } from '@/app/history';
import KeyConcepts from '../keyConcepts';

const { Panel } = Collapse;

import file from '../../images/functions/check-circle-broken.svg';

import fileCheck from '../../images/functions/crop-02.svg';

import zapFast from '../../images/functions/target-04.svg';

import filterFunnel from '../../images/functions/settings-01.svg';

import arrowsUp from '../../images/functions/search-refraction.svg';

import reverseLeft from '../../images/functions/presentation-chart-02.svg';

//import { BoldText } from 'utilities/helpers';
import arrowCircleLeft from '../../images/functions/trend-up-01.svg';

import dataflow from '../../images/functions/lightbulb-05.svg';

import database from '../../images/functions/refresh-cw-01.svg';
import panel1 from '../../images/functions/annotation-x.svg';
import panel2 from '../../images/functions/annotation-check.svg';
//import panel3 from '../../images/functions/annotation-question.svg';
// import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import arrowUp from '../../images/functions/chevron-up.svg';

import arrowDown from '../../images/functions/chevron-down.svg';

// import Alphabet from '../Glossary';
import AlphabetSelector from '../Glossary';
import { RightOutlined } from '@ant-design/icons';
// import { RightOutlined } from '@ant-design/icons';
// import { CaretDownFilled } from '@ant-design/icons';
// import { PlusCircleFilled } from '@ant-design/icons';

//mport CustomButton from '@/common/CustomButton';

// const containerStyle = {
//   display: 'flex',
//   justifyContent: 'space-between' // Adjust this based on your preference
// };

import Mandar from '../../images/learningBytes/Mandar.mp4';
import Pradeep from '../../images/learningBytes/Pradeep.mp4';
import Prasad from '../../images/learningBytes/Prasad.mp4';
import ScreenshotMandar from '../../images/learningBytes/Screenshot Mandar.png';
import ScreenshotPradeep from '../../images/learningBytes/Screenshot Pradeep.png';
import ScreenshotPrasad from '../../images/learningBytes/Screenshot Prasad.png';

const learningBytesVidoes = [
  {
    id: 1,
    video: Mandar,
    thumbnailUrl: ScreenshotMandar,
    title: 'Mandar Thakurdesai - Project Director - NEEV',
    topTitle: 'End-to-End Process in SAP'
  },
  {
    id: 2,
    video: Prasad,
    thumbnailUrl: ScreenshotPrasad,
    title:
      'Prasad Kulkarni - Assistant General Manager, Supply Chain Management',
    topTitle: 'Procurement Process in SAP'
  },
  {
    id: 3,
    video: Pradeep,
    thumbnailUrl: ScreenshotPradeep,
    title: 'Pradeep Konda - General Manager Finance and Accounts',
    topTitle: 'Finance Process in SOA'
  }
];

const data1 = [
  {
    id: 1,
    span: { xs: 24, sm: 8 },
    titleProps: {
      title: 'Project Management',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: [
      {
        image: file,
        text: (
          <div>
            System Driven
            <CustomText size={16.5}>
              {' '}
              project management and controls and moving away from manual ways
              of working
            </CustomText>
          </div>
        )
      },
      {
        image: fileCheck,
        text: 'Better Budgetary Controls and Predictable CTC'
      },
      {
        image: zapFast,
        text: (
          <div>
            {' '}
            Better Inventory Controls
            <CustomText size={16.5}>
              {' '}
              including sub-contract material reconciliation and scrap
            </CustomText>
          </div>
        )
      }
    ]
  },
  {
    id: 2,
    span: { xs: 24, sm: 8 },
    titleProps: {
      title: 'Source to Pay',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: [
      {
        image: filterFunnel,
        text: (
          <div>
            Single Platform
            <CustomText size={16.5}>
              {' '}
              for sourcing, procurement and invoice processing activities
            </CustomText>
          </div>
        )
      },
      {
        image: arrowsUp,
        text: (
          <div>
            Better Visibility{' '}
            <CustomText size={16.5}>
              on Comparative Quotes & Last Purchasing Price
            </CustomText>
          </div>
        )
      },
      {
        image: reverseLeft,
        text: (
          <div>
            Data Driven
            <CustomText size={16.5}>
              {' '}
              Supplier Performance Management
            </CustomText>
          </div>
        )
      }
    ]
  },
  {
    id: 3,
    span: { xs: 24, sm: 8 },
    titleProps: {
      title: 'Finance & Accounting',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: [
      {
        image: arrowCircleLeft,
        text: (
          <div>
            Automation of Manual Process{' '}
            <CustomText size={16}>
              relating to revenue recognition, treasury, financial statement
              reparation, consolidation, reporting, etc.
            </CustomText>
          </div>
        )
      },
      {
        image: dataflow,
        text: (
          <div>
            Single Source of Truth
            <CustomText size={16}>
              {' '}
              - Better visibility on financial performance
            </CustomText>
          </div>
        )
      },
      {
        image: database,
        text: (
          <div>
            Accurate Reporting,{' '}
            <CustomText size={16}>
              better internal controls and compliance
            </CustomText>
          </div>
        )
      }
    ]
  }
  // {
  //   id: 4,
  //   span: { xs: 24, sm: 8 },
  //   titleProps: {
  //     title: 'Travel & Expense Management',
  //     titleColor: 'primary',
  //     align: 'start',
  //     style: { backgroundColor: '#FFF' }
  //   },
  //   data: [
  //     {
  //       image: globe,
  //       text: 'Travel & Reimbursements'
  //     },
  //     {
  //       image: fileSearch,
  //       text: 'Project Imprest'
  //     }
  //   ]
  // },
  // {
  //   id: 5,
  //   span: { xs: 24, sm: 8 },
  //   titleProps: {
  //     title: 'Enterprise Asset Management',
  //     titleColor: 'primary',
  //     align: 'start',
  //     style: { backgroundColor: '#FFF' }
  //   },
  //   data: [
  //     {
  //       image: Plant_and_Machinery,
  //       text: 'Plant & Machinery'
  //     },
  //     {
  //       image: fleetManagement,
  //       text: 'Fleet Management'
  //     }
  //   ]
  // }
  /*  {
    id: 6,
    span: {xs: 24, sm: 8},
    titleProps: {
      title: 'Others',
      titleColor: 'primary',
      align: 'start',
      style: { backgroundColor: '#FFF' }
    },
    data: []
  } */
];

// const data2 = [
//   {
//     id:1,
//     span:{xs: 24, sm: 9},
//     titleProps: {
//       title: 'A',
//       align: 'start',
//       titleColor: 'primary'
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable',
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         header: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable',
//       },
//     ]
//   }
// ]

// const data2 = [
//   {
//     id: 1,
//     span: { xs: 24, sm: 8 },
//     titleProps: {
//       title: 'A',
//       titleColor: 'primary',
//       align: 'start',
//       style: { backgroundColor: '#FFF' }
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//     ]
//   },
//   {
//     id: 2,
//     span: { xs: 24, sm: 8 },
//     titleProps: {
//       title: 'B',
//       titleColor: 'primary',
//       align: 'start',
//       style: { backgroundColor: '#FFF' }
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//     ]
//   },
//   {
//     id: 3,
//     span: { xs: 24, sm: 8 },
//     titleProps: {
//       title: 'C',
//       titleColor: 'primary',
//       align: 'start',
//       style: { backgroundColor: '#FFF' }
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//     ]
//   }
//   {
//     id: 4,
//     span: { xs: 24, sm: 8 },
//     titleProps: {
//       title: 'D',
//       titleColor: 'primary',
//       align: 'start',
//       style: { backgroundColor: '#FFF' }
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//     ]
//   },
//    {
//      id: 5,
//      span: { xs: 24, sm: 8 },
//      titleProps: {
//       title: 'E',
//        titleColor: 'primary',
//        align: 'start',
//        style: { backgroundColor: '#FFF' }
//      },
//      data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//      ]
//    },
//     {
//     id: 6,
//     span: {xs: 24, sm: 8},
//     titleProps: {
//       title: 'F',
//       titleColor: 'primary',
//       align: 'start',
//       style: { backgroundColor: '#FFF' }
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//     ]
//   } ,
//   {
//     id: 7,
//     span: {xs: 24, sm: 8},
//     titleProps: {
//       title: 'G',
//       titleColor: 'primary',
//       align: 'start',
//       style: { backgroundColor: '#FFF' }
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//     ]
//   } ,
//   {
//     id: 8,
//     span: {xs: 24, sm: 8},
//     titleProps: {
//       title: 'H',
//       titleColor: 'primary',
//       align: 'start',
//       style: { backgroundColor: '#FFF' }
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//     ]
//   } ,
//   {
//     id: 9,
//     span: {xs: 24, sm: 8},
//     titleProps: {
//       title: 'I',
//       titleColor: 'primary',
//       align: 'start',
//       style: { backgroundColor: '#FFF' }
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//     ]
//   } ,
//   {
//     id: 10,
//     span: {xs: 24, sm: 8},
//     titleProps: {
//       title: 'J',
//       titleColor: 'primary',
//       align: 'start',
//       style: { backgroundColor: '#FFF' }
//     },
//     data: [
//       {
//         header: 'AA',
//         text: 'Asset Accounting'
//       },
//       {
//         header: 'AP',
//         text: 'Accounts Payable'
//       },
//       {
//         header: 'AWC',
//         text: 'Asset Under Construction'
//       },
//       {
//         herader: 'ABAP',
//         text: 'Advanced Business Application Programming'
//       },
//       {
//         header: 'AR',
//         text: 'Accounts Revisable'
//       }
//     ]
//   }
// ];

const panelStyle = {
  marginRight: '2px', // Adjust the spacing as per your preference
  flex: '1'
};

const processData = {
  cards: [
    {
      key: 1,
      title: 'Project Management',
      subTitles: [
        {
          key: 1,
          title: 'Project Setup​',
          pointers: [
            {
              key: 1,
              points: [
                'Project Creation​',
                'Project Structure (WBS)​',
                'Tend 3 (Baseline)​',
                'Sales Order Creation & Linkage',
                'Project Mobilization (Release WBS)',
                'Tend 3 Risk'
              ]
            }
          ]
        },
        {
          key: 2,
          title: 'Project Planning​',
          pointers: [
            {
              key: 2,
              points: [
                'Control Budget​',
                'WBS Finalization​',
                'Physical Progress Mapping​',
                'Periodic Cost & Billing Planning​'
              ]
            }
          ]
        },
        {
          key: 3,
          title: 'Project Execution​',
          pointers: [
            {
              key: 3,
              points: [
                'Procurement/Material Management​​',
                'Quality Management*​​',
                'Services Management​​',
                'Client Billing​​',
                'Invoice & Payments​',
                'Asset Management*​'
              ]
            }
          ]
        },
        {
          key: 4,
          title: 'Project Monitoring & Control​​',
          pointers: [
            {
              key: 4,
              points: [
                'Period end closing and POCM​​​',
                'Project MIS & Progress Review​​​',
                'CTC​​​',
                'Risk Management​​​',
                'Change Request (Claims & Settlement)​​',
                'Project Dashboards​​'
              ]
            }
          ]
        },
        {
          key: 5,
          title: 'Project Closure​',
          pointers: [
            {
              key: 5,
              points: ['Reconciliation​', 'Financial Closure​']
            }
          ]
        }
      ]
    },
    {
      key: 2,
      title: 'Source to Pay',
      subTitles: [
        {
          key: 1,
          title: 'Master Data Management​​',
          pointers: [
            {
              key: 1,
              points: [
                'Maintain Vendor Master​​',
                'Maintain Material Master​​',
                'Maintain Service Master​​'
              ]
            }
          ]
        },
        {
          key: 2,
          title: 'Vendor Management​​',
          pointers: [
            {
              key: 2,
              points: [
                'Manage Registration and Qualification​',
                'Monitor  Performance​​',
                'Manage grievance​​',
                'Certificate Management​​',
                'Termination​​​'
              ]
            }
          ]
        },
        {
          key: 3,
          title: 'Sourcing​',
          pointers: [
            {
              key: 3,
              points: [
                'Manage RFP​',
                'Reverse Auction​​',
                'Techno-Commercial Evaluation​​​',
                'Quote Evaluation​​​',
                'Final Negotiation​​',
                'Award Proposal​​',
                'LOI/LOA'
              ]
            }
          ]
        },
        {
          key: 4,
          title: 'Procurement​​',
          pointers: [
            {
              key: 4,
              points: [
                'Manage new material / service Creation​​​​',
                'Manage Material Requisition (MR)​​',
                'Manage Indent (PR)​',
                'Manage Purchase Orders (PAN)​​',
                'PAN/PO Amendment​​​',
                'Project Dashboards​​'
              ]
            }
          ]
        },
        {
          key: 5,
          title: 'Contract Management​​',
          pointers: [
            {
              key: 5,
              points: [
                'Contract Management/Rate Contract​',
                'Manage Contract Amendments​​'
              ]
            }
          ]
        },
        {
          key: 6,
          title: 'Receiving​​',
          pointers: [
            {
              key: 6,
              points: [
                'Goods/Service Receipt​​',
                'Creation of GRN/SES​​',
                'Quality Inspection and GRN approval​',
                'Upload Invoice​',
                'Material issue and transfers​',
                'Scrap Disposal​',
                'Gate Pass Process​'
              ]
            }
          ]
        },
        {
          key: 7,
          title: 'Invoice Processing​​',
          pointers: [
            {
              key: 7,
              points: [
                'Invoice Pre-processing (Receiving)​​',
                'Validate Invoices (e.g., partial invoices)​​',
                '3-way matching (SES/GRN, PO & Invoice)​​',
                'Process Non-PO invoices​​'
              ]
            }
          ]
        },
        {
          key: 8,
          title: 'Payments​​',
          pointers: [
            {
              key: 8,
              points: ['Transfer Payments​​​', 'Manage refunds​​​']
            }
          ]
        },
        {
          key: 9,
          title: 'Close​',
          pointers: [
            {
              key: 9,
              points: [
                'AP Reconciliation and compliance​​',
                'Accruals​​',
                'Reporting​'
              ]
            }
          ]
        }
      ]
    },
    {
      key: 3,
      title: 'Finance & Accounting',
      subTitles: [
        {
          key: 1,
          title: 'Accounts Payable​​​',
          pointers: [
            {
              key: 1,
              points: [
                'Invoice Accounting​​',
                'Sub-contractor invoice ​accounting​',
                'Vendor Debit ​notes​​',
                'Vendor payment process​',
                'Vendor LC-BOE​',
                'Vendor BG​',
                'Vendor Financial Services​'
              ]
            }
          ]
        },
        {
          key: 2,
          title: 'Accounts Receivable & Revenue Recognition​​​',
          pointers: [
            {
              key: 2,
              points: [
                'Customer Invoice Accounting​​',
                'Debit note/Credit Note​​',
                'Receipts accounting​​',
                'Scrap Accounting​​​',
                'Revenue Recognition​'
              ]
            }
          ]
        },
        {
          key: 3,
          title: 'Fixed ​Assets Accounting​',
          pointers: [
            {
              key: 3,
              points: [
                'Fixed Assets capitalization​​',
                'Depreciation calculation and posting​​​',
                'Fixed Assets Retirement Process​​​​'
              ]
            }
          ]
        },
        {
          key: 4,
          title: 'Master Data Management',
          pointers: [
            {
              key: 4,
              points: ['GL Creation​​​​​', 'Managing Bank Accounts​​​']
            }
          ]
        },
        {
          key: 5,
          title: 'GL-Transaction Processing​​',
          pointers: [
            {
              key: 5,
              points: [
                'Provisions & Accruals​​',
                'Employee imprest & expenses​​​',
                'Payroll accounting​'
              ]
            }
          ]
        },
        {
          key: 6,
          title: 'Period End Close​',
          pointers: [
            {
              key: 6,
              points: [
                'Period Closing ​​​',
                'Inter-unit & inter-company Reconciliation​​',
                'Forex Revaluation​​',
                'Reclassifications​​',
                'Material issue and transfers​',
                'Scrap Disposal​',
                'Gate Pass Process​'
              ]
            }
          ]
        },
        {
          key: 7,
          title: 'Statutory ​Reporting & MIS​​',
          pointers: [
            {
              key: 7,
              points: [
                'Preparation and consolidation of financial statements​​',
                'MIS (incl. profitability statement)​​​',
                '3-way matching (SES/GRN, PO & Invoice)​​',
                'Process Non-PO invoices​​'
              ]
            }
          ]
        },
        {
          key: 8,
          title: 'Treasury​',
          pointers: [
            {
              key: 8,
              points: [
                'Limit management (FB & NFB)​',
                'Forex management​',
                'WCS​',
                'Commercial Paper Process​',
                'NCD issuance​',
                'BG & LC process​'
              ]
            }
          ]
        },
        {
          key: 9,
          title: 'Taxation​',
          pointers: [
            {
              key: 9,
              points: [
                'TDS and TCS calculation and posting​​​',
                'TDS Return preparation​',
                'Input GST reconciliation​',
                'GST returns –Monthly [GSTR1, GSTR6 and GSTR 3B]​'
              ]
            }
          ]
        },
        {
          key: 10,
          title: 'Strategic planning, budget & forecast​',
          pointers: [
            {
              key: 10,
              points: ['5-year plan​​​', 'AOP​', 'BRM/Forecasting​']
            }
          ]
        }
      ]
    }
  ]
};

const data = [
  {
    id: 1,
    span: { xs: 24, sm: 9 },
    titleProps: {
      title: 'SAP S/4 HANA CORE ERP',
      align: 'start',
      titleColor: 'primary'
    },
    data: [
      {
        text: 'Enterprise Portfolio & Project Management (EPPM)',
        description:
          'Consists of 3 tools- PS, CPM and PPM which helps in assisting the end-end project management journey from Bidding to Project Planning, Structuring, Execution, Monitoring and Controlling. Provides a centralized system for Managing Resources, Task Schedules. Budgeting and Reporting on all project outcomes till closure​'
      },
      {
        text: 'Project System (PS)',
        description:
          'Integrated project management tool used for managing the end-to-end project life cycle starting from Planning, Budgeting, Execution and Monitoring till closure​'
      },
      {
        text: 'Commercial Project Management (CPM)',
        description:
          'Bidding and Estimation, Risk and Issue Management, Monitoring and Controlling of projects (Control Budget/ CTC) by providing various reporting views​'
      },
      {
        text: 'Portfolio Project Management (PPM)',
        description:
          'Manage multiple projects in a single portfolio or multiple portfolios with multiple projects​'
      },
      {
        text: 'Sales & Distribution (SD) - Billing',
        description:
          'SAP Sales & Distribution Module allows TPL to store and manage customer related billings, including the amounts of transactions and methods of payment​'
      },
      {
        text: 'Plant Maintenance & Asset Management',
        description:
          'Consists of key activities to include Inspection, Notifications, Corrective and Preventive Maintenance​'
      },
      {
        text: 'Production Planning (PP)',
        description:
          'Creating feasible production plans and schedules that optimizes resources, improves planning results through Material Requirement Plan (MRP) and Demand Driven Replenishment​'
      },
      {
        text: 'Material Management (MM)',
        description:
          'Purchasing, Goods Receiving, Material Storage, consumption-based Planning and Inventory​'
      },
      {
        text: 'Finance Accounting & Controlling (FICO)',
        description: (
          <ol>
            <li>
              {`SAP Finance and Controlling (FICO) is a central component for the
            finance module of the ERP solution from SAP`}
            </li>
            <li>
              {`FI consists of General Ledger, Accounts Payable, Accounts
              Receivable, Bank Accounting`}
            </li>
            <li>
              {`CO consists of Profit Center Accounting, Overhead Cost,
            Controlling, Product Cost Controlling`}
            </li>
          </ol>
        )
      },

      {
        text: 'Treasury',
        description:
          'Manage debt and investments to optimize borrowing interest calculations, investment costs, and capital structure with real-time insights​'
      },
      {
        text: 'Master Data Governance (MDG)',
        description:
          'Master Data Management and Governance (MDG) for Vendor, Material, Customer etc. to simplify enterprise data management, increase data quality & accuracy, consolidate Master Data and ensure centralized Data Governance​'
      },
      {
        text: 'Vendor Invoice Management (VIM)',
        description:
          'Manage Vendor Invoices through automated program/process by scanning the vendor invoices and fetching the invoice data automatically'
      },
      {
        text: 'Governance, Risk & Compliance (GRC)',
        description: (
          <ul>
            Managing access controls and authorization for users across SAP
            modules, Segregation of Duty (SOD) and Delegation of Authority
            (DOA). Following sub modules collectively constitute the GRC
            modules:
            <li>{`Access Risk Analysis - identify and detect access violations in the entire enterprise`}</li>
            <li>
              Business Role Management - create system - independent virtual
              roles to simplify the technical role assignment in the backend
              system
            </li>{' '}
            <li>
              Emergency Access Management - allows users to perform emergency
              activities outside his or her standard role{' '}
            </li>{' '}
            <li>
              {' '}
              Access Request Management - allows users can request access
              through a workflow - based module
            </li>
          </ul>
        )
      }
    ]
  },
  {
    id: 2,
    span: { xs: 24, sm: 15 },
    subData: [
      {
        id: 21,
        span: { xs: 24, sm: 12 },
        titleProps: {
          title: 'SAP ARIBA',
          align: 'start',
          titleColor: 'primary'
        },
        data: [
          {
            text: 'Strategic Sourcing',
            description:
              'Creation of RFP for Procurement, Vendor responses, Reverse Auction & Bidding by Vendors'
          },
          {
            text: 'Supplier Lifecycle and Performance (SLP)',
            description:
              'Onboarding of Suppliers/ Vendors, provide a Self-service Platform to Vendors and maintain Vendor data.​Evaluation of Supplier / Vendor Performance, Setting Preferences for given commodity, etc​'
          },
          {
            text: 'Commerce Automation',
            description:
              ' Supplier Management, Spend Control, Automated and Standardized Workflows and Configurable Business Rules and Network Validation​'
          }
        ]
      },
      {
        id: 22,
        span: { xs: 24, sm: 12 },
        titleProps: {
          title: 'SAP SAC & SAP CONCUR',
          align: 'start',
          titleColor: 'primary'
        },
        data: [
          {
            text: 'Travel and Expense Management',
            description:
              'Automates and integrates travel, expense, and invoice management. Simplify spend management and help TPL employees operate in more efficient and cost-effective ways. This will be replaced by Happay​'
          },
          {
            text: 'Annual Operating Plan',
            description:
              'End-to-end cloud solution that brings together business intelligence, augmented analytics, predictive analytics, and enterprise planning in a single system.Covers a range of planning operations like scheduling tasks, kickstarting forecasts with predictive features, building custom planning applications, carrying out data entry and version management, and writing powerful scripted calculations​'
          }
        ]
      },
      {
        id: 24,
        span: { xs: 24, sm: 12 },
        titleProps: {
          title: 'SAP ANALYTICS SUITE',
          align: 'start',
          titleColor: 'primary'
        },
        data: [
          {
            text: 'Dashboards and Reporting',
            description:
              'Store and manage dashboards and reports, control document lifecycle, manage document access and provide document search'
          }
        ]
      }
    ]
  }
];

function getCustomTextColor(panelIndex, itemIndex) {
  //console.log('panelIndex, itemIndex', panelIndex, itemIndex);
  if (panelIndex === 0 && (itemIndex === 0 || itemIndex === 1)) {
    return 'white';
  } else if (panelIndex === 1 && itemIndex === 0) {
    return 'white';
  } else if (panelIndex === 2 && (itemIndex === 0 || itemIndex === 1)) {
    return 'white';
  } else {
    return 'black'; // Set default color if none of the conditions match
  }
}
const panelData = [
  {
    key: 1,
    image: panel1,
    header: 'To be Decommissioned',
    content: [
      { key: 44, label: '1', value: 'Existing System​' },
      { key: 45, label: '2', value: 'Future State Application​' },
      { key: 0, label: '1', value: 'Happay (Employee Expenses & Imprest)' },
      { key: 1, label: '2', value: 'SAP Concur' },
      { key: 2, label: '3', value: 'A2P' },
      { key: 3, label: '4', value: 'SAP S4H' },
      { key: 4, label: '5', value: 'Power BI' },
      { key: 5, label: '6', value: 'SAP SAC' },
      { key: 6, label: '7', value: 'Host to Host' },
      { key: 7, label: '8', value: 'SAP iDOC' },
      { key: 8, label: '9', value: 'IIR & Logistic portal' },
      { key: 9, label: '10', value: 'VIM &S4H' },
      { key: 10, label: '11', value: 'LCBG portal' },
      { key: 11, label: '12', value: 'SAP Treasury Management' },
      { key: 12, label: '13', value: 'WEBAdi' },
      { key: 13, label: '14', value: 'SAP' },
      { key: 14, label: '15', value: 'Procure Tiger' },
      { key: 15, label: '16', value: 'Ariba - Sourcing' },
      { key: 16, label: '17', value: 'Vendor Portal' },
      { key: 17, label: '18', value: 'Ariba - SLP' },
      { key: 18, label: '19', value: 'MIDAS' },
      { key: 19, label: '20', value: 'SAP MDG' },
      { key: 20, label: '21', value: 'LOI/LOA Portal' },
      { key: 21, label: '22', value: 'SAP S4 HANA' },
      { key: 22, label: '23', value: 'T-Matrix (Maximo)*' },
      { key: 23, label: '24', value: 'SAP S4 HANA and BTP (Proposed*)' },
      { key: 24, label: '25', value: 'Tactive' },
      { key: 25, label: '26', value: 'SAP EAM' }
    ]
  },
  {
    key: 2,
    image: panel2,
    header: 'To Continue',
    content: [
      { key: 0, label: 'Single Data 0', value: 'Existing System​' },
      { key: 1, label: 'Single Data 1', value: 'Moglix/Amazon' },
      { key: 2, label: 'Single Data 2', value: 'Primavera' },
      { key: 3, label: 'Single Data 3', value: 'MS Projects' },
      { key: 4, label: 'Single Data 4', value: 'Wrench' },
      { key: 5, label: 'Single Data 5', value: 'Candy' },
      { key: 6, label: 'Single Data 6', value: 'DG TDS-EY' },
      {
        key: 7,
        label: 'Single Data 7',
        value: 'NFA portal (for Non ERP Process)'
      },
      { key: 8, label: 'Single Data 8', value: 'Gate Pass' },
      { key: 9, label: 'Single Data 9', value: 'Legatrix' },
      { key: 10, label: 'Single Data 10', value: 'Insurance Portal' }

      // Add more data objects as needed
    ]
  }
];
// const key_terminology_data = [
//   {
//     id: 1,
//     label: (
//       <>
//         <span>BPML</span>
//         <div style={{ fontSize: '14px' }}>(Business Process Masters List)</div>
//       </>
//     ),
//     text: 'A master list of business processes that have been considered in scope for the SAP ERP implementation'
//   },
//   {
//     id: 2,
//     label: (
//       <>
//         <span>SDD</span>
//         <div style={{ fontSize: '14px' }}>(Solution Design Document)</div>
//       </>
//     ),
//     text: 'A document that captures the in scope business processes step by step and serves as an input for development/configuration in the ERP'
//   },
//   {
//     id: 3,
//     label: 'Show & Tell',
//     text: "A series of workshops for SME's to get together and evaluate the look and feel of the ERP to suggest any user experience enhancements for their business processes"
//   },
//   {
//     id: 4,
//     label: 'SAP SAC Planning',
//     text: 'AA system which will be implemented for creating the Annual Operating Plans'
//   },
//   {
//     id: 5,
//     label: 'Concur',
//     text: 'An expense management system which will be implemented as a part of Project NEEV'
//   },
//   {
//     id: 6,
//     label: (
//       <>
//         <span>KDS</span>
//         <div style={{ fontSize: '14px' }}>(Key Data Structure)</div>
//       </>
//     ),
//     text: 'Key Data required for configuration of business processes in SAP'
//   },
//   {
//     id: 7,
//     label: 'Show & Tell Wave 2',
//     text: 'The sessions in Wave 2 will cover cross-functional and integration points between different modules'
//   }
// ];

// const faqs = [
//   {
//     key: '1',
//     header: 'What is Lorem Ipsum?',
//     text: 'Lorem ipsum lorem ipsum lorem ipsum'
//   },
//   {
//     key: '2',
//     header: 'Why do we use it?',
//     text: 'Lorem ipsum lorem ipsum lorem ipsum'
//   },
//   {
//     key: '3',
//     header: 'Where does it come from?',
//     text: 'Lorem ipsum lorem ipsum lorem ipsum'
//   },
//   {
//     key: '4',
//     header: 'Where can I get some?',
//     text: 'Lorem ipsum lorem ipsum lorem ipsum'
//   }
// ];

const KnowledgeHubPage = () => {
  const isMobile = sizeChecker('xs');

  const goto = LocalStorage.getItem('knowledgeHub_redirect');
  // const [activeKey, setActiveKey] = useState(1);
  // const [selectedTitle, setSelectedTitle] = useState(null);

  // const handleTitleClick = (title) => {
  //   setSelectedTitle(title);
  // };

  const [activePanel, setActivePanel] = useState(null);

  const handlePanelChange = (key) => {
    setActivePanel(key);
  };

  // const letters = Array.from({ length: 26 }, (_, index) =>
  //   String.fromCharCode(65 + index)
  // );
  useEffect(() => {
    if (goto === 'false') {
      window.scrollTo(0, 0); // Scroll to the top
    }
  }, []);

  useEffect(() => {
    if (goto === 'true') {
      window.scrollTo(0, document.body.scrollHeight);
      LocalStorage.setItem('knowledgeHub_redirect', JSON.stringify('false'));
    }
  }, [goto]);

  return (
    <div className={styles.container}>
      <CustomContainer containerClassname={styles.top_container}>
        <CustomText size={isMobile ? 26 : 24} color="white" lineHeight="normal">
          {`Access Extensive information on project NEEV's SAP implementation and
        its associated components`}
        </CustomText>
      </CustomContainer>

      <CustomContainer
        id="SAP_Modules"
        title="SAP Modules"
        containerClassname={styles.sap_container}
      >
        <Space direction="vertical" size={10}>
          <SectionTitle title="Know Your SAP Modules" />
          <CustomText size={18} color={2}>
            The different functional modules across sourcing, procurement,
            material management, planning, invoicing and payments will now work
            as one coherent whole affording us the possibility to do real-time
            dashboards on critical key performance indicators.
          </CustomText>
          <Row gutter={[20, 20]}>
            {data.map((item) => {
              const { id, span, titleProps, data, subData } = item;

              return (
                <Col key={id} {...span}>
                  {subData?.length ? (
                    <Row gutter={[20, 20]}>
                      {subData.map((sub_item) => {
                        const { id, span, titleProps, data } = sub_item;

                        return (
                          <Col key={id} {...span}>
                            <Paper titleProps={titleProps} data={data} />
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <>
                      <Paper titleProps={titleProps} data={data} />
                    </>
                  )}
                </Col>
              );
            })}
          </Row>
        </Space>
      </CustomContainer>
      <CustomContainer
        id="process"
        title="Process"
        containerClassname={styles.container1}
      >
        <Space direction="vertical" size={20}>
          <SectionTitle title="Processes in SAP" />
        </Space>
        <Row gutter={16}>
          {processData?.cards?.map((cardData, cardIndex) => (
            <Col className="mt-3" key={cardIndex} span={24}>
              <Card
                hoverable
                className={styles.processCard}
                title={
                  <CustomText font="bold" size={20} color={'black'}>
                    {cardData.title}
                  </CustomText>
                }
                bordered={true}
              >
                <div className={styles.scrollableContent}>
                  {/* {cardData.key === 3 && (
                    <>
                      <div className={styles.smallRectangle}>Hi</div>
                      <div className={styles.largeRectangle}>Hello</div>
                    </>
                  )} */}
                  {cardData.subTitles.map((subTitle, subTitleIndex) => (
                    <React.Fragment key={subTitleIndex}>
                      <div className={styles.subtitleContainer}>
                        <Collapse
                          className={`${styles.processCollapse} ${styles.commonCollapse}`}
                          ghost
                          key={`${cardData.key}-${subTitle.key}`}
                          activeKey={activePanel}
                          onChange={handlePanelChange}
                          expandIcon={() => null}
                        >
                          <Panel
                            header={
                              <>
                                <Space
                                  className={`${
                                    cardData.key === 2
                                      ? styles.triangle_collapse2
                                      : cardData.key === 3
                                      ? styles.triangle_collapse3
                                      : styles.triangle_collapse
                                  } text-center df-jc-ac`}
                                >
                                  {/* <div className={styles.arrow_right}></div> */}
                                  <div
                                    className={'cs-bold'}
                                    size={cardData.key === 1 ? 12 : 11}
                                  >
                                    {subTitle.title}
                                  </div>
                                </Space>
                              </>
                            }
                            key={`${cardData.key}-${subTitle.key}`}
                          >
                            {subTitle.pointers[0].points.map((point, index) => (
                              <div key={index}>
                                <div className="df-jc-ac" key={index}>
                                  <CustomText size={10.5} color={'black'}>
                                    {' '}
                                    {point}
                                  </CustomText>
                                </div>
                                <Divider className={styles.divider} />
                              </div>
                            ))}
                          </Panel>
                        </Collapse>
                      </div>
                      <div
                        className={`${styles.rightArrowWrapper} ${
                          subTitleIndex !== cardData.subTitles.length - 1
                            ? styles.rightArrow
                            : ''
                        }`}
                      >
                        {subTitleIndex !== cardData.subTitles.length - 1 ? (
                          <RightOutlined />
                        ) : null}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </CustomContainer>
      {/* <CustomContainer
        title="Key terminology"
        containerClassname={styles.key_terminology_container}
      >
        {key_terminology_data.map((item) => {
          const { id, label, text } = item;

          if (id !== activeKey) return null;

          return (
            <Row key={id} gutter={[20, 10]} align="middle" justify="center">
              <Col xs={24} sm={7} className="text-center">
                <CustomText
                  font="bold"
                  size={isMobile ? 26 : 48}
                  color={10}
                  letterSpacing={isMobile ? 0 : -0.96}
                  lineHeight="normal"
                >
                  {label}
                </CustomText>
              </Col>
              <Col xs={24} sm={14}>
                <div className={styles.middle_container}>
                  <CustomText
                    font="medium"
                    size={isMobile ? 20 : 26}
                    color={10}
                    lineHeight="normal"
                  >
                    {text}
                  </CustomText>
                </div>
              </Col>
              <Col xs={24} sm={3}>
                <Space size={0}>
                  <CustomButton
                    type="text"
                    onClick={() => {
                      setActiveKey((prevState) => prevState - 1);
                    }}
                    disabled={activeKey == 1}
                  >
                    <Image alt="arrow-left" src={arrowLeft} preview={false} />
                  </CustomButton>
                  <CustomButton
                    type="text"
                    onClick={() => {
                      setActiveKey((prevState) => prevState + 1);
                    }}
                    disabled={activeKey == key_terminology_data.length}
                  >
                    <Image alt="arrow-right" src={arrowRight} preview={false} />
                  </CustomButton>
                </Space>
              </Col>
            </Row>
          );
        })}
      </CustomContainer> */}
      {/* <CustomContainer title="FAQs" containerClassname={styles.faqs_container}>
        <Space direction="vertical" size={20} className="w-100">
          <CustomText font="bold" size={28} color={10} letterSpacing={-0.56}>
            Frequently Asked Questions
          </CustomText>
          <Space direction="vertical" size={16} className="w-100">
            {faqs.map((item) => {
              const { key, header, text } = item;

              return (
                <Collapse
                  key={key}
                  accordion={true}
                  defaultActiveKey={['1']}
                  expandIconPosition={'end'}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <MinusCircleOutlined
                        style={{ color: '#98A2B3', fontSize: 20 }}
                      />
                    ) : (
                      <PlusCircleOutlined
                        style={{ color: '#98A2B3', fontSize: 20 }}
                      />
                    )
                  }
                  className={styles.custom_collapse}
                >
                  <Panel
                    key={key}
                    header={
                      <CustomText font="medium" size={18} color={10}>
                        {header}
                      </CustomText>
                    }
                    className={styles.custom_panel}
                  >
                    <CustomText font="medium" size={16}>
                      {text}
                    </CustomText>
                  </Panel>
                </Collapse>
              );
            })}
          </Space>
        </Space>
      </CustomContainer> */}
      <CustomContainer
        id="keybenefits"
        title="Key Benefits"
        containerClassname={styles.sap_container}
      >
        <Space direction="vertical" size={10}>
          <Space direction="vertical" size={20}>
            <SectionTitle title="Key Benefits" />
            {/* <CustomText size={18} color={2}>
              The new ERP seeks to structurally overhaul key functions and
              processes across the company with simplicity and standardisation
              as twin objectives. This will unlock greater collaboration,
              productivity and visibility.
            </CustomText> */}
          </Space>
          <Row gutter={[10, 10]}>
            {data1.map((item) => {
              const { id, span, titleProps, data } = item;

              return (
                <Col key={id} {...span}>
                  <Paper titleProps={titleProps} data={data} />
                </Col>
              );
            })}
          </Row>
        </Space>
        <div className="mt-5 df-jc-ac">
          <CustomText size={14} font="bold" color={1}>
            Single integrated system offering standardized processes catering to
            all the businesses driving efficiencies and elevating operations
          </CustomText>
        </div>
      </CustomContainer>

      <CustomContainer
        id="Key_Concepts"
        title="Key Concepts"
        containerClassname={styles.glossarycontainer}
      >
        <Space direction="vertical" size={20}>
          <SectionTitle title="Key Concepts" />
        </Space>{' '}
        <KeyConcepts />
      </CustomContainer>
      <CustomContainer
        id="Learning_Bytes"
        title="Learning Bytes"
        containerClassname={styles.learningBytes_container}
      >
        <Space direction="vertical" size={10}>
          <SectionTitle title="Learning Bytes" />
          <CustomText size={18} color={2}>
            Explore a series of informative videos offering insights into
            project management, procurement and finance processes.
          </CustomText>
          <>
            <Row className="mt-4" gutter={[24, 48]}>
              {learningBytesVidoes?.map((item) => (
                <Col key={item.id} xl={8}>
                  <CustomText font="bold" size={14} color={1}>
                    {item.topTitle}
                  </CustomText>
                  <video
                    // poster={item.thumbnailUrl}
                    className={styles.custom_video}
                    controls={true}
                    src={item.video}
                    width="100%"
                    height="85%"
                  />
                  <CustomText font="bold" size={11} color={1} className="mb-3">
                    {item.title}
                  </CustomText>
                </Col>
              ))}
            </Row>
          </>
        </Space>
      </CustomContainer>

      <CustomContainer
        id="bespoke"
        title="Bespoke"
        //containerClassname={styles.sap_container}
        containerClassname={styles.glossarycontainer}
      >
        <Space direction="vertical" size={20}>
          <SectionTitle title="Bespoke Applications" />
        </Space>
        <Row>
          {panelData.map((panel, panelIndex) => (
            <Col xs={24} xl={12} key={panelIndex}>
              <Collapse
                key={panelIndex}
                activeKey={activePanel}
                onChange={handlePanelChange}
                expandIcon={() => null}
                className={`${styles.collapse_bottom} mt-3`}
                ghost
                style={panelStyle}
              >
                <Panel
                  className={`${styles.bespoke_panel} ${
                    styles[`panel_${panelIndex + 1}`]
                  }`}
                  header={
                    <Space className={`${styles.panel_header} df-jc-ac`}>
                      <Image
                        className={`${styles.left_Panel_image} mr-1`}
                        src={panel.image}
                        preview={false}
                      />
                      <CustomText
                        size={isMobile ? 12 : 16.5}
                        font="bold"
                        color={'white'}
                      >
                        {panel.header}
                      </CustomText>
                      {activePanel?.includes(panelIndex?.toString()) ? (
                        <Image src={arrowUp} preview={false} />
                      ) : (
                        <Image src={arrowDown} preview={false} />
                      )}
                    </Space>
                  }
                  key={panelIndex.toString()}
                >
                  <Row gutter={[8, 8]}>
                    {panel?.content?.map((item, itemIndex) => (
                      <Col
                        // xs={24}
                        span={panel?.key === 2 ? 24 : 12}
                        key={itemIndex}
                      >
                        <div
                          className={`df-jc-ac ${
                            styles[`content_${panelIndex + 1}_${itemIndex + 1}`]
                          }`}
                        >
                          <CustomText
                            font="bold"
                            size={12}
                            color={getCustomTextColor(panelIndex, itemIndex)}
                          >
                            {item?.value}
                          </CustomText>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          ))}
        </Row>
      </CustomContainer>

      <CustomContainer
        id="Glossary"
        title="Glossary"
        //containerClassname={styles.glossarycontainer}
        containerClassname={styles.sap_container}
      >
        <Space direction="vertical" size={20}>
          <SectionTitle title="Glossary" />
        </Space>
        <div>
          <AlphabetSelector />
        </div>
      </CustomContainer>
      {/* <div className="mt-3 df-jc-ac">
        {' '}
        <CustomText font="bold" size={15} color={2}>
          * Emerging view based on discussion; to be finalized
        </CustomText>
      </div> */}
      <AnchorButtons />
    </div>
  );
};

export default KnowledgeHubPage;
