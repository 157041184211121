import React from 'react';
import { Image, Space } from 'antd';
import CustomContainer from '@/common/CustomContainer';
import CustomText from '@/common/CustomText';
import styles from './share.module.less';
import CustomButton from '@/common/CustomButton';

const Share = ({ bgTitle, title, description, btnText }) => {
  return (
    <CustomContainer title={bgTitle} containerClassname={styles.container}>
      <div className={styles.neev_logo}>
        <Image alt="neev_logo" src={'images/neev_logo.svg'} preview={false} />
      </div>
      <Space direction="vertical" size={8} align="center" className="w-100">
        <Space direction="vertical" size={8} align="center" className="w-100">
          <CustomText
            font="bold"
            size={48}
            color="primary"
            letterSpacing={-0.96}
          >
            {title}
          </CustomText>
          <CustomText font="medium" size={26} color={2}>
            {description}
          </CustomText>
        </Space>
        <CustomButton type="primary" ghost font="bold">
          {btnText}
        </CustomButton>
      </Space>
    </CustomContainer>
  );
};

export default Share;
