import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { message } from 'antd';
import {
  ambassadors_lists,
  sponsorAndCoSponsor_lists,
  champions_lists,
  agents_lists
} from './api';
//import { history } from '@/app/history';

const initialState = {
  sponsorAndCoSponsorLoading: false,
  ambassadorsListsLoading: false,
  championsListsLoading: false,
  agentsListsLoading: false,
  ambassadorsLists: [],
  sponsorAndCoSponsorList: [],
  championsLists: [],
  agentsLists: [],
  paginationSponsor: null,
  paginationAmbassadors: null,
  paginationChampions: null,
  paginationAgents: null
};

const actions = {
  GET_AMBASSADORS_LISTS: 'getAmbassadorsLists/GET_AMBASSADORS_LISTS',
  GET_SPONSOR_AND_COSPONSOR_LISTS:
    'getSponsorAndCoSponsorList/GET_SPONSOR_AND_COSPONSOR_LISTS',
  GET_CHAMPIONS_LISTS: 'getChampionsLists/GET_CHAMPIONS_LISTS',
  GET_AGENTS_LISTS: 'getAgentsLists/GET_AGENTS_LISTS'
};

export const getAmbassadorsLists = createAsyncThunk(
  actions.GET_AMBASSADORS_LISTS,
  async (payload) => {
    const response = await ambassadors_lists(payload);

    return response;
  }
);
export const getSponsorAndCoSponsorList = createAsyncThunk(
  actions.GET_SPONSOR_AND_COSPONSOR_LISTS,
  async (payload) => {
    const response = await sponsorAndCoSponsor_lists(payload);
    return response;
  }
);
export const getChampionsLists = createAsyncThunk(
  actions.GET_CHAMPIONS_LISTS,
  async (payload) => {
    const response = await champions_lists(payload);

    return response;
  }
);
export const getAgentsLists = createAsyncThunk(
  actions.GET_AGENTS_LISTS,
  async (payload) => {
    const response = await agents_lists(payload);

    return response;
  }
);

export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAmbassadorsLists.pending, (state) => {
        state.ambassadorsListsLoading = true;
      })
      .addCase(getAmbassadorsLists.fulfilled, (state, action) => {
        state.ambassadorsListsLoading = false;
        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          state.ambassadorsLists = data ? data : [];
          state.paginationAmbassadors = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.ambassadorsLists = [];
          // message.error(msg);
        }
      })
      .addCase(getAmbassadorsLists.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.ambassadorsListsLoading = false;
        //message.error(msg);
        console.log(msg);
      });
    builder
      .addCase(getSponsorAndCoSponsorList.pending, (state) => {
        state.sponsorAndCoSponsorLoading = true;
      })
      .addCase(getSponsorAndCoSponsorList.fulfilled, (state, action) => {
        state.sponsorAndCoSponsorLoading = false;
        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          state.sponsorAndCoSponsorList = data ? data : [];
          state.paginationSponsor = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.sponsorAndCoSponsorList = [];
          //message.error(msg);
        }
      })
      .addCase(getSponsorAndCoSponsorList.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.sponsorAndCoSponsorLoading = false;
        //message.error(msg);
        console.log(msg);
      });

    builder
      .addCase(getChampionsLists.pending, (state) => {
        state.championsListsLoading = true;
      })
      .addCase(getChampionsLists.fulfilled, (state, action) => {
        state.championsListsLoading = false;
        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          state.championsLists = data ? data : [];
          state.paginationChampions = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.championsLists = [];
          //message.error(msg);
        }
      })
      .addCase(getChampionsLists.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.championsListsLoading = false;
        //message.error(msg);
        console.log(msg);
      });

    builder
      .addCase(getAgentsLists.pending, (state) => {
        state.agentsListsLoading = true;
      })
      .addCase(getAgentsLists.fulfilled, (state, action) => {
        state.agentsListsLoading = false;
        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          state.agentsLists = data ? data : [];
          state.paginationAgents = pagination ? pagination : null;
        } else {
          if (msg === 'Please sign in') {
            history.location.pathname.replace('/', '');
            localStorage.clear();
          }
          state.agentsLists = [];
          //message.error(msg);
        }
      })
      .addCase(getAgentsLists.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.agentsListsLoading = false;
        //message.error(msg);
        console.log(msg);
      });
  }
});

export default networksSlice.reducer;
