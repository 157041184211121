import IdeaCreatePage from '../components/IdeaCreatePage';
import Layout from 'common/CustomLayout';
import { useParams } from 'react-router-dom';

const IdeaCreate = () => {
  const params = useParams();
  const id_type = params;

  return (
    <Layout>
      <IdeaCreatePage id_type={id_type?.id} />
    </Layout>
  );
};

export default IdeaCreate;
