import TeamPage from './components/TeamPage';
import Layout from 'common/CustomLayout';
import { TabTitle } from 'utilities/helpers';

const Team = () => {
  TabTitle('Neev - Meet the NEEV Team');
  return (
    <Layout>
      <TeamPage />
    </Layout>
  );
};

export default Team;
